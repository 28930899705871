import {Platform} from 'react-native';

const images = [
  ['zenpark-pro-solutions', require('./zenpark-pro-solutions.png')],
  ['zenpark-pro', require('./zenpark-pro.png')],
  ['zenpark-flex-logo', require('./zenpark-flex-logo.png')],
  ['zenpark-illustration', require('./zenpark-illustration.png')],
  ['onBoarding-slide1', require('./onBoarding-slide1.png')],
  ['onBoarding-slide2', require('./onBoarding-slide2.png')],
  ['onBoarding-slide3', require('./onBoarding-slide3.png')],
  ['onBoarding-slide4', require('./onBoarding-slide4.png')],
];

const webImages: Record<string, number> = images.reduce((prev, image) => {
  return {
    ...prev,
    [image[0]]: image[1].default,
  };
}, {});

const mobileImages: Record<string, number> = images.reduce((prev, image) => {
  return {
    ...prev,
    [image[0]]: image[1],
  };
}, {});

export default Platform.OS === 'web' ? webImages : mobileImages;
