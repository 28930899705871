import * as React from 'react';
import {StyleSheet, Linking} from 'react-native';

import Theme from '@config/theme';

import TextButton from '@components/TextButton';

interface Props {
  location: string | null | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}
const MobileAllocationAddress: React.FC<Props> = ({
  location,
  latitude,
  longitude,
}) => {
  return (
    <>
      <TextButton
        style={Styles.parkingLocation}
        align="center"
        onPress={() =>
          Linking.openURL(
            `https://www.google.com/maps/@${latitude},${longitude}?q=${location}`,
          )
        }>
        {location}
      </TextButton>
    </>
  );
};

const Styles = StyleSheet.create({
  parkingLocation: {
    color: Theme.colors.white,
    textDecorationLine: 'underline',
  },
});

export default MobileAllocationAddress;
