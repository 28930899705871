import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';
import {
  persistStore,
  persistReducer,
  PersistConfig,
  createMigrate,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import appConfig from '../../app.json';
import AppConfig from '../config/appConfig';
import i18next from '../services/i18n';
import {reduxEnhancer} from '../services/logger/sentry';
import AppStorage from '../services/storage';

import {migrations} from './migrations';
import AppReducer from './slices/App';
import AuthReducer from './slices/Auth';
import MemberReducer from './slices/Member';
import PlanningReducer from './slices/Planning';

const rootReducer = combineReducers({
  App: AppReducer,
  Auth: AuthReducer,
  Member: MemberReducer,
  Planning: PlanningReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const persistanceConfig: PersistConfig<RootState> = {
  key: 'DFO_store',
  version: appConfig.storeVersion,
  storage: AppStorage,
  blacklist: ['Planning'],
  migrate: createMigrate(migrations, {
    debug: AppConfig.currentEnv !== 'production',
  }),
};

const persistedRootReducer = persistReducer(persistanceConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: getDefaultMiddleware => {
    const middlewares = [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ];

    if (AppConfig.shouldLogRedux) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const {logger} = require('redux-logger');

      middlewares.push(logger);
    }
    return middlewares;
  },
  enhancers: [reduxEnhancer],
});
export const persistor = persistStore(store, null, () => {
  const lang = store.getState().App.appLanguage;
  i18next.changeLanguage(lang);
});

export function useAppSelector<TSelected = unknown>(
  selector: (state: RootState) => TSelected,
): TSelected {
  return useSelector(selector);
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
