import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Loader from '@common/components/Loader';
import Popup from '@common/components/Popup';
import usePacoApiError from '@common/services/usePacoApiError';

import Block from '../../components/Block';
import Box from '../../components/Box';
import Button from '../../components/Button';
import DisconnectPopup from '../../components/DisconnectPopup';
import Input from '../../components/Input';
import Spacer from '../../components/Spacer';
import AppConfig from '../../config/appConfig';
import Theme from '../../config/theme';
import {RootNavigationProps} from '../../navigators';
import {useAppSelector} from '../../reducers';
import {getDeviceType} from '../../services/helpers/deviceHelper';
import {useApi} from '../../services/pacoApi';
import {useQuery} from '../../services/queries';
import SimpleLayout from '../../shared_layouts/SimpleLayout';

import Flex from './../../components/Flex';
import Text from './../../components/Text';
import UserRow from './UserRow';

const Admin: React.FC<RootNavigationProps<'Admin'>> = () => {
  const env = useAppSelector(state =>
    AppConfig.getEnvFromUrl(state.App.apiUrl),
  );
  const {t} = useTranslation();
  const api = useApi();
  const [inputValue, setInputValue] = React.useState('');
  const [showDisconnetPopup, setShowDisconnectPopup] = React.useState(false);

  const {popupProps, handle} = usePacoApiError();

  const version = AppConfig.appVersion;

  const {
    data: getDriversResponse,
    error: getDriversError,
    isError: getDriversIsError,
    isSuccess: getDriversIsSuccess,
    isLoading: getDriversIsLoading,
    refetch: refetchGetDriver,
  } = useQuery(
    'get-drivers',
    async () => {
      return api.Admin.v1AdminGetDriversFilterGet(inputValue);
    },
    {
      enabled: false,
      cacheTime: 1,
    },
  );

  const drivers = getDriversResponse?.data;
  const flexProps =
    getDeviceType() === 'web-desktop'
      ? {direction: 'row' as const, alignItems: 'center' as const}
      : {direction: 'column' as const};

  React.useEffect(() => {
    if (getDriversError) {
      handle(getDriversError);
    }
  }, [getDriversError, getDriversIsError, handle]);
  return (
    <SimpleLayout>
      <Block title={t('admin-title')}>
        <Box padding={Theme.spacing.l}>
          <Flex {...flexProps}>
            <Box padding={Theme.spacing.xl} skipLeft>
              <Text variant="Sub">{t('admin-env', {env, version})}</Text>
            </Box>
            <Button
              title={t('admin-logout')}
              variant="Secondary"
              onPress={() => setShowDisconnectPopup(true)}
            />
          </Flex>
          <Box padding={Theme.spacing.xl} skipLeft>
            <Text variant="Medium">{t('admin-sign-as-user')}</Text>
          </Box>
          <Flex
            direction={getDeviceType() === 'web-desktop' ? 'row' : 'column'}>
            <Input
              value={inputValue}
              placeholder={t('admin-username')}
              onChangeText={setInputValue}
            />
            {getDeviceType() === 'web-desktop' ? (
              <Spacer mode="horizontal" spacing={Theme.spacing.xl} />
            ) : null}
            <Button
              title={t('admin-find')}
              onPress={() => {
                if (inputValue) {
                  refetchGetDriver();
                }
              }}
            />
          </Flex>
          {(!drivers || drivers.length === 0) &&
          getDriversIsSuccess &&
          !getDriversIsLoading ? (
            <Text>{t('admin-no-users-found')}</Text>
          ) : null}
          {drivers && drivers.length > 5 ? (
            <Text color="red">{t('admin-users-full')}</Text>
          ) : null}
          <Spacer spacing={Theme.spacing.xl} />
          {drivers
            ? drivers.slice(0, 5).map(driver => {
                return <UserRow key={`${driver.email}`} driver={driver} />;
              })
            : null}
          <DisconnectPopup
            visible={showDisconnetPopup}
            close={() => setShowDisconnectPopup(false)}
          />
        </Box>
      </Block>
      <Loader isLoading={getDriversIsLoading} />
      <Popup {...popupProps} />
    </SimpleLayout>
  );
};

export default Admin;
