import {AppLanguage} from '../i18n';
import {Culture} from '../pacoApi';

export function getAppLanguageFromCulture(
  apiCulture: Culture | undefined,
): AppLanguage {
  if (apiCulture === Culture.Fr) {
    return 'fr';
  }
  return 'en';
}

export function getCultureFromAppLanguage(language: AppLanguage): Culture {
  if (language === 'fr') {
    return Culture.Fr;
  }
  return Culture.En;
}
