import * as analytics from './analytics';

export default {
  trackScreenView: (name: string): void => {
    analytics.trackScreenView(name);
  },
  trackTutorialEnd: (): void => {
    analytics.trackEvent('tutorial_end');
  },
  trackOptinPushAccepted: (): void => {
    analytics.trackEvent('accept_optin_push');
  },
  trackOptinPushDeclined: (): void => {
    analytics.trackEvent('decline_optin_push');
  },
};
