import * as React from 'react';
import {StyleSheet, useWindowDimensions} from 'react-native';
import {RenderHTML} from 'react-native-render-html';

import {AlertMessageLevel} from '@services/pacoApi';

import Theme from '../config/theme';

import Box from './Box';

export interface Props {
  level: AlertMessageLevel;
  message: string;
}

const AlertMessage: React.FC<Props> = props => {
  const {level, message} = props;
  const backgroundColor = getColor(level);
  const {width} = useWindowDimensions();

  const source = {
    html: message,
  };
  return (
    <Box
      padding={Theme.spacing.l}
      style={[{backgroundColor}, Styles.container]}>
      <RenderHTML
        defaultTextProps={{style: Styles.textStyle, selectable: true}}
        source={source}
        contentWidth={width}
      />
    </Box>
  );
};

const Styles = StyleSheet.create({
  container: {
    borderRadius: Theme.radius.container,
  },
  row: {
    width: '100%',
    padding: Theme.spacing.xl,
  },
  border: {
    borderBottomWidth: 1,
  },
  textStyle: {
    color: Theme.colors.black,
    fontSize: 16,
    lineHeight: 24,
    fontFamily: Theme.fonts.family.InterMedium,
    textAlign: 'left',
    userSelect: 'initial',
  },
});

const getColor = (level?: AlertMessageLevel | null): string | undefined => {
  switch (level) {
    case AlertMessageLevel.Info:
      return Theme.colors.white;
    case AlertMessageLevel.Warning:
      return Theme.colors.warningLight;
    case AlertMessageLevel.Danger:
      return Theme.colors.danger;
    default:
      return undefined;
  }
};
export default AlertMessage;
