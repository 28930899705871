import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';

import {
  dateTimeToDMY,
  DMY,
  parseDateTime,
} from '../../components/DatePicker/utils';
import Theme from '../../config/theme';
import {PlanningItemType, PlanningResponse} from '../../services/pacoApi';

type CustomStyle = {
  container?: Record<string, unknown>;
  text?: Record<string, unknown>;
  dots?: string[];
};
interface Planning {
  selectedDay: DMY;
  planningByDays: PlanningResponse['planningByDays'];
  minDate: DMY;
  maxDate: DMY;
  markedDates: Record<string, CustomStyle>;
  shouldReloadPlanning: boolean;
}

const now = DateTime.now();
const initialState: Planning = {
  selectedDay: dateTimeToDMY(now),
  planningByDays: null,
  minDate: dateTimeToDMY(now),
  maxDate: dateTimeToDMY(now),
  markedDates: {},
  shouldReloadPlanning: false,
};

const PlanningSlice = createSlice({
  name: 'Planning',
  initialState,
  reducers: {
    setSelectedDay: (state, action: PayloadAction<DMY>) => {
      const newSelectedDay = action.payload;
      const newSelectedDate = DateTime.local(
        newSelectedDay.year,
        newSelectedDay.month - 1,
        newSelectedDay.day,
      );
      const minDate = DateTime.local(
        state.minDate.year,
        state.minDate.month - 1,
        state.minDate.day,
      );
      const maxDate = DateTime.local(
        state.maxDate.year,
        state.maxDate.month - 1,
        state.maxDate.day,
      );
      if (newSelectedDate < minDate) {
        state.selectedDay = state.minDate;
      } else if (newSelectedDate > maxDate) {
        state.selectedDay = state.maxDate;
      } else {
        state.selectedDay = action.payload;
      }
      state.markedDates = getMarkedDates(state.planningByDays);
    },
    setPlanningByDays: (
      state,
      action: PayloadAction<PlanningResponse['planningByDays']>,
    ) => {
      state.planningByDays = action.payload;

      if (!state.planningByDays) {
        return;
      }
      const keys = Object.keys(state.planningByDays);
      if (keys.length === 0) {
        return;
      }
      const minDate = DateTime.fromISO(keys[0], {setZone: true});
      const maxDate = DateTime.fromISO(keys[keys.length - 1], {setZone: true});

      state.minDate = dateTimeToDMY(minDate);
      state.maxDate = dateTimeToDMY(maxDate);
      state.markedDates = getMarkedDates(state.planningByDays);
    },
    setShouldReloadPlanning: (state, action: PayloadAction<boolean>) => {
      state.shouldReloadPlanning = action.payload;
    },
  },
});

const getMarkedDates = (
  planningByDays: PlanningResponse['planningByDays'],
): Record<string, CustomStyle> => {
  if (!planningByDays) {
    return {};
  }

  const keys = Object.keys(planningByDays);
  if (keys.length === 0) {
    return {};
  }

  const planningMarkedDates: Record<string, CustomStyle> = {};
  keys.forEach(key => {
    const shouldBeMarked = planningByDays[key].items?.some(
      item =>
        item.type === PlanningItemType.Reservation ||
        item.type === PlanningItemType.ManualAllocation,
    );

    if (shouldBeMarked) {
      planningMarkedDates[key] = {
        container: {
          backgroundColor: Theme.colors.teaGreen,
        },
      };
    }
  });

  const nowKey = parseDateTime(DateTime.now()) as string;
  if (!planningMarkedDates[nowKey]) {
    planningMarkedDates[nowKey] = {};
  }

  planningMarkedDates[nowKey].text = {
    color: Theme.colors.callToAction,
  };

  return planningMarkedDates;
};

export const PlanningActions = PlanningSlice.actions;
export default PlanningSlice.reducer;
