import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import {Platform} from 'react-native';
import * as Localize from 'react-native-localize';

import en from './en';
import fr from './fr';

export type AppLanguage = 'en' | 'fr';

const userLanguage = Localize.getLocales()[0].languageCode as AppLanguage;

const supportedLanguages = ['en', 'fr'];
export const defaultLanguage = supportedLanguages.includes(userLanguage)
  ? userLanguage
  : 'en';

i18next.use(initReactI18next).init({
  defaultNS: 'common',
  resources: {en, fr},
  fallbackLng: 'en',
  lng: defaultLanguage,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

const setWebPageLanguage = (lng: string) => {
  if (Platform.OS !== 'web') {
    return;
  }

  const html = document?.querySelector('html');

  if (html) {
    html.setAttribute('lang', lng);
  }
};

i18next.on('languageChanged', setWebPageLanguage);

export default i18next;
