import PNF from 'google-libphonenumber';

export const getCountryCodeOrDefault = (
  phoneNumber?: string,
): number | undefined => {
  const instance = PNF.PhoneNumberUtil.getInstance();
  try {
    const number = instance.parse(phoneNumber, 'FR');

    return number.getCountryCode();
  } catch (error) {
    // ignore
  }
};

export const getNationalNumber = (phoneNumber?: string): number | undefined => {
  const instance = PNF.PhoneNumberUtil.getInstance();
  try {
    const number = instance.parse(phoneNumber, 'FR');

    return number.getNationalNumber();
  } catch (error) {
    // ignore
  }
};

export const getCountryAlpha2 = (countryCode?: number): string | undefined => {
  if (!countryCode) {
    return;
  }

  const instance = PNF.PhoneNumberUtil.getInstance();
  return instance.getRegionCodeForCountryCode(countryCode);
};
