import * as React from 'react';
import {
  TouchableOpacity,
  TouchableOpacityProps,
  StyleSheet,
} from 'react-native';
import {useHover} from 'react-native-web-hooks';

import Theme from '../config/theme';

import Flex from './Flex';
import Icon from './Icon';
import Spacer from './Spacer';
import Text from './Text';

type ButtonVariant = 'Primary' | 'Secondary' | 'Tertiary';
interface Props extends TouchableOpacityProps {
  variant?: ButtonVariant;
  title: string;
  leftIconName?: string;
  rightIconName?: string;
  iconColor?: string;
  expanded?: boolean;
  testID?: string;
}

const Button: React.FC<Props> = ({
  variant = 'Primary',
  title,
  leftIconName,
  rightIconName,
  iconColor: defaultIconColor,
  expanded = true,
  onPress,
  testID,
  ...props
}) => {
  const ref = React.useRef<TouchableOpacity>(null);

  const iconColor =
    defaultIconColor ||
    (variant === 'Primary'
      ? Theme.colors.white
      : props.disabled
      ? Theme.colors.rockBlue
      : Theme.colors.primary);

  const isHovered = useHover(ref);
  return (
    <TouchableOpacity
      {...props}
      ref={ref}
      onPress={e => {
        if (props.disabled) {
          return;
        }

        onPress && onPress(e);
      }}>
      <Flex
        direction="row"
        alignItems="center"
        style={[
          Styles.button,
          !expanded ? Styles.unexpanded : {},
          getContainerStyle(variant, Boolean(props.disabled), isHovered),
        ]}>
        {leftIconName ? (
          <>
            <Icon name={leftIconName} size={Theme.icons.xl} color={iconColor} />
            <Spacer mode="horizontal" spacing={Theme.spacing.xs} />
          </>
        ) : (
          Boolean(rightIconName) && (
            <Spacer
              mode="horizontal"
              spacing={Theme.spacing.xs + Theme.icons.xl}
            />
          )
        )}
        <Text
          testID={`${testID}`}
          numberOfLines={1}
          style={[
            Styles.text,
            getStyle(variant, Boolean(props.disabled), isHovered),
            expanded ? Theme.commonStyles.flex1 : {},
            props.style,
          ]}>
          {title}
        </Text>
        {rightIconName ? (
          <>
            <Spacer mode="horizontal" spacing={Theme.spacing.xs} />
            <Icon
              name={rightIconName}
              size={Theme.icons.xl}
              color={Theme.colors.primary}
            />
          </>
        ) : (
          Boolean(leftIconName) && (
            <Spacer
              mode="horizontal"
              spacing={Theme.spacing.xs + Theme.icons.xl}
            />
          )
        )}
      </Flex>
    </TouchableOpacity>
  );
};

const getContainerStyle = (
  variant: ButtonVariant,
  disabled: boolean,
  hovered: boolean,
) => {
  if (variant === 'Secondary') {
    return [
      Styles.secondaryContainer,
      hovered ? Styles.secondaryContainerHover : {},
      disabled ? Styles.secondaryContainerDisabled : {},
    ];
  }

  if (variant === 'Tertiary') {
    return [
      Styles.tertiaryContainer,
      hovered ? Styles.tertiaryContainerHover : {},
      disabled ? Styles.tertiaryContainerDisabled : {},
    ];
  }

  return [
    Styles.primaryContainer,
    hovered ? Styles.primaryContainerHover : {},
    disabled ? Styles.primaryContainerDisable : {},
  ];
};

const getStyle = (
  variant: ButtonVariant,
  disabled: boolean,
  hovered: boolean,
) => {
  if (variant === 'Secondary') {
    return [Styles.secondary, disabled ? Styles.secondaryDisable : {}];
  }

  if (variant === 'Tertiary') {
    return [
      Styles.tertiary,
      hovered ? Styles.tertiaryHover : {},
      disabled ? Styles.tertiaryDisable : {},
    ];
  }

  return [Styles.primary];
};

const Styles = StyleSheet.create({
  button: {
    borderRadius: Theme.radius.container,
    paddingHorizontal: Theme.spacing.l + Theme.spacing.xs,
  },
  text: {
    fontFamily: Theme.fonts.family.InterBold,
    textAlign: 'center',
    paddingVertical: 13,
  },
  unexpanded: {alignSelf: 'flex-start'},

  // primary
  primary: {
    color: Theme.colors.white,
  },
  primaryContainer: {
    backgroundColor: Theme.colors.callToAction,
  },
  primaryContainerHover: {
    backgroundColor: Theme.colors.mainHover,
  },
  primaryContainerDisable: {
    backgroundColor: Theme.colors.mainDisabled,
  },

  // secondary
  secondary: {
    color: Theme.colors.primary,
  },
  secondaryDisable: {
    color: Theme.colors.secondary,
  },
  secondaryContainer: {
    backgroundColor: Theme.colors.white,
    borderColor: Theme.colors.primary,
    borderWidth: 1,
  },
  secondaryContainerHover: {
    backgroundColor: Theme.colors.secondaryHover,
  },
  secondaryContainerDisabled: {
    borderColor: Theme.colors.border,
  },

  // tertiary
  tertiaryContainer: {
    backgroundColor: Theme.colors.white,
    borderColor: Theme.colors.callToAction,
    borderWidth: 1,
  },
  tertiaryContainerHover: {
    backgroundColor: Theme.colors.callToAction,
    borderColor: Theme.colors.callToAction,
    borderWidth: 1,
  },
  tertiaryContainerDisabled: {
    backgroundColor: Theme.colors.white,
    borderColor: Theme.colors.border,
    borderWidth: 1,
  },
  tertiary: {
    color: Theme.colors.callToAction,
  },
  tertiaryHover: {
    color: Theme.colors.white,
  },
  tertiaryDisable: {
    color: Theme.colors.secondary,
  },
});

export default Button;
