import * as React from 'react';
import {View} from 'react-native';

import Flex from '../../../components/Flex';
import Spacer from '../../../components/Spacer';
import VersionView from '../../../components/VersionView';
import Theme from '../../../config/theme';
import WebBaseContainer from '../../../containers/WebBaseContainer';
import {useResponsiveProperty} from '../../../services/responsive';
import LanguageBlock from '../LanguageBlock';
import PasswordBlock from '../PasswordBlock';
import SectionsBlock from '../SectionsBlock';

const SM_PROPERTIES = {
  spacerMode: 'vertical',
  flexDirection: 'column',
};

const LG_PROPERTIES = {
  spacerMode: 'horizontal',
  flexDirection: 'row',
  sectionBlockStyle: {flex: 2},
};
const WebAccountLayout: React.FC = () => {
  const {spacerMode, flexDirection, sectionBlockStyle} = useResponsiveProperty({
    xs: SM_PROPERTIES,
    sm: SM_PROPERTIES,
    lg: LG_PROPERTIES,
  });
  return (
    <>
      <WebBaseContainer>
        <Flex direction={flexDirection}>
          <View style={sectionBlockStyle}>
            <SectionsBlock />
          </View>

          <Spacer mode={spacerMode} spacing={Theme.spacing.xl} />

          <Flex style={Theme.commonStyles.flex1}>
            <PasswordBlock />
            <LanguageBlock />
          </Flex>
        </Flex>
      </WebBaseContainer>
      <VersionView />
    </>
  );
};

export default WebAccountLayout;
