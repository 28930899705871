import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollView, TouchableOpacity} from 'react-native';

import Theme from '@config/theme';

import SimpleLayout from '@common/shared_layouts/SimpleLayout';

import Block from '@components/Block';
import Box from '@components/Box';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import DatePicker from '@components/DatePicker';
import {DMY} from '@components/DatePicker/utils';
import Flex from '@components/Flex';
import Icon from '@components/Icon';
import Input from '@components/Input';
import PhoneInput from '@components/PhoneInput';
import RulesView from '@components/RulesView';
import Select from '@components/Select';
import Spacer from '@components/Spacer';
import Text from '@components/Text';
import TimePicker from '@components/TimePicker';
import {HM} from '@components/TimePicker/utils';
import VerticalBoxShadow from '@components/VerticalBoxShadow';

const Examples: React.FC = () => {
  const {t} = useTranslation();
  const [checked, setChecked] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [selected, setSelected] = React.useState(-1);
  const [selectedDate, setSelectedDate] = React.useState<DMY>();
  const [selectedTime, setSelectedTime] = React.useState<HM>();
  const [phoneValue, setPhoneValue] = React.useState<string>('+33664494546');

  const options = [
    {value: 'a', label: 'a'},
    {value: 'b', label: 'b'},
    {value: 'c', label: 'c'},
    {value: 'd', label: 'd'},
    // To test Scroll
    // {value: 'd', label: 'd'},
    // {value: 'a', label: 'a'},
    // {value: 'b', label: 'b'},
    // {value: 'c', label: 'c'},
    // {value: 'd', label: 'd'},
    // {value: 'a', label: 'a'},
    // {value: 'b', label: 'b'},
    // {value: 'c', label: 'c'},
    // {value: 'd', label: 'd'},
    // {value: 'a', label: 'a'},
    // {value: 'b', label: 'b'},
    // {value: 'c', label: 'c'},
    // {value: 'd', label: 'd'},
    // {value: 'a', label: 'a'},
    // {value: 'b', label: 'b'},
    // {value: 'c', label: 'c'},
    // {value: 'd', label: 'd'},
  ];

  const rules1 = [
    {message: 'Nombre de réservations simultanées (5 max.) : 4', success: true},
    {message: 'Durée de réservation (16 h max.)'},
    {message: 'Plage de réservation (J+30 max.)', success: true},
  ];

  const rules2 = [
    {message: 'Nombre de réservations simultanées (5 max.) : 4', success: true},
    {message: 'Durée de réservation (16 h max.) : 8 h 30 min', success: true},
    {message: 'Plage de réservation (J+30 max.) : J+32', success: false},
  ];

  return (
    <SimpleLayout>
      <Block title={t('examples')}>
        <Box padding={Theme.spacing.l}>
          <Flex backgroundColor={Theme.colors.white}>
            <ScrollView>
              <Box padding={20}>
                <>
                  <PhoneInput
                    value={phoneValue}
                    onPhoneNumberChange={value => setPhoneValue(value)}
                  />
                  <PhoneInput
                    value={phoneValue}
                    placeholder={'Phone Number'}
                    onPhoneNumberChange={value => setPhoneValue(value)}
                  />
                  <Checkbox
                    checked={checked}
                    onPress={value => setChecked(value)}
                  />
                  <Checkbox
                    checked={checked}
                    onPress={value => setChecked(value)}
                    label="with label "
                  />
                  <Checkbox
                    disabled
                    checked={checked}
                    onPress={value => setChecked(value)}
                    label="disabled"
                  />
                </>
                <Spacer spacing={Theme.spacing.l} />
                <>
                  <Icon
                    name="Profile"
                    color={Theme.colors.callToAction}
                    size={100}
                  />
                  <Icon name="Bill" size={50} color={Theme.colors.teaGreen} />
                  <Icon name="Clock" size={25} />
                </>
                <Spacer spacing={Theme.spacing.l} />
                <>
                  <Text variant="Bigger">Bigger</Text>
                  <Text variant="Big">Big</Text>
                  <Text variant="Medium">Medium</Text>
                  <Text variant="Small">Small</Text>
                  <Text variant="Sub">Sub</Text>
                  <Text variant="Body">Body</Text>
                  <Text variant="Label">Label</Text>
                </>
                <Spacer spacing={Theme.spacing.l} />
                <>
                  <Flex direction="row">
                    <Input
                      value={inputValue}
                      style={Theme.commonStyles.flex1}
                      onChangeText={setInputValue}
                      placeholder="With label"
                    />
                    <Spacer spacing={Theme.spacing.s} mode="horizontal" />
                    <Input
                      style={Theme.commonStyles.flex1}
                      value={inputValue}
                      onChangeText={setInputValue}
                    />
                  </Flex>
                  <Input
                    value={inputValue}
                    onChangeText={setInputValue}
                    showLabel={false}
                    placeholder="Without label"
                  />
                  <Input
                    disabled
                    value={inputValue}
                    onChangeText={setInputValue}
                    showLabel={false}
                    placeholder="Without label"
                  />
                  <Input
                    value={inputValue}
                    onChangeText={setInputValue}
                    placeholder={'with left icon'}
                    leftIconName="Planning"
                  />
                  <Input
                    value={inputValue}
                    onChangeText={setInputValue}
                    showLabel
                    placeholder="with left icon + Label"
                    leftIconName="Planning"
                  />
                  <Input
                    value={inputValue}
                    onChangeText={setInputValue}
                    placeholder={'with right icon'}
                    rightIconName="Planning"
                  />
                  <Input
                    value={inputValue}
                    onChangeText={setInputValue}
                    error="This is an error"
                  />
                </>
                <Spacer spacing={Theme.spacing.l} />
                <>
                  <Flex direction="row">
                    <Select
                      options={options}
                      selected={selected}
                      onSelect={(_value, index) => setSelected(index)}
                    />
                    <Select
                      placeholder="with label"
                      options={options}
                      selected={selected}
                      onSelect={(_value, index) => setSelected(index)}
                    />
                  </Flex>
                  <Select
                    placeholder="With custom Row Component"
                    options={options}
                    selected={selected}
                    onSelect={(_value, index) => setSelected(index)}
                    RowComponent={props => {
                      const {index, isSelected, onPress} = props;

                      return (
                        <TouchableOpacity onPress={onPress}>
                          <Box padding={Theme.spacing.l} skipRight skipLeft>
                            <Box padding={Theme.spacing.xl} skipTop skipBottom>
                              <Flex direction="row">
                                <Icon
                                  name={isSelected ? 'Dot' : 'PricingTag'}
                                  size={Theme.icons.xl}
                                  color={
                                    isSelected
                                      ? Theme.colors.secondary
                                      : Theme.colors.primary
                                  }
                                />
                                <Spacer
                                  spacing={Theme.spacing.l}
                                  mode="horizontal"
                                />
                                <Text
                                  color={
                                    isSelected
                                      ? Theme.colors.primary
                                      : Theme.colors.secondary
                                  }>
                                  {options[index].label}
                                </Text>
                              </Flex>
                            </Box>
                          </Box>
                        </TouchableOpacity>
                      );
                    }}
                  />
                  <Select
                    disabled
                    placeholder="disabled"
                    options={options}
                    selected={selected}
                    onSelect={(_value, index) => setSelected(index)}
                  />
                  <Select
                    error="This is an error"
                    options={options}
                    selected={selected}
                    onSelect={(_value, index) => setSelected(index)}
                  />
                </>

                <Spacer spacing={Theme.spacing.l} />
                <>
                  <DatePicker
                    value={selectedDate}
                    onSelect={setSelectedDate}
                    placeholder="Date picker"
                  />
                  <DatePicker value={selectedDate} onSelect={setSelectedDate} />

                  <Flex direction="row">
                    <DatePicker
                      disabled
                      value={selectedDate}
                      onSelect={setSelectedDate}
                    />
                    <DatePicker
                      error="This is an error"
                      value={selectedDate}
                      onSelect={setSelectedDate}
                    />
                  </Flex>
                </>
                <>
                  <TimePicker value={selectedTime} onChange={setSelectedTime} />
                  <Flex direction="row">
                    <TimePicker
                      value={selectedTime}
                      placeholder="Time Picker"
                      onChange={setSelectedTime}
                    />
                    <TimePicker
                      value={selectedTime}
                      placeholder="Time Picker"
                      onChange={setSelectedTime}
                    />
                  </Flex>
                </>
                <Spacer spacing={Theme.spacing.l} />
                <>
                  <Button
                    variant="Primary"
                    title="Primary"
                    onPress={() => null}
                  />
                  <Spacer spacing={Theme.spacing.s} />
                  <Button
                    disabled
                    variant="Primary"
                    title="Primary disabled"
                    onPress={() => null}
                  />
                  <Spacer spacing={Theme.spacing.s} />
                  <Button
                    variant="Secondary"
                    title="Secondary"
                    onPress={() => null}
                  />
                  <Spacer spacing={Theme.spacing.s} />
                  <Button
                    disabled
                    variant="Secondary"
                    title="Secondary disabled"
                    onPress={() => null}
                  />
                  <Button
                    variant="Tertiary"
                    title="Tertiary"
                    onPress={() => null}
                  />
                  <Spacer spacing={Theme.spacing.s} />
                  <Button
                    disabled
                    variant="Tertiary"
                    title="Tertiary disabled"
                    onPress={() => null}
                  />
                  <Spacer spacing={Theme.spacing.s} />
                  <Button
                    variant="Primary"
                    title="Primary with icon"
                    leftIconName="CarEntry"
                    onPress={() => null}
                  />
                  <Spacer spacing={Theme.spacing.s} />
                  <Button
                    variant="Secondary"
                    title="Secondary with icon"
                    leftIconName="CarEntry"
                    onPress={() => null}
                  />
                  <Spacer spacing={Theme.spacing.s} />
                  <Button
                    disabled
                    variant="Secondary"
                    title="Secondary with icon disabled"
                    leftIconName="CarEntry"
                    onPress={() => null}
                  />
                  <Spacer spacing={Theme.spacing.s} />
                  <Button
                    variant="Secondary"
                    title="Secondary with colored icon"
                    leftIconName="CarEntry"
                    iconColor={Theme.colors.good}
                    onPress={() => null}
                  />
                  <Spacer spacing={Theme.spacing.s} />
                </>
                <Spacer spacing={Theme.spacing.l} />
                <RulesView rules={rules1} />
                <Spacer spacing={Theme.spacing.l} />
                <RulesView rules={rules2} />

                <VerticalBoxShadow>
                  <RulesView rules={rules2} />
                </VerticalBoxShadow>
              </Box>
            </ScrollView>
          </Flex>
        </Box>
      </Block>
    </SimpleLayout>
  );
};

export default Examples;
