import testIDs from '@testIDs';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../../components/Button';
import Flex from '../../components/Flex';
import Popup from '../../components/Popup';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import Theme from '../../config/theme';

interface Props {
  vehicleId: string;
  visible: boolean;
  close: () => void;
  onDelete: () => void;
}
const DeleteVehiclePopup: React.FC<Props> = ({
  visible,
  vehicleId,
  close,
  onDelete,
}) => {
  const {t} = useTranslation();
  return (
    <Popup visible={visible} close={close}>
      <Flex alignItems="center">
        <Text>{t('vehicle-delete-confirm')}</Text>
      </Flex>
      <Spacer spacing={Theme.spacing.l} />
      <Button
        title={t('vehicle-delete-popup-yes')}
        variant="Primary"
        onPress={() => {
          onDelete();
          close();
        }}
        testID={`${testIDs.vehicles.item.confirmDelete}-${vehicleId}`}
      />
      <Spacer spacing={Theme.spacing.m} />
      <Button
        title={t('vehicle-delete-popup-no')}
        variant="Secondary"
        onPress={close}
      />
    </Popup>
  );
};

export default DeleteVehiclePopup;
