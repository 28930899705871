import * as React from 'react';
import {View} from 'react-native';

import Block from '../../components/Block';
import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Spacer from '../../components/Spacer';
import Theme from '../../config/theme';
import WebBaseContainer from '../../containers/WebBaseContainer';
import {useResponsiveProperty} from '../../services/responsive';

interface Props {
  Footer: JSX.Element;
  children: React.ReactNode;
}

export interface WebReservationLayoutRef {
  scrollToBottom: () => void;
}

const SM_POPERTIES = {
  spacerMode: 'vertical',
  flexDirection: 'column',
};

const LG_PROPERTIES = {
  spacerMode: 'horizontal',
  flexDirection: 'row',
  formContainerStyle: {flex: 2},
};
const WebReservationLayout = React.forwardRef<WebReservationLayoutRef, Props>(
  (props, _) => {
    const {spacerMode, flexDirection, formContainerStyle} =
      useResponsiveProperty({
        xs: SM_POPERTIES,
        sm: SM_POPERTIES,
        lg: LG_PROPERTIES,
      });

    return (
      <Flex
        style={Theme.commonStyles.flex1}
        backgroundColor={Theme.colors.secondaryHover}>
        <WebBaseContainer>
          <Flex style={Theme.commonStyles.flex1} direction={flexDirection}>
            <View style={formContainerStyle}>{props.children}</View>
            <Spacer mode={spacerMode} spacing={24} />
            <Block
              style={Theme.commonStyles.flex1}
              title={flexDirection === 'row' ? ' ' : ''}>
              <Box padding={Theme.spacing.l}>{props.Footer}</Box>
            </Block>
          </Flex>
        </WebBaseContainer>
      </Flex>
    );
  },
);

export default WebReservationLayout;
