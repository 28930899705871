import * as React from 'react';

import Theme from '../../config/theme';
import Box from '../Box';
import Flex from '../Flex';
import PressableIcon from '../PressableIcon';
import Text from '../Text';

import Styles from './MonthView.styles';

type Props = {
  title: string;
  showLeftArrow: boolean;
  showRightArrow: boolean;
  onLeftArrowPress: () => void;
  onRightArrowPress: () => void;
};
class Header extends React.PureComponent<Props> {
  render(): JSX.Element {
    const {
      title,
      showLeftArrow,
      showRightArrow,
      onLeftArrowPress,
      onRightArrowPress,
    } = this.props;
    return (
      <Flex
        direction="row"
        justifyContent={'space-between'}
        alignItems="center">
        <PressableIcon
          name={'Previous'}
          color={showLeftArrow ? Theme.colors.primary : Theme.colors.white}
          onPress={() => {
            if (showLeftArrow) {
              onLeftArrowPress();
            }
          }}
        />

        <Box padding={Theme.spacing.m}>
          <Text style={Styles.text}>{title}</Text>
        </Box>

        <PressableIcon
          name={'Next'}
          color={showRightArrow ? Theme.colors.primary : Theme.colors.white}
          onPress={() => {
            if (showRightArrow) {
              onRightArrowPress();
            }
          }}
        />
      </Flex>
    );
  }
}

export default Header;
