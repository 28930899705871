import {StyleSheet} from 'react-native';

import Theme from '@config/theme';

const Styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Theme.colors.ghostWhite,
  },
  slideContainer: {width: Theme.spacing.window.width, overflow: 'visible'},
  slideImage: {
    width: Theme.spacing.window.width * 0.82,
    height: Theme.spacing.window.width * 0.82,
    resizeMode: 'contain',
  },
  slideBodyContainer: {
    height: Theme.spacing.window.height * 0.25,
  },
  slideBodyContent: {
    padding: Theme.spacing.l,
  },
  lastSlideBodyContainer: {
    height: Theme.spacing.window.height * 0.25 + 72,
  },
  buttonCTA: {
    marginHorizontal: Theme.spacing.l,
    paddingBottom: Theme.spacing.l,
  },
});
export default Styles;
