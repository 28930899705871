import testIDs from '@testIDs';
import {DateTime} from 'luxon';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList} from 'react-native';

import Box from '../../../components/Box';
import {dateTimeToDMY, parseDMY} from '../../../components/DatePicker/utils';
import Spacer from '../../../components/Spacer';
import TextButton from '../../../components/TextButton';
import Theme from '../../../config/theme';
import {useAppDispatch, useAppSelector} from '../../../reducers';
import {PlanningActions} from '../../../reducers/slices/Planning';
import {
  getDateTimeFromDMYWithDayOffset,
  compareDateTimeDMY,
  getFirstDayOfWeek,
  getLastDayOfWeek,
  getTimeDMYWithDayOffset,
  getOffsetKey,
} from '../../../services/helpers/dateHelper';

import AgendaRow from './AgendaRow';
import {AgendaItem} from './types';

const Agenda: React.FC = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {planningByDays, selectedDay, maxDate, minDate} = useAppSelector(
    state => {
      return {
        planningByDays: state.Planning.planningByDays,
        selectedDay: state.Planning.selectedDay,
        maxDate: state.Planning.maxDate,
        minDate: state.Planning.minDate,
      };
    },
  );
  const flatlist = React.useRef<FlatList>(null);

  const appLocale = useAppSelector(state => state.App.appLanguage);

  const data = React.useMemo(() => {
    if (!planningByDays) {
      return [];
    }

    const firstDay = getFirstDayOfWeek(selectedDay);
    return Array(7)
      .fill(0)
      .map((_, index) => {
        const key = getOffsetKey(firstDay, index);
        const planningDay = planningByDays[key] || {items: null, isFull: false};
        return {
          ...planningDay,
          date: DateTime.fromISO(key).setLocale(appLocale),
          dmy: dateTimeToDMY(DateTime.fromISO(key)),
        };
      });
  }, [planningByDays, selectedDay, appLocale]);

  const isMinWeek =
    compareDateTimeDMY(
      minDate,
      getTimeDMYWithDayOffset(getLastDayOfWeek(selectedDay), -7),
    ) >= 0;
  const isMaxWeek =
    compareDateTimeDMY(
      maxDate,
      getTimeDMYWithDayOffset(getFirstDayOfWeek(selectedDay), 7),
    ) <= 0;

  return (
    <FlatList<AgendaItem>
      ref={flatlist}
      refreshing={false}
      ItemSeparatorComponent={() => <Spacer spacing={Theme.spacing.m} />}
      onRefresh={() => dispatch(PlanningActions.setShouldReloadPlanning(true))}
      ListHeaderComponent={() => {
        return (
          <Box padding={Theme.spacing.l}>
            {!isMinWeek && (
              <TextButton
                testID={testIDs.planning.previousWeek}
                onPress={() => {
                  const newDate = getDateTimeFromDMYWithDayOffset(
                    selectedDay,
                    -7,
                  );
                  const newDateDMY = dateTimeToDMY(newDate);

                  dispatch(PlanningActions.setSelectedDay(newDateDMY));
                }}>
                {t('planning-previous-week')}
              </TextButton>
            )}
          </Box>
        );
      }}
      ListFooterComponent={() => {
        return (
          <Box padding={Theme.spacing.l}>
            {!isMaxWeek && (
              <TextButton
                testID={testIDs.planning.nextWeek}
                onPress={() => {
                  const newDate = getDateTimeFromDMYWithDayOffset(
                    selectedDay,
                    7,
                  );
                  const newDateDMY = dateTimeToDMY(newDate);

                  dispatch(PlanningActions.setSelectedDay(newDateDMY));
                }}>
                {t('planning-next-week')}
              </TextButton>
            )}
          </Box>
        );
      }}
      data={data}
      keyExtractor={(item, index) => `${parseDMY(item.dmy)}-${index}`}
      renderItem={({item, index}) => {
        return <AgendaRow key={index} item={item} />;
      }}
    />
  );
};

export default Agenda;
