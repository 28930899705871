import * as React from 'react';
import {TouchableOpacity} from 'react-native';

import Theme from '../../config/theme';
import Box from '../Box';
import Flex from '../Flex';
import Hoverable from '../Hoverable';
import ParkingRow from '../ParkingRow';
import {RowComponentProps} from '../Select/RowComponent';

import {NO_ITEMS_VALUE, ParkingOption} from './utils';

const RowComponent: React.FC<RowComponentProps<number>> = props => {
  const {index, isSelected, options, onPress, testID} = props;
  const option = options[index] as ParkingOption;

  if (option.value === NO_ITEMS_VALUE) {
    return null;
  }

  return (
    <TouchableOpacity onPress={onPress} testID={testID}>
      <Hoverable
        backgroundColor={Theme.colors.white}
        hoverColor={Theme.colors.secondaryHover}>
        <Box padding={Theme.spacing.l} skipTop skipBottom>
          <Flex direction="row" alignItems="center">
            <ParkingRow label={option.label} isSelected={isSelected} />
          </Flex>
        </Box>
      </Hoverable>
    </TouchableOpacity>
  );
};

export default RowComponent;
