import {createStackNavigator} from '@react-navigation/stack';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {RootParamList} from './type';

export const RootStack = createStackNavigator<RootParamList>();
export type RootStack = typeof RootStack;

export const useScreenTitle = () => {
  const {t} = useTranslation();

  const getTitle = React.useCallback(
    (key: string) => {
      return `${t(key)}${t('layout-head-title-suffix')}`;
    },
    [t],
  );
  return getTitle;
};
