import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollView} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import Block from '../../../components/Block';
import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import VerticalBorderShadow from '../../../components/VerticalBoxShadow';
import Theme from '../../../config/theme';
import AddVehicleButton from '../AddVehicleButton';
import VehiclesList from '../VehiclesList';

const MobileVehiclesLayout: React.FC = () => {
  const {t} = useTranslation();
  return (
    <Flex
      style={Theme.commonStyles.flex1}
      backgroundColor={Theme.colors.secondaryHover}
      justifyContent="space-between">
      <ScrollView style={Theme.commonStyles.flex1}>
        <Box padding={Theme.spacing.l}>
          <Block title={t('vehicles')}>
            <Box padding={Theme.spacing.l}>
              <VehiclesList />
            </Box>
          </Block>
        </Box>
      </ScrollView>
      <VerticalBorderShadow>
        <Flex backgroundColor={Theme.colors.white}>
          <SafeAreaView edges={['bottom']}>
            <Box padding={Theme.spacing.l}>
              <AddVehicleButton expanded />
            </Box>
          </SafeAreaView>
        </Flex>
      </VerticalBorderShadow>
    </Flex>
  );
};

export default MobileVehiclesLayout;
