import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import Theme from '../config/theme';
import {
  responsiveContainerWidth,
  useResponsiveProperty,
} from '../services/responsive';

const WebBaseContainer: React.FC = ({children}) => {
  const containerStyle = useResponsiveProperty(responsiveContainerWidth);

  return (
    <View style={Styles.mainContainer}>
      <View style={[containerStyle]}>{children}</View>
    </View>
  );
};

const Styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: Theme.colors.secondaryHover,
    paddingVertical: Theme.spacing.xl,
    overflow: 'scroll',
  },
});

export default WebBaseContainer;
