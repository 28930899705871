import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Linking} from 'react-native';

import {useAppSelector} from '@common/reducers';

import {version} from '@common/../package.json';

import {getDeviceType} from './deviceHelper';

export const openZenparkPro = (): void => {
  Linking.openURL('https://zenpark.pro');
};

export const useMailToContact = (): (() => void) => {
  const {t} = useTranslation();
  const {email, parkingName} = useAppSelector(state => {
    return {
      email: state.Member.driverInfo?.email,
      parkingName: state.Member.driverInfo?.parkings?.find(
        item => item.id === state.Member.lastUsedParkingId,
      )?.name,
    };
  });

  const subject = t('contact-mail-subject');

  const body = React.useMemo(() => {
    const user = email ? `${email} /` : '';
    const parking = parkingName ? ` ${parkingName} /` : '';
    const appVersion = ` ${getDeviceType()} ${version}`;

    return encodeURI(
      t('contact-mail-body', {
        user,
        parking,
        appVersion,
      }),
    );
  }, [parkingName, email, t]);

  return () => {
    Linking.openURL(
      `mailto:support-flex@zenpark.pro?subject=${subject}&body=${body}`,
    );
  };
};
