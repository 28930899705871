import * as React from 'react';
import {Platform} from 'react-native';

import theme from '../../../config/theme';
import Field from '../../Field';
import {LabelComponentProps} from '../../Select/LabelComponent';
import Spacer from '../../Spacer';
import VehicleRow from '../../VehicleRow';

import {getIconName, VehicleOption} from './utils';

const LabelComponent: React.FC<LabelComponentProps<number>> = props => {
  const option = props.option as VehicleOption;

  const {label, type} = option;

  return (
    <>
      <Field
        isFocused={false}
        isOpened
        disabled={props.disabled}
        isValid
        {...props}
        rightIconName={Platform.OS === 'web' ? 'Dropdown' : undefined}
        onPressIconRight={props.onPressIcon}>
        <VehicleRow label={label} iconName={getIconName(type)} />
      </Field>
      <Spacer spacing={theme.spacing.s} />
    </>
  );
};

export default LabelComponent;
