import {useNavigation} from '@react-navigation/core';
import testIDs from '@testIDs';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../../components/Button';
import {RootNavigationProps} from '../../navigators';

interface Props {
  expanded: boolean;
}

const AddVehicleButton: React.FC<Props> = ({expanded}) => {
  const {t} = useTranslation();
  const navigation =
    useNavigation<RootNavigationProps<'Vehicles'>['navigation']>();

  return (
    <Button
      testID={testIDs.vehicles.addVehicleButton}
      expanded={expanded}
      title={t('vehicle-add')}
      onPress={() => navigation.navigate('AddVehicle')}
    />
  );
};

export default AddVehicleButton;
