import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';

import {AppActions, useAppDispatch, useAppSelector} from '@common/reducers';
import usePacoApiError from '@common/services/usePacoApiError';

import Popup from '@components/Popup';
import Select from '@components/Select';

import {getCultureFromAppLanguage} from '@services/helpers/languageHelper';
import {AppLanguage} from '@services/i18n';
import {useApi} from '@services/pacoApi';

import LabelComponent from './LabelComponent';
import RowComponent from './RowComponent';
import {
  getSelectedLanguageByIndex,
  getSelectedOption,
  LanguageOption,
} from './utils';

const LanguageSelector: React.FC = () => {
  const {t} = useTranslation();
  const api = useApi();
  const dispatch = useAppDispatch();
  const appLanguage = useAppSelector(state => state.App.appLanguage);
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError();

  const {
    isError,
    error: apiError,
    mutate: changeCulture,
  } = useMutation(async (selectedLanguage: AppLanguage) => {
    return api.Drivers.v1DriversChangeCulturePatch({
      newCulture: getCultureFromAppLanguage(selectedLanguage),
    });
  });

  React.useEffect(() => {
    if (isError) {
      handleError(apiError);
    }
  }, [isError, apiError, handleError]);

  const languageOptions: LanguageOption[] = [
    {value: 0, language: 'fr', label: t('french')},
    {value: 1, language: 'en', label: t('english')},
  ];

  return (
    <>
      <Select
        selected={getSelectedOption(languageOptions, appLanguage)}
        options={languageOptions}
        onSelect={value => {
          const selectedLanguage = getSelectedLanguageByIndex(
            languageOptions,
            value,
          );
          if (selectedLanguage !== appLanguage) {
            changeCulture(selectedLanguage);
            dispatch(AppActions.setAppLocale(selectedLanguage));
          }
        }}
        placeholder={t('change-language')}
        RowComponent={RowComponent}
        LabelComponent={LabelComponent}
      />
      <Popup {...errorPopupProps} />
    </>
  );
};

export default LanguageSelector;
