import {useTranslation} from 'react-i18next';

import {SpaceTypes} from '@services/pacoApi';

export const useVehicleTypeLabel = (): ((type: SpaceTypes) => string) => {
  const {t} = useTranslation();
  return (type: SpaceTypes): string => {
    switch (type) {
      case SpaceTypes.Utility:
        return t('vehicle-type-utility-label');
      case SpaceTypes.Car:
        return t('vehicle-type-car-label');
      case SpaceTypes.Moto:
        return t('vehicle-type-moto-label');
      case SpaceTypes.Bike:
        return t('vehicle-type-bike-label');
      default:
        return '';
    }
  };
};

export const useVehicleName = (): ((
  type: SpaceTypes,
  isEv: boolean,
) => string) => {
  const {t} = useTranslation();
  return (type: SpaceTypes, isEv: boolean): string => {
    const electric = isEv ? t('electric') : '';
    switch (type) {
      case SpaceTypes.Utility:
        return t('vehicle-type-utility-name', {
          electric: electric,
        });
      case SpaceTypes.Car:
        return t('vehicle-type-car-name', {
          electric: electric,
        });
      case SpaceTypes.Moto:
        return t('vehicle-type-moto-name', {
          electric: electric,
        });
      case SpaceTypes.Bike:
        return t('vehicle-type-bike-name');
      default:
        return '';
    }
  };
};
