import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Block from '../../../components/Block';
import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Spacer from '../../../components/Spacer';
import Theme from '../../../config/theme';
import SimpleLayout from '../../../shared_layouts/SimpleLayout';
import AddVehicleButton from '../AddVehicleButton';
import VehiclesList from '../VehiclesList';

const WebVehiclesLayout: React.FC = () => {
  const {t} = useTranslation();
  return (
    <SimpleLayout>
      <Block title={t('vehicles')}>
        <Box padding={Theme.spacing.l}>
          <VehiclesList />
          <Spacer spacing={Theme.spacing.xl} />
          <Flex alignItems="center">
            <AddVehicleButton expanded={false} />
          </Flex>
        </Box>
      </Block>
    </SimpleLayout>
  );
};

export default WebVehiclesLayout;
