import {TransitionPresets} from '@react-navigation/stack';
import * as React from 'react';

import ActivateAccount from '@screens/ActivateAccount';
import CompanyLogin from '@screens/CompanyLogin';
import ForgottenPassword from '@screens/ForgottenPassword';
import Login from '@screens/Login';
import OnBoardingScreen from '@screens/OnBoardingScreen';
import Registration from '@screens/Registration';
import ResetPassword from '@screens/ResetPassword';
import SsoCompanyLogin from '@screens/SsoCompanyLogin';
import SsoLogin from '@screens/SsoLogin';
import SsoRedirect from '@screens/SsoRedirect';
import SsoValidate from '@screens/SsoValidate';
import SsoWebview from '@screens/SsoWebview';

import {RootStack} from './utils';

export const renderUnauthenticatedScreens = (
  Stack: RootStack,
  getTitle: (key: string) => string,
) => {
  return (
    <>
      <Stack.Screen
        name="Login"
        component={Login}
        options={{
          title: getTitle('login'),
        }}
      />
      <Stack.Screen
        name="OnBoarding"
        component={OnBoardingScreen}
        options={{
          ...TransitionPresets.ModalTransition,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ResetPassword"
        component={ResetPassword}
        options={{
          title: getTitle('reset-password-screen-title'),
        }}
      />
      <Stack.Screen
        name="ForgottenPassword"
        component={ForgottenPassword}
        options={{
          title: getTitle('forgotten-password-screen-title'),
        }}
      />
      <Stack.Screen
        name="SsoWebview"
        component={SsoWebview}
        options={{
          title: getTitle('sso-webview-screen-title'),
        }}
      />
      <Stack.Screen
        name="SsoCompanyLogin"
        component={SsoCompanyLogin}
        options={{
          title: getTitle('sso-company-login-screen-title'),
        }}
      />
      <Stack.Screen
        name="SsoLogin"
        component={SsoLogin}
        options={{
          title: getTitle('sso-login-screen-title'),
        }}
      />
      <Stack.Screen
        name="SsoRedirect"
        component={SsoRedirect}
        options={{
          title: getTitle('sso-signin-title'),
        }}
      />
      <Stack.Screen
        name="SsoValidate"
        component={SsoValidate}
        options={{
          title: getTitle('sso-signin-title'),
        }}
      />

      <Stack.Screen
        name="Registration"
        component={Registration}
        options={{
          title: getTitle('registration'),
        }}
      />
      <Stack.Screen
        name="CompanyLogin"
        component={CompanyLogin}
        options={{
          title: getTitle('login'),
        }}
      />
      <Stack.Screen
        name="ActivateAccount"
        component={ActivateAccount}
        options={{
          title: getTitle('activate-account'),
        }}
      />
    </>
  );
};
