import * as React from 'react';
import {
  TextInput,
  StyleSheet,
  Platform,
  Pressable,
  View,
  ViewStyle,
  KeyboardTypeOptions,
  TextInputProps,
  StyleProp,
} from 'react-native';

import Theme from '../../config/theme';
import Field from '../Field';
import Text, {Styles as TextStyles} from '../Text';

export interface InputProps {
  disabled?: boolean;
  disabledBackgroundColor?: string;
  placeholder?: string;
  error?: string;
  leftIconName?: string;
  rightIconName?: string;
  iconColor?: string;
  showLabel?: boolean;
  label?: string;
  style?: StyleProp<ViewStyle>;
  onBlur?: TextInputProps['onBlur'];
  onFocus?: TextInputProps['onFocus'];
  pointerEvents?: 'box-none' | 'none' | 'box-only' | 'auto';

  value?: string;
  maxLength?: number;
  secureTextEntry?: boolean;
  keyboardType?: KeyboardTypeOptions;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  onChangeText?: (text: string) => void;
  onSubmitEditing?: () => void;
  onPressIconLeft?: () => void;
  onPressIconRight?: () => void;

  testID?: string;
}
const Input: React.FC<InputProps> = ({
  value = '',
  style,
  maxLength,
  pointerEvents,
  secureTextEntry,
  onSubmitEditing,
  keyboardType,
  autoCapitalize,
  error,
  placeholder,
  label,
  onBlur,
  onFocus,
  onPressIconRight,
  testID,
  ...fieldProps
}) => {
  const {disabled, showLabel = true, onChangeText} = fieldProps;

  const container = React.useRef<View>(null);
  const input = React.useRef<TextInput>(null);

  const [isFocused, setIsFocused] = React.useState(false);
  const [secureTextEntryValue, setSecureTextEntryValue] =
    React.useState(secureTextEntry);

  const isOpened = Boolean(isFocused || value);

  const onPressFallback = () =>
    secureTextEntry
      ? setSecureTextEntryValue(!secureTextEntryValue)
      : () => null;

  return (
    <View testID={testID} ref={container} style={style}>
      <Pressable
        focusable={false}
        pointerEvents={disabled ? 'none' : pointerEvents}
        onPress={() => {
          if (disabled || isFocused || !input.current) {
            return;
          }

          input.current.focus();
        }}>
        <Field
          testID={testID}
          isFocused={isFocused}
          isOpened={isOpened}
          isValid={!error}
          placeholder={isOpened ? label || placeholder : placeholder}
          onPressIconRight={onPressIconRight ?? onPressFallback}
          {...fieldProps}>
          <TextInput
            testID={`${testID}-input`}
            value={value}
            keyboardType={keyboardType}
            editable={!disabled}
            autoCapitalize={autoCapitalize}
            placeholder={!showLabel ? placeholder : ''}
            ref={input}
            style={[
              TextStyles.Body,
              Styles.input,
              Platform.OS === 'web' ? webInputStyle : {},
            ]}
            onFocus={args => {
              setIsFocused(true);
              onFocus && onFocus(args);
            }}
            onBlur={args => {
              onChangeText && onChangeText(value.trim());
              setIsFocused(false);
              onBlur && onBlur(args);
            }}
            placeholderTextColor={Theme.colors.secondary}
            secureTextEntry={secureTextEntryValue}
            onSubmitEditing={onSubmitEditing}
            onChangeText={onChangeText}
            maxLength={maxLength}
          />
        </Field>
      </Pressable>
      <Text testID={`${testID}-error`} variant="Label" style={Styles.errorText}>
        {error || ' '}
      </Text>
    </View>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const webInputStyle: any = {outline: 'none'};

const Styles = StyleSheet.create({
  input: {
    lineHeight: undefined,
    height: 20,
    padding: 0,
    margin: 0,
    color: Theme.colors.primary,
  },
  errorText: {
    color: Theme.colors.error,
  },
});

export default Input;
