/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import {Animated, ViewProps} from 'react-native';

interface Props extends ViewProps {
  spacing: number;
  mode?: 'vertical' | 'horizontal';
  animated?: boolean;
  animationDuration?: number;
}
const Spacer: React.FC<Props> = ({
  spacing,
  mode = 'vertical',
  animated = false,
  animationDuration = 100,
  style,
  ...props
}) => {
  const animation = React.useRef(new Animated.Value(spacing)).current;

  React.useEffect(() => {
    if (!animated) {
      animation.setValue(spacing);

      return;
    }

    Animated.timing(animation, {
      toValue: spacing,
      duration: animationDuration,
      useNativeDriver: false,
    }).start();
  }, [spacing, animationDuration, animated, animation]);
  return (
    <Animated.View
      {...props}
      style={[
        style,
        mode === 'vertical'
          ? {width: '100%', height: animation}
          : {width: animation, height: '100%'},
      ]}
    />
  );
};

export default Spacer;
