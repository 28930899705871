import * as React from 'react';
import {TouchableOpacity} from 'react-native';

import Box from '../../../components/Box';
import Hoverable from '../../../components/Hoverable';
import {RowComponentProps} from '../../../components/Select/RowComponent';
import Theme from '../../../config/theme';

import LanguageRow from './LanguageRow';
import {LanguageOption, getIconName} from './utils';

const RowComponent: React.FC<RowComponentProps<number>> = props => {
  const {index, isSelected, options, onPress} = props;
  const {label, language} = options[index] as LanguageOption;
  const iconName = getIconName(language);

  return (
    <TouchableOpacity onPress={onPress}>
      <Hoverable
        backgroundColor={Theme.colors.white}
        hoverColor={Theme.colors.secondaryHover}>
        <Box padding={Theme.spacing.l} skipTop skipBottom>
          <LanguageRow
            iconName={iconName}
            label={label}
            isSelected={isSelected}
          />
        </Box>
      </Hoverable>
    </TouchableOpacity>
  );
};

export default RowComponent;
