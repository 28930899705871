import {useFocusEffect} from '@react-navigation/native';
import * as React from 'react';

import Theme from '@config/theme';

import LoginLayout from '@common/shared_layouts/LoginLayout';

import Loader from '@components/Loader';
import Spacer from '@components/Spacer';
import TermsAndConditionsAndPolicyText from '@components/TermsAndConditionsAndPolicyText';

import {useApi} from '@services/pacoApi';
import {useQuery} from '@services/queries';

import ActivateAccountErrorPopup from './ActivateAccountErrorPopup';
import ActivateAccountForm from './ActivateAccountForm';

type Props = {
  code: string;
  email: string;
};

const ActivateAccountContainer: React.FC<Props> = ({code, email}) => {
  const api = useApi();

  const [showActivateAccountErrorPopup, setShowActivateAccountErrorPopup] =
    React.useState(false);

  const {
    isError: fetchingCanActivateIsError,
    isLoading: fetchingCanActivateIsLoading,
    error: apiErrorCanActivateAccount,
    refetch: refetchCanActivateAccount,
  } = useQuery(
    'canActivateAccount',
    async () =>
      api.Authentication.v1AuthenticationCanActivateAccountGet(code, email),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  React.useEffect(() => {
    if (fetchingCanActivateIsError) {
      setShowActivateAccountErrorPopup(true);
    }
  }, [fetchingCanActivateIsError, apiErrorCanActivateAccount]);

  useFocusEffect(
    React.useCallback(() => {
      refetchCanActivateAccount();
    }, [refetchCanActivateAccount]),
  );

  return (
    <LoginLayout>
      <ActivateAccountForm code={code} email={email} />
      <Spacer spacing={Theme.spacing.l} />
      <TermsAndConditionsAndPolicyText />

      <ActivateAccountErrorPopup
        visible={showActivateAccountErrorPopup}
        close={() => setShowActivateAccountErrorPopup(false)}
        error={apiErrorCanActivateAccount}
        email={email}
      />
      <Loader isLoading={fetchingCanActivateIsLoading} />
    </LoginLayout>
  );
};

export default ActivateAccountContainer;
