import * as React from 'react';
import {View, StyleSheet} from 'react-native';

import Theme from '../config/theme';
import {RuleState} from '../services/pacoApi';

import Flex from './Flex';
import Icon from './Icon';
import Spacer from './Spacer';
import Text from './Text';

interface Props {
  rules: RuleState[];
}

const RulesView: React.FC<Props> = props => {
  const {rules} = props;
  return (
    <View style={Styles.container}>
      {rules.map((rule, index) => {
        if (!rule.message) {
          return null;
        }

        return (
          <React.Fragment key={index}>
            <Flex
              style={[
                Styles.row,
                index !== rules.length - 1 ? Styles.border : null,
                rule.success === false ? Styles.ruleEvaluationFail : null,
              ]}
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <Text style={Theme.commonStyles.flex1}>{rule.message}</Text>
              <Spacer spacing={Theme.spacing.l} mode="horizontal" />
              <Icon
                name={getIconName(rule?.success)}
                color={getColor(rule?.success)}
                size={Theme.icons.xl}
              />
            </Flex>
          </React.Fragment>
        );
      })}
    </View>
  );
};
const Styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: Theme.radius.default,
    borderColor: Theme.colors.border,
  },
  row: {
    width: '100%',
    padding: Theme.spacing.m,
  },
  border: {
    borderBottomWidth: 1,
    borderColor: Theme.colors.border,
  },
  ruleEvaluationFail: {
    backgroundColor: Theme.colors.errorLight,
  },
});

const getIconName = (success?: boolean | null): string => {
  if (success == null) {
    return 'QuestionMark';
  }
  return success ? 'Available' : 'Unavailable';
};

const getColor = (success?: boolean | null): string => {
  if (success == null) {
    return Theme.colors.primary;
  }
  return success ? Theme.colors.good : Theme.colors.error;
};

export default RulesView;
