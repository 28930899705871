import {getAnalytics, logEvent, setCurrentScreen} from 'firebase/analytics';

const instance = getAnalytics();

export const trackScreenView = (name: string): void => {
  setCurrentScreen(instance, name);
  logEvent(instance, 'screen_view');
};
export const trackEvent = (name: string): void => {
  logEvent(instance, name);
};
