import * as yup from 'yup';

import {DMY} from '../../components/DatePicker/utils';
import {HM} from '../../components/TimePicker/utils';

export type FormValues = {
  selectedVehicle?: number;
  isEv: boolean;
  fromDate?: DMY;
  fromHour?: HM;
  toDate?: DMY;
  toHour?: HM;
  isPrmSpaceRequired: boolean;
  selectedParking?: number;
};

export const validationSchema = yup.object().shape({
  selectedVehicle: yup.number().required(),
  isEv: yup.boolean(),
  fromDate: yup.object().required(),
  fromHour: yup.object().required(),
  toDate: yup.object().required(),
  toHour: yup.object().required(),
  isPrmSpaceRequired: yup.boolean(),
  selectedParking: yup.number().required(),
});
