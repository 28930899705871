import * as React from 'react';

import VehicleForm from '../components/VehicleForm/VehicleForm';
import {RootNavigationProps} from '../navigators';

const EditVehicle: React.FC<RootNavigationProps<'EditVehicle'>> = props => {
  const {id, name, isEv, registrationPlateCountry, registrationPlate, type} =
    props.route.params;
  if (
    id == null ||
    name == null ||
    registrationPlateCountry === undefined ||
    registrationPlate === undefined
  ) {
    props.navigation.goBack();
  }

  return (
    <>
      <VehicleForm
        id={id}
        name={name}
        registrationPlate={registrationPlate}
        registrationPlateCountry={registrationPlateCountry}
        isEv={isEv}
        type={type}
      />
    </>
  );
};

export default EditVehicle;
