import testIDs from '@testIDs';
import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import * as yup from 'yup';

import Theme from '@config/theme';

import {RootNavigationProps} from '@common/navigators';
import LoginLayout from '@common/shared_layouts/LoginLayout';

import Block from '@components/Block';
import Box from '@components/Box';
import Button from '@components/Button';
import Input from '@components/Input';
import Loader from '@components/Loader';
import Popup from '@components/Popup';
import Spacer from '@components/Spacer';

import {useApi} from '@services/pacoApi';
import {useMessagePopup} from '@services/popups';
import usePacoApiError from '@services/usePacoApiError';

type FormValues = {
  email: string;
};

const ForgottenPassword: React.FC<RootNavigationProps<'ForgottenPassword'>> = ({
  navigation,
}) => {
  const {t} = useTranslation();
  const api = useApi();
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError();
  const {props: successPopupProps, show: showSuccess} = useMessagePopup({
    testID: testIDs.forgottenPassword.successPopup,
  });

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid-email-format'))
      .required(t('email-required')),
  });

  const {values, errors, touched, setFieldValue, handleSubmit, handleBlur} =
    useFormik<FormValues>({
      initialValues: {
        email: '',
      },
      validationSchema,
      validateOnChange: false,
      validateOnBlur: true,
      onSubmit: () => {
        sendForgottenPasswordRequest();
      },
    });

  const {
    isLoading,
    isSuccess,
    data: apiResponse,
    isError,
    error: apiError,
    mutate: sendForgottenPasswordRequest,
  } = useMutation(async () => {
    return api.Drivers.v1DriversForgottenPasswordPatch({
      email: values.email,
    });
  });

  React.useEffect(() => {
    if (isError) {
      handleError(apiError);
    }
  }, [isError, apiError, handleError]);

  React.useEffect(() => {
    if (isSuccess && apiResponse?.data) {
      showSuccess({
        message: apiResponse.data.message,
        title: apiResponse.data.title,
        onClose: () => navigation.navigate('Login'),
      });
    }
  }, [isSuccess, apiResponse, navigation, showSuccess]);

  return (
    <LoginLayout>
      <Block title={t('forgotten-password-screen-title')}>
        <Box padding={Theme.spacing.l}>
          <Input
            value={values.email}
            keyboardType="email-address"
            autoCapitalize="none"
            onChangeText={value => setFieldValue('email', value)}
            error={touched.email ? errors.email : undefined}
            placeholder={t('email-placeholder')}
            onBlur={handleBlur('email')}
            testID={testIDs.forgottenPassword.email}
          />
          <Spacer spacing={Theme.spacing.s} />
          <Button
            testID={testIDs.forgottenPassword.submit}
            title={t('forgotten-password-confirm-reset')}
            onPress={() => handleSubmit()}
          />
        </Box>
      </Block>

      <Popup {...successPopupProps} />
      <Popup {...errorPopupProps} />
      <Loader isLoading={isLoading} />
    </LoginLayout>
  );
};

export default ForgottenPassword;
