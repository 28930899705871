/* eslint-disable @typescript-eslint/no-var-requires */
import Theme from './theme';

const getFontStyle = (font: string, family: string): string => {
  return `
      @font-face {
        src: url(${font});
        font-family: ${family};
      }
      `;
};

const iconFontStyles = getFontStyle(
  require('../../../assets/icomoon/fonts/icomoon.ttf').default,
  'icomoon',
);

const fontsStyles = Object.values(Theme.fonts.family).map(family => {
  return getFontStyle(
    require(`../../../assets/fonts/${family}.ttf`).default,
    family,
  );
});

const webStyles = [iconFontStyles, ...fontsStyles].join('\n');

// Create stylesheet
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const style: any = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = webStyles;
} else {
  style.appendChild(document.createTextNode(webStyles));
}

// Inject stylesheet
document.head.appendChild(style);

export {default} from './theme';
