import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {DriverInfo, UserType} from '../../services/pacoApi';

interface MemberState {
  lastUsedVehicleId: string | null;
  lastUsedParkingId: string | null;
  driverInfo: DriverInfo | null;
}

const initialState: MemberState = {
  lastUsedVehicleId: null,
  lastUsedParkingId: null,
  driverInfo: null as unknown as DriverInfo,
};

const MemberSlice = createSlice({
  name: 'Member',
  initialState,
  reducers: {
    setLastUsedVehicleId: (state, action: PayloadAction<string>) => {
      state.lastUsedVehicleId = action.payload;
    },
    setLastUsedParkingId: (state, action: PayloadAction<string>) => {
      state.lastUsedParkingId = action.payload;
    },
    setDriverInfo: (state, action: PayloadAction<DriverInfo>) => {
      state.driverInfo = action.payload;
      if (
        !state.driverInfo.parkings ||
        state.driverInfo.parkings.length === 0 ||
        !state.driverInfo.parkings[0].id
      ) {
        if (state.driverInfo.userType === UserType.Driver) {
          throw 'Driver has no access to a parking';
        }
        return;
      }
    },
    clearDriverInfo: state => {
      state.driverInfo = null;
      state.lastUsedVehicleId = null;
      state.lastUsedParkingId = null;
    },
  },
});

export const MemberActions = MemberSlice.actions;
export default MemberSlice.reducer;
