import testIDs from '@testIDs';
import {DateTime} from 'luxon';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Theme from '@config/theme';

import {PlanningActions, useAppDispatch} from '@common/reducers';

import Button from '@components/Button';
import Flex from '@components/Flex';
import Loader from '@components/Loader';
import Popup from '@components/Popup';
import Spacer from '@components/Spacer';
import Text from '@components/Text';
import {dateTimeToHM, HMtoString} from '@components/TimePicker/utils';

import {useApi} from '@services/pacoApi';
import {useMessagePopup} from '@services/popups';
import {useMutation} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

interface Props {
  allocationId: string;
  from: DateTime;
  to: DateTime;
  visible: boolean;
  parkingName: string;
  close: () => void;
}
const CancelReservationPopup: React.FC<Props> = ({
  allocationId,
  from,
  to,
  visible,
  parkingName,
  close,
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const api = useApi();

  const {popupProps: errorPopupProps, handle: showError} = usePacoApiError();
  const {props: successPopupProps, show: showSuccess} = useMessagePopup({
    testID: testIDs.planning.popup.success,
  });
  const {
    isError,
    isSuccess,
    isLoading,
    error: apiError,
    mutate: handleConfirmClick,
    data: apiResponse,
  } = useMutation(async () => {
    return await api.Reservations.v1ReservationsReservationIdDelete(
      allocationId,
    );
  });

  React.useEffect(() => {
    if (isError) {
      showError(apiError);
      dispatch(PlanningActions.setShouldReloadPlanning(true));
    }

    if (isSuccess) {
      showSuccess({
        message: apiResponse?.data.message,
        title: apiResponse?.data.title,
        onClose: () => dispatch(PlanningActions.setShouldReloadPlanning(true)),
      });
    }
  }, [
    isError,
    isSuccess,
    apiResponse,
    apiError,
    showError,
    showSuccess,
    dispatch,
  ]);

  return (
    <>
      <Popup
        visible={visible}
        close={close}
        testID={testIDs.planning.popup.cancelReservation}>
        <Flex alignItems="center">
          <Text>
            {t('cancel-reservation-confirm', {
              fromDay: from.day,
              fromMonth: from.toLocaleString({month: 'long'}),
              fromTime: HMtoString(dateTimeToHM(from)),
              toDay: to.day,
              toMonth: to.toLocaleString({month: 'long'}),
              toTime: HMtoString(dateTimeToHM(to)),
              parkingName: parkingName,
            })}
          </Text>
        </Flex>
        <Spacer spacing={Theme.spacing.l} />
        <Button
          testID={`${testIDs.planning.popup.cancelReservation}-yes`}
          title={t('cancel-reservation-popup-yes')}
          variant="Primary"
          onPress={() => {
            handleConfirmClick();
            close();
          }}
        />
        <Spacer spacing={Theme.spacing.m} />
        <Button
          title={t('cancel-reservation-popup-no')}
          variant="Secondary"
          onPress={close}
        />
      </Popup>
      <Loader
        isLoading={isLoading}
        title={t('cancel-reservation-button-click-loading')}
      />
      <Popup {...successPopupProps} />
      <Popup {...errorPopupProps} />
    </>
  );
};

export default CancelReservationPopup;
