/* eslint-disable @typescript-eslint/no-explicit-any */
import {useMediaQuery} from 'react-responsive';

import {getDeviceType} from './helpers/deviceHelper';

type BreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

type ResponsiveStyle = Partial<Record<BreakPoint, any>>;

export const responsiveContainerWidth: Record<BreakPoint, any> = {
  xs: {
    width: '100%',
  },
  sm: {
    width: '540px',
  },
  md: {
    width: '720px',
  },
  lg: {
    width: '960px',
  },
  xl: {
    width: '1140px',
  },
  xxl: {
    width: '1320px',
  },
};

type Responsive = {
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  isXxl: boolean;
};
export const useResponsive = (): Responsive => {
  if (getDeviceType() !== 'web-desktop') {
    throw 'Not supported for this platform';
  }

  const isXs = useMediaQuery({query: '(max-width: 576px)'});
  const isSm = useMediaQuery({query: '(min-width: 576px)'});
  const isMd = useMediaQuery({query: '(min-width: 768px)'});
  const isLg = useMediaQuery({query: '(min-width: 992px)'});
  const isXl = useMediaQuery({query: '(min-width: 1200px)'});
  const isXxl = useMediaQuery({query: '(min-width: 1400px)'});

  return {isXs, isSm, isMd, isLg, isXl, isXxl};
};

export const useResponsiveProperty = (styles: ResponsiveStyle): any => {
  const getStyle = (breakpoint: BreakPoint): any => styles[breakpoint] ?? {};
  const {isXs, isSm, isMd, isLg, isXl, isXxl} = useResponsive();
  return {
    ...(isXs ? getStyle('xs') : {}),
    ...(isSm ? getStyle('sm') : {}),
    ...(isMd ? getStyle('md') : {}),
    ...(isLg ? getStyle('lg') : {}),
    ...(isXl ? getStyle('xl') : {}),
    ...(isXxl ? getStyle('xxl') : {}),
  };
};
