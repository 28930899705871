import * as React from 'react';
import {View, ViewProps} from 'react-native';
import {useHover} from 'react-native-web-hooks';

import Theme from '../config/theme';

interface Props extends ViewProps {
  backgroundColor?: string;
  hoverColor?: string;
}
const Hoverable: React.FC<Props> = ({
  backgroundColor = Theme.colors.white,
  hoverColor = Theme.colors.secondaryHover,
  children,
  style,
  ...props
}) => {
  const ref = React.useRef<View>(null);
  const isHover = useHover(ref);

  const _backgroundColor = isHover ? hoverColor : backgroundColor;

  return (
    <View
      ref={ref}
      {...props}
      style={[
        {
          backgroundColor: _backgroundColor,
        },
        style,
      ]}>
      {children}
    </View>
  );
};

export default Hoverable;
