import * as React from 'react';
import {StyleSheet} from 'react-native';

import Theme from '../config/theme';

import Text, {Props as TextProps} from './Text';

interface Props extends TextProps {
  onPress: () => void;
}

const TextButton: React.FC<Props> = props => {
  return <Text align="center" {...props} style={[Styles.text, props.style]} />;
};

const Styles = StyleSheet.create({
  text: {
    fontSize: 16,
    lineHeight: 24,
    color: Theme.colors.black,
    fontFamily: Theme.fonts.family.InterBold,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
  },
});

export default TextButton;
