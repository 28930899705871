import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Image,
  ScrollView,
  ActivityIndicator,
  StyleSheet,
  Dimensions,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import theme from '@config/theme';

import Box from '@components/Box';
import Flex from '@components/Flex';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import {HeaderFlexLogo} from '@navigators/Root/Header';

import {restartApp} from '@services/helpers/appHelper';
import {useUpdates} from '@services/updates';

import images from '../../assets/images';

const Updates: React.FC = () => {
  const {t} = useTranslation();
  const {status, update} = useUpdates();

  React.useEffect(() => {
    switch (status) {
      case 'FAIL':
      case 'SUCCESS':
        setTimeout(() => restartApp(), 1000);
    }
  }, [status]);

  return (
    <Flex
      style={theme.commonStyles.flex1}
      backgroundColor={theme.colors.secondaryHover}>
      <SafeAreaView style={theme.commonStyles.flex1}>
        <ScrollView>
          <Box padding={theme.spacing.xxxl}>
            <Flex>
              <HeaderFlexLogo />
              <Spacer spacing={theme.spacing.xl} />
              <Text variant="Medium" align="center">
                {t('update-in-progress')}
              </Text>
              <Spacer spacing={theme.spacing.xl} />
              <Text> {update?.description}</Text>
              <Spacer spacing={theme.spacing.xxl} />
              <ActivityIndicator color={theme.colors.black} size={'large'} />
            </Flex>
          </Box>
        </ScrollView>
      </SafeAreaView>
      <Image
        source={images['zenpark-illustration']}
        style={Styles.illustration}
        resizeMode="cover"
      />
    </Flex>
  );
};

const Styles = StyleSheet.create({
  illustration: {
    bottom: 0,
    position: 'absolute',
    width: Dimensions.get('screen').width,
    height: Dimensions.get('screen').width * 0.67,
  },
});
export default Updates;
