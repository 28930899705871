import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import testIDs from '@testIDs';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Icon from '../components/Icon';
import Theme from '../config/theme';
import {AppActions, useAppDispatch} from '../reducers';
import Access from '../screens/Access/Access';
import Account from '../screens/Account';
import Planning from '../screens/Planning';
import {getDeviceType} from '../services/helpers/deviceHelper';
import {useVersionState} from '../services/helpers/versionHelper';

export type HomeParamList = {
  Planning: undefined;
  Access: undefined;
  Account: undefined;
};

const Tab = createBottomTabNavigator<HomeParamList>();

const HomeNavigator: React.FC = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const versionState = useVersionState();
  const pageTitleSuffix = t('layout-head-title-suffix');

  return (
    <Tab.Navigator
      initialRouteName="Planning"
      tabBar={getDeviceType() === 'web-desktop' ? () => null : undefined}
      tabBarOptions={{
        labelStyle: {
          fontFamily: Theme.fonts.family.InterMedium,
          fontSize: 12,
        },
        activeTintColor: Theme.colors.callToAction,
        inactiveTintColor: Theme.colors.primary,
      }}>
      <Tab.Screen
        name="Planning"
        component={Planning}
        listeners={{
          focus: () => dispatch(AppActions.setCurrentTab('Planning')),
        }}
        options={{
          tabBarTestID: testIDs.tabBar.planning,
          title: t('tabbar-planning') + pageTitleSuffix,
          tabBarLabel: t('tabbar-planning'),
          tabBarIcon: ({size, color}) => (
            <Icon size={size} color={color} name="Planning" />
          ),
        }}
      />
      <Tab.Screen
        name="Access"
        component={Access}
        listeners={{
          focus: () => dispatch(AppActions.setCurrentTab('Access')),
        }}
        options={{
          tabBarTestID: testIDs.tabBar.access,
          tabBarLabel: t('tabbar-access'),
          title: t('tabbar-access') + pageTitleSuffix,
          tabBarIcon: ({size, color}) => (
            <Icon size={size} color={color} name="Access" />
          ),
        }}
      />
      <Tab.Screen
        name="Account"
        component={Account}
        listeners={{
          focus: () => dispatch(AppActions.setCurrentTab('Account')),
        }}
        options={{
          tabBarTestID: testIDs.tabBar.myAccount,
          title: t('tabbar-account') + pageTitleSuffix,
          tabBarLabel: t('tabbar-account'),
          tabBarIcon: ({size, color}) => (
            <Icon size={size} color={color} name="Profile" />
          ),
          tabBarBadge: versionState === 'WARNING' ? '' : undefined,
          tabBarBadgeStyle: {backgroundColor: Theme.colors.warning},
        }}
      />
    </Tab.Navigator>
  );
};

export default HomeNavigator;
