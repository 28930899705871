import * as React from 'react';

import {RootNavigationProps} from '../../navigators';

import ActivateAccountContainer from './ActivateAccountContainer';

const ActivateAccount: React.FC<RootNavigationProps<'ActivateAccount'>> = ({
  route,
  navigation,
}) => {
  const {email, code} = route.params ?? {};

  React.useEffect(() => {
    if (!email || !code) {
      navigation.navigate('Login');
    }
  }, [navigation, email, code]);

  if (!email || !code) {
    return null;
  }

  return <ActivateAccountContainer code={code} email={email} />;
};

export default ActivateAccount;
