import {
  ENV,
  API_ENV_NAME,
  LOG_REDUX,
  SHOW_DEV_TAB,
  DEEPLINK_PREFIXS,
  SSO_REDIRECT_URL,
  FRONT_PUBLIC_URL,
  BASE_URL,
  // eslint-disable-next-line import/no-unresolved
} from '@env';
import {Platform} from 'react-native';

import AppInfo from '../../package.json';

export type Env = 'dev' | 'preprod' | 'production' | 'demo';

const pacoApiUrls = {
  dev: 'https://paco-driverfrontofficeapi-dev.azurewebsites.net',
  preprod: 'https://paco-driverfrontofficeapi-preprod.azurewebsites.net',
  production: 'https://paco-driverfrontofficeapi-prod.azurewebsites.net',
  demo: 'https://paco-driverfrontofficeapi-demo.azurewebsites.net',
};

const getEnvFromUrl = (url: string): string => {
  const index = Object.values(pacoApiUrls).findIndex(item => item === url);
  if (index < 0) {
    return 'custom';
  }
  return Object.keys(pacoApiUrls)[index];
};

// TODO change url when apps on store
const storeUrl =
  Platform.OS === 'ios'
    ? 'https://apps.apple.com/fr/app/flex-zenpark-pro/id1585367209'
    : 'https://play.google.com/store/apps/details?id=pro.zenpark.flex';

const currentEnv: Env = API_ENV_NAME;

const showDevTab = Boolean(Number(SHOW_DEV_TAB));

const shouldLogRedux = Boolean(Number(LOG_REDUX));

const appVersion = AppInfo.version;
const deeplinkPrefixs = DEEPLINK_PREFIXS.split(',');

const isProduction = ENV === 'prod' || ENV === 'store';

export default {
  appEnvName: ENV,
  ssoRedirectUrl: SSO_REDIRECT_URL,
  frontPublicUrl: FRONT_PUBLIC_URL,
  webBaseUrl: BASE_URL,
  isProduction,
  apiTimeout: 120000,
  showDevTab,
  shouldLogRedux,
  pacoApiUrls,
  currentEnv,
  storeUrl,
  appVersion,
  getEnvFromUrl,
  deeplinkPrefixs,
};
