import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';
import {
  default as Animated,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';
import TouchBlocker from 'react-native-touch-blocker';

import Theme from '@config/theme';

import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import Spacer from '@components/Spacer';

interface Props {
  size: number;
  currentIndex: number;
  onNextPress: () => void;
}

const OnBoardingPagination: React.FC<Props> = ({
  currentIndex,
  size,
  onNextPress,
}) => {
  const {t} = useTranslation();

  const style = useAnimatedStyle(() => {
    const animationValue =
      currentIndex <= size - 2 ? 1 : size - currentIndex - 1;
    const height = withTiming(56 * animationValue, {
      duration: 500,
    });

    return {
      opacity: animationValue,
      height,
    };
  });

  return (
    <Animated.View style={style}>
      <Box style={Styles.innerContainer} padding={Theme.spacing.l} skipTop>
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Spacer spacing={100} mode="horizontal" />
          <Flex direction="row">
            {Array.from({length: size}).map((_, index) => {
              return (
                <Box padding={Theme.spacing.s / 2} key={index}>
                  <Flex
                    backgroundColor={
                      index === Math.round(currentIndex)
                        ? Theme.colors.primary
                        : Theme.colors.sapphire60
                    }
                    style={Styles.dot}
                  />
                </Box>
              );
            })}
          </Flex>
          <TouchBlocker enableTouchEvents={currentIndex < size - 1}>
            <Button
              style={Styles.nextButton}
              title={t('onboarding-next-button')}
              onPress={onNextPress}
            />
          </TouchBlocker>
        </Flex>
      </Box>
    </Animated.View>
  );
};

const Styles = StyleSheet.create({
  dot: {
    width: Theme.spacing.m,
    height: Theme.spacing.m,
    borderRadius: Theme.spacing.m / 2,
  },
  nextButton: {
    paddingVertical: Theme.spacing.s,
    width: 100,
  },
  innerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});

export default OnBoardingPagination;
