import {getNavigationContainer} from '@common/navigators';
import {AuthActions, MemberActions, store} from '@common/reducers';

import {AppAction} from './pacoApi';

export const handle = (appAction?: AppAction): void => {
  switch (appAction) {
    case AppAction.Logout: {
      getNavigationContainer()?.navigate('Login');
      store.dispatch(AuthActions.clearTicket());
      store.dispatch(MemberActions.clearDriverInfo());
      return;
    }
  }
};
