import {Dimensions, StyleSheet} from 'react-native';
import NestedText from 'rn-nested-text';

import {
  openPrivacyPolicy,
  openTermsAndConditions,
} from '@services/helpers/appHelper';

const white = '#FFF';
const sapphire = '#102567';
const rockBlue = '#939CB3';
const persianRose = '#F92D9E';
const pastelGreen = '#75D06F';
const coralRed = '#FF4646';
const cottonCandy = '#FFB7DF';
const whisper = '#EAEAEA';
const teaGreen = '#C5EDC2';
const ghostWhite = '#F5F6FA';
const deepCerise = '#E72290';
const backdrop = '#10256733';
const lavenderBlush = '#FFF0F0';
const green = '#166C4A';
const sapphire60 = '#707ca4';
const green60 = '#73a792';
const rockBlue60 = '#bec4d1';
const koromiko = '#ffad4e'; //enGrosOrange
const black = '#262626';
const gray = '#565c70';
const cherub = '#f8d7da';
const barleyWhite = '#fff3cd';

const colors = {
  white,
  ghostWhite,
  sapphire60,
  black,
  gray,
  sapphire,
  rockBlue,
  persianRose,
  cottonCandy,
  whisper,
  teaGreen,
  backdrop,
  lavenderBlush,
  koromiko,

  primary: sapphire,
  secondary: rockBlue,
  callToAction: persianRose,
  good: pastelGreen,
  error: coralRed,
  border: whisper,
  mainHover: deepCerise,
  mainDisabled: cottonCandy,
  secondaryHover: ghostWhite,
  warning: koromiko,
  danger: cherub,

  reservation: green,
  pastReservation: green60,
  releasedSpaceAllocation: rockBlue,
  pastReleasedSpaceAllocation: rockBlue60,
  manualAllocation: sapphire,
  pastManualAllocation: sapphire60,

  errorLight: lavenderBlush,
  warningLight: barleyWhite,
};

const spacing = {
  xs: 4,
  s: 8,
  m: 12,
  l: 16,
  xl: 24,
  xxl: 32,
  xxxl: 48,
  window: Dimensions.get('window'),
};
const icons = {
  m: 16,
  l: 20,
  xl: 30,
  xxl: 42,
};

const radius = {
  default: 8,
  container: 16,
};

const fonts = {
  family: {
    BasicGrotesqueBold: 'BasisGrotesque-Bold',
    InterMedium: 'Inter-Medium',
    InterBold: 'Inter-Bold',
  },
};

const commonStyles = StyleSheet.create({
  flex1: {
    flex: 1,
  },
  backgroundColor1: {
    backgroundColor: colors.white,
  },
});

NestedText.defaultTextProps.cgu = {
  onPress: () => openTermsAndConditions(),
};

NestedText.defaultTextProps.privacyPolicy = {
  onPress: () => openPrivacyPolicy(),
};

export default {fonts, spacing, icons, colors, commonStyles, radius};
