import {useFocusEffect, useNavigation} from '@react-navigation/native';
import testIDs from '@testIDs';
import * as React from 'react';
import {FlatList} from 'react-native';

import usePacoApiError from '@services/usePacoApiError';

import Loader from '../../components/Loader';
import Popup from '../../components/Popup';
import Separator from '../../components/Separator';
import {RootNavigationProps} from '../../navigators';
import {useApi} from '../../services/pacoApi';
import {useQuery} from '../../services/queries';

import VehicleItem from './VehicleItem';

const Vehicles: React.FC = () => {
  const navigation =
    useNavigation<RootNavigationProps<'Vehicles'>['navigation']>();

  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError({
    onCloseErrorPopup: () => {
      navigation.goBack();
    },
  });
  const api = useApi();
  const {
    data: fetchVehiclesResponse,
    error: fetchingVehiclesError,
    isLoading: isLoadingVehicles,
    refetch: refetchVehicles,
  } = useQuery('vehicles', async () => api.Vehicles.v1VehiclesGet(), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  React.useEffect(() => {
    if (fetchingVehiclesError) {
      handleError(fetchingVehiclesError);
    }
  }, [fetchingVehiclesError, handleError]);

  useFocusEffect(
    React.useCallback(() => {
      refetchVehicles();
    }, [refetchVehicles]),
  );
  return (
    <>
      <FlatList
        data={(fetchVehiclesResponse?.data ?? []).filter(function (element) {
          return element !== null;
        })}
        keyExtractor={data => `${data.vehicleId}`}
        ItemSeparatorComponent={Separator}
        testID={testIDs.vehicles.list}
        renderItem={({item: vehicle}) => {
          return (
            <VehicleItem
              vehicle={vehicle}
              openEditVehiclePage={() => {
                if (
                  vehicle.vehicleId == null ||
                  vehicle.name == null ||
                  vehicle.registrationPlateCountry === undefined ||
                  vehicle.registrationPlate === undefined ||
                  vehicle.type == null ||
                  vehicle.isEv == null
                ) {
                  return;
                }
                navigation.navigate('EditVehicle', {
                  id: vehicle.vehicleId,
                  name: vehicle.name,
                  type: vehicle.type,
                  registrationPlate: vehicle.registrationPlate,
                  registrationPlateCountry: vehicle.registrationPlateCountry,
                  isEv: vehicle.isEv,
                });
              }}
              onDelete={() => {
                refetchVehicles();
              }}
            />
          );
        }}
      />
      <Loader isLoading={isLoadingVehicles} />
      <Popup {...errorPopupProps} />
    </>
  );
};

export default Vehicles;
