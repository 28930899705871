import {DateTime} from 'luxon';
import * as React from 'react';

import {RootNavigationProps} from '@common/navigators';
import {useAppSelector} from '@common/reducers';

import {dateTimeToDMY} from '@components/DatePicker/utils';
import Loader from '@components/Loader';
import Popup from '@components/Popup';
import {dateTimeToHM} from '@components/TimePicker/utils';

import {useApi} from '@services/pacoApi';
import {useQuery} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

import Container from './Container';

const NewReservationForm: React.FC<RootNavigationProps<'NewReservationForm'>> =
  props => {
    const {route, navigation} = props;
    const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError({
      onCloseErrorPopup: () => {
        navigation.goBack();
      },
    });
    const driverInfo = useAppSelector(state => state.Member.driverInfo);
    const api = useApi();

    const {
      data: fetchVehiclesResponse,
      error: fetchingVehiclesError,
      isLoading: isLoadingVehicles,
      isSuccess: isFetchingVehiclesSuccess,
    } = useQuery(
      'vehicles',
      React.useCallback(async () => {
        return api.Vehicles.v1VehiclesGet();
      }, [api]),
    );

    const {
      isSuccess: isFetchingNewestReservationSuccess,
      data: fetchNewestReservationResponse,
      isLoading: isLoadingNewestReservation,
      error: fetchNewestReservationError,
    } = useQuery(
      'newestReservation',
      React.useCallback(async () => {
        return api.Reservations.v1ReservationsNewestGet();
      }, [api]),
    );

    const driverId = driverInfo?.company?.id ?? '';
    const {
      isSuccess: isFectchingAlertMessagSuccess,
      data: fetchAlertMessage,
      error: fetchAlertMessageError,
      isLoading: isLoadingAlertMessage,
    } = useQuery(
      'alertMessage',
      React.useCallback(async () => {
        return api.Companies.v1CompaniesCompanyIdAlertMessageGet(driverId);
      }, [api.Companies, driverId]),
    );

    const alertMessage =
      fetchAlertMessage?.status === 200 ? fetchAlertMessage.data : null;

    const isSuccess =
      !isLoadingVehicles &&
      !isLoadingNewestReservation &&
      isFetchingVehiclesSuccess &&
      isFetchingNewestReservationSuccess &&
      isFectchingAlertMessagSuccess;

    const defaultReservationPeriod = React.useMemo(() => {
      if (
        !isFetchingNewestReservationSuccess ||
        !fetchNewestReservationResponse?.data
      ) {
        return;
      }

      const {localFrom, localTo} = fetchNewestReservationResponse.data;
      if (!localFrom || !localTo) {
        return;
      }

      return {
        from: dateTimeToHM(DateTime.fromISO(localFrom, {setZone: true})),
        to: dateTimeToHM(DateTime.fromISO(localTo, {setZone: true})),
      };
    }, [
      fetchNewestReservationResponse?.data,
      isFetchingNewestReservationSuccess,
    ]);

    const defaultDate = React.useMemo(
      () => dateTimeToDMY(DateTime.fromISO(route.params.date)),
      [route.params.date],
    );

    React.useEffect(() => {
      if (fetchingVehiclesError) {
        handleError(fetchingVehiclesError);
      }
    }, [fetchingVehiclesError, handleError]);

    React.useEffect(() => {
      if (fetchNewestReservationError) {
        handleError(fetchNewestReservationError);
      }
    }, [fetchNewestReservationError, handleError]);

    React.useEffect(() => {
      if (fetchAlertMessageError) {
        handleError(fetchAlertMessageError);
      }
    }, [fetchAlertMessageError, handleError]);

    return (
      <>
        {isSuccess && (
          <Container
            {...props}
            isDriverPrm={driverInfo?.isPrm || false}
            defaultDate={defaultDate}
            defaultReservationPeriod={defaultReservationPeriod}
            vehicles={fetchVehiclesResponse?.data ?? []}
            alertMessage={alertMessage}
          />
        )}
        <Loader
          isLoading={
            isLoadingVehicles ||
            isLoadingNewestReservation ||
            isLoadingAlertMessage
          }
        />
        <Popup {...errorPopupProps} />
      </>
    );
  };

export default NewReservationForm;
