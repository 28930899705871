import {produce} from 'immer';
import {Platform} from 'react-native';
import {MigrationManifest} from 'redux-persist';

export const migrations: MigrationManifest = {
  //   '0': state => {
  //     // create a new State
  //     // do not mutate data !
  //     const newState : RootState =  { ....}
  //     return newState;
  //   },
  '2': state => {
    const newState = produce(state, draft => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const oldState = draft as any;
      oldState.App.shouldDisplayTutorial = false;
      oldState.App.shouldDisplayPushNotificationRequest = Platform.OS === 'ios';
      oldState.Auth.ticket = null;
    });
    return newState;
  },
};
