import * as React from 'react';
import {Platform} from 'react-native';

import theme from '../../config/theme';
import Field from '../Field';
import Flex from '../Flex';
import ParkingRow from '../ParkingRow';
import BaseLabelComponent, {
  LabelComponentProps,
} from '../Select/LabelComponent';
import Spacer from '../Spacer';

import {NO_ITEMS_VALUE, ParkingOption} from './utils';

const LabelComponent: React.FC<LabelComponentProps<number>> = props => {
  const option = props.option as ParkingOption;

  if (!option || option.value === NO_ITEMS_VALUE) {
    return <BaseLabelComponent {...props} />;
  }

  const {label} = option;

  return (
    <>
      <Field
        isFocused={false}
        isOpened
        isValid
        {...props}
        rightIconName={Platform.OS === 'web' ? 'Dropdown' : undefined}
        onPressIconRight={props.onPressIcon}>
        <Flex direction="row" alignItems="center">
          <ParkingRow label={label} />
        </Flex>
      </Field>
      <Spacer spacing={theme.spacing.s} />
    </>
  );
};

export default LabelComponent;
