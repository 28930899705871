import * as React from 'react';
import {Platform} from 'react-native';

import Theme from '@config/theme';

import Field from '../Field';
import Flex from '../Flex';
import Icon from '../Icon';
import BaseLabelComponent, {
  LabelComponentProps,
} from '../Select/LabelComponent';
import Spacer from '../Spacer';
import VehicleRow from '../VehicleRow';

import {getIconName, NO_ITEMS_VALUE, VehicleOption} from './utils';

const LabelComponent: React.FC<LabelComponentProps<number>> = props => {
  const option = props.option as VehicleOption;

  if (!option || option.value === NO_ITEMS_VALUE) {
    return <BaseLabelComponent {...props} />;
  }

  const {isEv, label, type} = option;

  return (
    <>
      <Field
        isFocused={false}
        isOpened
        isValid
        {...props}
        rightIconName={Platform.OS === 'web' ? 'Dropdown' : undefined}
        onPressIconRight={props.onPressIcon}>
        <Flex direction="row" alignItems="center">
          {isEv && <Icon name="Electric" size={Theme.icons.l} />}
          <VehicleRow label={label} iconName={getIconName(type)} />
        </Flex>
      </Field>
      <Spacer spacing={Theme.spacing.s} />
    </>
  );
};

export default LabelComponent;
