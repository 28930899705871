import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import {parseDMY} from '../../../../components/DatePicker/utils';
import Flex from '../../../../components/Flex';
import Text from '../../../../components/Text';
import Theme from '../../../../config/theme';
import {useAppSelector} from '../../../../reducers';
import {AgendaItem} from '../types';

interface Props {
  item: AgendaItem;
}

const DateView: React.FC<Props> = ({item}) => {
  const markedDates = useAppSelector(state => state.Planning.markedDates);

  const date = item.date.day;
  const dayOfWeek = item.date.toLocaleString({weekday: 'short'}).toUpperCase();
  const customStyle = markedDates[parseDMY(item.dmy) as string];

  return (
    <View style={Styles.container}>
      <Flex style={[Styles.content, customStyle?.container]}>
        <Text style={customStyle?.text} variant="Label">
          {dayOfWeek}
        </Text>
        <Text style={[Styles.date, customStyle?.text]} variant="Medium">
          {date}
        </Text>
      </Flex>
    </View>
  );
};

const Styles = StyleSheet.create({
  container: {
    width: 64,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    width: 32,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: Theme.radius.default,
  },
  date: {
    fontSize: 18,
    lineHeight: 24,
  },
});

export default DateView;
