import {DateTime} from 'luxon';
import * as React from 'react';
import {StyleSheet} from 'react-native';

import Theme from '@config/theme';

import {parseDateTime} from '@components/DatePicker/utils';
import Flex from '@components/Flex';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import {parseEndStr, parseStartStr} from '@services/helpers/dateHelper';

interface Props {
  day: DateTime;
  localFrom: string;
  localTo?: string | null;
}
const AllocationPeriod: React.FC<Props> = ({day, localFrom, localTo}) => {
  const startStr = parseStartStr(parseDateTime(day), localFrom);
  const endStr = parseEndStr(parseDateTime(day), localTo);

  return (
    <Flex direction="row">
      <Text style={styles.text}>{startStr}</Text>
      <Spacer mode="horizontal" spacing={Theme.spacing.l} />
      <Text style={styles.text}>{endStr}</Text>
    </Flex>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: Theme.fonts.family.InterBold,
    lineHeight: 20,
    fontSize: 12,
  },
});
export default AllocationPeriod;
