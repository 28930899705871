import {
  NavigationContainerRef,
  NavigationContainer as BaseNavigationContainer,
  DefaultTheme,
} from '@react-navigation/native';
import * as React from 'react';

import {LoadingView} from '../components/Loader';
import Theme from '../config/theme';
import {AppActions, useAppDispatch} from '../reducers';
import {linkingOptions} from '../services/deeplinks';
import tracker from '../services/tracker';

import RootNavigator from './Root';

let navigationContainerRef: NavigationContainerRef | null = null;

export const getNavigationContainer = (): NavigationContainerRef | null =>
  navigationContainerRef;

const appTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Theme.colors.white,
  },
};

const setRef = (ref: NavigationContainerRef) => (navigationContainerRef = ref);

const withoutNavbarPages: Array<string | undefined> = [
  'SsoMarketingOptin',
  'PrivacyPolicy',
  'TermsAndConditions',
];

const NavigationContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const routeNameRef = React.useRef<string>();

  const checkNavbar = () => {
    const currentPage = getNavigationContainer()?.getCurrentRoute()?.name;
    dispatch(
      AppActions.setShowNavbar(!withoutNavbarPages.includes(currentPage)),
    );
  };

  const onStateChange = () => {
    checkNavbar();

    const previousRouteName = routeNameRef.current;
    const currentRouteName = getNavigationContainer()?.getCurrentRoute()?.name;

    if (currentRouteName && previousRouteName !== currentRouteName) {
      tracker.trackScreenView(currentRouteName);
    }

    routeNameRef.current = currentRouteName;
  };

  const onReady = () => {
    checkNavbar();

    routeNameRef.current = getNavigationContainer()?.getCurrentRoute()?.name;
  };

  return (
    <BaseNavigationContainer
      ref={setRef}
      theme={appTheme}
      linking={linkingOptions}
      fallback={<LoadingView />}
      onReady={onReady}
      onStateChange={onStateChange}>
      <RootNavigator />
    </BaseNavigationContainer>
  );
};

export default NavigationContainer;
