import * as React from 'react';
import {
  Pressable,
  View,
  Dimensions,
  ViewStyle,
  FlatList,
  StyleSheet,
} from 'react-native';

import Theme from '@common/config/theme';

import {getDeviceType} from '../../services/helpers/deviceHelper';
import Box from '../Box';
import DefaultDropdownComponent, {
  PopoverComponentProps,
} from '../PopoverComponent';
import Text from '../Text';

import DefaultLabelComponent, {LabelComponentProps} from './LabelComponent';
import Option from './Option';
import DefaultRowComponent, {RowComponentProps} from './RowComponent';

const DROP_DOWN_MAX_HEIGHT =
  getDeviceType() === 'web-desktop' ? 230 : Dimensions.get('window').height / 2;
export interface Props<T = string> {
  testID?: string;
  options: Option<T>[];
  containerStyle?: ViewStyle;
  selected?: number;
  RowComponent?: React.FC<RowComponentProps<T>>;
  LabelComponent?: React.FC<LabelComponentProps<T>>;
  DropdownComponent?: React.FC<PopoverComponentProps>;
  onSelect?: (value: T, index: number, options: Option<T>[]) => void;
  error?: string;
  placeholder?: string;
  showLabel?: boolean;
  disabled?: boolean;
  allowReselect?: boolean;
  description?: string;
  enableSameWidthModifier?: PopoverComponentProps['enableSameWidthModifier'];
  placement?: PopoverComponentProps['placement'];
}

function Select<T = string>(props: Props<T>): JSX.Element {
  const {
    testID,
    options,
    containerStyle,
    selected,
    RowComponent = DefaultRowComponent,
    LabelComponent = DefaultLabelComponent,
    DropdownComponent = DefaultDropdownComponent,
    onSelect,
    error,
    placeholder,
    showLabel,
    disabled,
    allowReselect,
    description,
    enableSameWidthModifier = true,
    placement,
  } = props;
  const [target, setTarget] = React.useState<View | null>(null);
  const [dropdownVisible, setDropdownVisible] = React.useState(false);

  return (
    <>
      <Pressable
        testID={testID}
        ref={setTarget}
        style={containerStyle}
        onPress={() => !disabled && setDropdownVisible(true)}>
        <LabelComponent
          opened={dropdownVisible}
          error={error}
          disabled={disabled}
          placeholder={placeholder}
          showLabel={showLabel}
          option={options[selected ?? -1]}
          onPressIcon={() => !disabled && setDropdownVisible(true)}
        />
      </Pressable>
      <DropdownComponent
        visible={dropdownVisible}
        placeholder={placeholder}
        close={() => setDropdownVisible(false)}
        target={target as Element | null}
        maxHeight={DROP_DOWN_MAX_HEIGHT}
        enableSameWidthModifier={enableSameWidthModifier}
        placement={placement}>
        {description ? (
          <Box
            padding={Theme.spacing.m}
            skipRight
            skipLeft
            skipTop={getDeviceType() !== 'web-desktop'}>
            <Box padding={Theme.spacing.xl} skipBottom skipTop>
              <Text variant="Small" style={Styles.textStyle}>
                {description}
              </Text>
            </Box>
          </Box>
        ) : null}
        <FlatList
          data={options}
          keyExtractor={(_, index) => `option_${index}`}
          nestedScrollEnabled={true}
          renderItem={({item: option, index}) => {
            return (
              <RowComponent
                testID={`${testID}-item-${index}`}
                index={index}
                isSelected={selected === index}
                options={options}
                onPress={() => {
                  if (allowReselect || index !== selected) {
                    onSelect && onSelect(option.value, index, options);
                  }
                  setDropdownVisible(false);
                }}
              />
            );
          }}
        />
      </DropdownComponent>
    </>
  );
}
const Styles = StyleSheet.create({
  textStyle: {
    fontStyle: 'italic',
  },
});

export default Select;
