import {CommonActions} from '@react-navigation/native';
import * as React from 'react';
import {AppState, Platform} from 'react-native';

import {getNavigationContainer} from '@common/navigators';

import logger from '../logger';

import CodePush, {DownloadProgress} from './codepush';
import {
  UpdatesContext,
  UpdatesContextState,
  UpdatesContextInitialState,
} from './UpdatesContext';

class UpdatesProvider extends React.Component<unknown, UpdatesContextState> {
  state = UpdatesContextInitialState;
  _appState = '';

  componentDidMount(): void {
    AppState.addEventListener('change', this.handleAppStateChange);
    this.checkAndUpdateApp();
  }

  componentWillUnmount(): void {
    AppState.removeEventListener('change', this.handleAppStateChange);
  }

  handleAppStateChange = (nextAppState: string): void => {
    if (Platform.OS === 'web') {
      return;
    }

    if (
      this._appState.match(/inactive|background/) &&
      nextAppState === 'active'
    ) {
      this.checkAndUpdateApp();
    }

    this._appState = nextAppState;
  };

  checkAndUpdateApp = (): void => {
    if (Platform.OS !== 'web') {
      this.updateApp().catch(error => {
        this.setState({status: 'FAIL'});
        logger.log(error, 'Error');
      });
    }
  };

  updateApp = async (): Promise<void> => {
    logger.log('[CODE_PUSH] : Checking for updates.');

    this.setState({status: 'IDLE'});

    const update = await CodePush.checkForUpdate();
    if (!update) {
      return;
    }

    this.setState({status: 'SYNC', update});

    if (update.isMandatory) {
      logger.log('[CODE_PUSH] : Mandatory update.');
      getNavigationContainer()?.dispatch(
        CommonActions.reset({
          routes: [
            {
              name: 'Updates',
            },
          ],
        }),
      );
    }
    logger.log('[CODE_PUSH] : Downloading package.');
    const localPackage = await update.download(
      this.codePushDownloadDidProgress,
    );

    localPackage
      .install(
        CodePush.InstallMode.ON_NEXT_RESUME,
        60 * 60, //1 hour
      )
      .then(
        () => {
          CodePush.notifyAppReady();
          this.setState({status: 'SUCCESS'});
        },
        reason => {
          logger.log(`[CODE_PUSH] : Installation failed for reason ${reason}.`);
        },
      );
  };

  codePushDownloadDidProgress = (progress: DownloadProgress): void => {
    this.setState({progress: progress.receivedBytes / progress.totalBytes});
  };

  render(): JSX.Element {
    return (
      <UpdatesContext.Provider
        value={{
          status: this.state.status,
          progress: this.state.progress,
          update: this.state.update,
        }}>
        {this.props.children}
      </UpdatesContext.Provider>
    );
  }
}

export default UpdatesProvider;
