import {useNavigation} from '@react-navigation/core';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Image, View, StyleSheet, Pressable} from 'react-native';

import Hoverable from '@common/components/Hoverable';
import {openZenparkPro} from '@common/services/helpers/linksHelper';

import images from '../../../assets/images';
import Flex from '../../components/Flex';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import Theme from '../../config/theme';
import {getFontImageUrl} from '../../services/helpers/ressourceHelper';
import {useResponsiveProperty} from '../../services/responsive';

const WebLoginLayout: React.FC = props => {
  const navigation = useNavigation();
  const {t} = useTranslation();
  const introductionResponsiveStyle = useResponsiveProperty({
    sm: {
      width: '0%',
    },
    lg: {
      width: '30%',
    },
  });

  const contentResponsiveStyle = useResponsiveProperty({
    sm: {
      width: '100%',
    },
    md: {
      width: 632,
    },
  });

  return (
    <Flex direction={'row'} style={Theme.commonStyles.flex1}>
      <View style={[Styles.introduction, introductionResponsiveStyle]}>
        <View style={Styles.leftContainer}>
          <Flex direction={'row'} style={Styles.bannerContainer}>
            <Pressable onPress={() => navigation.navigate('Login')}>
              <img src={getFontImageUrl('flex-logo.svg')} />
            </Pressable>
            <Pressable onPress={openZenparkPro}>
              <img
                src={getFontImageUrl('zenpark-flex-logo-white.svg')}
                style={PureStyles.bannerImage}
              />
            </Pressable>
          </Flex>
          <Spacer spacing={Theme.spacing.xxxl} />
          <Text variant={'Big'} style={Styles.title}>
            {t('login-screen-banner-title')}
          </Text>
          <Spacer spacing={Theme.spacing.xxl} />
          <Text variant={'Sub'} style={Styles.subtitle}>
            {t('login-screen-banner-subtitle')}
          </Text>
          <Spacer spacing={Theme.spacing.xxxl} />
          <Pressable onPress={openZenparkPro}>
            <Hoverable
              backgroundColor={Theme.colors.primary}
              hoverColor={Theme.colors.secondary}
              style={Styles.zpProSolutionContainer}>
              <Text variant={'Label'} style={Styles.zpProSolutionText}>
                {t('login-screen-banner-solution-text')}
              </Text>
              <Image
                source={images['zenpark-pro-solutions' as keyof typeof images]}
                style={Styles.zpProSolutionImage}
                resizeMode="contain"
              />
            </Hoverable>
          </Pressable>
        </View>
      </View>
      <View style={Styles.rightContainer}>
        <View style={contentResponsiveStyle}>{props.children}</View>
      </View>
    </Flex>
  );
};

const PureStyles: Record<'bannerImage', React.CSSProperties> = {
  bannerImage: {
    maxHeight: 20,
  },
};

const Styles = StyleSheet.create({
  leftContainer: {
    padding: '15%',
  },
  bannerContainer: {
    maxHeight: 65,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  introduction: {
    width: '0%',
    backgroundColor: Theme.colors.primary,
  },
  title: {
    color: Theme.colors.white,
  },
  subtitle: {
    color: Theme.colors.white,
  },
  zpProSolutionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: 100,
    minHeight: 90,
    borderWidth: 1,
    borderColor: Theme.colors.white,
    borderRadius: 22,
    overflow: 'hidden',
  },
  zpProSolutionText: {
    color: Theme.colors.white,
    marginLeft: Theme.spacing.xl,
    fontSize: 14,
    lineHeight: 20,
  },
  zpProSolutionImage: {
    width: 109,
    height: 112,
    marginRight: -30,
  },
  rightContainer: {
    flex: 1,
    backgroundColor: Theme.colors.secondaryHover,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default WebLoginLayout;
