import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';

import Block from '../../../components/Block';
import Box from '../../../components/Box';
import Spacer from '../../../components/Spacer';
import Text from '../../../components/Text';
import Theme from '../../../config/theme';

import LanguageSelector from './LanguageSelector';

const LanguageBlock: React.FC = () => {
  const {t} = useTranslation();
  return (
    <Block title={t('change-language')}>
      <Box padding={Theme.spacing.l} skipTop skipBottom>
        <Spacer spacing={Theme.spacing.xl} />
        <LanguageSelector />
        <Spacer spacing={Theme.spacing.s} />
        <Text style={Styles.changeLanguageDescription} align="center">
          {t('change-language-description')}
        </Text>
        <Spacer spacing={Theme.spacing.m} />
      </Box>
    </Block>
  );
};

const Styles = StyleSheet.create({
  changeLanguageDescription: {fontStyle: 'italic'},
});
export default LanguageBlock;
