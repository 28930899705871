import * as React from 'react';
import {StyleSheet} from 'react-native';

import {useAppVersion} from '@services/helpers/appHelper';

import Theme from '../config/theme';

import Text from './Text';

const VersionView: React.FC = () => {
  const appVersion = useAppVersion();
  return <Text style={Styles.text}>{appVersion}</Text>;
};

const Styles = StyleSheet.create({
  text: {
    textAlign: 'right',
    position: 'absolute',
    bottom: Theme.spacing.l,
    right: Theme.spacing.l,
  },
});

export default VersionView;
