import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {RootNavigationProps} from '@common/navigators';
import {useAppSelector} from '@common/reducers';

import {Vehicle} from '@services/pacoApi';

import Select from '../Select';

import LabelComponent from './LabelComponent';
import RowComponent from './RowComponent';
import {
  EDIT_VEHICLES_VALUE,
  getDefaultVehicleTypeIndex,
  getOptions,
} from './utils';

interface Props {
  value?: number;
  onVehicleChange: (vehicle?: number) => void;
  vehicles: Vehicle[];
  testID?: string;
}

const VehicleSelector: React.FC<Props> = ({
  value,
  vehicles,
  testID,
  onVehicleChange,
}) => {
  const navigation =
    useNavigation<RootNavigationProps<'NewReservationForm'>['navigation']>();
  const {t} = useTranslation();
  const lastUsedVehicleId = useAppSelector(
    state => state.Member.lastUsedVehicleId,
  );

  React.useEffect(() => {
    if (value == null) {
      const index = getDefaultVehicleTypeIndex(vehicles, lastUsedVehicleId);
      if (index != null) {
        onVehicleChange(index);
      }
    }
  }, [value, lastUsedVehicleId, onVehicleChange, vehicles]);

  const options = getOptions(
    vehicles,
    t('new-reservation-form-edit-vehicles-button'),
    t('new-reservation-form-no-vehicle-registred'),
  );
  const isEmpty = vehicles.length === 0;
  return (
    <Select
      testID={testID}
      selected={isEmpty ? 1 : value}
      options={options}
      onSelect={_value => {
        if (_value === EDIT_VEHICLES_VALUE) {
          navigation.navigate('Vehicles');
          return;
        }
        onVehicleChange(_value);
      }}
      placeholder={t('new-reservation-form-vehicle-choice')}
      RowComponent={RowComponent}
      LabelComponent={LabelComponent}
    />
  );
};

export default VehicleSelector;
