import {StyleSheet} from 'react-native';

import Theme from '../../config/theme';

const Styles = StyleSheet.create({
  text: {
    textAlign: 'center',
    textTransform: 'capitalize',
    fontFamily: Theme.fonts.family.BasicGrotesqueBold,
    fontSize: 18,
  },
  dayName: {
    fontSize: 10,
    width: 28,
    fontFamily: Theme.fonts.family.InterMedium,
  },
  dayContainer: {
    width: 30,
    height: 30,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Theme.colors.white,
  },
  selectedDay: {
    color: Theme.colors.white,
  },
  today: {
    borderColor: Theme.colors.primary,
    borderWidth: 1,
  },
  dot: {
    width: 4,
    height: 4,
    borderRadius: 2,
    marginRight: Theme.spacing.xs,
  },
  disabled: {
    color: Theme.colors.secondary,
  },
  dotContainer: {
    width: '100%',
    height: 4,
  },
});

export default Styles;
