import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';

import Theme from '../../config/theme';
import {getDeviceType} from '../../services/helpers/deviceHelper';
import Box from '../Box';
import Button from '../Button';
import Flex from '../Flex';
import PopoverComponent from '../PopoverComponent';
import Text from '../Text';
import VerticalBorderShadow from '../VerticalBoxShadow';

import {shiftZero, stringToHM, TimeComponentProps} from './utils';

const MINUTES = Array(12)
  .fill(0)
  .map((_, index) => shiftZero(index * 5));

const HOURS = Array(24)
  .fill(0)
  .map((_, index) => shiftZero(index));

const checkMinMax = (toCheck: number, min: number, max: number) => {
  if (isNaN(toCheck)) {
    return '00';
  }
  if (toCheck < min) {
    toCheck = min;
  }
  if (toCheck > max) {
    toCheck = max;
  }

  return shiftZero(toCheck);
};

const TimeComponent: React.FC<TimeComponentProps> = ({
  value,
  onTimeChange,
  visible,
  close,
  placeholder,
  target,
  testID,
}) => {
  const {t} = useTranslation();
  const [selectedHour, _setSelectedHour] = React.useState<string>('00');
  const [selectedMinutes, _setSelectedMinutes] = React.useState<string>('00');

  React.useEffect(() => {
    if (!visible) {
      return;
    }

    const hm = stringToHM(value);

    _setSelectedHour(shiftZero(hm?.hour ?? 0));
    _setSelectedMinutes(shiftZero(hm?.minutes ?? 0));
  }, [value, visible]);

  const setSelectedHour = (hour: string) => {
    _setSelectedHour(checkMinMax(Number(hour), 0, 23));
  };

  const setSelectedMinutes = (minutes: string) => {
    _setSelectedMinutes(checkMinMax(Number(minutes), 0, 59));
  };

  return (
    <>
      <Text>{value || ' '}</Text>
      <PopoverComponent
        visible={visible}
        placeholder={placeholder}
        target={target as Element | null}
        close={() => close()}>
        <View style={webStyle}>
          <Box padding={Theme.spacing.xl} skipBottom skipTop>
            <Flex
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Box
                padding={Theme.spacing.m}
                style={[Theme.commonStyles.flex1, Styles.leftContainer]}
                skipLeft>
                <View style={Styles.container}>
                  <select
                    data-testid={`${testID}-web-hour-select`}
                    value={selectedHour}
                    onChange={e => setSelectedHour(e.target.value)}
                    style={PureStyles.select}>
                    {HOURS.map((hour, index) => (
                      <option key={index} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                  <input
                    data-testid={`${testID}-web-hour-input`}
                    type={'number'}
                    value={selectedHour}
                    onChange={e => setSelectedHour(e.target.value)}
                    style={PureStyles.input}
                  />
                </View>
              </Box>
              <Text>{':'}</Text>
              <Box
                padding={Theme.spacing.m}
                style={[Theme.commonStyles.flex1, Styles.rightContainer]}
                skipRight>
                <View style={Styles.container}>
                  <select
                    data-testid={`${testID}-web-minutes`}
                    value={selectedMinutes}
                    onChange={e => setSelectedMinutes(e.target.value)}
                    style={PureStyles.select}>
                    {MINUTES.map((minutes, index) => (
                      <option key={index} value={minutes}>
                        {minutes}
                      </option>
                    ))}
                  </select>
                  <input
                    data-testid={`${testID}-web-minutes-input`}
                    type={'number'}
                    value={selectedMinutes}
                    onChange={e => setSelectedMinutes(e.target.value)}
                    style={PureStyles.input}
                  />
                </View>
              </Box>
            </Flex>
          </Box>
          <VerticalBorderShadow>
            <Box padding={Theme.spacing.xl}>
              <Button
                testID={`${testID}-web-button`}
                title={t('ok')}
                disabled={selectedMinutes == null || selectedHour == null}
                onPress={() => {
                  if (
                    selectedMinutes != null &&
                    selectedHour != null &&
                    onTimeChange
                  ) {
                    onTimeChange({
                      hour: Number(selectedHour),
                      minutes: Number(selectedMinutes),
                    });
                    close();
                  }
                }}
              />
            </Box>
          </VerticalBorderShadow>
        </View>
      </PopoverComponent>
    </>
  );
};

const PureStyles: Record<'select' | 'input', React.CSSProperties> = {
  select: {
    height: 50,
    width: '100%',
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    backgroundImage:
      'url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20fill%3D%22%23142863%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M18.414%209.707L17%208.293%2012.353%2012.939%207.707%208.293%206.293%209.707%2012.354%2015.768z%22%20transform%3D%22translate%28-795.000000%2C%20-149.000000%29%20translate%28795.000000%2C%20149.000000%29%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    borderColor: Theme.colors.primary,
    borderRadius: Theme.radius.default,
    borderWidth: 1,
  },
  input: {
    height: 48,
    width: 30,
    appearance: 'textfield',
    WebkitAppearance: 'textfield',
    MozAppearance: 'textfield',
    outline: 'none',
    fontSize: 'x-large',
    margin: '1px 0px 1px 1px',
    padding: `0px ${Theme.spacing.l}px 0px ${Theme.spacing.l}px`,
    position: 'absolute',
    left: 0,
    color: Theme.colors.primary,
    borderColor: Theme.colors.primary,
    borderRadius: `${Theme.radius.default}px 0px 0px ${Theme.radius.default}px`,
    borderWidth: '0px 1px 0px 0px',
  },
};

const Styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 90,
  },
  leftContainer: {
    alignItems: 'flex-end',
  },
  rightContainer: {
    alignItems: 'flex-start',
  },
});

const webStyle = getDeviceType() === 'web-desktop' ? {width: '250px'} : {};

export default TimeComponent;
