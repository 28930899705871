import {DateTime} from 'luxon';

import {SpaceTypes, Vehicle} from '../../services/pacoApi';

export const EDIT_VEHICLES_VALUE = -1;
export const NO_ITEMS_VALUE = -2;

export const getIconName = (
  type: SpaceTypes | undefined,
  value?: number,
): string | null => {
  if (value === NO_ITEMS_VALUE) {
    return null;
  }

  if (value === EDIT_VEHICLES_VALUE) {
    return 'Edit';
  }

  switch (type) {
    case SpaceTypes.Utility:
      return 'Utility';
    case SpaceTypes.Car:
      return 'Car';
    case SpaceTypes.Moto:
      return 'Moto';
    case SpaceTypes.Bike:
      return 'Bike';
    default:
      return null;
  }
};

export const getDefaultVehicleTypeIndex = (
  vehicles: Vehicle[],
  lastUsedVehicleId: string | null,
): number | undefined => {
  if (vehicles.length <= 0) {
    return;
  }

  if (vehicles.length === 1) {
    return 0;
  }

  if (lastUsedVehicleId) {
    const index = vehicles.findIndex(
      vehicle => vehicle.vehicleId === lastUsedVehicleId,
    );
    if (index >= 0) {
      return index;
    }
  }

  return getNewestVehicleIndex(vehicles);
};

const getNewestVehicleIndex = (vehicles: Vehicle[]) => {
  const compareDate = (a: Vehicle, b: Vehicle) => {
    if (!a.creationDateTime || !b.creationDateTime) {
      throw 'invalid arguments';
    }

    const aDate = DateTime.fromISO(a.creationDateTime).toMillis();
    const bDate = DateTime.fromISO(b.creationDateTime).toMillis();

    if (aDate === bDate) {
      return 0;
    }

    return aDate > bDate ? 1 : -1;
  };

  const sortedVehicles = vehicles.sort(compareDate);
  return sortedVehicles.length - 1;
};

export type VehicleOption = {
  value: number;
  label?: string | null;
  isEv?: boolean;
  type?: SpaceTypes;
};

export const getOptions = (
  vehicles: Vehicle[],
  editVehiclesLabel: string,
  noItemsLabel: string,
): VehicleOption[] => {
  const options = vehicles.map((v, index) => {
    return {value: index, label: v.name, type: v.type, isEv: v.isEv};
  });

  options.push({
    value: EDIT_VEHICLES_VALUE,
    label: editVehiclesLabel,
    isEv: false,
    type: undefined,
  });

  options.push({
    value: NO_ITEMS_VALUE,
    label: noItemsLabel,
    isEv: false,
    type: undefined,
  });

  return options;
};
