import {useNavigation} from '@react-navigation/native';
import testIDs from '@testIDs';
import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';
import * as yup from 'yup';

import Theme from '@config/theme';

import {useAppSelector} from '@reducers/configureStore';

import {useVehicleName} from '@services/helpers/vehicleHelper';
import {FieldEditionParam, SpaceTypes, useApi} from '@services/pacoApi';
import {useMessagePopup} from '@services/popups';
import {useMutation, useQuery} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

import Button from '../Button';
import Checkbox from '../Checkbox';
import CountrySelector from '../CountrySelector/CountrySelector';
import Flex from '../Flex';
import Input from '../Input';
import Loader from '../Loader';
import Popup from '../Popup';
import Spacer from '../Spacer';

import {
  FormValues,
  getTypeIndex,
  types,
  useSetVehicleIsEv,
  useSetVehicleType,
} from './utils';
import VehiclesFormLayout from './VehiclesFormLayout';
import VehicleTypeSelector from './VehicleTypeSelector';

interface Props {
  id?: string;
  name?: string;
  registrationPlate?: string | null;
  registrationPlateCountry?: string | null;
  isEv?: boolean;
  type: SpaceTypes;
}

const VehicleForm: React.FC<Props> = ({
  id,
  name = '',
  registrationPlate = '',
  registrationPlateCountry = 'FR',
  isEv = false,
  type = SpaceTypes.None,
}) => {
  const api = useApi();
  const {t} = useTranslation();
  const nav = useNavigation();
  const getVehicleName = useVehicleName();
  const setVehicleType = useSetVehicleType();
  const setVehicleIsEv = useSetVehicleIsEv();

  const {popupProps: errorPopupProps, handle: showError} = usePacoApiError();
  const {props: successPopupProps, show: showSuccess} = useMessagePopup({
    onClose: () => nav.goBack(),
    testID: testIDs.vehicles.form.successPopup,
  });

  const registrationPlateEditionParam = useAppSelector(
    state =>
      state.Member.driverInfo?.company?.config?.registrationPlateEditionParam,
  );
  const registrationPlateIsMandatory =
    registrationPlateEditionParam === FieldEditionParam.Mandatory;

  const registrationPlateIsDisabled =
    registrationPlateEditionParam === FieldEditionParam.Disabled;

  const validationSchema = yup.object().shape({
    isEv: yup.boolean(),
    country: yup.string().when('selectedType', {
      is: (val: number) =>
        types[val] !== SpaceTypes.Bike && !registrationPlateIsDisabled,
      then: yup.string(),
      otherwise: yup.string().nullable(true),
    }),
    selectedType: yup.number().required(),
    registrationPlate: yup.string().when('selectedType', {
      is: (val: number) =>
        types[val] !== SpaceTypes.Bike && registrationPlateIsMandatory,
      then: yup
        .string()
        .max(30, t('vehicle-registration-plate-max-length'))
        .required(t('vehicle-registration-plate-required')),
      otherwise: yup.string().nullable(true),
    }),
    vehicleName: yup
      .string()
      .max(25, t('vehicle-name-max-length'))
      .required(t('vehicle-vehicle-name-required')),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      isEv: isEv,
      vehicleName:
        name || getVehicleName(types[getTypeIndex(types, type)], isEv),
      registrationPlate: registrationPlateIsDisabled
        ? null
        : registrationPlate ?? '',
      registrationPlateCountry: registrationPlateIsDisabled
        ? null
        : registrationPlateCountry ?? 'FR',
      selectedType: getTypeIndex(types, type),
      _nameEdited: Boolean(id),
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {
      submit();
    },
  });
  const {values, errors, handleSubmit, setFieldValue} = formik;
  const {
    isError,
    isSuccess,
    isLoading,
    error,
    data: response,
    mutate: submit,
  } = useMutation(async () => {
    return await insertOrUpdateVehicle();
  });

  async function insertOrUpdateVehicle() {
    const vehicle = {
      isEv: values.isEv,
      name: values.vehicleName,
      registrationPlate: values.registrationPlate,
      registrationPlateCountry: values.registrationPlateCountry,
      type: types[values.selectedType],
    };
    if (id) {
      return api.Vehicles.v1VehiclesVehicleIdPut(id, vehicle);
    }
    return api.Vehicles.v1VehiclesPost(vehicle);
  }
  const {
    data: fetchSupportedVehicleTypesResponse,
    error: fetchSupportedVehicleTypesError,
    isLoading: isLoadingSupportedVehicleTypes,
    isSuccess: isSuccessSupportedVehicleTypes,
  } = useQuery(
    'supportedVehicleTypes',
    React.useCallback(async () => {
      return api.Companies.v1CompaniesSupportedVehiclesTypesGet();
    }, [api]),
  );

  React.useEffect(() => {
    if (
      isSuccessSupportedVehicleTypes &&
      fetchSupportedVehicleTypesResponse?.data != null
    ) {
      const supportedTypes = fetchSupportedVehicleTypesResponse.data;
      if (
        supportedTypes.findIndex(
          supportedType => supportedType === SpaceTypes.Car,
        ) === -1
      ) {
        const selectedType =
          supportedTypes.length === 0 ? SpaceTypes.None : supportedTypes[0];
        const selectedTypeIndex = getTypeIndex(types, selectedType);
        if (formik.values.selectedType !== selectedTypeIndex) {
          setVehicleType(formik, selectedTypeIndex);
        }
      }
    }
  }, [
    isSuccessSupportedVehicleTypes,
    fetchSupportedVehicleTypesResponse,
    formik,
    setVehicleType,
  ]);

  React.useEffect(() => {
    if (
      isSuccessSupportedVehicleTypes &&
      fetchSupportedVehicleTypesResponse?.data != null &&
      values.selectedType === getTypeIndex(types, SpaceTypes.None)
    ) {
      const supportedTypes = fetchSupportedVehicleTypesResponse.data;
      if (
        supportedTypes.findIndex(
          supportedType => supportedType === SpaceTypes.Car,
        ) === -1
      ) {
        const selectedType =
          supportedTypes.length === 0 ? SpaceTypes.None : supportedTypes[0];
        const selectedTypeIndex = getTypeIndex(types, selectedType);
        if (formik.values.selectedType !== selectedTypeIndex) {
          setVehicleType(formik, selectedTypeIndex);
        }
      } else {
        const selectedTypeIndex = getTypeIndex(types, SpaceTypes.Car);
        if (formik.values.selectedType !== selectedTypeIndex) {
          setVehicleType(formik, selectedTypeIndex);
        }
      }
    }
  }, [
    isSuccessSupportedVehicleTypes,
    fetchSupportedVehicleTypesResponse,
    formik,
    values.selectedType,
    setVehicleType,
  ]);

  React.useEffect(() => {
    if (fetchSupportedVehicleTypesError) {
      showError(fetchSupportedVehicleTypesError);
    }
  }, [fetchSupportedVehicleTypesError, showError]);

  React.useEffect(() => {
    if (isError) {
      showError(error);
    }

    if (isSuccess && response?.data) {
      showSuccess({
        message: response?.data?.message,
      });
    }
  }, [isError, isSuccess, error, response, showError, showSuccess]);

  if (!isSuccessSupportedVehicleTypes) {
    return <></>;
  }
  return (
    <VehiclesFormLayout
      title={id ? t('vehicle-edit-title') : t('vehicle-add-title')}
      Footer={
        <Button
          testID={testIDs.vehicles.form.submit}
          title={
            id
              ? t('vehicle-edit-confirm-button')
              : t('vehicle-add-confirm-button')
          }
          onPress={() => handleSubmit()}
        />
      }>
      <Input
        testID={testIDs.vehicles.form.name}
        label={t('vehicle-name-placeholder')}
        placeholder={t('vehicle-name-example-placeholder')}
        value={values.vehicleName}
        error={errors.vehicleName}
        maxLength={30}
        onChangeText={value => {
          setFieldValue('_nameEdited', true);
          setFieldValue('vehicleName', value);
        }}
      />
      <VehicleTypeSelector
        testID={testIDs.vehicles.form.type}
        types={types}
        formik={formik}
        supportedTypes={fetchSupportedVehicleTypesResponse?.data || []}
        isLoadingSupportedVehicleTypes={isLoadingSupportedVehicleTypes}
      />
      <Spacer spacing={Theme.spacing.s} />
      {types[formik.values.selectedType] !== SpaceTypes.Bike &&
      !registrationPlateIsDisabled ? (
        <>
          <Flex direction="row">
            <CountrySelector
              testID={testIDs.vehicles.form.country}
              selectedCountryAlpha2={values.registrationPlateCountry as string}
              onSelect={countryAlpha2 => {
                setFieldValue('registrationPlateCountry', countryAlpha2);
              }}
            />

            <Spacer mode="horizontal" spacing={Theme.spacing.s} />
            <Input
              testID={testIDs.vehicles.form.registrationPlate}
              style={Styles.registrationPlate}
              placeholder={t('vehicle-registration-plate-input')}
              value={values.registrationPlate ?? ''}
              error={errors.registrationPlate}
              maxLength={25}
              onChangeText={value => setFieldValue('registrationPlate', value)}
            />
          </Flex>
        </>
      ) : null}
      {types[formik.values.selectedType] !== SpaceTypes.Bike && (
        <Checkbox
          testID={testIDs.vehicles.form.chargingPoint}
          label={t('vehicle-charging-point')}
          checked={values.isEv}
          onPress={value => setVehicleIsEv(formik, value)}
        />
      )}

      <Loader
        isLoading={isLoading}
        title={
          id
            ? t('vehicle-edit-form-submit-loader-title')
            : t('vehicle-add-form-submit-loader-title')
        }
      />
      <Popup {...successPopupProps} />
      <Popup
        {...errorPopupProps}
        testID={`${testIDs.vehicles.form.errorPopup}-${id}`}
      />
    </VehiclesFormLayout>
  );
};

const Styles = StyleSheet.create({
  registrationPlate: {flex: 2},
});

export default VehicleForm;
