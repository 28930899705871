import * as React from 'react';
import {ScrollView} from 'react-native';

import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Spacer from '../../../components/Spacer';
import VersionView from '../../../components/VersionView';
import Theme from '../../../config/theme';
import LanguageBlock from '../LanguageBlock';
import OutdateAppWarning from '../OutdatedAppWarning';
import PasswordBlock from '../PasswordBlock';
import SectionsBlock from '../SectionsBlock';

const MobileAccountLayout: React.FC = () => {
  return (
    <Flex
      style={Theme.commonStyles.flex1}
      backgroundColor={Theme.colors.secondaryHover}>
      <VersionView />
      <ScrollView>
        <Box style={Theme.commonStyles.flex1} padding={Theme.spacing.l}>
          <OutdateAppWarning />
          <Spacer spacing={Theme.spacing.l} />
          <SectionsBlock />
          <Spacer spacing={Theme.spacing.xl} />
          <PasswordBlock />
          <LanguageBlock />
        </Box>
      </ScrollView>
    </Flex>
  );
};

export default MobileAccountLayout;
