import {Buffer} from 'buffer';

import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../../components/Button';
import Flex from '../../components/Flex';
import Popup from '../../components/Popup';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import Theme from '../../config/theme';
import {RootNavigationProps} from '../../navigators';
import {useApi} from '../../services/pacoApi';

interface Props {
  visible: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  data: string;

  close: () => void;
}
const ResetPasswordPopup: React.FC<Props> = ({visible, error, data, close}) => {
  const {t} = useTranslation();
  const api = useApi();
  const navigation =
    useNavigation<RootNavigationProps<'ResetPassword'>['navigation']>();
  const title = error?.response?.data?.title || t('error');
  const message =
    error?.response?.data?.message || t('unexpected-api-error-message');

  const isTokenError = error?.response?.data?.errorCode === 'EXPIRED_TOKEN';

  return (
    <Popup title={title} visible={visible} close={close}>
      <Flex alignItems="center">
        <Text>{message}</Text>
      </Flex>
      <Spacer spacing={Theme.spacing.l} />
      <Button
        title={t('reset-password-error-popup-yes')}
        variant="Primary"
        onPress={() => {
          close();
          if (isTokenError) {
            navigation.navigate('Login');
          }
        }}
      />
      {isTokenError && (
        <>
          <Spacer spacing={Theme.spacing.m} />

          <Button
            title={t('reset-password-error-popup-new-link')}
            variant="Secondary"
            onPress={() => {
              close();
              const decoded = Buffer.from(data, 'base64').toString();
              const separatorIndex = decoded.indexOf('_');
              const email = decoded.substring(separatorIndex + 1);
              api.Drivers.v1DriversForgottenPasswordPatch({
                email: email,
              });
            }}
          />
        </>
      )}
    </Popup>
  );
};

export default ResetPasswordPopup;
