import {Buffer} from 'buffer';

import {useFocusEffect} from '@react-navigation/core';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ActivityIndicator} from 'react-native';

import Theme from '@config/theme';

import Spacer from '@common/components/Spacer';
import {RootNavigationProps} from '@common/navigators';
import {AppActions, AuthActions, useAppDispatch} from '@common/reducers';
import LoginLayout from '@common/shared_layouts/LoginLayout';

import Block from '@components/Block';
import Box from '@components/Box';
import Loader from '@components/Loader';
import Popup from '@components/Popup';
import Text from '@components/Text';

import {SsoProvider, useApi} from '@services/pacoApi';
import {useMutation} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

const SsoValidate: React.FC<RootNavigationProps<'SsoValidate'>> = ({
  route,
  navigation,
}) => {
  const {t} = useTranslation();
  const api = useApi();
  const dispatch = useAppDispatch();

  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError({
    onCloseErrorPopup: () => navigation.navigate('Login'),
  });

  const {code, state, iss: issuer} = route.params || {};

  const [ssoProvider, redirectUri, codeVerifier, nonce] = React.useMemo(() => {
    return Buffer.from(state, 'base64').toString().split(',');
  }, [state]);

  const {
    isSuccess: isSuccessValidateCode,
    isLoading: isLoadingValidateCode,
    data: apiResponseValidateCode,
    isError: isErrorValidateCode,
    error: apiErrorValidateCode,
    mutate: validate,
  } = useMutation(async () => {
    return api.Sso.ssoValidateCodePost({
      code,
      issuer,
      provider: ssoProvider as SsoProvider,
      redirectUri,
      codeVerifier: codeVerifier
        ? Buffer.from(codeVerifier).toString('base64')
        : '',
      nonce: nonce ? Buffer.from(nonce).toString('base64') : '',
    });
  });

  useFocusEffect(
    React.useCallback(() => {
      validate();
    }, [validate]),
  );

  React.useEffect(() => {
    if (isErrorValidateCode) {
      handleError(apiErrorValidateCode);
    }
  }, [isErrorValidateCode, apiErrorValidateCode, handleError]);

  React.useEffect(() => {
    if (isSuccessValidateCode && apiResponseValidateCode?.data) {
      dispatch(AppActions.setShouldCheckMarketingOptin(true));
      dispatch(AuthActions.setTicket(apiResponseValidateCode.data));
    }
  }, [isSuccessValidateCode, apiResponseValidateCode, dispatch, navigation]);

  return (
    <LoginLayout>
      <Block title={t('sso-signin-title')}>
        <Box padding={Theme.spacing.xxxl}>
          <ActivityIndicator color={Theme.colors.primary} />
          <Spacer spacing={Theme.spacing.xl} />
          <Text align="center">{t('sso-validate-description')}</Text>
        </Box>
      </Block>

      <Popup {...errorPopupProps} />
      <Loader isLoading={isLoadingValidateCode} />
    </LoginLayout>
  );
};

export default SsoValidate;
