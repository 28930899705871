import React from 'react';
import {useTranslation} from 'react-i18next';
import {Image, StyleSheet} from 'react-native';

import Theme from '@config/theme';

import Flex from '@components/Flex';
import Text from '@components/Text';

import images from '../../../assets/images';

interface Props {
  onSkipPress?: () => void;
  showSkipButton: boolean;
}

const OnBoardingHeader: React.FC<Props> = ({onSkipPress, showSkipButton}) => {
  const {t} = useTranslation();
  return (
    <Flex justifyContent="center" alignItems="center">
      <Image source={images['zenpark-flex-logo']} style={Styles.slideImage} />
      {showSkipButton && (
        <Text style={Styles.skipText} onPress={onSkipPress}>
          {t('onboarding-skip-button')}
        </Text>
      )}
    </Flex>
  );
};

const Styles = StyleSheet.create({
  slideImage: {
    height: 100,
    width: 100,
    resizeMode: 'contain',
  },
  skipText: {
    position: 'absolute',
    alignSelf: 'flex-end',
    top: 42,
    color: Theme.colors.primary,
    right: Theme.spacing.l,
  },
});

export default OnBoardingHeader;
