import * as React from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import VerticalBorderShadow from '../../components/VerticalBoxShadow';
import Theme from '../../config/theme';

interface Props {
  Footer: JSX.Element;
  children: React.ReactNode;
}

export interface MobileReservationLayoutRef {
  scrollToBottom: () => void;
}

const MobileReservationLayout = React.forwardRef<
  MobileReservationLayoutRef,
  Props
>((props, ref) => {
  const scrollViewRef = React.useRef<ScrollView>(null);

  React.useImperativeHandle(ref, () => ({
    scrollToBottom: () => scrollViewRef.current?.scrollToEnd(),
  }));
  return (
    <Flex
      style={Theme.commonStyles.flex1}
      backgroundColor={Theme.colors.secondaryHover}
      justifyContent="space-between">
      <ScrollView
        ref={scrollViewRef}
        style={Theme.commonStyles.flex1}
        contentContainerStyle={Styles.content}>
        {props.children}
      </ScrollView>
      <VerticalBorderShadow>
        <Flex backgroundColor={Theme.colors.white}>
          <SafeAreaView edges={['bottom']}>
            <Box padding={Theme.spacing.l}>{props.Footer}</Box>
          </SafeAreaView>
        </Flex>
      </VerticalBorderShadow>
    </Flex>
  );
});

const Styles = StyleSheet.create({
  content: {
    padding: Theme.spacing.l,
  },
});
export default MobileReservationLayout;
