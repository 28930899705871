import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Block from '../components/Block';
import Box from '../components/Box';
import Button from '../components/Button';
import Flex from '../components/Flex';
import Icon from '../components/Icon';
import Input from '../components/Input';
import Popup from '../components/Popup';
import Select from '../components/Select';
import Spacer from '../components/Spacer';
import Text from '../components/Text';
import AppConfig, {Env} from '../config/appConfig';
import Theme from '../config/theme';
import {AppActions, useAppDispatch, useAppSelector} from '../reducers';
import {useApi} from '../services/pacoApi';
import {queryClient, useQuery} from '../services/queries';
import SimpleLayout from '../shared_layouts/SimpleLayout';

const envs = Object.keys(AppConfig.pacoApiUrls) as Env[];
const urls = Object.values(AppConfig.pacoApiUrls);

const getIndex = (apiUrl: string, defaultValueIndex: number): number => {
  const index = urls.findIndex(url => url === apiUrl);

  if (index === -1) {
    return defaultValueIndex;
  }

  return index;
};

const Environment: React.FC = () => {
  const {t} = useTranslation();
  const api = useApi();
  const query = useQuery('apiVersion', async () => api.Home.versionGet(), {
    enabled: false,
  });
  const apiUrl = useAppSelector(state => state.App.apiUrl);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [isApiUp, setIsApiUp] = React.useState<boolean>();

  const options = [
    ...envs.map((env, index) => ({label: env, value: urls[index]})),
    {value: 'custom', label: t('custom')},
  ];

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (query.isSuccess) {
      setIsApiUp(true);
      return;
    }

    if (query.isError) {
      setIsApiUp(false);
      return;
    }

    setIsApiUp(undefined);
  }, [query]);

  const updateApi = (url: string) => {
    queryClient.resetQueries('apiVersion');
    dispatch(AppActions.setApiUrl(url));
  };

  return (
    <SimpleLayout>
      <Block title={t('api-env')}>
        <Box padding={Theme.spacing.l}>
          <Select
            allowReselect
            placeholder={t('placeholder-api-env')}
            selected={getIndex(apiUrl, options.length - 1)}
            options={options}
            onSelect={selected => {
              if (selected === 'custom') {
                setModalVisible(true);
                return;
              }
              updateApi(selected);
            }}
          />

          <Spacer spacing={Theme.spacing.m} />

          <Text variant="Small">{t('api-url')}:</Text>
          <Spacer spacing={Theme.spacing.m} />
          <Text>{apiUrl}</Text>
          <Spacer spacing={Theme.spacing.xs} />
          <Flex direction="row" alignItems="center">
            <Text>{t('status')}:</Text>
            {isApiUp != null && (
              <Icon
                name={isApiUp ? 'Available' : 'Unavailable'}
                color={isApiUp ? Theme.colors.good : Theme.colors.error}
                size={Theme.icons.l}
              />
            )}
          </Flex>
          <Spacer spacing={Theme.spacing.m} />
          <Button
            title={query.isLoading ? t('loading') : t('check-api')}
            disabled={query.isLoading}
            onPress={() => {
              query.refetch();
            }}
          />

          <Popup
            visible={modalVisible}
            close={() => setModalVisible(false)}
            title={t('custom-url')}>
            <Input
              placeholder={t('placeholder-custom-url')}
              value={inputValue}
              onChangeText={setInputValue}
            />
            <Spacer spacing={Theme.spacing.m} />
            <Button
              variant="Primary"
              title={t('ok')}
              disabled={!inputValue}
              onPress={() => {
                updateApi(inputValue);
                setModalVisible(false);
              }}
            />
          </Popup>
        </Box>
      </Block>
    </SimpleLayout>
  );
};

export default Environment;
