import {AxiosResponse} from 'axios';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../components/Button';
import Spacer from '../components/Spacer';
import Text from '../components/Text';
import Theme from '../config/theme';

import * as appActionHandler from './appActionHandler';
import {ApiErrorResponse, AppAction} from './pacoApi';

type PacoApiErrorConfig = {
  onCloseErrorPopup?: () => void;
  buttonTitle?: string;
  buttonTestId?: string;
};

type ApiError = {
  response?: AxiosResponse<ApiErrorResponse>;
};

type PacoApiErrorReturn = {
  handle: (error: unknown) => void;
  closeErrorPopup: () => void;
  popupProps: {
    title?: string | null;
    close: () => void;
    visible: boolean;
    children: JSX.Element;
  };
};
const usePacoApiError = (
  defaultConfig?: PacoApiErrorConfig,
): PacoApiErrorReturn => {
  const {t} = useTranslation();
  const [visible, setVisible] = React.useState(false);
  const [config, setConfig] = React.useState(defaultConfig);
  const [error, setError] = React.useState<ApiError>();
  const handle = React.useCallback(
    (newError: unknown, newConfig?: PacoApiErrorConfig) => {
      const apiError = newError as ApiError;
      setError(apiError);
      setConfig(_config => ({..._config, ...newConfig}));
      setVisible(apiError?.response?.data?.action === AppAction.ShowError);
      appActionHandler.handle(apiError?.response?.data?.action);
    },
    [setVisible, setConfig, setError],
  );

  const closeErrorPopup = React.useCallback(() => {
    config?.onCloseErrorPopup && config.onCloseErrorPopup();
    setVisible(false);
  }, [setVisible, config]);

  const title = error?.response?.data?.title || t('error');
  const message =
    error?.response?.data?.message || t('unexpected-api-error-message');
  const buttonTitle = config?.buttonTitle || t('ok');
  const buttonTestId = config?.buttonTestId;

  const children = (
    <>
      <Text>{message}</Text>
      <Spacer spacing={Theme.spacing.m} />
      {buttonTitle &&
        (buttonTestId ? (
          <Button
            variant="Secondary"
            title={buttonTitle}
            onPress={closeErrorPopup}
            testID={buttonTestId}
          />
        ) : (
          <Button
            variant="Secondary"
            title={buttonTitle}
            onPress={closeErrorPopup}
          />
        ))}
    </>
  );

  const popupProps = {title, close: closeErrorPopup, visible, children};
  return {handle, closeErrorPopup, popupProps};
};

export default usePacoApiError;
