import * as React from 'react';
import {PersistGate as BasePersistorGate} from 'redux-persist/integration/react';

import {persistor} from '../reducers';

const PersistorGate: React.FC = props => {
  return <BasePersistorGate loading={null} persistor={persistor} {...props} />;
};

export default PersistorGate;
