import {BottomTabNavigationProp} from '@react-navigation/bottom-tabs';
import {CompositeNavigationProp, RouteProp} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';

import {HomeParamList} from './Home';
import {RootParamList} from './Root';

export type RootNavigationProps<T extends keyof RootParamList> = {
  navigation: StackNavigationProp<RootParamList, T>;
  route: RouteProp<RootParamList, T>;
};

export type HomeNavigationProps<T extends keyof HomeParamList> = {
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<HomeParamList, T>,
    StackNavigationProp<RootParamList>
  >;
  route: RouteProp<HomeParamList, T>;
};

export {getNavigationContainer} from './NavigationContainer';

export {default as NavigationContainer} from './NavigationContainer';
