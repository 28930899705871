import {DateTime} from 'luxon';
import * as React from 'react';

import {dateTimeToDMY} from '../../components/DatePicker/utils';
import {PlanningActions, useAppDispatch, useAppSelector} from '../../reducers';
import {getDateTimeFromDMYWithMonthOffset} from '../../services/helpers/dateHelper';
import {useApi} from '../../services/pacoApi';
import {useQuery} from '../../services/queries';

import PlanningLayout from './PlanningLayout';

const Planning: React.FC = () => {
  const dispatch = useAppDispatch();
  const shouldReloadPlanning = useAppSelector(
    s => s.Planning.shouldReloadPlanning,
  );
  const api = useApi();

  const allocationsQuery = useQuery('allocations', async () => {
    const today = dateTimeToDMY(DateTime.now());
    const newStartDate = getDateTimeFromDMYWithMonthOffset(today, -1);
    const newEndDate = getDateTimeFromDMYWithMonthOffset(today, 12);

    return api.Drivers.v1DriversAllocationsGet(
      newStartDate.toJSON(),
      newEndDate.toJSON(),
    );
  });

  const planningByDays = allocationsQuery.data?.data.planningByDays;

  React.useEffect(() => {
    if (planningByDays) {
      dispatch(PlanningActions.setPlanningByDays(planningByDays));
    }
  }, [planningByDays, dispatch]);

  React.useEffect(() => {
    if (!shouldReloadPlanning) {
      return;
    }
    allocationsQuery.refetch();
    dispatch(PlanningActions.setShouldReloadPlanning(false));
  }, [shouldReloadPlanning, allocationsQuery, dispatch]);

  return <PlanningLayout />;
};

export default Planning;
