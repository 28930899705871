import * as React from 'react';
import {View, ViewProps} from 'react-native';

export interface Props extends ViewProps {
  padding: number;
  skipTop?: boolean;
  skipBottom?: boolean;
  skipLeft?: boolean;
  skipRight?: boolean;
}

const Box: React.FC<Props> = ({
  padding,
  skipTop,
  skipBottom,
  skipLeft,
  skipRight,
  style,
  ...props
}) => {
  return (
    <View
      {...props}
      style={[
        {
          paddingLeft: skipLeft ? undefined : padding,
          paddingRight: skipRight ? undefined : padding,
          paddingTop: skipTop ? undefined : padding,
          paddingBottom: skipBottom ? undefined : padding,
        },
        style,
      ]}
    />
  );
};

export default Box;
