/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import {View, ViewProps} from 'react-native';
import {Svg, Rect, Defs, LinearGradient, Stop} from 'react-native-svg';

import Theme from '../config/theme';

interface Props extends ViewProps {
  side?: 'top' | 'bottom';
  color?: string;
  opacity?: number;
  reversed?: boolean;
  height?: number;
}

const VerticalBorderShadow: React.FC<Props> = props => {
  const {
    side = 'top',
    color = Theme.colors.border,
    height = 15,
    opacity = 0.5,
    reversed = false,
    style,
    children,
  } = props;

  const [width, setWidth] = React.useState(0);

  const linear = (key: string) => {
    return [
      <Stop
        offset="0"
        stopColor={color}
        stopOpacity={opacity}
        key={key + 'Linear0'}
      />,
      <Stop
        offset="1"
        stopColor={color}
        stopOpacity="0"
        key={key + 'Linear1'}
      />,
    ];
  };

  return (
    <View
      style={[{position: 'relative'}, style]}
      onLayout={e => setWidth(e.nativeEvent.layout.width)}>
      {(() => {
        switch (side) {
          case 'top':
            return (
              <>
                <Svg
                  height={height}
                  width={width}
                  style={{
                    position: 'absolute',
                    zIndex: 999,
                    top: reversed ? 0 : -height,
                  }}>
                  <Defs>
                    <LinearGradient id="top" x1="0%" x2="0%" y1="100%" y2="0%">
                      {linear('BorderTop')}
                    </LinearGradient>
                    <LinearGradient
                      id="top-inset"
                      x1="0%"
                      x2="0%"
                      y1="0%"
                      y2="100%">
                      {linear('BorderTopInset')}
                    </LinearGradient>
                  </Defs>
                  <Rect
                    x={0}
                    y={0}
                    width={width}
                    height={height}
                    fill={`url(#top${reversed ? '-inset' : ''})`}
                  />
                </Svg>
                {children}
              </>
            );
          case 'bottom':
            return (
              <>
                {children}
                <Svg
                  key={'border'}
                  height={height}
                  width={width}
                  style={{
                    position: 'absolute',
                    bottom: reversed ? 0 : -height,
                  }}>
                  <Defs>
                    <LinearGradient
                      id="bottom"
                      x1="0%"
                      x2="0%"
                      y1="0%"
                      y2="100%">
                      {linear('BorderBottom')}
                    </LinearGradient>
                    <LinearGradient
                      id="bottom-inset"
                      x1="0%"
                      x2="0%"
                      y1="100%"
                      y2="0%">
                      {linear('BorderBottomInset')}
                    </LinearGradient>
                  </Defs>
                  <Rect
                    x={0}
                    y={0}
                    width={width}
                    height={height}
                    fill={`url(#bottom${reversed ? '-inset' : ''})`}
                  />
                </Svg>
              </>
            );
          default:
            throw new Error(
              "Wrong Type of Side! We just support 'top' and 'bottom'",
            );
        }
      })()}
    </View>
  );
};

export default VerticalBorderShadow;
