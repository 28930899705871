import * as React from 'react';

import Flex from '../../../components/Flex';
import VerticalBorderShadow from '../../../components/VerticalBoxShadow';
import Theme from '../../../config/theme';
import Agenda from '../Agenda';
import AgendaHeader from '../AgendaHeader';

const MobilePlanningLayout: React.FC = () => {
  return (
    <Flex
      style={Theme.commonStyles.flex1}
      backgroundColor={Theme.colors.secondaryHover}>
      <VerticalBorderShadow side="top" height={20} reversed>
        <AgendaHeader />
      </VerticalBorderShadow>
      <Agenda />
    </Flex>
  );
};

export default MobilePlanningLayout;
