import testIDs from '@testIDs';
import * as React from 'react';
import {useMutation} from 'react-query';

import usePacoApiError from '@services/usePacoApiError';

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Icon from '../../components/Icon';
import Loader from '../../components/Loader';
import Popup from '../../components/Popup';
import PressableIcon from '../../components/PressableIcon';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import Theme from '../../config/theme';
import {SpaceTypes, Vehicle, useApi} from '../../services/pacoApi';

import DeleteVehiclePopup from './DeleteVehiclePopup';

interface Props {
  vehicle: Vehicle;
  openEditVehiclePage: () => void;
  onDelete: () => void;
}

const getIconeName = (type: SpaceTypes | undefined): string | null => {
  switch (type) {
    case SpaceTypes.Utility:
      return 'Utility';
    case SpaceTypes.Car:
      return 'Car';
    case SpaceTypes.Moto:
      return 'Moto';
    case SpaceTypes.Bike:
      return 'Bike';
    default:
      return null;
  }
};
const VehicleItem: React.FC<Props> = ({
  vehicle,
  openEditVehiclePage,
  onDelete,
}) => {
  const {isEv, name, type, registrationPlate, vehicleId} = vehicle;
  const iconName = getIconeName(type);
  const [isPopupVisible, setPopupVisible] = React.useState<boolean>(false);
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError();

  const api = useApi();
  const {
    mutate,
    isSuccess,
    error,
    isLoading: isDeleting,
  } = useMutation(async () => {
    return api.Vehicles.v1VehiclesVehicleIdDelete(vehicleId ?? '');
  });

  React.useEffect(() => {
    if (isSuccess) {
      onDelete();
    }
  }, [isSuccess, onDelete]);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  const registrationPlateIsNullOrEmpty =
    registrationPlate == null || registrationPlate.trim().length === 0;

  return (
    <Box padding={Theme.spacing.l} skipRight skipLeft>
      <Flex
        direction="row"
        alignItems="center"
        style={Theme.commonStyles.flex1}>
        {isEv ? (
          <Icon name={'Electric'} size={Theme.icons.l} />
        ) : (
          <Spacer spacing={Theme.icons.l} mode="horizontal" />
        )}
        {iconName ? (
          <Icon name={iconName} size={Theme.icons.l} />
        ) : (
          <Spacer spacing={Theme.icons.l} mode="horizontal" />
        )}
        <Spacer spacing={Theme.spacing.l} mode="horizontal" />
        <Flex style={Theme.commonStyles.flex1}>
          <Text numberOfLines={1} style={Theme.commonStyles.flex1}>
            {name}
          </Text>
          {!registrationPlateIsNullOrEmpty && (
            <>
              <Spacer spacing={Theme.spacing.s} />
              <Text>{registrationPlate}</Text>
            </>
          )}
        </Flex>
        <Spacer spacing={Theme.spacing.s} mode="horizontal" />
        <PressableIcon
          name="Edit"
          size={Theme.icons.l}
          testID={`${testIDs.vehicles.item.update}-${vehicle.vehicleId}`}
          onPress={() => {
            openEditVehiclePage();
          }}
        />
        <Spacer mode="horizontal" spacing={Theme.spacing.m} />
        <PressableIcon
          name="Delete"
          size={Theme.icons.xl}
          testID={`${testIDs.vehicles.item.delete}-${vehicle.vehicleId}`}
          onPress={() => setPopupVisible(true)}
        />
      </Flex>
      {vehicle.vehicleId ? (
        <DeleteVehiclePopup
          vehicleId={vehicle.vehicleId}
          visible={isPopupVisible}
          close={() => setPopupVisible(false)}
          onDelete={() => {
            mutate();
          }}
        />
      ) : null}
      <Popup
        {...errorPopupProps}
        testID={`${testIDs.vehicles.item.popup}-${vehicle.vehicleId}`}
      />
      <Loader isLoading={isDeleting} />
    </Box>
  );
};

export default VehicleItem;
