import testIDs from '@testIDs';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Theme from '../config/theme';
import {AuthActions, useAppDispatch} from '../reducers';

import {MemberActions} from './../reducers/slices/Member';
import Button from './Button';
import Flex from './Flex';
import Popup from './Popup';
import Spacer from './Spacer';
import Text from './Text';

interface Props {
  visible: boolean;
  close: () => void;
  onDisconnect?: () => void;
}
const DisconnectPopup: React.FC<Props> = ({
  visible,
  close,
  onDisconnect = () => null,
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <Popup title={t('popup-disconnect-title')} visible={visible} close={close}>
      <Flex alignItems="center">
        <Text>{t('disconnect-confirm')}</Text>
      </Flex>
      <Spacer spacing={Theme.spacing.l} />
      <Button
        title={t('disconnect-popup-yes')}
        variant="Primary"
        onPress={() => {
          dispatch(AuthActions.clearTicket());
          dispatch(MemberActions.clearDriverInfo());
          close();
          onDisconnect();
        }}
        testID={testIDs.disconnectPopup.yesButton}
      />
      <Spacer spacing={Theme.spacing.m} />
      <Button
        title={t('disconnect-popup-no')}
        variant="Secondary"
        onPress={close}
      />
    </Popup>
  );
};

export default DisconnectPopup;
