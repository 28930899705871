import {PortalHost} from '@gorhom/portal';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollViewProps} from 'react-native';

import Block from '../../../components/Block';
import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Spacer from '../../../components/Spacer';
import Theme from '../../../config/theme';
import WebBaseContainer from '../../../containers/WebBaseContainer';
import {useResponsiveProperty} from '../../../services/responsive';

interface Props {
  showRemote?: boolean;
  refreshControl: ScrollViewProps['refreshControl'];
}

const SM_PROPERTIES = {
  isSmall: true,
};

const LG_PROPERTIES = {
  isSmall: false,
  remoteContainerStyle: {width: 375, height: '100%'},
};

const WebAccessLayout: React.FC<Props> = ({showRemote, children}) => {
  const {t} = useTranslation();
  const {isSmall, remoteContainerStyle} = useResponsiveProperty({
    xs: SM_PROPERTIES,
    sm: SM_PROPERTIES,
    lg: LG_PROPERTIES,
  });
  return (
    <WebBaseContainer>
      {children}
      <Flex
        style={Theme.commonStyles.flex1}
        direction={isSmall ? 'column' : 'row'}>
        <Block
          style={Theme.commonStyles.flex1}
          title={t('access-screen-title')}>
          <PortalHost name="access-parking-block" />
        </Block>
        {showRemote && (
          <>
            <Spacer
              mode={isSmall ? 'vertical' : 'horizontal'}
              spacing={Theme.spacing.l}
            />
            <Block style={remoteContainerStyle} title={isSmall ? '' : ' '}>
              <Flex style={Theme.commonStyles.flex1} justifyContent="center">
                <Box padding={Theme.spacing.l}>
                  <PortalHost name="access-remote-block" />
                </Box>
              </Flex>
            </Block>
          </>
        )}
      </Flex>
    </WebBaseContainer>
  );
};

export default WebAccessLayout;
