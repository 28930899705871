import testIDs from '@testIDs';
import {DateTime} from 'luxon';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Theme from '@config/theme';

import {PlanningActions, useAppDispatch} from '@common/reducers';

import Button from '@components/Button';
import Flex from '@components/Flex';
import Loader from '@components/Loader';
import Popup from '@components/Popup';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import {useApi} from '@services/pacoApi';
import {useMessagePopup} from '@services/popups';
import {useMutation} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

interface Props {
  allocationId: string;
  day: DateTime;
  visible: boolean;
  parkingName: string;
  close: () => void;
}
const ReleaseSpacePopup: React.FC<Props> = ({
  allocationId,
  day,
  visible,
  parkingName,
  close,
}) => {
  const {t} = useTranslation();
  const api = useApi();
  const dispatch = useAppDispatch();
  const {popupProps: errorPopupProps, handle: showError} = usePacoApiError({
    buttonTestId: testIDs.planning.releaseErrorPopupButton,
  });
  const {props: successPopupProps, show: showSuccess} = useMessagePopup({
    testID: testIDs.planning.releaseSuccessPopup,
  });

  const {
    isError,
    isSuccess,
    isLoading,
    error: apiError,
    mutate: handleConfirmClick,
    data: apiResponse,
  } = useMutation(async () => {
    return await api.SpaceReleases.v1SpaceReleasesSpaceReleasesNewPost(
      allocationId,
      day.toISO(),
    );
  });

  React.useEffect(() => {
    if (isError) {
      showError(apiError);
    }

    if (isSuccess) {
      showSuccess({
        message: apiResponse?.data.message,
        title: apiResponse?.data.title,
        onClose: () => dispatch(PlanningActions.setShouldReloadPlanning(true)),
      });
    }
  }, [
    isError,
    isSuccess,
    apiResponse,
    apiError,
    showError,
    showSuccess,
    dispatch,
  ]);

  return (
    <>
      <Popup
        visible={visible}
        close={close}
        testID={testIDs.planning.releasePopup}>
        <Flex alignItems="center">
          <Text>
            {t('release-confirm', {
              day: day.day,
              month: day.toLocaleString({month: 'long'}),
              parkingName: parkingName,
            })}
          </Text>
        </Flex>
        <Spacer spacing={Theme.spacing.l} />
        <Button
          title={t('release-popup-yes')}
          variant="Primary"
          onPress={() => {
            handleConfirmClick();
            close();
          }}
          testID={testIDs.planning.releasePopupConfirmButton}
        />
        <Spacer spacing={Theme.spacing.m} />
        <Button
          title={t('release-popup-no')}
          variant="Secondary"
          onPress={close}
        />
      </Popup>
      <Loader
        isLoading={isLoading}
        title={t('release-space-button-click-loading')}
      />
      <Popup {...successPopupProps} />
      <Popup {...errorPopupProps} testID={testIDs.planning.releaseErrorPopup} />
    </>
  );
};

export default ReleaseSpacePopup;
