import {FormikContextType} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {useAppSelector} from '../../reducers';
import {FormValues} from '../../screens/NewReservationForm/utils';
import {Parking} from '../../services/pacoApi';
import Select from '../Select';

import LabelComponent from './LabelComponent';
import RowComponent from './RowComponent';
import {getOptions, getDefaultParkingIndex} from './utils';

interface Props {
  testID?: string;
  formik: FormikContextType<FormValues>;
  parkings: Parking[];
}

const ParkingSelector: React.FC<Props> = ({parkings, formik, testID}) => {
  const {t} = useTranslation();
  const {values, setFieldValue} = formik;

  const options = getOptions(parkings);
  const isEmpty = parkings.length === 0;

  const lastUsedParkingId = useAppSelector(
    state => state.Member.lastUsedParkingId,
  );

  React.useEffect(() => {
    if (values.selectedParking == null) {
      const id = getDefaultParkingIndex(parkings, lastUsedParkingId);
      setFieldValue('selectedParking', id);
    }
  }, [values, lastUsedParkingId, setFieldValue, parkings]);

  return (
    <Select
      testID={testID}
      selected={isEmpty ? 1 : values.selectedParking}
      options={options}
      onSelect={value => {
        setFieldValue('selectedParking', value);
      }}
      placeholder={t('new-reservation-form-parking-choice')}
      RowComponent={RowComponent}
      LabelComponent={LabelComponent}
    />
  );
};

export default ParkingSelector;
