import * as React from 'react';
import {View, StyleSheet, Pressable} from 'react-native';

import Theme from '../config/theme';
import {getDeviceType} from '../services/helpers/deviceHelper';

import Modal from './Modal';
import Spacer from './Spacer';
import Text from './Text';

interface Props {
  title?: string | null;
  visible?: boolean;
  close: () => void;
  testID?: string;
}

const Popup: React.FC<Props> = ({title, visible, close, children, testID}) => {
  return (
    <Modal visible={visible}>
      <Pressable style={Styles.outterContainer} onPress={() => close()}>
        <View style={Styles.container} testID={testID}>
          {title && (
            <>
              <Text
                variant="Medium"
                style={Styles.title}
                testID={`${testID}-title`}>
                {title}
              </Text>
              <Spacer spacing={Theme.spacing.l} />
            </>
          )}
          {children}
        </View>
      </Pressable>
    </Modal>
  );
};

const Styles = StyleSheet.create({
  outterContainer: {
    flex: 1,
    backgroundColor: Theme.colors.backdrop,
    justifyContent: 'center',
    alignItems: getDeviceType() === 'web-desktop' ? 'center' : undefined,
    padding: Theme.spacing.l,
  },
  container: {
    backgroundColor: Theme.colors.white,
    borderRadius: Theme.radius.default,
    paddingVertical: Theme.spacing.xxl,
    paddingHorizontal: Theme.spacing.xl,
    width: getDeviceType() === 'web-desktop' ? 496 : undefined,
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
  },
});

export default Popup;
