import * as React from 'react';
import {StyleSheet, Pressable, View, ViewStyle} from 'react-native';

import Theme from '../../config/theme';
import Field from '../Field';
import Text from '../Text';

import TimeComponent from './TimeComponent';
import {HM, HMtoString} from './utils';

interface Props {
  disabled?: boolean;
  disabledBackgroundColor?: string;
  style?: ViewStyle;
  value?: HM | null;
  placeholder?: string;
  onChange?: (hm?: HM) => void;
  error?: string;
  outerContainerStyles?: ViewStyle;
  containerStyles?: ViewStyle;
  leftIconName?: string;
  rightIconName?: string;
  iconColor?: string;
  showLabel?: boolean;
  secureTextEntry?: boolean;
  pointerEvents?: 'box-none' | 'none' | 'box-only' | 'auto';
  testID?: string;
}

interface State {
  isVisible: boolean;
  isFocused: boolean;
}

class TimePicker extends React.Component<Props, State> {
  container = React.createRef<View>();
  state = {
    isVisible: false,
    isFocused: false,
  };

  close = (): void => {
    this.setState({
      isVisible: false,
      isFocused: false,
    });
  };

  open = (): void => {
    this.setState({
      isVisible: true,
      isFocused: true,
    });
  };

  render(): JSX.Element {
    const {error, pointerEvents, value, onChange, ...fieldProps} = this.props;
    const {disabled, placeholder} = fieldProps;
    const isOpened = Boolean(this.state.isFocused || value);

    return (
      <View ref={this.container} style={Theme.commonStyles.flex1}>
        <Pressable
          testID={this.props.testID}
          pointerEvents={disabled ? 'none' : pointerEvents}
          onPress={this.open}>
          <Field
            isFocused={this.state.isFocused}
            isOpened={isOpened}
            isValid={!error}
            {...fieldProps}>
            <TimeComponent
              value={HMtoString(value)}
              placeholder={placeholder}
              target={this.container.current as Element | null}
              onTimeChange={onChange}
              visible={this.state.isVisible}
              close={this.close}
              testID={this.props.testID}
            />
          </Field>
        </Pressable>
        <Text variant="Label" style={Styles.errorText}>
          {error || ' '}
        </Text>
      </View>
    );
  }
}

const Styles = StyleSheet.create({
  errorText: {
    color: Theme.colors.error,
  },
});

export default TimePicker;
