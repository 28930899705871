import * as React from 'react';
import {View, ViewProps} from 'react-native';

interface Props extends ViewProps {
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  backgroundColor?: string;
}
const Flex: React.FC<Props> = ({
  direction,
  justifyContent,
  alignItems,
  style,
  backgroundColor,
  ...props
}) => {
  return (
    <View
      {...props}
      style={[
        {flexDirection: direction, justifyContent, alignItems, backgroundColor},
        style,
      ]}
    />
  );
};

export default Flex;
