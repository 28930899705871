import {DateTime} from 'luxon';
import * as React from 'react';
import {Pressable, StyleSheet, View} from 'react-native';

import Theme from '../../config/theme';
import {getDateTimeFromDMY} from '../../services/helpers/dateHelper';
import {getDeviceType} from '../../services/helpers/deviceHelper';
import Field from '../Field';
import MonthView from '../MonthView';
import PopoverComponent from '../PopoverComponent';
import Text from '../Text';

import {dateTimeToDMY, DMY, formatDMY, parseDMY} from './utils';

interface Props {
  disabled?: boolean;
  value?: DMY;
  minDate?: DMY;
  maxDate?: DMY;
  error?: string;
  showLabel?: boolean;
  placeholder?: string;
  onSelect?: (selected: DMY) => void;
}

interface State {
  isFocused: boolean;
  isVisible: boolean;
  monthIndex: number;
  year: number;
}
class DatePicker extends React.Component<Props, State> {
  state = {
    isFocused: false,
    isVisible: false,
    monthIndex: 0,
    year: 0,
  };
  target = React.createRef<View>();

  open = (): void => {
    const now = dateTimeToDMY(DateTime.now());

    this.setState({
      isFocused: true,
      isVisible: true,
      monthIndex: this.props.value ? this.props.value.month : now.month,
      year: this.props.value ? this.props.value.year : now.year,
    });
  };

  close = (): void => {
    this.setState({
      isFocused: false,
      isVisible: false,
    });
  };

  render(): JSX.Element {
    const {
      error,
      value: selectedDate,
      maxDate,
      minDate,
      onSelect,
      ...fieldProps
    } = this.props;
    const {placeholder, disabled} = fieldProps;

    const isOpened = Boolean(this.state.isFocused || selectedDate);
    const nowKey = parseDMY(dateTimeToDMY(DateTime.now())) as string;
    const selectedKey = parseDMY(selectedDate);
    const marking = {
      [nowKey]: {text: {color: Theme.colors.callToAction}},
      ...(selectedKey
        ? {
            [selectedKey]: {
              container: {backgroundColor: Theme.colors.teaGreen},
            },
          }
        : {}),
    };

    return (
      <View style={[Theme.commonStyles.flex1, Styles.container]}>
        <Pressable
          ref={this.target}
          style={Theme.commonStyles.flex1}
          onPress={() => !disabled && this.open()}>
          <Field
            isFocused={this.state.isFocused}
            isOpened={isOpened}
            isValid={!error}
            {...fieldProps}
            leftIconName="Planning">
            <Text>{formatDMY(selectedDate) || ' '}</Text>
          </Field>
        </Pressable>
        <Text variant="Label" style={Styles.errorText}>
          {error || ' '}
        </Text>
        <PopoverComponent
          visible={this.state.isVisible}
          placeholder={placeholder}
          close={this.close}
          target={this.target.current as Element | null}>
          <View style={[Styles.calendar, webStyle]}>
            <MonthView
              month={this.state.monthIndex}
              year={this.state.year}
              onDayPress={dateObject => {
                onSelect && onSelect(dateObject);
                this.close();
              }}
              minDate={minDate}
              maxDate={maxDate}
              markedDates={marking}
              showArrows
              onLeftArrowPress={() => {
                const date = getDateTimeFromDMY({
                  day: 1,
                  month: this.state.monthIndex,
                  year: this.state.year,
                }).minus({month: 1});
                this.setState({
                  monthIndex: date.month,
                  year: date.year,
                });
              }}
              onRightArrowPress={() => {
                const date = getDateTimeFromDMY({
                  day: 1,
                  month: this.state.monthIndex,
                  year: this.state.year,
                }).plus({month: 1});
                this.setState({
                  monthIndex: date.month,
                  year: date.year,
                });
              }}
            />
          </View>
        </PopoverComponent>
      </View>
    );
  }
}

const webStyle = getDeviceType() === 'web-desktop' ? {width: '370px'} : {};

const Styles = StyleSheet.create({
  container: {flexBasis: 80},
  calendar: {height: 310, paddingHorizontal: Theme.spacing.l},
  errorText: {
    color: Theme.colors.error,
  },
});
export default DatePicker;
