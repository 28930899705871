import {CommonActions} from '@react-navigation/native';
import {
  StackHeaderLeftButtonProps,
  StackHeaderProps,
} from '@react-navigation/stack';
import testIDs from '@testIDs';
import * as React from 'react';
import {StyleSheet, Image, Platform} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import images from '../../../assets/images';
import Flex from '../../components/Flex';
import PressableIcon from '../../components/PressableIcon';
import Theme from '../../config/theme';

export const HeaderFlexLogo: React.FC = () => {
  return (
    <Image
      source={images['zenpark-flex-logo']}
      style={Styles.logo}
      resizeMode="contain"
    />
  );
};

export const HeaderBack: React.FC<StackHeaderLeftButtonProps> = ({
  canGoBack,
  onPress,
}) => {
  const hidden = !canGoBack || Platform.OS === 'web';

  return (
    <PressableIcon
      padding={Theme.spacing.l}
      onPress={() => onPress && onPress()}
      name={'PreviousPage'}
      size={Theme.icons.xl}
      color={hidden ? Theme.colors.white : Theme.colors.primary}
    />
  );
};

interface HeaderMenuButtonProps {
  iconName?: string;
  iconSize?: number;
  onPress: () => void;
}

export const HeaderMenuButton: React.FC<HeaderMenuButtonProps> = ({
  iconName = 'BurgerMenu',
  iconSize = 24,
  onPress,
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      style={Styles.burgerMenu}
      backgroundColor={Theme.colors.white}>
      <PressableIcon
        onPress={onPress}
        name={iconName}
        size={iconSize}
        testID={testIDs.headerMenu.openButton}
      />
    </Flex>
  );
};

const Header: React.FC<StackHeaderProps> = ({navigation, previous}) => {
  return (
    <SafeAreaView edges={['top']}>
      <Flex
        backgroundColor={Theme.colors.white}
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <HeaderBack
          canGoBack={Boolean(previous)}
          onPress={() => navigation.goBack()}
        />

        <HeaderFlexLogo />

        <HeaderMenuButton
          onPress={() => {
            navigation.dispatch(CommonActions.navigate('HeaderMenu'));
          }}
        />
      </Flex>
    </SafeAreaView>
  );
};

const Styles = StyleSheet.create({
  logo: {
    alignSelf: 'center',
    width: 65,
    height: 74,
  },
  burgerMenu: {
    marginRight: Theme.spacing.xl,
    height: 40,
    width: 40,
    borderRadius: 20,

    shadowColor: '#000000AA',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 20.22,
    elevation: 10,
  },
});

export default Header;
