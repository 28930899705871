import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import Theme from '@config/theme';

import {RootNavigationProps} from '@common/navigators';
import SimpleLayout from '@common/shared_layouts/SimpleLayout';

import Block from '@components/Block';
import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import Input from '@components/Input';
import Loader from '@components/Loader';
import Popup from '@components/Popup';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import {useApi} from '@services/pacoApi';
import usePacoApiError from '@services/usePacoApiError';

YupPassword(yup);

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ChangePassword: React.FC<RootNavigationProps<'ChangePassword'>> = ({
  navigation,
}) => {
  const {t} = useTranslation();
  const api = useApi();
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError();

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        currentPassword: yup.string().required(t('password-required')),
        newPassword: yup
          .string()
          .min(8, t('new-password-too-short'))
          .max(128, t('new-password-too-long'))
          .minLowercase(1, t('new-password-needs-lowercase'))
          .minUppercase(1, t('new-password-needs-uppercase'))
          .minNumbers(1, t('new-password-needs-digit'))
          .minSymbols(1, t('new-password-needs-symbol'))
          .required(t('new-password-required')),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('newPassword'), ''], t('passwords-not-match'))
          .required(t('confirm-password-required')),
      }),
    [t],
  );

  const {values, errors, touched, setFieldValue, handleSubmit, handleBlur} =
    useFormik<FormValues>({
      initialValues: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      validationSchema,
      validateOnChange: true,
      onSubmit: () => {
        changePassword();
      },
    });

  const {
    isLoading,
    isSuccess,
    isError,
    error: apiError,
    mutate: changePassword,
  } = useMutation(async () => {
    return api.Drivers.v1DriversUpdatePasswordPatch({
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    });
  });

  React.useEffect(() => {
    if (isSuccess) {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.replace('Home', {
          screen: 'Account',
        });
      }
    }
  }, [isSuccess, navigation]);

  React.useEffect(() => {
    if (isError) {
      handleError(apiError);
    }
  }, [isError, apiError, handleError]);
  return (
    <SimpleLayout>
      <Block title={t('change-password')}>
        <Box padding={Theme.spacing.l}>
          <Input
            value={values.currentPassword}
            onChangeText={value => setFieldValue('currentPassword', value)}
            secureTextEntry={true}
            placeholder={t('current-password')}
            error={touched.currentPassword ? errors.currentPassword : undefined}
            onSubmitEditing={() => handleSubmit()}
            onBlur={handleBlur('currentPassword')}
            rightIconName={'Password'}
          />
          <Spacer spacing={Theme.spacing.s} />
          <Input
            value={values.newPassword}
            onChangeText={value => setFieldValue('newPassword', value)}
            secureTextEntry={true}
            placeholder={t('new-password')}
            error={touched.newPassword ? errors.newPassword : undefined}
            onSubmitEditing={() => handleSubmit()}
            onBlur={handleBlur('newPassword')}
            rightIconName={'Password'}
          />
          <Text variant="Label">{t('password-rules')}</Text>
          <Spacer spacing={Theme.spacing.s} />
          <Input
            value={values.confirmPassword}
            onChangeText={value => setFieldValue('confirmPassword', value)}
            secureTextEntry={true}
            placeholder={t('confirm-password')}
            error={touched.confirmPassword ? errors.confirmPassword : undefined}
            onSubmitEditing={() => handleSubmit()}
            onBlur={handleBlur('confirmPassword')}
            rightIconName={'Password'}
          />
          <Spacer spacing={Theme.spacing.l} />
          <Flex alignItems="center">
            <Button
              expanded={false}
              title={t('change-password-button')}
              onPress={() => handleSubmit()}
            />
          </Flex>
        </Box>
      </Block>
      <Popup {...errorPopupProps} />
      <Loader isLoading={isLoading} />
    </SimpleLayout>
  );
};

export default ChangePassword;
