import testIDs from '@testIDs';
import {DateTime} from 'luxon';
import * as React from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';

import Theme from '@config/theme';

import {useAppSelector} from '@common/reducers';

import Flex from '@components/Flex';
import Text from '@components/Text';

import {PlanningItem, PlanningItemType} from '@services/pacoApi';

import AllocationInformation from './AllocationInformation';
import AllocationItemButtons from './AllocationItemButtons';
import AllocationPeriod from './AllocationPeriod';

interface Props {
  day: DateTime;
  canBeReleased: boolean;
  allocation: PlanningItem;
}
const AllocationItem: React.FC<Props> = ({day, allocation, canBeReleased}) => {
  const [dropdownVisible, setDropdownVisible] = React.useState(false);
  const [target, setTarget] = React.useState<View | null>(null);

  const parkings = useAppSelector(state => state.Member.driverInfo?.parkings);
  const hasManyParkings = parkings ? parkings?.length > 1 : false;

  if (allocation.type === PlanningItemType.Release || !allocation.localFrom) {
    return null;
  }

  return (
    <Flex
      style={styles.container}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor={Theme.colors.white}>
      <TouchableOpacity
        testID={`${testIDs.planning.allocation}-${day.day}`}
        onPress={() => setDropdownVisible(true)}
        ref={setTarget}
        style={[Theme.commonStyles.flex1]}>
        <AllocationPeriod
          day={day}
          localFrom={allocation.localFrom}
          localTo={allocation.localTo}
        />
        {hasManyParkings && (
          <Text numberOfLines={1} style={styles.parking}>
            {allocation.parkingName}
          </Text>
        )}
      </TouchableOpacity>
      <AllocationItemButtons
        day={day}
        allocation={allocation}
        canBeReleased={canBeReleased}
      />
      <AllocationInformation
        dropdownVisible={dropdownVisible}
        setDropdownVisible={setDropdownVisible}
        target={target as Element | null}
        allocation={allocation}
        day={day}
        needsToShowParking={hasManyParkings}
        canBeUpdated={
          canBeReleased && allocation.type === PlanningItemType.Reservation
        }
      />
    </Flex>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 56,
    width: 200,
    borderWidth: 1,
    paddingHorizontal: Theme.spacing.l,
    borderColor: Theme.colors.border,
    borderRadius: Theme.radius.default,
  },
  paddingStyle: {
    paddingVertical: 20,
  },
  parking: {
    fontFamily: Theme.fonts.family.InterMedium,
    lineHeight: 20,
    fontSize: 12,
    width: 150,
  },
});

export default AllocationItem;
