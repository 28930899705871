import {CommonActions} from '@react-navigation/native';
import * as React from 'react';

import {AppActions, useAppDispatch, useAppSelector} from '@common/reducers';

import Loader from '@components/Loader';

import {getNavigationContainer} from '@navigators/NavigationContainer';

import {useApi} from '@services/pacoApi';
import {useQuery} from '@services/queries';

const SsoMarketingOptinGate: React.FC = ({children}) => {
  const api = useApi();
  const {isSuccess, data, isLoading, refetch} = useQuery(
    'canActivateAccount',
    async () => api.MarketingOptin.v1MarketingOptinShouldOptinGet(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const dispatch = useAppDispatch();
  const {ticket, shouldCheckMarketingOptin} = useAppSelector(state => ({
    shouldCheckMarketingOptin: state.App.shouldCheckMarketingOptin,
    ticket: state.Auth.ticket,
  }));

  React.useEffect(() => {
    if (ticket && shouldCheckMarketingOptin) {
      dispatch(AppActions.setShouldCheckMarketingOptin(false));
      refetch();
    }
  }, [dispatch, refetch, shouldCheckMarketingOptin, ticket]);

  React.useEffect(() => {
    if (isSuccess && data?.data) {
      getNavigationContainer()?.dispatch(
        CommonActions.navigate({
          name: 'SsoMarketingOptin',
        }),
      );
    }
  }, [data, isSuccess]);

  return (
    <>
      {children}
      <Loader isLoading={isLoading} />
    </>
  );
};

export default SsoMarketingOptinGate;
