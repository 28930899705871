import {useNavigation} from '@react-navigation/native';
import testIDs from '@testIDs';
import {DateTime} from 'luxon';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, StyleSheet} from 'react-native';

import {dateTimeToDMY, parseDMY} from '../../../../components/DatePicker/utils';
import Flex from '../../../../components/Flex';
import Hoverable from '../../../../components/Hoverable';
import Icon from '../../../../components/Icon';
import Spacer from '../../../../components/Spacer';
import Text from '../../../../components/Text';
import Theme from '../../../../config/theme';
import {HomeNavigationProps} from '../../../../navigators';

interface Props {
  day: DateTime;
}

const AddReservationItem: React.FC<Props> = ({day}) => {
  const navigation =
    useNavigation<HomeNavigationProps<'Planning'>['navigation']>();
  const {t} = useTranslation();
  return (
    <Hoverable
      style={styles.container}
      hoverColor={Theme.colors.secondaryHover}>
      <Pressable
        testID={testIDs.planning.addReservationButton}
        style={Theme.commonStyles.flex1}
        onPress={() => {
          navigation.navigate('NewReservationForm', {
            date: parseDMY(dateTimeToDMY(day)) as string,
          });
        }}>
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          style={Theme.commonStyles.flex1}>
          <Icon name="Add" size={24} color={Theme.colors.primary} />
          <Spacer mode="horizontal" spacing={Theme.spacing.m} />
          <Text style={styles.text}>{t('planning-add-reservation')}</Text>
        </Flex>
      </Pressable>
    </Hoverable>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 56,
    width: 200,
    borderWidth: 1,
    paddingHorizontal: Theme.spacing.l,
    borderColor: Theme.colors.border,
    borderRadius: Theme.radius.default,
  },
  text: {
    fontFamily: Theme.fonts.family.InterMedium,
    lineHeight: 20,
    fontSize: 12,
    width: 150,
  },
});

export default AddReservationItem;
