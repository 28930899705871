import * as React from 'react';

import {RootNavigationProps} from '@common/navigators';

const PrivacyPolicy: React.FC<RootNavigationProps<'PrivacyPolicy'>> = () => {
  return (
    <embed
      src="https://cdn.flex.zenpark.pro/legal/privacy_fr.pdf"
      width="100%"
      height="100%"
    />
  );
};

export default PrivacyPolicy;
