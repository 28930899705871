import * as React from 'react';

import Flex from '@components/Flex';

import {useResponsiveProperty} from '@services/responsive';

const SM_PROPERTIES = {
  flexDirection: 'column',
};

const MD_PROPERTIES = {
  flexDirection: 'row',
};

const WebRowContainer: React.FC = ({children}) => {
  const {flexDirection} = useResponsiveProperty({
    xs: SM_PROPERTIES,
    sm: SM_PROPERTIES,
    md: MD_PROPERTIES,
  });

  return <Flex direction={flexDirection}>{children}</Flex>;
};

export default WebRowContainer;
