import testIDs from '@testIDs';
import {DateTime} from 'luxon';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../../components/Button';
import Flex from '../../components/Flex';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import Theme from '../../config/theme';
import {Interval, Way} from '../../services/pacoApi';

interface Props {
  onPress: (way: Way) => void;
  carEntryInterval: Interval | null;
  carExitInterval: Interval | null;
  pedestrianInterval: Interval | null;
}
const Remote: React.FC<Props> = props => {
  const {t} = useTranslation();
  const isButtonDisabled = (interval: Interval | null): boolean => {
    if (interval == null) {
      return true;
    }

    const now = DateTime.now();
    if (
      interval.localFrom &&
      DateTime.fromISO(interval.localFrom, {setZone: true}) > now
    ) {
      return true;
    }

    return (
      interval.localTo != null &&
      DateTime.fromISO(interval.localTo, {setZone: true}) < now
    );
  };

  const visibleAndDisabled =
    props.carEntryInterval == null &&
    props.carExitInterval == null &&
    props.pedestrianInterval == null;

  const buttons = [
    {
      title: t('car-entry-button'),
      icon: 'CarEntry',
      way: Way.CarEntry,
      visible: props.carEntryInterval != null || visibleAndDisabled,
      disabled: isButtonDisabled(props.carEntryInterval) || visibleAndDisabled,
      testID: testIDs.access.remote.carEntry,
    },
    {
      title: t('car-exit-button'),
      icon: 'CarExit',
      way: Way.CarExit,
      visible: props.carExitInterval != null || visibleAndDisabled,
      disabled: isButtonDisabled(props.carExitInterval) || visibleAndDisabled,
      testID: testIDs.access.remote.carExit,
    },
    {
      title: t('pedestrian-button'),
      icon: 'PedestrianAccess',
      way: Way.Pedestrian,
      visible: props.pedestrianInterval != null,
      disabled: isButtonDisabled(props.pedestrianInterval),
      testID: testIDs.access.remote.pedestrianAccess,
    },
  ];

  return (
    <Flex>
      <Text align="center" variant="Small">
        {t('i-wish-to')}
      </Text>
      {buttons
        .filter(b => b.visible)
        .map((button, i) => (
          <React.Fragment key={`button-container-${i}`}>
            <Spacer spacing={Theme.spacing.s} />
            <Button
              leftIconName={button.icon}
              variant={'Primary'}
              title={button.title}
              disabled={button.disabled}
              onPress={() => {
                props.onPress(button.way);
              }}
              testID={button.testID}
            />
          </React.Fragment>
        ))}
    </Flex>
  );
};

export default Remote;
