import {DateTime} from 'luxon';

import {dateTimeToHM, HM, HMtoString} from '@components/TimePicker/utils';

import {
  dateTimeToDMY,
  DMY,
  parseDateTime,
  parseDMY,
} from '../../components/DatePicker/utils';

export const isDateTimeInPast = (date: DateTime): boolean => {
  const now = DateTime.now();
  return (
    (date.day < now.day &&
      date.month === now.month &&
      date.year === now.year) ||
    (date.month < now.month && date.year === now.year) ||
    date.year < now.year
  );
};

export const isDateTimeToday = (date: DateTime): boolean => {
  const now = DateTime.now();
  return (
    date.day === now.day && date.month === now.month && date.year === now.year
  );
};

export const getDateTimeFromDMY = (dmy: DMY): DateTime => {
  return DateTime.local(dmy.year, dmy.month, dmy.day);
};

export const getDateTimeFromDMYWithMonthOffset = (
  dmy: DMY,
  monthOffset: number,
): DateTime => {
  const dateTimeMonth = DateTime.local(dmy.year, dmy.month, dmy.day);
  return dateTimeMonth.plus({month: monthOffset});
};

export const getDateTimeFromDMYWithDayOffset = (
  dmy: DMY,
  dayOffset: number,
): DateTime => {
  const dateTimeDay = DateTime.local(dmy.year, dmy.month, dmy.day);
  return dateTimeDay.plus({day: dayOffset});
};

export const getTimeDMYWithDayOffset = (dmy: DMY, offset: number): DMY => {
  return dateTimeToDMY(getDateTimeFromDMYWithDayOffset(dmy, offset));
};

export const compareDateTimeDMY = (dmy: DMY, dmy2: DMY): number => {
  const date = getDateTimeFromDMY(dmy);
  const date2 = getDateTimeFromDMY(dmy2);

  return date.toMillis() - date2.toMillis();
};

export const compareMonths = (dmy: DMY, dmy2: DMY): number => {
  const date = getDateTimeFromDMY(dmy).set({day: 1});
  const date2 = getDateTimeFromDMY(dmy2).set({day: 1});
  return date.toMillis() - date2.toMillis();
};

export const monthDiff = (dmy1: DMY, dmy2: DMY): number => {
  const d1 = getDateTimeFromDMY(dmy1);
  const d2 = getDateTimeFromDMY(dmy2);

  let months;
  months = (d2.year - d1.year) * 12;
  months -= d1.month;
  months += d2.month;
  months++;
  return months <= 0 ? 0 : months;
};

export const getFirstDayOfWeek = (date: DMY) => {
  const newDate = getDateTimeFromDMY(date);
  const dayOfWeek = newDate.weekday % 7;
  const offset = dayOfWeek === 0 ? 6 : dayOfWeek > 1 ? dayOfWeek - 1 : 0;
  return dateTimeToDMY(newDate.minus({day: offset}));
};

export const getLastDayOfWeek = (date: DMY): DMY => {
  const newDate = getDateTimeFromDMY(date);
  const dayOfWeek = newDate.weekday % 7;
  const offset = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  const newDateWithOffset = newDate.day + offset;
  return dateTimeToDMY(newDate.set({day: newDateWithOffset}));
};

export function getLocalDateTime(dmy?: DMY, hm?: HM): DateTime | undefined {
  if (!dmy || !hm) {
    return;
  }

  return DateTime.local(dmy.year, dmy.month, dmy.day, hm.hour, hm.minutes);
}

export function toLocaleISOStringTime(
  date: DateTime | undefined,
): string | undefined {
  if (!date) {
    return '';
  }
  const newDate = DateTime.fromISO(date.toString());
  const newUTCDate = DateTime.utc(
    newDate.year,
    newDate.month,
    newDate.day,
    newDate.hour,
    newDate.minute,
  );
  return newUTCDate.toISO();
}

export const parseStartStr = (planningDay: string, localFrom: string) => {
  const date = DateTime.fromISO(localFrom, {setZone: true});
  const key = parseDateTime(date);
  if (planningDay === key) {
    return HMtoString(dateTimeToHM(date));
  }

  return '00:00';
};

export const parseEndStr = (planningDay: string, localEnd?: string | null) => {
  if (localEnd) {
    const date = DateTime.fromISO(localEnd, {setZone: true});

    const key = parseDateTime(date);
    if (planningDay === key) {
      return HMtoString(dateTimeToHM(date));
    }
  }

  return '23:59';
};

export const isPastDate = (dmy?: DMY, hm?: HM): boolean => {
  if (!dmy) {
    return false;
  }

  const min = DateTime.now();
  const _hm = hm || dateTimeToHM(min);
  const date = getLocalDateTime(dmy, _hm) as DateTime;

  return date < min.minus({minute: 10});
};

export const isHMGreaterThan = (hm: HM, hm2: HM): boolean => {
  return (
    hm.hour > hm2.hour || (hm.hour === hm2.hour && hm.minutes > hm2.minutes)
  );
};

export const isDateSmallerThan = (
  dmy: DMY,
  hm: HM | undefined,
  dmy2: DMY,
  hm2: HM | undefined,
) => {
  const date = DateTime.now().set({
    year: dmy.year,
    month: dmy.month - 1,
    day: dmy.day,
    hour: hm?.hour ?? 0,
    minute: hm?.minutes ?? 0,
  });

  const date2 = DateTime.now().set({
    year: dmy2.year,
    month: dmy2.month - 1,
    day: dmy2.day,
    hour: hm2?.hour ?? 0,
    minute: hm2?.minutes ?? 0,
  });

  return date < date2;
};

export const isDateGreaterThan = (
  dmy: DMY,
  hm: HM | undefined,
  dmy2: DMY,
  hm2: HM | undefined,
) => {
  const date = DateTime.now().set({
    year: dmy.year,
    month: dmy.month - 1,
    day: dmy.day,
    hour: hm?.hour ?? 0,
    minute: hm?.minutes ?? 0,
  });

  const date2 = DateTime.now().set({
    year: dmy2.year,
    month: dmy2.month - 1,
    day: dmy2.day,
    hour: hm2?.hour ?? 0,
    minute: hm2?.minutes ?? 0,
  });

  return date > date2;
};

export const getOffsetKey = (date: DMY, offset: number) => {
  const newDate = getDateTimeFromDMYWithDayOffset(date, offset);
  const newDMY = dateTimeToDMY(newDate);
  return parseDMY(newDMY) as string;
};
