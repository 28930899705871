import * as React from 'react';
import {View, StyleSheet} from 'react-native';

import Theme from '@config/theme';

import {getDeviceType} from '@services/helpers/deviceHelper';
import {RuleState} from '@services/pacoApi';

import Button from './Button';
import RulesView from './RulesView';
import Spacer from './Spacer';

interface Props {
  rules: RuleState[];
  onSubmit: () => void;
  onSubmitInvalid: () => void;
  canSubmit: boolean;
  submitButtonLabel: string;
  disabled?: boolean;
  testIDs?: {
    submit?: string;
  };
}

const Footer: React.FC<Props> = ({
  rules,
  onSubmit,
  onSubmitInvalid,
  canSubmit,
  submitButtonLabel,
  disabled,
  testIDs,
}) => {
  const submitButtonDisabled =
    disabled || (getDeviceType() === 'web-desktop' && !canSubmit);

  return (
    <View style={getDeviceType() === 'web-desktop' ? Styles.container : null}>
      {getDeviceType() === 'web-desktop' ? (
        <>
          <RulesView rules={rules} />
          <Spacer spacing={Theme.spacing.xl} />
        </>
      ) : null}
      <Button
        testID={testIDs?.submit}
        disabled={submitButtonDisabled}
        title={submitButtonLabel}
        onPress={() => {
          if (canSubmit) {
            onSubmit();
          } else {
            //scroll to errors
            onSubmitInvalid();
          }
        }}
      />
    </View>
  );
};

const Styles = StyleSheet.create({
  container: {
    padding: Theme.spacing.l,
  },
});

export default Footer;
