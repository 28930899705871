const webCodePush =
  (_config: Record<string, unknown>) =>
  <T>(comp: T): T =>
    comp;

webCodePush.prototype.SyncStatus = {};
webCodePush.prototype.InstallMode = {};
webCodePush.prototype.CheckFrequency = {};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const clearUpdates = (): void => {};

export default webCodePush;
