import * as React from 'react';

import Theme from '../../config/theme';
import {useAppSelector} from '../../reducers';
import Flex from '../Flex';
import Select from '../Select';

import LabelComponent from './LabelComponent';
import RowComponent from './RowComponent';
import {getOptions, getSelectedCountryIndex} from './utils';

interface Props {
  selectedCountryAlpha2: string;
  onSelect: (value: string) => void;

  testID?: string;
}

const CountrySelector: React.FC<Props> = ({
  selectedCountryAlpha2,
  testID,
  onSelect,
}) => {
  const appLanguage = useAppSelector(state => state.App.appLanguage);
  const options = getOptions(appLanguage);

  return (
    <Flex style={Theme.commonStyles.flex1}>
      <Select
        testID={testID}
        options={options}
        onSelect={onSelect}
        selected={getSelectedCountryIndex(options, selectedCountryAlpha2)}
        RowComponent={RowComponent}
        LabelComponent={LabelComponent}
        enableSameWidthModifier={false}
        placement={'bottom-start'}
      />
    </Flex>
  );
};

export default CountrySelector;
