import {AppLanguage} from '../../../services/i18n';

export type LanguageOption = {
  value: number;
  label: string | null;
  language: AppLanguage;
};

export const getSelectedOption = (
  languageOptions: LanguageOption[],
  appLocale: AppLanguage,
): number => {
  const selectedLanguage = languageOptions.find(o => o.language === appLocale);
  if (selectedLanguage) {
    return selectedLanguage.value;
  }
  return languageOptions[0].value;
};

export const getSelectedLanguageByIndex = (
  languageOptions: LanguageOption[],
  value: number,
): AppLanguage => {
  const selectedLanguage = languageOptions.find(o => o.value === value);
  if (selectedLanguage) {
    return selectedLanguage.language;
  }
  return languageOptions[0].language;
};

export const getIconName = (language: AppLanguage): string => {
  switch (language) {
    case 'en':
      return 'gb';
    default:
      return language;
  }
};
