import {DateTime} from 'luxon';
import * as React from 'react';
import {ViewProps} from 'react-native';

import {dateTimeToDMY, DMY} from '../../components/DatePicker/utils';
import MonthView from '../../components/MonthView';
import {useAppDispatch, useAppSelector, PlanningActions} from '../../reducers';
import {
  compareDateTimeDMY,
  getDateTimeFromDMY,
} from '../../services/helpers/dateHelper';

interface Props extends ViewProps {
  onDaySelected?: (dmy: DMY) => void;
}

const PlanningCalendar: React.FC<Props> = props => {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = React.useState({
    month: DateTime.now().month.valueOf(),
    day: 1,
    year: DateTime.now().year,
  });
  const {selectedDay, minDate, maxDate, markedDates} = useAppSelector(state => {
    return {
      planningByDays: state.Planning.planningByDays,
      selectedDay: state.Planning.selectedDay,
      minDate: state.Planning.minDate,
      maxDate: state.Planning.maxDate,
      markedDates: state.Planning.markedDates,
    };
  });
  React.useEffect(() => {
    setSelected({...selectedDay, day: 1});
  }, [selectedDay]);

  return (
    <MonthView
      {...props}
      month={selected.month}
      year={selected.year}
      onDayPress={dmy => {
        dispatch(PlanningActions.setSelectedDay(dmy));
        if (props.onDaySelected) {
          props.onDaySelected(dmy);
        }
      }}
      minDate={minDate}
      maxDate={maxDate}
      markedDates={markedDates}
      showArrows
      onLeftArrowPress={() => {
        const date = getDateTimeFromDMY(selected).minus({month: 1});
        const newSelected = dateTimeToDMY(date);
        if (compareDateTimeDMY({...minDate, day: 1}, newSelected) <= 0) {
          setSelected(dateTimeToDMY(date));
        }
      }}
      onRightArrowPress={() => {
        const date = getDateTimeFromDMY(selected).plus({month: 1});
        const newSelected = dateTimeToDMY(date);
        if (compareDateTimeDMY({...maxDate, day: 1}, newSelected) >= 0) {
          setSelected(dateTimeToDMY(date));
        }
      }}
    />
  );
};

export default PlanningCalendar;
