import testIDs from '@testIDs';
import {DateTime} from 'luxon';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import {shiftZero} from '../../components/TimePicker/utils';
import Theme from '../../config/theme';

interface Props {
  localFrom: string | undefined;
  localTo: string | null | undefined;
}
const AllocationInterval: React.FC<Props> = props => {
  const {t} = useTranslation();

  const formatDate = (dateString: string | null | undefined): string | null => {
    if (!dateString) {
      return null;
    }

    const currentYear = DateTime.now().year;
    const _date = DateTime.fromISO(dateString, {setZone: true});

    const day = _date.toLocaleString({weekday: 'short'});

    const date = _date.day;
    const month = _date.toLocaleString({month: 'short'});
    const year = _date.year;

    const yeartext = currentYear !== year ? ` ${year}` : '';
    const formatted = `${day} ${date} ${month}${yeartext}`;

    return formatted;
  };

  const formatTime = (dateString: string | null | undefined): string | null => {
    if (!dateString) {
      return null;
    }

    const _date = DateTime.fromISO(dateString, {setZone: true});

    const hours = shiftZero(_date.hour);
    const minutes = shiftZero(_date.minute);

    return `${hours}:${minutes}`;
  };

  const fromDate = formatDate(props.localFrom);
  const toDate = formatDate(props.localTo);

  const fromTime = formatTime(props.localFrom);
  const toTime = formatTime(props.localTo);

  if (!fromDate) {
    return null;
  }

  return (
    <Flex direction={'row'}>
      <Flex style={Theme.commonStyles.flex1}>
        <Box padding={Theme.spacing.m}>
          <Text style={[Styles.text, Styles.dateText]}>
            {t('allocated-since')}
          </Text>
          <Text
            style={[Styles.text, Styles.dateText]}
            testID={testIDs.access.allocation.fromDate}>
            {fromDate}
          </Text>
          <Text
            style={Styles.text}
            testID={testIDs.access.allocation.fromTime}
            variant="Medium">
            {fromTime}
          </Text>
        </Box>
      </Flex>

      {Boolean(toDate) && (
        <Spacer spacing={1} mode={'horizontal'} style={Styles.spacer} />
      )}
      {Boolean(toDate) && (
        <Flex
          style={Theme.commonStyles.flex1}
          justifyContent="center"
          alignItems="center">
          <Box padding={Theme.spacing.m}>
            <Text style={[Styles.text, Styles.dateText]}>
              {t('allocated-to')}
            </Text>
            <Text
              style={[Styles.text, Styles.dateText]}
              testID={testIDs.access.allocation.toDate}>
              {toDate}
            </Text>
            <Text
              style={Styles.text}
              testID={testIDs.access.allocation.toTime}
              variant="Medium">
              {toTime}
            </Text>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
const Styles = StyleSheet.create({
  spacer: {
    backgroundColor: Theme.colors.secondary,
  },
  text: {
    textAlign: 'center',
  },
  dateText: {
    fontSize: 12,
    fontFamily: Theme.fonts.family.InterMedium,
    lineHeight: 20,
  },
});

export default AllocationInterval;
