import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Platform} from 'react-native';
import {showTranslations} from 'translation-check';

import Theme from '@config/theme';

import {RootNavigationProps} from '@common/navigators';
import SimpleLayout from '@common/shared_layouts/SimpleLayout';

import Block from '@components/Block';
import Box from '@components/Box';
import Button from '@components/Button';
import Spacer from '@components/Spacer';

import {persistor} from '@reducers/configureStore';

import {restartApp} from '@services/helpers/appHelper';
import i18next from '@services/i18n';
import {crash} from '@services/logger/crashlytics';
import {clearUpdates} from '@services/updates';

const Dev: React.FC<RootNavigationProps<'Dev'>> = props => {
  const {t} = useTranslation();
  return (
    <SimpleLayout>
      <Block title={t('dev-tools')}>
        <Box padding={Theme.spacing.l}>
          <Spacer spacing={Theme.spacing.s} />
          <Button
            onPress={() => props.navigation.navigate('Environment')}
            title={t('env-config')}
          />
          <Spacer spacing={Theme.spacing.s} />
          <Button
            onPress={() => props.navigation.navigate('Examples')}
            title={t('examples')}
          />

          {Platform.OS === 'web' && (
            <>
              <Spacer spacing={Theme.spacing.s} />

              <Button
                title={'Check translation'}
                onPress={() => {
                  showTranslations(i18next);
                }}
              />
            </>
          )}

          <Spacer spacing={Theme.spacing.s} />

          <Button
            onPress={() =>
              persistor.purge().then(() => {
                clearUpdates();
                restartApp();
              })
            }
            title={'Wipe data & codepush '}
          />
        </Box>
      </Block>
      <Spacer spacing={Theme.spacing.l} />
      <Block title="Crash">
        <Box padding={Theme.spacing.l}>
          <Button
            onPress={() => {
              throw new Error('test');
            }}
            title="JS Crash"
          />
          <Spacer spacing={Theme.spacing.s} />
          <Button onPress={() => crash()} title="Native Crrash" />
        </Box>
      </Block>
    </SimpleLayout>
  );
};

export default Dev;
