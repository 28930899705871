import {Portal} from '@gorhom/portal';
import * as React from 'react';
import {StyleSheet} from 'react-native';

import theme from '../config/theme';

import Flex from './Flex';

interface Props {
  visible?: boolean;
}
const Modal: React.FC<Props> = ({visible, children}) => {
  return visible ? (
    <Portal>
      <Flex
        style={[StyleSheet.absoluteFill, theme.commonStyles.flex1]}
        backgroundColor="transparent">
        {children}
      </Flex>
    </Portal>
  ) : null;
};

export default Modal;
