import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Theme from '@config/theme';

import Spacer from '@common/components/Spacer';
import {RootNavigationProps} from '@common/navigators';
import LoginLayout from '@common/shared_layouts/LoginLayout';

import Block from '@components/Block';
import Box from '@components/Box';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import Loader from '@components/Loader';
import Popup from '@components/Popup';
import TermsAndConditionsAndPolicyText from '@components/TermsAndConditionsAndPolicyText';

import {useApi} from '@services/pacoApi';
import {useMutation} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

type FormValues = {
  marketingOptin: boolean;
};

const SsoMarketingOptin: React.FC<RootNavigationProps<'SsoMarketingOptin'>> = ({
  navigation,
}) => {
  const {t} = useTranslation();
  const api = useApi();

  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError();

  const {
    isLoading: isLoadingSaveOptin,
    isSuccess: isSuccessSaveOptin,
    isError: isErrorSaveOptin,
    error: apiErrorSaveOptin,
    mutate: submitMarketingOptin,
  } = useMutation(async () => {
    return api.MarketingOptin.v1MarketingOptinSaveOptinPost({
      marketingOptin: values.marketingOptin,
    });
  });

  const {values, setFieldValue, handleSubmit} = useFormik<FormValues>({
    initialValues: {
      marketingOptin: false,
    },
    validateOnChange: false,
    onSubmit: () => {
      submitMarketingOptin();
    },
  });

  React.useEffect(() => {
    if (isErrorSaveOptin) {
      handleError(apiErrorSaveOptin);
    }
  }, [isErrorSaveOptin, apiErrorSaveOptin, handleError]);

  React.useEffect(() => {
    if (isSuccessSaveOptin) {
      navigation.navigate('Home', {
        screen: 'Planning',
      });
    }
  }, [navigation, isSuccessSaveOptin]);

  return (
    <LoginLayout>
      <Block title={t('sso-signin-title')}>
        <Box padding={Theme.spacing.xxxl}>
          <Checkbox
            label={t('marketing-label')}
            checked={values.marketingOptin}
            onPress={value => setFieldValue('marketingOptin', value)}
          />
          <Spacer spacing={Theme.spacing.xl} />
          <Button
            title={t('save-marketing-optin-button')}
            onPress={() => handleSubmit()}
          />
        </Box>
      </Block>
      <Spacer spacing={Theme.spacing.l} />
      <TermsAndConditionsAndPolicyText />

      <Popup {...errorPopupProps} />
      <Loader isLoading={isLoadingSaveOptin} />
    </LoginLayout>
  );
};

export default SsoMarketingOptin;
