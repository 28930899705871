import testIDs from '@testIDs';
import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';
import * as yup from 'yup';

import Theme from '@config/theme';

import {RootNavigationProps} from '@common/navigators';
import RegistrationLayout from '@common/shared_layouts/RegistrationLayout';

import Block from '@components/Block';
import Box from '@components/Box';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import Flex from '@components/Flex';
import Input from '@components/Input';
import Loader from '@components/Loader';
import PhoneInput from '@components/PhoneInput';
import Popup from '@components/Popup';
import RowContainer from '@components/ResponsiveRowContainer';
import RowSpacer from '@components/ResponsiveRowSpacer';
import Spacer from '@components/Spacer';
import TermsAndConditionsAndPolicyText from '@components/TermsAndConditionsAndPolicyText';
import Text from '@components/Text';
import TextButton from '@components/TextButton';

import {getDeviceType} from '@services/helpers/deviceHelper';
import {useApi} from '@services/pacoApi';
import {useMessagePopup} from '@services/popups';
import {useMutation, useQuery} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  number: string;
  isPrm: boolean;
};

const Registration: React.FC<RootNavigationProps<'Registration'>> = ({
  route,
  navigation,
}) => {
  const {t} = useTranslation();
  const {companyUrn} = route.params;
  const api = useApi();

  const isMobile = getDeviceType() !== 'web-desktop';

  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError({
    onCloseErrorPopup: () => {
      navigation.navigate('Login');
    },
  });
  const {props: successPopupProps, show: showSuccess} = useMessagePopup({
    onClose: () => navigation.replace('CompanyLogin', {companyUrn: companyUrn}),
    testID: testIDs.registration.successPopup,
  });

  const {
    isError: isGetCompanyConfigError,
    data: getCompanyConfigResponse,
    error: getCompanyConfigError,
    isLoading: isLoadingCompanyConfig,
  } = useQuery('getcompanyconfig', async () =>
    api.Companies.v1CompaniesCompanyUrnConfigGet(companyUrn),
  );

  React.useEffect(() => {
    if (isGetCompanyConfigError) {
      handleError(getCompanyConfigError);
    }
  }, [isGetCompanyConfigError, getCompanyConfigError, handleError]);

  const config = getCompanyConfigResponse?.data;

  React.useEffect(() => {
    if (config?.isRegistrationAllowed === false) {
      navigation.replace('Login');
    }
  }, [config, navigation]);

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('first-name-required'))
      .max(60, t('first-name-too-long')),
    lastName: yup
      .string()
      .required(t('last-name-required'))
      .max(60, t('last-name-too-long')),
    isPrm: yup.boolean(),
    phone: yup.string().phone(false, t('invalid-phone-format')),
    email: yup
      .string()
      .email(t('invalid-email-format'))
      .matches(/^[^!]+@[^!]+$/, t('invalid-email-format'))
      .required(t('email-required'))
      .max(256, t('email-too-long')),
    number:
      config?.isDriverNumberRequired === true
        ? yup
            .string()
            .required(t('number-required'))
            .max(30, t('number-too-long'))
        : yup.string().max(30, t('number-too-long')),
  });

  const {values, errors, touched, setFieldValue, handleSubmit, handleBlur} =
    useFormik<FormValues>({
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        number: '',
        isPrm: false,
      },
      validationSchema,
      validateOnChange: false,
      validateOnBlur: true,
      onSubmit: () => {
        register();
      },
    });

  const {
    isLoading: isLoadingRegistration,
    isSuccess: isRegistrationSuccess,
    data: registrationResponse,
    isError: isRegistrationError,
    error: registrationError,
    mutate: register,
  } = useMutation(async () => {
    return api.Drivers.v1DriversRegisterPost({
      companyId: config?.companyId ?? '',
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      isPrm: values.isPrm,
      number: values.number,
      phone: values.phone,
    });
  });

  React.useEffect(() => {
    if (isRegistrationError) {
      handleError(registrationError);
    }

    if (isRegistrationSuccess && registrationResponse?.data) {
      showSuccess({
        message: registrationResponse?.data?.message,
      });
    }
  }, [
    isRegistrationError,
    registrationError,
    handleError,
    isRegistrationSuccess,
    registrationResponse,
    showSuccess,
  ]);
  return (
    <>
      {config?.isRegistrationAllowed && (
        <RegistrationLayout
          landingPageDescription={config?.landingPageDescription ?? ''}
          companyIllustrationUrl={config?.companyIllustrationUrl ?? ''}>
          <Block title={t('registration')}>
            <Flex direction="row" style={Styles.subtitleContainer}>
              <Text variant="Small">{t('registration-screen-subtitle')}</Text>
              <TextButton
                style={Styles.loginText}
                onPress={() =>
                  navigation.navigate('CompanyLogin', {companyUrn: companyUrn})
                }>
                {t('login')}
              </TextButton>
            </Flex>
            <Box padding={Theme.spacing.l}>
              <RowContainer>
                <Input
                  testID={testIDs.registration.firstName}
                  style={Theme.commonStyles.flex1}
                  value={values.firstName}
                  onChangeText={value => setFieldValue('firstName', value)}
                  placeholder={t('first-name-placeholder')}
                  error={touched.firstName ? errors.firstName : undefined}
                  onSubmitEditing={() => handleSubmit()}
                  onBlur={handleBlur('firstName')}
                />
                <RowSpacer />
                <Input
                  testID={testIDs.registration.lastName}
                  style={Theme.commonStyles.flex1}
                  value={values.lastName}
                  onChangeText={value => setFieldValue('lastName', value)}
                  placeholder={t('last-name-placeholder')}
                  error={touched.lastName ? errors.lastName : undefined}
                  onSubmitEditing={() => handleSubmit()}
                  onBlur={handleBlur('lastName')}
                />
              </RowContainer>
              <RowContainer>
                <Input
                  testID={testIDs.registration.email}
                  style={Theme.commonStyles.flex1}
                  value={values.email}
                  keyboardType="email-address"
                  autoCapitalize="none"
                  onChangeText={value => setFieldValue('email', value)}
                  placeholder={t('email-placeholder')}
                  error={touched.email ? errors.email : undefined}
                  onSubmitEditing={() => handleSubmit()}
                  onBlur={handleBlur('email')}
                />
                <RowSpacer />
                <PhoneInput
                  testID={testIDs.registration.phoneNumber}
                  style={Theme.commonStyles.flex1}
                  value={values.phone}
                  placeholder={t('phone-placeholder')}
                  error={touched.phone ? errors.phone : undefined}
                  onSubmitEditing={() => handleSubmit()}
                  onBlur={handleBlur('phone')}
                  onPhoneNumberChange={value => {
                    // const phone = cleanPhoneNumber(value);
                    setFieldValue('phone', value);
                  }}
                />
              </RowContainer>
              <Flex direction="row">
                <Input
                  testID={testIDs.registration.number}
                  style={Theme.commonStyles.flex1}
                  value={values.number}
                  onChangeText={value => setFieldValue('number', value)}
                  placeholder={t('identification-number-placeholder')}
                  error={touched.number ? errors.number : undefined}
                  onSubmitEditing={() => handleSubmit()}
                  onBlur={handleBlur('number')}
                />
                {!isMobile && (
                  <>
                    <Spacer spacing={Theme.spacing.s} mode="horizontal" />
                    <View style={Theme.commonStyles.flex1} />
                  </>
                )}
              </Flex>
              <Checkbox
                testID={testIDs.registration.isPrm}
                checked={values.isPrm}
                label={t('is-prm')}
                labelColor={Theme.colors.secondary}
                onPress={value => setFieldValue('isPrm', value)}
              />
              <Spacer spacing={Theme.spacing.l} />
              <Button
                testID={testIDs.registration.submit}
                title={t('registration')}
                onPress={() => handleSubmit()}
              />
            </Box>
          </Block>
          <TermsAndConditionsAndPolicyText />
        </RegistrationLayout>
      )}

      <Popup {...successPopupProps} />
      <Popup {...errorPopupProps} />

      <Loader isLoading={isLoadingCompanyConfig || isLoadingRegistration} />
    </>
  );
};

const Styles = StyleSheet.create({
  subtitleContainer: {
    padding: Theme.spacing.l,
    paddingBottom: Theme.spacing.xs,
    alignItems: 'center',
  },
  loginText: {
    color: Theme.colors.primary,
    fontFamily: Theme.fonts.family.BasicGrotesqueBold,
  },
});

export default Registration;
