import * as React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
export {useMutation, useQuery} from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

export const QueryProvider: React.FC = props => {
  return <QueryClientProvider client={queryClient} {...props} />;
};
