import * as React from 'react';

import VehicleForm from '../../components/VehicleForm/VehicleForm';
import {RootNavigationProps} from '../../navigators';
import {SpaceTypes} from '../../services/pacoApi';

const AddVehicle: React.FC<RootNavigationProps<'AddVehicle'>> = () => {
  return <VehicleForm type={SpaceTypes.None} />;
};

export default AddVehicle;
