import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';
import {useMutation, useQuery} from 'react-query';
import * as yup from 'yup';

import usePacoApiError from '@services/usePacoApiError';

import Block from '../../components/Block';
import Box from '../../components/Box';
import Button from '../../components/Button';
import Flex from '../../components/Flex';
import Input from '../../components/Input';
import Loader from '../../components/Loader';
import Popup from '../../components/Popup';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import TextButton from '../../components/TextButton';
import Theme from '../../config/theme';
import {RootNavigationProps} from '../../navigators';
import {AuthActions, useAppDispatch} from '../../reducers';
import {useApi} from '../../services/pacoApi';
import LandingPageLayout from '../../shared_layouts/RegistrationLayout';

type FormValues = {
  username: string;
  password: string;
};

const CompanyLogin: React.FC<RootNavigationProps<'CompanyLogin'>> = ({
  route,
  navigation,
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {companyUrn} = route.params;
  const api = useApi();
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError({
    onCloseErrorPopup: () => {
      navigation.navigate('Login');
    },
  });
  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .email(t('invalid-email-format'))
      .required(t('email-required')),
    password: yup.string().required(t('password-required')),
  });

  const {
    isError: isGetCompanyConfigError,
    data: getCompanyConfigResponse,
    error: getCompanyConfigError,
    isLoading: isLoadingCompanyConfig,
  } = useQuery('getcompanyconfig', async () =>
    api.Companies.v1CompaniesCompanyUrnConfigGet(companyUrn),
  );

  React.useEffect(() => {
    if (isGetCompanyConfigError) {
      handleError(getCompanyConfigError);
    }
  }, [isGetCompanyConfigError, getCompanyConfigError, handleError]);

  const config = getCompanyConfigResponse?.data;

  React.useEffect(() => {
    if (config?.isRegistrationAllowed === false) {
      navigation.replace('Login');
    }
  }, [config, navigation]);

  const {values, errors, touched, setFieldValue, handleSubmit, handleBlur} =
    useFormik<FormValues>({
      initialValues: {
        username: '',
        password: '',
      },
      validationSchema,
      validateOnChange: false,
      validateOnBlur: true,
      onSubmit: () => {
        authenticate();
      },
    });

  const {
    isLoading: isLoadingAuthentication,
    isSuccess: isAuthenticationSuccess,
    data: apiAuthenticationResponse,
    isError: isAuthenticationError,
    error: apiAuthenticationError,
    mutate: authenticate,
  } = useMutation(async () => {
    return api.Authentication.v1AuthenticationLoginPost({
      email: values.username,
      password: values.password,
    });
  });

  React.useEffect(() => {
    if (
      isAuthenticationSuccess &&
      apiAuthenticationResponse?.data.accessToken
    ) {
      dispatch(AuthActions.setTicket(apiAuthenticationResponse.data));
    }
  }, [dispatch, isAuthenticationSuccess, apiAuthenticationResponse]);

  React.useEffect(() => {
    if (isAuthenticationError) {
      handleError(apiAuthenticationError);
    }
  }, [isAuthenticationError, apiAuthenticationError, handleError]);

  return (
    <LandingPageLayout
      landingPageDescription={config?.landingPageDescription ?? ''}
      companyIllustrationUrl={config?.companyIllustrationUrl ?? ''}>
      <Block title={t('login')}>
        <Flex direction="row" style={Styles.subtitleContainer}>
          <Text variant="Small">{t('company-login-screen-subtitle')}</Text>
          <TextButton
            style={Styles.loginText}
            onPress={() =>
              navigation.navigate('Registration', {companyUrn: companyUrn})
            }>
            {t('registration')}
          </TextButton>
        </Flex>
        <Box padding={Theme.spacing.l}>
          <Input
            value={values.username}
            keyboardType="email-address"
            autoCapitalize="none"
            onChangeText={value => setFieldValue('username', value)}
            placeholder={t('email-placeholder')}
            error={touched.username ? errors.username : undefined}
            onSubmitEditing={() => handleSubmit()}
            onBlur={handleBlur('username')}
          />
          <Spacer spacing={Theme.spacing.s} />
          <Input
            value={values.password}
            onChangeText={value => setFieldValue('password', value)}
            placeholder={t('login-password')}
            error={touched.password ? errors.password : undefined}
            secureTextEntry={true}
            onSubmitEditing={() => handleSubmit()}
            onBlur={handleBlur('password')}
            rightIconName={'Password'}
          />
          <Spacer spacing={Theme.spacing.s} />
          <Button title={t('login')} onPress={() => handleSubmit()} />
          <Spacer spacing={Theme.spacing.l} />
          <TextButton onPress={() => navigation.navigate('ForgottenPassword')}>
            {t('forgotten-password')}
          </TextButton>
        </Box>
      </Block>

      <Popup {...errorPopupProps} />
      <Loader isLoading={isLoadingCompanyConfig || isLoadingAuthentication} />
    </LandingPageLayout>
  );
};

const Styles = StyleSheet.create({
  subtitleContainer: {
    padding: Theme.spacing.l,
    paddingBottom: Theme.spacing.xs,
    alignItems: 'center',
  },
  loginText: {
    color: Theme.colors.primary,
    fontFamily: Theme.fonts.family.BasicGrotesqueBold,
  },
});

export default CompanyLogin;
