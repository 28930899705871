import * as React from 'react';

import Admin from '@screens/Admin';

import {RootStack} from './utils';

export const renderAdminScreens = (
  Stack: RootStack,
  getTitle: (key: string) => string,
) => {
  return (
    <Stack.Screen
      name="Admin"
      component={Admin}
      options={{
        headerShown: false,
        title: getTitle('tabbar-admin'),
      }}
    />
  );
};
