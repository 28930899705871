import './services/i18n';
import './services/validators';

import {PortalProvider} from '@gorhom/portal';
import * as React from 'react';
import {View, Platform, StatusBar} from 'react-native';
import {hide as hideRNBootSplash} from 'react-native-bootsplash';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Provider} from 'react-redux';

import {optinToPushNotificationSubscription} from '@services/braze';
import {UpdatesProvider} from '@services/updates';

import theme from './config/theme';
import {PersistorGate, DriverInfoGate} from './gates';
import ConfigurationGate from './gates/ConfigurationGate';
import SsoMarketingOptinGate from './gates/SsoMarketingOptinGate';
import {NavigationContainer} from './navigators';
import {store} from './reducers';
import {QueryProvider} from './services/queries';

const App: React.FC = () => {
  React.useEffect(() => {
    if (Platform.OS !== 'web') {
      hideRNBootSplash();
    }
    optinToPushNotificationSubscription();
  }, []);
  return (
    <View
      style={Platform.select({
        default: {flex: 1},
        web: webStyle,
      })}>
      <StatusBar
        translucent
        barStyle={'dark-content'}
        backgroundColor={theme.colors.white}
      />
      <UpdatesProvider>
        <SafeAreaProvider>
          <QueryProvider>
            <Provider store={store}>
              <PortalProvider>
                <PersistorGate>
                  <ConfigurationGate>
                    <DriverInfoGate>
                      <SsoMarketingOptinGate>
                        <NavigationContainer />
                      </SsoMarketingOptinGate>
                    </DriverInfoGate>
                  </ConfigurationGate>
                </PersistorGate>
              </PortalProvider>
            </Provider>
          </QueryProvider>
        </SafeAreaProvider>
      </UpdatesProvider>
    </View>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const webStyle: any = {position: 'fixed', top: 0, right: 0, left: 0, bottom: 0};
export default App;
