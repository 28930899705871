import {DateTime, Info} from 'luxon';
import * as React from 'react';
import {Pressable, TextStyle, View, ViewProps, ViewStyle} from 'react-native';

import Theme from '../../config/theme';
import {compareDateTimeDMY} from '../../services/helpers/dateHelper';
import {dateTimeToDMY, DMY, parseDMY} from '../DatePicker/utils';
import Flex from '../Flex';
import Spacer from '../Spacer';
import Text from '../Text';

import Header from './Header';
import Styles from './MonthView.styles';

type CustomStyle = {
  container?: ViewStyle;
  text?: TextStyle;
  dots?: string[];
};

interface State {
  rows: number[][];
}
interface Props extends ViewProps {
  month: number;
  year: number;
  minDate?: DMY;
  maxDate?: DMY;
  firstDay?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  markedDates?: Record<string, CustomStyle>;
  hideExtraDays?: boolean;
  showArrows?: boolean;
  onDayPress?: (dmy: DMY) => void;
  onLeftArrowPress?: () => void;
  onRightArrowPress?: () => void;
}
class MonthView extends React.PureComponent<Props, State> {
  state = {
    rows: [],
  };

  static indexToDayOfWeek = (
    index: number,
    firstDay: number = DEFAULT_FIRST_DAY,
  ) => {
    return (index + firstDay) % 7;
  };

  static dayOfWeekToIndex = (
    dayOfWeek: number,
    firstDay: number = DEFAULT_FIRST_DAY,
  ) => {
    return (7 + dayOfWeek - firstDay) % 7;
  };

  static getDerivedStateFromProps(props: Props) {
    const {year, month, firstDay = DEFAULT_FIRST_DAY} = props;
    let lastDate = DateTime.local(year, month, 1);
    const rows: number[][] = [];

    let rowIndex = -1;
    while (lastDate.month === month) {
      if (lastDate.weekday === firstDay || rowIndex === -1) {
        rowIndex++;
      }
      if (!rows[rowIndex]) {
        rows[rowIndex] = [];
      }
      const row = rows[rowIndex];
      const index = MonthView.dayOfWeekToIndex(lastDate.weekday, firstDay);
      row[index] = lastDate.day;
      lastDate = lastDate.plus({day: 1});
    }

    for (let i = 0; i < 7; i++) {
      if (!rows[rows.length - 1][i]) {
        rows[rows.length - 1][i] = lastDate.day;
        lastDate = lastDate.plus({day: 1});
      }
    }

    let firstDate = DateTime.local(year, month, 1);
    for (let i = 6; i >= 0; i--) {
      if (!rows[0][i]) {
        firstDate = firstDate.minus({day: 1});
        rows[0][i] = firstDate.day;
      }
    }

    return {
      rows,
    };
  }

  showLeftArrow = () => {
    const {showArrows, minDate, month, year} = this.props;
    if (!showArrows) {
      return false;
    }

    if (
      !minDate ||
      year > minDate.year ||
      (year === minDate.year && month > minDate.month)
    ) {
      return true;
    }
    return false;
  };

  showRightArrow = () => {
    const {showArrows, maxDate, month, year} = this.props;
    if (!showArrows) {
      return false;
    }

    if (
      !maxDate ||
      year < maxDate.year ||
      (year === maxDate.year && month < maxDate.month)
    ) {
      return true;
    }

    return false;
  };

  renderWeekDays = () => {
    return (
      <Flex direction="row" justifyContent="space-between">
        {WEEKDAYS.map(i => {
          return (
            <Text key={i} style={[Styles.text, Styles.dayName]}>
              {Info.weekdays('short')[i].toUpperCase()}
            </Text>
          );
        })}
      </Flex>
    );
  };

  render() {
    const {
      month,
      year,
      markedDates = {},
      minDate,
      maxDate,
      hideExtraDays,
      onLeftArrowPress = () => null,
      onRightArrowPress = () => null,
      onDayPress = () => null,
      ...props
    } = this.props;

    return (
      <View {...props}>
        <Header
          title={`${Info.months('long')[month - 1]} ${year}`}
          onLeftArrowPress={onLeftArrowPress}
          onRightArrowPress={onRightArrowPress}
          showLeftArrow={this.showLeftArrow()}
          showRightArrow={this.showRightArrow()}
        />

        {this.renderWeekDays()}

        {this.state.rows.map((row, ri) => {
          return (
            <React.Fragment key={`MonthView-${year}-${month}-r${ri}`}>
              <Spacer spacing={Theme.spacing.s} />
              <Flex direction="row" justifyContent="space-between">
                {WEEKDAYS.map((_, i) => {
                  const isLastMonthDay = ri === 0 && row[i] > 7;
                  const isNextMonthDay = ri >= 2 && row[i] < 7;
                  const dateMonth = isLastMonthDay
                    ? month - 1
                    : isNextMonthDay
                    ? month + 1
                    : month;
                  const date = DateTime.local(year, dateMonth, row[i]);

                  const dmy = dateTimeToDMY(date);

                  const key = parseDMY(dmy) as string;
                  const customStyle = markedDates[key];

                  const isBeforeMinDate =
                    minDate && compareDateTimeDMY(dmy, minDate) < 0;
                  const isAfterMaxDate =
                    maxDate && compareDateTimeDMY(dmy, maxDate) > 0;

                  const isDisabled = isBeforeMinDate || isAfterMaxDate;

                  const isExtraDay = isLastMonthDay || isNextMonthDay;

                  const text =
                    !row[i] || (hideExtraDays && isExtraDay) ? ' ' : row[i];
                  const textIsEmpty = text === ' ';
                  return (
                    <Pressable
                      key={`MonthView-${year}-${month}-r${ri}-${i}}`}
                      onPress={() => {
                        if (isExtraDay && hideExtraDays) {
                          return;
                        }

                        if (isDisabled) {
                          return;
                        }

                        onDayPress(dmy);
                      }}>
                      <Flex
                        style={[
                          Styles.dayContainer,
                          textIsEmpty ? {} : customStyle?.container,
                        ]}
                        justifyContent="center">
                        {/* day */}
                        <Text
                          style={[
                            Styles.text,
                            isExtraDay ? Styles.disabled : {},
                            isDisabled ? Styles.disabled : {},
                            customStyle?.text,
                          ]}>
                          {text}
                        </Text>
                        {/* dots */}
                        <Flex
                          direction="row"
                          justifyContent="center"
                          style={Styles.dotContainer}>
                          {(!isExtraDay || !hideExtraDays) &&
                            customStyle?.dots?.map(
                              (backgroundColor, index, dots) => {
                                const marginRight =
                                  index === dots.length - 1
                                    ? 0
                                    : Theme.spacing.xs / 2;
                                return (
                                  <View
                                    key={`MonthView-${year}-${month}-r${ri}-${i}-dot-${index}}`}
                                    style={[
                                      Styles.dot,
                                      {backgroundColor},
                                      {marginRight},
                                    ]}
                                  />
                                );
                              },
                            )}
                        </Flex>
                      </Flex>
                    </Pressable>
                  );
                })}
              </Flex>
            </React.Fragment>
          );
        })}
      </View>
    );
  }
}

const DEFAULT_FIRST_DAY = 1;
const WEEKDAYS = [0, 1, 2, 3, 4, 5, 6];

export default MonthView;
