import testIDs from '@testIDs';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Linking, StyleSheet, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import {
  openPrivacyPolicy,
  openTermsAndConditions,
} from '@services/helpers/appHelper';
import {openZenparkPro, useMailToContact} from '@services/helpers/linksHelper';
import logger from '@services/logger';

import Box from '../components/Box';
import DisconnectPopup from '../components/DisconnectPopup';
import Flex from '../components/Flex';
import Spacer from '../components/Spacer';
import Text from '../components/Text';
import appConfig from '../config/appConfig';
import Theme from '../config/theme';
import {RootNavigationProps} from '../navigators';
import {HeaderMenuButton, HeaderFlexLogo} from '../navigators/Root/Header';
import {useAppSelector} from '../reducers';
import {UserType} from '../services/pacoApi';

type MenuItem = {
  hidden?: boolean;
  title: string;
  onPress: () => void;
  testID?: string;
};

const HeaderMenu: React.FC<RootNavigationProps<'HeaderMenu'>> = ({
  navigation,
}) => {
  const {t} = useTranslation();
  const mailToContact = useMailToContact();
  const {ticket, driverInfo, shouldShowBeeMyFlexButton, BeeMyFlexAppUrl} =
    useAppSelector(state => ({
      ticket: state.Auth.ticket,
      driverInfo: state.Member.driverInfo,
      BeeMyFlexAppUrl: state.App.configuration.beeMyFlex?.appUrl || '',
      shouldShowBeeMyFlexButton: Boolean(
        state.App.configuration.beeMyFlex?.appUrl &&
          state.App.configuration.beeMyFlex?.connectedCompanyIds?.find(
            id => id === state.Member.driverInfo?.company?.id,
          ),
      ),
    }));
  const edges = useSafeAreaInsets();
  const [showDisconnectPopup, setShowDisconnectPopup] = React.useState(false);

  const menu: MenuItem[] = React.useMemo(
    () => [
      {
        title: `😎 ${t('navbar-dev')}`,
        hidden:
          !appConfig.showDevTab || driverInfo?.userType === UserType.Admin,
        onPress: () => {
          navigation.goBack();
          navigation.navigate('Dev');
        },
      },
      {
        title: t('navbar-contact-us'),
        onPress: mailToContact,
      },
      {
        title: t('navbar-discover-zenpark-pro'),
        onPress: openZenparkPro,
      },
      {
        title: t('navbar-terms-and-conditions'),
        onPress: () => openTermsAndConditions(),
      },
      {
        title: t('navbar-privacy-policy'),
        onPress: () => openPrivacyPolicy(),
      },
      {
        title: t('navbar-go-back-beemyflex'),
        onPress: () =>
          Linking.openURL(BeeMyFlexAppUrl).catch(logger.recordError),
        hidden: !shouldShowBeeMyFlexButton,
      },
      {
        title: t('navbar-logout'),
        hidden: !ticket,
        testID: testIDs.headerMenu.disconnect,
        onPress: () => setShowDisconnectPopup(true),
      },
    ],
    [
      BeeMyFlexAppUrl,
      driverInfo?.userType,
      mailToContact,
      navigation,
      shouldShowBeeMyFlexButton,
      t,
      ticket,
    ],
  );

  return (
    <Flex
      backgroundColor={Theme.colors.primary}
      style={Theme.commonStyles.flex1}>
      <Flex backgroundColor={Theme.colors.white}>
        <Spacer spacing={edges.top} />
        <Flex backgroundColor={Theme.colors.white}>
          <Flex direction="row" justifyContent="center" alignItems="center">
            <HeaderFlexLogo />

            <View style={Styles.close}>
              <HeaderMenuButton
                iconName="Unavailable"
                iconSize={Theme.icons.xl}
                onPress={() =>
                  navigation.canGoBack()
                    ? navigation.goBack()
                    : navigation.replace('Login')
                }
              />
            </View>
          </Flex>
          {menu.map((item, index) => {
            if (item.hidden) {
              return null;
            }

            return (
              <Box key={index} padding={Theme.spacing.l}>
                <Text
                  style={Styles.text}
                  onPress={item.onPress}
                  testID={item.testID}>
                  {item.title}
                </Text>
              </Box>
            );
          })}
        </Flex>
        <Spacer spacing={Theme.spacing.xl} />
      </Flex>
      <DisconnectPopup
        visible={showDisconnectPopup}
        close={() => setShowDisconnectPopup(false)}
        onDisconnect={() =>
          navigation.canGoBack()
            ? navigation.goBack()
            : navigation.replace('Login')
        }
      />
    </Flex>
  );
};

const Styles = StyleSheet.create({
  close: {position: 'absolute', right: 0},
  text: {
    textAlign: 'center',
    color: Theme.colors.black,
  },
});

export default HeaderMenu;
