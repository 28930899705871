import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import * as yup from 'yup';

import Theme from '@config/theme';

import LoginLayout from '@common/shared_layouts/LoginLayout';

import Block from '@components/Block';
import Box from '@components/Box';
import Button from '@components/Button';
import Input from '@components/Input';
import Loader from '@components/Loader';
import Popup from '@components/Popup';
import Spacer from '@components/Spacer';

import {useApi} from '@services/pacoApi';
import SsoService from '@services/sso';
import usePacoApiError from '@services/usePacoApiError';

type FormValues = {
  email: string;
};

const SsoLogin: React.FC = () => {
  const {t} = useTranslation();
  const api = useApi();
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid-email-format'))
      .required(t('email-required')),
  });

  const {values, errors, touched, setFieldValue, handleSubmit, handleBlur} =
    useFormik<FormValues>({
      initialValues: {
        email: '',
      },
      validationSchema,
      validateOnChange: false,
      validateOnBlur: true,
      onSubmit: () => {
        fetchSsoConfigs();
      },
    });

  const {
    isLoading,
    isSuccess,
    data: apiResponse,
    isError,
    error: apiError,
    refetch: fetchSsoConfigs,
  } = useQuery(
    'SsoConfig',
    async () => {
      return api.Sso.ssoConfigGet(values.email);
    },
    {
      enabled: false,
    },
  );

  React.useEffect(() => {
    if (isError) {
      handleError(apiError);
    }
  }, [isError, apiError, handleError]);

  React.useEffect(() => {
    if (isSuccess && apiResponse?.data) {
      const config = apiResponse?.data;
      SsoService.authorize(config);
    }
  }, [isSuccess, apiResponse]);

  return (
    <LoginLayout>
      <Block title={t('sso-signin-title')}>
        <Box padding={Theme.spacing.l}>
          <Spacer spacing={Theme.spacing.m} />
          <Input
            value={values.email}
            keyboardType="email-address"
            autoCapitalize="none"
            onChangeText={value => setFieldValue('email', value)}
            error={touched.email ? errors.email : undefined}
            placeholder={t('email-placeholder')}
            onBlur={handleBlur('email')}
          />
          <Spacer spacing={Theme.spacing.s} />
          <Button
            title={t('sso-login-confirm')}
            onPress={() => {
              handleSubmit();
            }}
          />
          <Popup {...errorPopupProps} />
          <Loader isLoading={isLoading} />
        </Box>
      </Block>
    </LoginLayout>
  );
};

export default SsoLogin;
