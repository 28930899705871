import * as React from 'react';

import Theme from '@config/theme';

import Spacer from '@components/Spacer';

import {useResponsiveProperty} from '@services/responsive';

const MD_PROPERTIES = {
  showSpacer: true,
};

const WebRowContainer: React.FC = () => {
  const {showSpacer} = useResponsiveProperty({
    md: MD_PROPERTIES,
  });

  if (!showSpacer) {
    return null;
  }

  return <Spacer spacing={Theme.spacing.s} mode="horizontal" />;
};

export default WebRowContainer;
