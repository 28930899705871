import * as React from 'react';

import Theme from '../../config/theme';
import Box from '../Box';
import Flex from '../Flex';
import Icon from '../Icon';
import Spacer from '../Spacer';
import Text from '../Text';

interface Props {
  countryAlpha2: string;
  countryName?: string | null;
  isSelected?: boolean;
}
const CountryRow: React.FC<Props> = props => {
  const {countryAlpha2, countryName, isSelected} = props;

  return (
    <Box padding={Theme.spacing.s} skipRight>
      <Flex direction="row" alignItems="center">
        <Icon name={countryAlpha2.toLocaleLowerCase()} />
        <Spacer spacing={Theme.spacing.s} mode="horizontal" />
        <Text
          color={isSelected ? Theme.colors.secondary : Theme.colors.primary}
          numberOfLines={1}
          style={Theme.commonStyles.flex1}>
          {countryName}
        </Text>
      </Flex>
    </Box>
  );
};

export default CountryRow;
