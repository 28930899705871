import testIDs from '@testIDs';
import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Theme from '@config/theme';

import {RootNavigationProps} from '@common/navigators';
import {
  MemberActions,
  PlanningActions,
  useAppDispatch,
  useAppSelector,
} from '@common/reducers';

import Block from '@components/Block';
import Box from '@components/Box';
import Checkbox from '@components/Checkbox';
import {DMY} from '@components/DatePicker/utils';
import Loader from '@components/Loader';
import ParkingSelector from '@components/ParkingSelector';
import {getDefaultParkingIndex} from '@components/ParkingSelector/utils';
import Popup from '@components/Popup';
import Footer from '@components/ReservationFooter';
import RulesView from '@components/RulesView';
import Spacer from '@components/Spacer';
import {HM} from '@components/TimePicker/utils';
import VehicleSelector from '@components/VehicleSelector';

import {getLocalDateTime} from '@services/helpers/dateHelper';
import {getDeviceType} from '@services/helpers/deviceHelper';
import {SpaceAllocationType, useApi, Vehicle} from '@services/pacoApi';
import {useMessagePopup} from '@services/popups';
import {queryClient, useMutation} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

import ReservationLayout from '../../shared_layouts/ReservationLayout';
import {MobileReservationLayoutRef} from '../../shared_layouts/ReservationLayout/MobileReservationLayout';

import PeriodView from './PeriodView';
import {FormValues, validationSchema} from './utils';

interface Props extends RootNavigationProps<'EditReservationForm'> {
  allocationId: string;
  vehicles: Vehicle[];
  reservationPeriod?: {fromHour: HM; fromDate: DMY; toHour: HM; toDate: DMY};
  isDriverPrm: boolean;
  defaultSelectedVehicle?: number;
  defaultSelectedParkingId?: string;
  defaultIsPrm: boolean;
  defaultHasChargingPoint: boolean;
}
const Container: React.FC<Props> = props => {
  const {
    allocationId,
    vehicles,
    isDriverPrm,
    reservationPeriod,
    defaultSelectedVehicle,
    defaultHasChargingPoint,
    defaultIsPrm,
    defaultSelectedParkingId,
    navigation,
  } = props;
  const {lastUsedVehicleId, parkings, lastUsedParkingId} = useAppSelector(
    state => {
      return {
        lastUsedVehicleId: state.Member.lastUsedVehicleId,
        parkings:
          state.Member.driverInfo?.parkings?.filter(
            p => p.spaceAllocationType === SpaceAllocationType.Priority,
          ) || [],
        lastUsedParkingId: state.Member.lastUsedParkingId,
      };
    },
  );

  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const layoutRef = React.useRef<MobileReservationLayoutRef>(null);
  const api = useApi();
  const {popupProps: errorPopupProps, handle: showError} = usePacoApiError();
  const {props: successPopupProps, show: showSuccess} = useMessagePopup({
    testID: testIDs.editReservation.form.successPopup,
    onClose: () => navigation.goBack(),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      isEv: defaultHasChargingPoint,
      isPrmSpaceRequired: defaultIsPrm && isDriverPrm,
      selectedParking: getDefaultParkingIndex(
        parkings,
        defaultSelectedParkingId ?? null,
      ),
      selectedVehicle: defaultSelectedVehicle,
    },
    validationSchema,
    onSubmit: () => {
      submit();
    },
  });
  const {values, setFieldValue} = formik;
  const {
    isError,
    isSuccess,
    isLoading,
    error,
    data: response,
    mutate: submit,
  } = useMutation(async () =>
    api.Reservations.v1ReservationsPatch({
      id: allocationId,
      parkingId:
        values.selectedParking != null
          ? parkings[values.selectedParking].id
          : undefined,
      spaceType:
        values.selectedVehicle != null
          ? vehicles[values.selectedVehicle].type
          : undefined,
      chargingPointRequired: values.isEv,
      prmSpaceRequired: values.isPrmSpaceRequired,
      vehicleId:
        values.selectedVehicle != null
          ? vehicles[values.selectedVehicle].vehicleId
          : undefined,
    }),
  );

  const {data: apiResponse, mutate: evaluateRules} = useMutation(async () => {
    if (
      values.selectedParking == null ||
      values.selectedParking === undefined
    ) {
      return;
    }
    return api.Parkings.v1ParkingsParkingIdEvaluateRulesGet(
      parkings[values.selectedParking].id as string,
      getLocalDateTime(
        reservationPeriod?.fromDate,
        reservationPeriod?.fromHour,
      )?.toJSON(),
      getLocalDateTime(
        reservationPeriod?.toDate,
        reservationPeriod?.toHour,
      )?.toJSON(),
      allocationId,
    );
  });

  const rules = apiResponse?.data ?? [];

  React.useEffect(() => {
    queryClient.resetQueries('');
    evaluateRules();
  }, [values.selectedParking, evaluateRules]);

  React.useEffect(() => {
    if (!isSuccess || values.selectedVehicle == null) {
      return;
    }
    const {vehicleId} = vehicles[values.selectedVehicle];

    if (vehicleId && vehicleId !== lastUsedVehicleId) {
      dispatch(MemberActions.setLastUsedVehicleId(vehicleId));
    }
  }, [values, vehicles, isSuccess, dispatch, lastUsedVehicleId]);

  React.useEffect(() => {
    if (!isSuccess || values.selectedParking == null) {
      return;
    }
    const {id} = parkings[values.selectedParking];

    if (id && id !== lastUsedParkingId) {
      dispatch(MemberActions.setLastUsedParkingId(id));
    }
  }, [values, parkings, isSuccess, dispatch, lastUsedParkingId]);

  React.useEffect(() => {
    if (isError) {
      showError(error);
      dispatch(PlanningActions.setShouldReloadPlanning(true));
    }

    if (isSuccess && response?.data) {
      showSuccess({
        message: response?.data?.message,
        title: response?.data?.title,
      });
      dispatch(PlanningActions.setShouldReloadPlanning(true));
    }
  }, [isError, isSuccess, error, response, showError, showSuccess, dispatch]);

  return (
    <ReservationLayout
      ref={layoutRef}
      Footer={
        <Footer
          rules={rules}
          testIDs={{
            submit: testIDs.editReservation.form.submit,
          }}
          onSubmit={() => {
            formik.handleSubmit();
          }}
          onSubmitInvalid={() => layoutRef.current?.scrollToBottom()}
          canSubmit={
            rules.every(rule => rule.success) && values.selectedVehicle != null
          }
          submitButtonLabel={t('edit-reservation-form-confirm-button')}
          disabled={!formik.dirty}
        />
      }>
      <Block
        style={Theme.commonStyles.flex1}
        title={t('edit-reservation-screen-title')}>
        <Box padding={Theme.spacing.l}>
          {parkings.length > 1 && (
            <ParkingSelector
              testID={testIDs.editReservation.form.parkingSelector}
              parkings={parkings}
              formik={formik}
            />
          )}
          <Spacer spacing={Theme.spacing.s} />
          <PeriodView
            testIDs={{
              fromDate: testIDs.editReservation.form.fromDate,
              fromHour: testIDs.editReservation.form.fromHour,
              toDate: testIDs.editReservation.form.toDate,
              toHour: testIDs.editReservation.form.toHour,
            }}
            period={
              reservationPeriod as {
                fromHour: HM;
                toHour: HM;
                fromDate: DMY;
                toDate: DMY;
              }
            }
          />
          <VehicleSelector
            testID={testIDs.editReservation.form.vehicleSelector}
            vehicles={vehicles}
            value={values.selectedVehicle}
            onVehicleChange={index => {
              setFieldValue('selectedVehicle', index);
              if (index != null) {
                setFieldValue('isEv', vehicles[index]?.isEv);
              }
            }}
          />
          {values.selectedVehicle != null && (
            <>
              <Spacer spacing={Theme.spacing.xs} />
              <Checkbox
                testID={testIDs.editReservation.form.isEv}
                disabled={!vehicles[values.selectedVehicle].isEv}
                label={t('new-reservation-form-charging-point')}
                checked={values.isEv}
                onPress={value => setFieldValue('isEv', value)}
              />
            </>
          )}
          {isDriverPrm && (
            <>
              <Spacer spacing={Theme.spacing.s} />
              <Checkbox
                testID={testIDs.editReservation.form.isPrm}
                label={t('new-reservation-form-prm-space')}
                checked={values.isPrmSpaceRequired}
                onPress={value => setFieldValue('isPrmSpaceRequired', value)}
              />
            </>
          )}
        </Box>
      </Block>

      {getDeviceType() !== 'web-desktop' ? (
        <>
          <Spacer spacing={Theme.spacing.xl} />
          <Block>
            <RulesView rules={rules} />
          </Block>
          <Spacer spacing={Theme.spacing.m} />
        </>
      ) : null}
      <Loader
        isLoading={isLoading}
        title={t('new-reservation-form-submit-loader-title')}
      />

      <Popup {...successPopupProps} />
      <Popup {...errorPopupProps} />
    </ReservationLayout>
  );
};

export default Container;
