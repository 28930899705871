import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';

import Flex from '../components/Flex';
import Text from '../components/Text';

export interface Props {
  color?: string;
  formattedPasscode: string | null | undefined;
}

const Passcode: React.FC<Props> = ({formattedPasscode, color}) => {
  const {t} = useTranslation();
  if (!formattedPasscode) {
    return null;
  }

  return (
    <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}>
      <Text color={color}>{`${t('passcode')}`}</Text>
      <Text color={color}>
        {`🚘 ${formattedPasscode} # `}
        <Text color={color} style={Styles.separator}>
          |
        </Text>
        {` 🚶‍♂️ ${formattedPasscode} ✱`}
      </Text>
    </Flex>
  );
};

const Styles = StyleSheet.create({
  separator: {
    opacity: 0.6,
  },
});

export default Passcode;
