import gPhoneNumber from 'google-libphonenumber';
import * as Yup from 'yup';

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

const YUP_PHONE_METHOD = 'phone';

Yup.addMethod(
  Yup.string,
  YUP_PHONE_METHOD,
  function yupPhone(onlyFr = false, errorMessage = '') {
    return this.test(YUP_PHONE_METHOD, errorMessage, value => {
      if (!value) {
        return true;
      }
      try {
        const phoneNumber = phoneUtil.parseAndKeepRawInput(value, 'FR');

        if (!phoneUtil.isPossibleNumber(phoneNumber)) {
          return false;
        }
        const regionCodeFromPhoneNumber =
          phoneUtil.getRegionCodeForNumber(phoneNumber);

        return onlyFr
          ? phoneUtil.isValidNumberForRegion(phoneNumber, 'FR')
          : phoneUtil.isValidNumberForRegion(
              phoneNumber,
              regionCodeFromPhoneNumber,
            );
      } catch {
        return false;
      }
    });
  },
);
