import * as yup from 'yup';

export type FormValues = {
  selectedVehicle?: number;
  isEv: boolean;
  isPrmSpaceRequired: boolean;
  selectedParking?: number;
};

export const validationSchema = yup.object().shape({
  selectedVehicle: yup.number().required(),
  isEv: yup.boolean(),
  isPrmSpaceRequired: yup.boolean(),
  selectedParking: yup.number().required(),
});
