import * as React from 'react';
import {TouchableOpacity} from 'react-native';

import Theme from '../../../config/theme';
import Box from '../../Box';
import Hoverable from '../../Hoverable';
import {RowComponentProps} from '../../Select/RowComponent';
import VehicleRow from '../../VehicleRow';

import {getIconName, VehicleOption} from './utils';

const RowComponent: React.FC<RowComponentProps<number>> = props => {
  const {index, isSelected, options, onPress} = props;
  const option = options[index] as VehicleOption;
  const isTypeUnsupported = option.unsupported;

  const iconName = getIconName(option.type);
  return (
    <TouchableOpacity
      disabled={isTypeUnsupported}
      onPress={() => {
        if (!isTypeUnsupported) {
          onPress();
        }
      }}>
      <Hoverable
        backgroundColor={Theme.colors.white}
        hoverColor={Theme.colors.secondaryHover}>
        <Box padding={Theme.spacing.l} skipBottom skipTop>
          <VehicleRow
            iconName={iconName}
            label={option.label}
            isSelected={isSelected}
            isTypeUnsupported={isTypeUnsupported}
          />
        </Box>
      </Hoverable>
    </TouchableOpacity>
  );
};

export default RowComponent;
