import * as React from 'react';
import {TouchableOpacity, StyleSheet} from 'react-native';
import {useHover} from 'react-native-web-hooks';

import Theme from '../../config/theme';
import Box from '../Box';
import Text from '../Text';

import Option from './Option';
export interface RowComponentProps<T = unknown> {
  options: Option<T>[];
  index: number;
  isSelected: boolean;
  onPress: () => void;
  testID?: string;
}

const RowComponent: React.FC<RowComponentProps> = props => {
  const {options, index, isSelected, onPress, testID} = props;
  const ref = React.useRef<TouchableOpacity>(null);
  const isHover = useHover(ref);
  return (
    <TouchableOpacity
      ref={ref}
      testID={testID}
      style={[styles.option, isHover ? styles.hover : {}]}
      onPress={onPress}>
      <Box padding={Theme.spacing.l} skipRight skipLeft>
        <Box padding={Theme.spacing.xl} skipTop skipBottom>
          <Text
            color={!isSelected ? Theme.colors.primary : Theme.colors.secondary}>
            {options[index].label}
          </Text>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  option: {
    backgroundColor: Theme.colors.white,
  },
  hover: {
    backgroundColor: Theme.colors.secondaryHover,
  },
});

export default RowComponent;
