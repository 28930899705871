import {Buffer} from 'buffer';

import {SHA256, enc} from 'crypto-js';
import {Platform} from 'react-native';
import {v4 as uuidv4} from 'uuid';

import appConfig from '@common/config/appConfig';
import {getNavigationContainer} from '@common/navigators';

import {CompanySsoConfig} from '../pacoApi';

function toBase64(input: string): string {
  return Buffer.from(input)
    .toString('base64')
    .replace(/[=]/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

const generateRandomString = (length: number): string => {
  const char =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  const random = Array.from(
    {length: length},
    () => char[Math.floor(Math.random() * char.length)],
  );

  return random.join('');
};

const getStateContent = (
  config: CompanySsoConfig,
  params: URLSearchParams,
): string => {
  if (config.usingPkce && config.usingPkce === true) {
    const codeVerifier = generateRandomString(128);
    const codeChallenge = SHA256(codeVerifier)
      .toString(enc.Base64)
      .replace(/[=]/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');

    params.append('code_challenge', codeChallenge);
    params.append('code_challenge_method', 'S256');

    if (config.authenticationChannel) {
      params.append('acr_values', config.authenticationChannel);
    }

    const nonce = uuidv4();
    const pkceStateContent = `${
      config.provider
    },${getRedirectUri()},${codeVerifier},${nonce}`;

    return pkceStateContent;
  }
  const stateContent = `${config.provider},${getRedirectUri()}`;
  return stateContent;
};

export const authorize = (config: CompanySsoConfig): void => {
  const params = new URLSearchParams({
    client_id: config.clientId || '',
    redirect_uri: getRedirectUri(),
    scope: config.scopes?.join(' ') || '',
    response_type: 'code',
    prompt: 'login',
  });

  const stateContent = getStateContent(config, params);

  params.append('state', toBase64(stateContent));
  const url = `${config.authorizationEndpoint}?${params.toString()}`;

  if (Platform.OS === 'web') {
    location.href = url;
  } else {
    getNavigationContainer()?.navigate('SsoWebview', {
      url,
    });
  }
};

export const getRedirectUri = (): string => {
  return appConfig.ssoRedirectUrl;
};
