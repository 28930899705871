import * as React from 'react';

import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Icon from '../../../components/Icon';
import Spacer from '../../../components/Spacer';
import Text from '../../../components/Text';
import Theme from '../../../config/theme';

interface Props {
  label?: string | null;
  isSelected?: boolean;
  iconName: string;
}
const LanguageRow: React.FC<Props> = props => {
  const {iconName, label, isSelected} = props;
  return (
    <Box padding={Theme.spacing.m} skipRight skipLeft>
      <Flex direction="row" alignItems="center">
        <Icon name={iconName} size={Theme.icons.xl} />
        <Spacer spacing={Theme.spacing.l} mode="horizontal" />
        <Text
          color={isSelected ? Theme.colors.callToAction : Theme.colors.primary}>
          {label}
        </Text>
      </Flex>
    </Box>
  );
};

export default LanguageRow;
