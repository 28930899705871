import * as React from 'react';
import {useTranslation} from 'react-i18next';

import usePacoApiError from '@services/usePacoApiError';

import Box from '../../components/Box';
import Button from '../../components/Button';
import Flex from '../../components/Flex';
import Loader from '../../components/Loader';
import Popup from '../../components/Popup';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import Theme from '../../config/theme';
import {AuthActions, useAppDispatch} from '../../reducers';
import {getDeviceType} from '../../services/helpers/deviceHelper';
import {DriverLight} from '../../services/pacoApi';
import {useMutation} from '../../services/queries';

import {useApi} from './../../services/pacoApi/pacoApi';

interface Props {
  driver: DriverLight;
}

const UserRow: React.FC<Props> = props => {
  const {firstName, lastName, email} = props.driver;
  const {t} = useTranslation();
  const api = useApi();
  const dispatch = useAppDispatch();
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError();
  const {
    isLoading,
    isSuccess,
    data: apiResponse,
    isError,
    error: apiError,
    mutate: authenticate,
  } = useMutation(async () => {
    if (!email) {
      throw 'Email is null, can not log in as null user';
    }
    return api.Admin.v1AdminLoginAsUserPost(JSON.stringify(email));
  });

  React.useEffect(() => {
    if (isSuccess && apiResponse?.data.accessToken) {
      dispatch(AuthActions.setTicket(apiResponse.data));
    }
  }, [dispatch, isSuccess, apiResponse]);

  React.useEffect(() => {
    if (isError) {
      handleError(apiError);
    }
  }, [isError, apiError, handleError]);

  const flexProps =
    getDeviceType() === 'web-desktop'
      ? {
          direction: 'row' as const,
          alignItems: 'center' as const,
          justifyContent: 'space-between' as const,
        }
      : {direction: 'column' as const};
  return (
    <>
      <Flex {...flexProps}>
        <Box skipLeft skipRight padding={Theme.spacing.l}>
          <Text>{`${firstName} ${lastName} • ${email}`}</Text>
        </Box>
        <Button
          title={t('login')}
          variant="Tertiary"
          onPress={() => authenticate()}
        />
      </Flex>
      <Box skipLeft skipRight padding={Theme.spacing.l}>
        <Flex backgroundColor={Theme.colors.border}>
          <Spacer spacing={1} />
        </Flex>
      </Box>
      <Popup {...errorPopupProps} />
      <Loader isLoading={isLoading} />
    </>
  );
};

export default UserRow;
