import {Parking} from '../../services/pacoApi';

export const NO_ITEMS_VALUE = -1;
export const NO_ITEMS_LABEL = 'No items';

export type ParkingOption = {
  value: number;
  label?: string | null;
  id?: string | null;
};

export const getOptions = (parkings: Parking[]): ParkingOption[] => {
  let options = [];
  options = parkings.map((p, index) => {
    return {value: index, id: p.id, label: p.name};
  });

  options.push({
    value: NO_ITEMS_VALUE,
    label: NO_ITEMS_LABEL,
    id: NO_ITEMS_LABEL,
  });

  return options;
};

export const getDefaultParkingIndex = (
  parkings: Parking[],
  lastUsedParkingId: string | null,
): number | undefined => {
  if (parkings.length <= 0) {
    return;
  }

  if (parkings.length === 1) {
    return 0;
  }

  if (lastUsedParkingId) {
    const index = parkings.findIndex(
      parking => parking.id === lastUsedParkingId,
    );
    if (index >= 0) {
      return index;
    }
  }

  return 0;
};
