import * as React from 'react';

import Flex from '../../../../components/Flex';
import Spacer from '../../../../components/Spacer';
import theme from '../../../../config/theme';
import {AgendaItem} from '../types';

import AgendRowContent from './AgendaRowContent';
import DateView from './DateView';

interface Props {
  item: AgendaItem;
}

const AgendaRow: React.FC<Props> = ({item}) => {
  return (
    <Flex alignItems="center" direction="row">
      <DateView item={item} />
      <AgendRowContent item={item} />
      <Spacer mode="horizontal" spacing={theme.spacing.l} />
    </Flex>
  );
};

export default AgendaRow;
