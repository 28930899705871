import * as React from 'react';

import Theme from '../../config/theme';
import Input from '../Input';

import Option from './Option';

export interface LabelComponentProps<T = unknown> {
  option?: Option<T>;
  opened: boolean;
  error?: string;
  placeholder?: string;
  showLabel?: boolean;
  disabled?: boolean;
  onPressIcon: () => void;
}

const LabelComponent: React.FC<LabelComponentProps<unknown>> = ({
  option,
  opened,
  disabled,
  onPressIcon,
  ...props
}) => {
  return (
    <Input
      {...props}
      disabled={disabled}
      rightIconName="Dropdown"
      iconColor={disabled ? Theme.colors.secondary : Theme.colors.primary}
      value={option?.label ?? (opened ? ' ' : '')}
      pointerEvents="none"
      onPressIconRight={onPressIcon}
    />
  );
};

export default LabelComponent;
