import testIDs from '@testIDs';
import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import * as yup from 'yup';

import Theme from '@config/theme';

import Box from '@components/Box';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import Flex from '@components/Flex';
import Input from '@components/Input';
import PhoneInput from '@components/PhoneInput';
import RowContainer from '@components/ResponsiveRowContainer';
import RowSpacer from '@components/ResponsiveRowSpacer';
import Spacer from '@components/Spacer';

import {getDeviceType} from '@services/helpers/deviceHelper';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  number: string;
  isPrm: boolean;
};

interface Props {
  values: FormValues;
  onSubmit: (data: FormValues) => void;
}

const InformationsForm: React.FC<Props> = ({values: _values, onSubmit}) => {
  const isMobile = getDeviceType() !== 'web-desktop';

  const {t} = useTranslation();

  const validationSchema = yup.object().shape({
    phone: yup.string().phone(false, t('invalid-phone-format')),
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleBlur,
    setValues,
  } = useFormik<FormValues>({
    initialValues: _values,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: onSubmit,
  });

  React.useEffect(() => {
    setValues(_values);
  }, [_values, setValues]);

  return (
    <Box padding={Theme.spacing.l}>
      <RowContainer>
        <Input
          testID={testIDs.informations.firstName}
          style={Theme.commonStyles.flex1}
          value={values.firstName}
          onChangeText={value => setFieldValue('firstName', value)}
          placeholder={t('first-name-placeholder')}
          error={touched.firstName ? errors.firstName : undefined}
          onSubmitEditing={() => handleSubmit()}
          onBlur={handleBlur('firstName')}
          disabled
        />
        <RowSpacer />
        <Input
          testID={testIDs.informations.lastName}
          style={Theme.commonStyles.flex1}
          value={values.lastName}
          onChangeText={value => setFieldValue('lastName', value)}
          placeholder={t('last-name-placeholder')}
          error={touched.lastName ? errors.lastName : undefined}
          onSubmitEditing={() => handleSubmit()}
          onBlur={handleBlur('lastName')}
          disabled
        />
      </RowContainer>
      <RowContainer>
        <Input
          testID={testIDs.informations.number}
          style={Theme.commonStyles.flex1}
          value={values.number}
          onChangeText={value => setFieldValue('number', value)}
          placeholder={t('identification-number-placeholder')}
          error={touched.number ? errors.number : undefined}
          onSubmitEditing={() => handleSubmit()}
          onBlur={handleBlur('number')}
          disabled
        />
        <RowSpacer />
        <PhoneInput
          testID={testIDs.informations.phoneNumber}
          style={Theme.commonStyles.flex1}
          value={values.phone}
          placeholder={t('phone-placeholder')}
          error={touched.phone ? errors.phone : undefined}
          onSubmitEditing={() => handleSubmit()}
          onBlur={handleBlur('phone')}
          onPhoneNumberChange={value => {
            setFieldValue('phone', value);
          }}
        />
      </RowContainer>
      <Flex direction="row">
        <Input
          testID={testIDs.informations.email}
          style={Theme.commonStyles.flex1}
          value={values.email}
          keyboardType="email-address"
          autoCapitalize="none"
          onChangeText={value => setFieldValue('email', value)}
          placeholder={t('email-placeholder')}
          error={touched.email ? errors.email : undefined}
          onSubmitEditing={() => handleSubmit()}
          onBlur={handleBlur('email')}
          disabled
        />
        {!isMobile && (
          <>
            <Spacer spacing={Theme.spacing.s} mode="horizontal" />
            <View style={Theme.commonStyles.flex1} />
          </>
        )}
      </Flex>
      <Checkbox
        testID={testIDs.informations.isPrm}
        checked={values.isPrm}
        label={t('is-prm')}
        labelColor={Theme.colors.secondary}
        onPress={value => setFieldValue('isPrm', value)}
        disabled
      />
      <Spacer spacing={Theme.spacing.l} />
      <Flex alignItems={isMobile ? 'stretch' : 'center'}>
        <Button
          testID={testIDs.informations.submit}
          title={t('update')}
          onPress={() => handleSubmit()}
        />
      </Flex>
    </Box>
  );
};

export default InformationsForm;
