import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {changeBrazeUser} from '@services/braze';
import {AuthTicket} from '@services/pacoApi/generated';

interface AuthState {
  ticket: AuthTicket | null;
}

const initialState: AuthState = {
  ticket: null,
};

const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setTicket: (state, action: PayloadAction<AuthTicket>) => {
      state.ticket = action.payload;

      if (action.payload.pushNotifMemberExternalId) {
        changeBrazeUser(action.payload.pushNotifMemberExternalId);
      }
    },
    clearTicket: state => {
      state.ticket = null;
    },
  },
});

export const AuthActions = AuthSlice.actions;
export default AuthSlice.reducer;
