import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import Theme from '../config/theme';

const Separator: React.FC = () => {
  return <View style={Styles.horizontalLine} />;
};

const Styles = StyleSheet.create({
  horizontalLine: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: Theme.colors.border,
  },
});

export default Separator;
