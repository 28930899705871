import {FormikContextType} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {useVehicleTypeLabel} from '@services/helpers/vehicleHelper';

import {SpaceTypes} from '../../../services/pacoApi';
import Select from '../../Select';
import {FormValues, getTypeIndex, useSetVehicleType} from '../utils';

import LabelComponent from './LabelComponent';
import RowComponent from './RowComponent';
import {VehicleOption} from './utils';

interface Props {
  formik: FormikContextType<FormValues>;
  types: SpaceTypes[];
  supportedTypes: SpaceTypes[];
  isLoadingSupportedVehicleTypes: boolean;

  testID?: string;
}

const VehicleTypeSelector: React.FC<Props> = ({
  types,
  formik,
  supportedTypes,
  isLoadingSupportedVehicleTypes,
  testID,
}) => {
  const {t} = useTranslation();

  const setVehicleType = useSetVehicleType();

  const getVehicleTypeLabel = useVehicleTypeLabel();

  const getOptions = (_types: SpaceTypes[]): VehicleOption[] => {
    const options = _types
      .map((type, index) => {
        return {
          value: index,
          label: getVehicleTypeLabel(type),
          type: type,
          unsupported: !supportedTypes.includes(type),
        };
      })
      .filter(o => o.label);
    return options;
  };

  const {values} = formik;

  const options = getOptions(types);

  return (
    <Select
      testID={testID}
      selected={getTypeIndex(types, types[values.selectedType])}
      disabled={isLoadingSupportedVehicleTypes}
      description={
        supportedTypes.length !== types.length
          ? t('vehicle-type-unsupported-description')
          : undefined
      }
      options={options}
      onSelect={value => {
        setVehicleType(formik, value);
      }}
      placeholder={t('vehicle-add-type-choice')}
      RowComponent={RowComponent}
      LabelComponent={LabelComponent}
    />
  );
};

export default VehicleTypeSelector;
