import * as React from 'react';
import {View, Image, StyleSheet, ScrollView} from 'react-native';
import {SafeAreaView, SafeAreaViewProps} from 'react-native-safe-area-context';

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Spacer from '../../components/Spacer';
import Theme from '../../config/theme';

interface Props extends SafeAreaViewProps {
  companyIllustrationUrl: string;
}

const LandingPageLayout: React.FC<Props> = ({
  companyIllustrationUrl,
  children,
}) => {
  return (
    <SafeAreaView style={Styles.container} edges={['bottom']}>
      <ScrollView>
        <View>
          <Spacer spacing={Theme.spacing.s} />
          <Box padding={Theme.spacing.l}>{children}</Box>
        </View>
        <Flex alignItems="center">
          <Image
            source={{
              uri: companyIllustrationUrl,
            }}
            style={Styles.logo}
            resizeMode="contain"
          />
          <Spacer spacing={Theme.spacing.l} />
        </Flex>
      </ScrollView>
    </SafeAreaView>
  );
};

const Styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Theme.colors.secondaryHover,
    justifyContent: 'space-between',
  },
  logo: {
    width: 180,
    height: 100,
  },
});

export default LandingPageLayout;
