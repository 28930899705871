import * as React from 'react';
import {Pressable, StyleSheet} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import Flex from '../../components/Flex';
import Modal from '../../components/Modal';
import Theme from '../../config/theme';

import PlanningCalendar from './PlanningCalendar';

interface Props {
  visible: boolean;
  close: () => void;
}

const PlanningCalendarModal: React.FC<Props> = ({visible, close}) => {
  return (
    <Modal visible={visible}>
      <Flex
        style={Theme.commonStyles.flex1}
        backgroundColor={Theme.colors.backdrop}>
        <Pressable style={Theme.commonStyles.flex1} onPress={() => close()}>
          <Flex style={Theme.commonStyles.flex1} justifyContent="flex-end">
            <Pressable onPress={() => null}>
              <Flex backgroundColor={Theme.colors.white}>
                <SafeAreaView edges={['bottom']}>
                  <PlanningCalendar
                    style={Styles.calendar}
                    onDaySelected={() => close()}
                  />
                </SafeAreaView>
              </Flex>
            </Pressable>
          </Flex>
        </Pressable>
      </Flex>
    </Modal>
  );
};

const Styles = StyleSheet.create({
  calendar: {
    height: 310,
    paddingHorizontal: Theme.spacing.l,
    backgroundColor: Theme.colors.white,
  },
});
export default PlanningCalendarModal;
