import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Image, StyleSheet, View} from 'react-native';

import Theme from '@config/theme';

import {useAppSelector} from '@common/reducers';

import Block from '@components/Block';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Passcode from '@components/Passcode';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import {Allocation, SpaceTypes} from '@services/pacoApi';

import AllocationAddress from './AllocationAddress';
import AllocationInterval from './AllocationInterval';

interface Props {
  isZenithParking?: boolean;
  allocation: Allocation | null;
  hasDigicodeAccess: boolean;
  hasUpcomingAccess: boolean;
}
const AllocationInfo: React.FC<Props> = ({
  allocation,
  isZenithParking,
  hasDigicodeAccess,
  hasUpcomingAccess,
}) => {
  const {t} = useTranslation();

  const {company, parking, formattedPasscode} = useAppSelector(state => {
    return {
      company: state.Member.driverInfo?.company,
      parking: state.Member.driverInfo?.parkings?.find(
        item => item.id === allocation?.parkingId,
      ),
      formattedPasscode: state.Member.driverInfo?.formattedPasscode,
    };
  });

  const spaceTypeLabel = React.useMemo(() => {
    switch (allocation?.spaceType) {
      case SpaceTypes.Utility:
        return t('access-utility');

      case SpaceTypes.Car:
        return t('access-car');

      case SpaceTypes.Moto:
        return t('access-moto');

      case SpaceTypes.Bike:
        return t('access-bike');

      default:
        return '';
    }
  }, [allocation?.spaceType, t]);

  return (
    <View>
      {company?.logoUrl && (
        <Flex backgroundColor={Theme.colors.white}>
          <Box padding={Theme.spacing.l}>
            <Image
              source={{uri: company?.logoUrl}}
              style={Styles.image}
              resizeMode="contain"
            />
          </Box>
        </Flex>
      )}
      <Flex backgroundColor={Theme.colors.primary}>
        <Box padding={Theme.spacing.l}>
          <Text variant="Medium" style={Styles.parkingName} align="center">
            {parking?.name}
          </Text>
          <Spacer spacing={Theme.spacing.xs} />
          <AllocationAddress
            latitude={parking?.latitude}
            longitude={parking?.longitude}
            location={parking?.location}
          />
          <Spacer spacing={Theme.spacing.l} />
          <Block>
            {!allocation &&
              (hasUpcomingAccess ? (
                <Box padding={Theme.spacing.l}>
                  <Text>{t('no-allocation')}</Text>
                  <Text>{t('allocation-reminder')}</Text>
                </Box>
              ) : (
                <Box padding={Theme.spacing.l}>
                  <Text align="center">{t('no-allocation')}</Text>
                </Box>
              ))}
            {allocation && (
              <AllocationInterval
                localFrom={allocation.localFrom}
                localTo={allocation.localTo}
              />
            )}
          </Block>

          {isZenithParking && hasDigicodeAccess && formattedPasscode !== null && (
            <>
              <Spacer spacing={Theme.spacing.l} />
              <Passcode
                color={Theme.colors.white}
                formattedPasscode={formattedPasscode}
              />
            </>
          )}

          {!isZenithParking && allocation?.spaceType && (
            <>
              <Spacer spacing={Theme.spacing.l} />
              <Text color={Theme.colors.white} align="center">
                {t('access-allocation-message', {
                  type: spaceTypeLabel,
                })}
              </Text>
            </>
          )}
        </Box>
      </Flex>
    </View>
  );
};

const Styles = StyleSheet.create({
  image: {
    width: '100%',
    height: 50,
  },
  parkingName: {
    color: Theme.colors.white,
  },
  parkingLocation: {
    color: Theme.colors.white,
    textDecorationLine: 'underline',
  },
});

export default AllocationInfo;
