import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Block from '../../../components/Block';
import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Spacer from '../../../components/Spacer';
import VerticalBorderShadow from '../../../components/VerticalBoxShadow';
import Theme from '../../../config/theme';
import WebBaseContainer from '../../../containers/WebBaseContainer';
import {useResponsiveProperty} from '../../../services/responsive';
import Agenda from '../Agenda';
import AgendaHeader from '../AgendaHeader';
import PlanningCalendar from '../PlanningCalendar';
import PlanningParkingInfo from '../PlanningParkingInfo';

const SM_PROPERTIES = {
  isSmall: true,

  planningStyle: {width: '100%'},
};

const MD_PROPERTIES = {
  isSmall: false,
  planningStyle: {width: 375},
};

const WebPlanningLayout: React.FC = () => {
  const {t} = useTranslation();
  const {isSmall, planningStyle} = useResponsiveProperty({
    xs: SM_PROPERTIES,
    sm: SM_PROPERTIES,
    md: MD_PROPERTIES,
  });

  return (
    <Flex style={Theme.commonStyles.flex1}>
      <WebBaseContainer>
        <Flex
          direction={isSmall ? 'column' : 'row'}
          style={Theme.commonStyles.flex1}>
          <Flex
            style={planningStyle}
            direction={isSmall ? 'column-reverse' : 'column'}>
            <Block title={t('planning-screen-title')}>
              <Box padding={Theme.spacing.l}>
                <PlanningCalendar />
              </Box>
            </Block>
            <Spacer spacing={Theme.spacing.xl} />
            <Block>
              <PlanningParkingInfo />
            </Block>
          </Flex>

          <Spacer
            mode={isSmall ? 'vertical' : 'horizontal'}
            spacing={Theme.spacing.xl}
          />

          <Block style={Theme.commonStyles.flex1} title={!isSmall ? ' ' : ''}>
            <VerticalBorderShadow side="bottom">
              <AgendaHeader />
            </VerticalBorderShadow>
            <Agenda />
          </Block>
        </Flex>
      </WebBaseContainer>
    </Flex>
  );
};

export default WebPlanningLayout;
