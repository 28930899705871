import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet, ActivityIndicator} from 'react-native';

import Theme from '../config/theme';

import Modal from './Modal';
import Spacer from './Spacer';
import Text from './Text';

interface Props {
  isLoading: boolean;
  title?: string;
}

export const LoadingView: React.FC<Omit<Props, 'isLoading'>> = ({title}) => {
  const {t} = useTranslation();

  return (
    <View style={Styles.outterContainer}>
      <View style={Styles.container}>
        <ActivityIndicator color={Theme.colors.primary} />
        <Spacer spacing={Theme.spacing.m} />
        <Text style={Styles.title}>{title ?? t('loading')}</Text>
      </View>
    </View>
  );
};

const Loader: React.FC<Props> = ({isLoading, ...props}) => {
  return (
    <Modal visible={isLoading}>
      <LoadingView {...props} />
    </Modal>
  );
};

const Styles = StyleSheet.create({
  outterContainer: {
    flex: 1,
    backgroundColor: Theme.colors.backdrop,
    justifyContent: 'center',
    padding: Theme.spacing.l,
    alignItems: 'center',
  },
  container: {
    backgroundColor: Theme.colors.white,
    borderRadius: Theme.radius.default,
    padding: Theme.spacing.xl,
  },
  title: {
    textAlign: 'center',
  },
});

export default Loader;
