import {useNavigation} from '@react-navigation/core';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Theme from '@common/config/theme';
import {HomeNavigationProps} from '@common/navigators';
import {useAppSelector} from '@common/reducers';

import Block from '@components/Block';
import Button from '@components/Button';
import Flex from '@components/Flex';
import Spacer from '@components/Spacer';

import {CreationOrigin} from '@services/pacoApi';

const PasswordBlock: React.FC = () => {
  const {t} = useTranslation();
  const {navigate} =
    useNavigation<HomeNavigationProps<'Account'>['navigation']>();

  const showPasswordBlock = useAppSelector(
    state => state.Member.driverInfo?.creationOrigin !== CreationOrigin.Sso,
  );

  if (!showPasswordBlock) {
    return null;
  }

  return (
    <>
      <Block title={t('reset-password-section-title')}>
        <Spacer spacing={Theme.spacing.xl} />
        <Flex alignItems="center">
          <Button
            title={t('reset-password-button')}
            expanded={false}
            onPress={() => navigate('ChangePassword')}
          />
        </Flex>
        <Spacer spacing={Theme.spacing.xl} />
      </Block>
      <Spacer spacing={Theme.spacing.xl} />
    </>
  );
};

export default PasswordBlock;
