import {DateTime} from 'luxon';
import * as React from 'react';

import {RootNavigationProps} from '@common/navigators';
import {useAppSelector} from '@common/reducers';

import {dateTimeToDMY} from '@components/DatePicker/utils';
import Loader from '@components/Loader';
import Popup from '@components/Popup';
import {dateTimeToHM} from '@components/TimePicker/utils';

import {useApi} from '@services/pacoApi';
import {useQuery} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

import Container from './Container';

const EditReservationForm: React.FC<
  RootNavigationProps<'EditReservationForm'>
> = props => {
  const {route, navigation} = props;
  const allocationId = route.params.allocationId;
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError({
    onCloseErrorPopup: () => {
      navigation.goBack();
    },
  });
  const driverInfo = useAppSelector(state => state.Member.driverInfo);
  const api = useApi();

  const {
    data: fetchVehiclesResponse,
    error: fetchingVehiclesError,
    isLoading: isLoadingVehicles,
    isSuccess: isFetchingVehiclesSuccess,
  } = useQuery(
    'vehicles',
    React.useCallback(async () => {
      return api.Vehicles.v1VehiclesGet();
    }, [api]),
  );

  const {
    isSuccess: isFetchingGetReservationSuccess,
    data: fetchGetReservationResponse,
    isLoading: isLoadingGetReservation,
    error: fetchGetReservationError,
  } = useQuery(
    'getReservation',
    React.useCallback(async () => {
      return api.Reservations.v1ReservationsReservationIdGet(allocationId);
    }, [api, allocationId]),
  );

  const isSuccess =
    !isLoadingVehicles &&
    !isLoadingGetReservation &&
    isFetchingVehiclesSuccess &&
    isFetchingGetReservationSuccess;

  const reservationPeriod = React.useMemo(() => {
    if (
      !isFetchingGetReservationSuccess ||
      !fetchGetReservationResponse?.data
    ) {
      return;
    }

    const {localFrom, localTo} = fetchGetReservationResponse.data;
    if (!localFrom || !localTo) {
      return;
    }
    return {
      fromHour: dateTimeToHM(DateTime.fromISO(localFrom, {setZone: true})),
      fromDate: dateTimeToDMY(DateTime.fromISO(localFrom, {setZone: true})),
      toHour: dateTimeToHM(DateTime.fromISO(localTo, {setZone: true})),
      toDate: dateTimeToDMY(DateTime.fromISO(localTo, {setZone: true})),
    };
  }, [fetchGetReservationResponse?.data, isFetchingGetReservationSuccess]);

  const selectedVehicle = React.useMemo(() => {
    if (
      !isFetchingGetReservationSuccess ||
      !fetchGetReservationResponse?.data
    ) {
      return;
    }

    const {vehicleId, spaceType, hasChargingPoint} =
      fetchGetReservationResponse.data;
    if (spaceType === undefined || hasChargingPoint === undefined) {
      return;
    }
    const index = fetchVehiclesResponse?.data.findIndex(
      vehicle => vehicle.vehicleId === vehicleId,
    );

    if (index == null || index < 0) {
      return;
    }

    return index;
  }, [
    fetchGetReservationResponse?.data,
    isFetchingGetReservationSuccess,
    fetchVehiclesResponse,
  ]);

  React.useEffect(() => {
    if (fetchingVehiclesError) {
      handleError(fetchingVehiclesError);
    }
  }, [fetchingVehiclesError, handleError]);

  React.useEffect(() => {
    if (fetchGetReservationError) {
      handleError(fetchGetReservationError);
    }
  }, [fetchGetReservationError, handleError]);

  return (
    <>
      {isSuccess && (
        <Container
          {...props}
          allocationId={allocationId}
          isDriverPrm={driverInfo?.isPrm || false}
          reservationPeriod={reservationPeriod}
          vehicles={fetchVehiclesResponse?.data ?? []}
          defaultHasChargingPoint={
            fetchGetReservationResponse?.data.hasChargingPoint || false
          }
          defaultIsPrm={fetchGetReservationResponse?.data.isForPrm || false}
          defaultSelectedParkingId={fetchGetReservationResponse?.data.parkingId}
          defaultSelectedVehicle={selectedVehicle}
        />
      )}
      <Loader isLoading={isLoadingVehicles || isLoadingGetReservation} />
      <Popup {...errorPopupProps} />
    </>
  );
};

export default EditReservationForm;
