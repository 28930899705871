import * as React from 'react';
import {StyleSheet, Text as RNText, TextProps} from 'react-native';

import Theme from '../config/theme';

type TextVariant =
  | 'Bigger'
  | 'Big'
  | 'Medium'
  | 'Small'
  | 'Sub'
  | 'Label'
  | 'Body';
export interface Props extends TextProps {
  variant?: TextVariant;
  color?: string;
  align?: 'right' | 'left' | 'auto' | 'center' | 'justify';
}

const Text: React.FC<Props> = ({variant = 'Body', color, align, ...props}) => {
  return (
    <RNText
      {...props}
      style={[
        Styles.text,
        Styles[variant],
        color ? {color} : {},
        align ? {textAlign: align} : {},
        props.style,
      ]}
    />
  );
};

export const Styles = StyleSheet.create({
  text: {
    fontFamily: Theme.fonts.family.BasicGrotesqueBold,
    color: Theme.colors.black,
  },
  Bigger: {
    fontSize: 40,
    lineHeight: 52,
  },
  Big: {
    fontSize: 32,
    lineHeight: 40,
  },
  Medium: {
    fontSize: 24,
    lineHeight: 32,
  },
  Small: {
    fontSize: 16,
    lineHeight: 24,
  },
  Sub: {
    color: Theme.colors.gray,
    fontSize: 18,
    lineHeight: 27,
    fontFamily: Theme.fonts.family.InterMedium,
  },
  Body: {
    color: Theme.colors.gray,
    fontSize: 16,
    lineHeight: 24,
    fontFamily: Theme.fonts.family.InterMedium,
  },
  Label: {
    color: Theme.colors.gray,
    fontSize: 10,
    lineHeight: 14,
    fontFamily: Theme.fonts.family.InterMedium,
  },
});

export default Text;
