import testIDs from '@testIDs';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Theme from '@config/theme';

import {RootNavigationProps} from '@common/navigators';
import SimpleLayout from '@common/shared_layouts/SimpleLayout';

import Block from '@components/Block';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Loader from '@components/Loader';
import Passcode from '@components/Passcode';
import Popup from '@components/Popup';
import Separator from '@components/Separator';
import Spacer from '@components/Spacer';

import {useApi, UpdatedDriver} from '@services/pacoApi';
import {useMessagePopup} from '@services/popups';
import {useMutation, useQuery} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

import InformationsForm from './InformationsForm';

const Informations: React.FC<RootNavigationProps<'Informations'>> = props => {
  const {t} = useTranslation();
  const {navigation} = props;
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError({
    onCloseErrorPopup: () => {
      navigation.goBack();
    },
  });
  const {props: successPopupProps, show: showSuccess} = useMessagePopup({
    testID: testIDs.informations.successPopup,
  });
  const api = useApi();
  const {
    data: fetchDriverResponse,
    error: fetchingDriverError,
    isLoading: isLoadingDriver,
  } = useQuery(
    'driver',
    React.useCallback(async () => {
      return api.Drivers.v1DriversInfoGet();
    }, [api]),
  );

  const driverInfo = fetchDriverResponse?.data;

  React.useEffect(() => {
    if (fetchingDriverError) {
      handleError(fetchingDriverError);
    }
  }, [fetchingDriverError, handleError]);

  const {
    isLoading: isLoadingUpdateDriver,
    isSuccess: isUpdateDriverSuccess,
    data: updateDriverResponse,
    isError: isUpdateDriverError,
    error: updateDriverError,
    mutate: updateDriver,
  } = useMutation(async (data: UpdatedDriver) => {
    return api.Drivers.v1DriversUpdateDriverPatch(data);
  });

  React.useEffect(() => {
    if (isUpdateDriverError) {
      handleError(updateDriverError);
    }
  }, [isUpdateDriverError, updateDriverError, handleError]);

  React.useEffect(() => {
    if (isUpdateDriverSuccess && updateDriverResponse?.data) {
      showSuccess({
        message: updateDriverResponse?.data?.message,
      });
    }
  }, [isUpdateDriverSuccess, updateDriverResponse, showSuccess]);

  const initialFormValues = React.useMemo(() => {
    return {
      firstName: driverInfo?.firstName || '',
      lastName: driverInfo?.lastName || '',
      email: driverInfo?.email || '',
      phone: driverInfo?.phone || '',
      number: driverInfo?.number || '',
      isPrm: driverInfo?.isPrm || false,
    };
  }, [driverInfo]);

  return (
    <SimpleLayout>
      <Block title={t('informations')}>
        <Box padding={Theme.spacing.l}>
          <Spacer spacing={Theme.spacing.s} />
          {driverInfo?.formattedPasscode != null && (
            <>
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center">
                <Spacer spacing={Theme.spacing.m} />
                <Passcode formattedPasscode={driverInfo?.formattedPasscode} />
                <Spacer spacing={Theme.spacing.xl} />
              </Flex>
              <Separator />
            </>
          )}

          <InformationsForm
            values={initialFormValues}
            onSubmit={data => {
              updateDriver({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phoneNumber: data.phone,
                number: data.number,
                isPrm: data.isPrm,
              });
            }}
          />

          <Loader isLoading={isLoadingDriver || isLoadingUpdateDriver} />
          <Popup {...errorPopupProps} />
          <Popup {...successPopupProps} />
        </Box>
      </Block>
    </SimpleLayout>
  );
};

export default Informations;
