import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';

import Theme from '@config/theme';

import {DMY, formatDMY} from '@components/DatePicker/utils';
import Flex from '@components/Flex';
import Input from '@components/Input';
import Spacer from '@components/Spacer';
import {HM, HMtoString} from '@components/TimePicker/utils';

interface Props {
  period: {fromHour: HM; toHour: HM; fromDate: DMY; toDate: DMY};
  testIDs?: {
    fromDate?: string;
    fromHour?: string;
    toDate?: string;
    toHour?: string;
  };
}

const PeriodView: React.FC<Props> = props => {
  const {t} = useTranslation();
  return (
    <Flex>
      <Flex direction="row">
        <Input
          testID={props.testIDs?.fromDate}
          style={[Theme.commonStyles.flex1, Styles.dateInput]}
          disabled
          leftIconName="Planning"
          placeholder={t('placeholder-start-date')}
          value={formatDMY(props.period.fromDate)}
        />
        <Spacer spacing={Theme.spacing.s} mode="horizontal" />
        <Input
          testID={props.testIDs?.fromHour}
          style={Theme.commonStyles.flex1}
          disabled
          placeholder={t('placeholder-start-hour')}
          value={HMtoString(props.period.fromHour)}
        />
      </Flex>
      <Flex direction="row">
        <Input
          testID={props.testIDs?.toDate}
          style={[Theme.commonStyles.flex1, Styles.dateInput]}
          disabled
          leftIconName="Planning"
          placeholder={t('placeholder-end-date')}
          value={formatDMY(props.period.toDate)}
        />
        <Spacer spacing={Theme.spacing.s} mode="horizontal" />
        <Input
          testID={props.testIDs?.toHour}
          style={Theme.commonStyles.flex1}
          disabled
          placeholder={t('placeholder-end-hour')}
          value={HMtoString(props.period.toHour)}
        />
      </Flex>
    </Flex>
  );
};

const Styles = StyleSheet.create({
  dateInput: {flexBasis: 80},
});

export default PeriodView;
