import * as React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';

import Theme from '../../config/theme';
import Hoverable from '../Hoverable';
import Option from '../Select/Option';
import {RowComponentProps} from '../Select/RowComponent';

import CountryRow from './CountryRow';

const RowComponent: React.FC<RowComponentProps<string>> = props => {
  const {index, isSelected, options, onPress} = props;
  const {label: countryName, value: countryAlpha2} = options[
    index
  ] as Option<string>;

  return (
    <TouchableOpacity onPress={onPress} style={styles.dropdownOptionsWidth}>
      <Hoverable
        backgroundColor={Theme.colors.white}
        hoverColor={Theme.colors.secondaryHover}>
        <CountryRow
          countryName={countryName}
          countryAlpha2={countryAlpha2}
          isSelected={isSelected}
        />
      </Hoverable>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  dropdownOptionsWidth: {
    width: 415,
  },
});

export default RowComponent;
