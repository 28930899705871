import {TransitionPresets} from '@react-navigation/stack';
import * as React from 'react';

import appConfig from '@config/appConfig';

import Dev from '@screens/Dev';
import Environment from '@screens/Environment';
import Examples from '@screens/Examples';
import HeaderMenu from '@screens/HeaderMenu';
import PrivacyPolicy from '@screens/PrivacyPolicy';
import PushNotificationRequest from '@screens/PushNotificationRequest';
import TermsAndConditions from '@screens/TermsAndConditions';
import Updates from '@screens/Updates';

import {RootStack} from './utils';

export const renderCommonScreens = (
  Stack: RootStack,
  getTitle: (key: string) => string,
) => {
  return (
    <>
      <Stack.Screen
        name="PushNotificationRequest"
        component={PushNotificationRequest}
        options={{
          ...TransitionPresets.ModalTransition,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Updates"
        component={Updates}
        options={{
          ...TransitionPresets.ModalTransition,
          headerShown: false,
        }}
      />

      <Stack.Screen
        name="HeaderMenu"
        component={HeaderMenu}
        options={{
          ...TransitionPresets.ModalTransition,
          headerShown: false,
          title: getTitle('header-menu-screen-title'),
        }}
      />

      <Stack.Screen
        name="Examples"
        component={Examples}
        options={{
          title: getTitle('examples-screen-title'),
        }}
      />

      <Stack.Screen
        name="TermsAndConditions"
        component={TermsAndConditions}
        options={{
          ...TransitionPresets.ModalTransition,
          title: getTitle('terms-and-conditions-screen-title'),
          headerShown: false,
          cardStyle: {backgroundColor: 'transparent'},
        }}
      />

      <Stack.Screen
        name="PrivacyPolicy"
        component={PrivacyPolicy}
        options={{
          title: getTitle('privacy-policy-screen-title'),
          headerShown: false,
          cardStyle: {backgroundColor: 'transparent'},
        }}
      />

      {appConfig.showDevTab && <Stack.Screen name="Dev" component={Dev} />}

      <Stack.Screen name="Environment" component={Environment} />
    </>
  );
};
