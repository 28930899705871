import testIDs from '@testIDs';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Theme from '../config/theme';

import Box from './Box';
import Flex from './Flex';
import Icon from './Icon';
import Spacer from './Spacer';
import Text from './Text';

interface Props {
  testID?: string;
  iconName?: string | null;
  label?: string | null;
  isSelected?: boolean;
  isTypeUnsupported?: boolean;
}
const VehicleRow: React.FC<Props> = props => {
  const {iconName, label, isSelected, isTypeUnsupported} = props;
  const {t} = useTranslation();

  return (
    <Box
      testID={`${testIDs.vehicles.form.type}-option`}
      padding={Theme.spacing.m}
      skipRight
      skipLeft>
      <Box padding={Theme.spacing.s} skipTop skipBottom skipLeft>
        <Flex direction="row" alignItems="center">
          {iconName ? (
            <Icon
              name={iconName}
              size={Theme.icons.l}
              color={
                isTypeUnsupported
                  ? Theme.colors.secondary
                  : isSelected
                  ? Theme.colors.callToAction
                  : Theme.colors.primary
              }
            />
          ) : (
            <Spacer spacing={Theme.icons.xl} mode="horizontal" />
          )}
          <Spacer spacing={Theme.spacing.s} mode="horizontal" />
          <Text
            color={
              isTypeUnsupported
                ? Theme.colors.secondary
                : isSelected
                ? Theme.colors.callToAction
                : Theme.colors.primary
            }>
            {isTypeUnsupported
              ? `${label} (${t('vehicle-type-unsupported')})`
              : label}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default VehicleRow;
