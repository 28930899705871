import {DateTime} from 'luxon';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Theme from '@config/theme';

import {
  compareDateTimeDMY,
  isDateGreaterThan,
  isDateSmallerThan,
  isHMGreaterThan,
  isPastDate,
} from '@services/helpers/dateHelper';
import {useMessagePopup} from '@services/popups';

import DatePicker from './DatePicker';
import {dateTimeToDMY, DMY} from './DatePicker/utils';
import Flex from './Flex';
import Popup from './Popup';
import Spacer from './Spacer';
import TimePicker from './TimePicker';
import {dateTimeToHM, HM} from './TimePicker/utils';

type Period = {
  fromHour?: HM;
  toHour?: HM;
  fromDate?: DMY;
  toDate?: DMY;
};
interface Props {
  defaultDate: DMY;
  value: Period;
  onChange: (
    field: 'fromDate' | 'toDate' | 'fromHour' | 'toHour',
    value?: DMY | HM,
  ) => void;
  defaultReservationPeriod?: {from: HM; to: HM};
  testIDs?: {
    timePickerTo?: string;
    timePickerFrom?: string;
  };
}

const PeriodSelector: React.FC<Props> = props => {
  const {t} = useTranslation();
  const {defaultDate, value, onChange, defaultReservationPeriod, testIDs} =
    props;
  const {fromHour, toHour, fromDate, toDate} = value;

  const fromDateRef = React.useRef<DatePicker>(null);
  const toDateRef = React.useRef<DatePicker>(null);
  const fromHourRef = React.useRef<TimePicker>(null);
  const toHourRef = React.useRef<TimePicker>(null);

  const [lastChangedField, setLastChanged] = React.useState<
    'from' | 'to' | null
  >(null);

  const {props: fromDateErrorPopupProps, show: showFromDateErrorPopup} =
    useMessagePopup({
      title: t('error'),
      message: t('new-reservation-past-start-date-error-message'),
    });
  const {props: toDateErrorPopupProps, show: showToDateErrorPopup} =
    useMessagePopup({
      title: t('error'),
      message: t('new-reservation-past-end-date-error-message'),
    });

  const checkAndSetFromDate = React.useCallback(
    (dmy?: DMY, hm?: HM) => {
      if (isPastDate(dmy, hm)) {
        return showFromDateErrorPopup({
          onClose: () => {
            const now = DateTime.now();
            onChange('fromHour', dateTimeToHM(now));
            onChange('fromDate', dateTimeToDMY(now));
          },
        });
      }
      onChange('fromHour', hm);
      onChange('fromDate', dmy);
    },
    [onChange, showFromDateErrorPopup],
  );

  const checkAndSetToDate = React.useCallback(
    (dmy?: DMY, hm?: HM) => {
      if (isPastDate(dmy, hm)) {
        return showToDateErrorPopup({
          onClose: () => {
            const now = DateTime.now();
            onChange('toHour', dateTimeToHM(now));
            onChange('toDate', dateTimeToDMY(now));
          },
        });
      }
      onChange('toHour', hm);
      onChange('toDate', dmy);
    },
    [onChange, showToDateErrorPopup],
  );

  React.useEffect(() => {
    const getConfig = () => {
      if (
        !defaultReservationPeriod ||
        isPastDate(defaultDate, defaultReservationPeriod.from)
      ) {
        return {
          from: {date: defaultDate},
          to: {date: defaultDate},
        };
      }

      const {from: _fromHour, to: _toHour} = defaultReservationPeriod;
      if (isHMGreaterThan(_fromHour, _toHour)) {
        const date = DateTime.now().set({
          year: defaultDate.year,
          month: defaultDate.month,
          day: defaultDate.day + 1,
        });

        return {
          from: {date: defaultDate, hour: _fromHour},
          to: {date: dateTimeToDMY(date), hour: _toHour},
        };
      }

      return {
        from: {date: defaultDate, hour: _fromHour},
        to: {date: defaultDate, hour: _toHour},
      };
    };

    const {from, to} = getConfig();

    checkAndSetFromDate(from.date, from.hour);
    checkAndSetToDate(to.date, to.hour);
  }, [
    defaultDate,
    defaultReservationPeriod,
    checkAndSetToDate,
    checkAndSetFromDate,
  ]);

  React.useEffect(() => {
    if (!fromDate || !toDate) {
      return;
    }

    if (compareDateTimeDMY(fromDate, toDate) === 0 && !toHour) {
      return;
    }

    if (
      lastChangedField === 'from' &&
      isDateGreaterThan(fromDate, fromHour, toDate, toHour)
    ) {
      onChange('toHour', undefined);
      onChange('toDate', undefined);
      return;
    }

    if (
      lastChangedField === 'to' &&
      isDateSmallerThan(toDate, toHour, fromDate, fromHour)
    ) {
      onChange('fromHour', undefined);
      onChange('fromDate', undefined);

      return;
    }
  }, [onChange, fromDate, toDate, fromHour, toHour, lastChangedField]);

  React.useEffect(() => {
    const fromDateVisible = fromDateRef.current?.state.isVisible;
    const toDateVisible = toDateRef.current?.state.isVisible;
    const fromHourVisible = fromHourRef.current?.state.isVisible;
    const toHourVisible = toHourRef.current?.state.isVisible;

    if (
      fromDateVisible &&
      toDateVisible &&
      fromHourVisible &&
      toHourVisible &&
      toDateErrorPopupProps.visible &&
      fromDateErrorPopupProps.visible
    ) {
      return;
    }

    if (fromDate && toDate && fromHour && toHour) {
      return;
    }

    if (fromDate && fromHour && !toDate) {
      toDateRef.current?.open();
    }

    if (fromDate && toDate && fromHour && !toHour) {
      toHourRef.current?.open();
    }
  }, [
    fromDate,
    toDate,
    fromHour,
    toHour,
    toDateErrorPopupProps.visible,
    fromDateErrorPopupProps.visible,
  ]);

  return (
    <>
      <Flex direction="row">
        <DatePicker
          ref={fromDateRef}
          value={fromDate}
          minDate={dateTimeToDMY(DateTime.now())}
          onSelect={_value => {
            setLastChanged('from');
            checkAndSetFromDate(_value, fromHour);
          }}
          placeholder={t('placeholder-start-date')}
        />
        <Spacer spacing={Theme.spacing.s} mode="horizontal" />
        <TimePicker
          testID={testIDs?.timePickerFrom}
          ref={fromHourRef}
          value={fromHour}
          onChange={_value => {
            setLastChanged('from');
            checkAndSetFromDate(fromDate, _value);
          }}
          placeholder={t('placeholder-start-hour')}
        />
      </Flex>
      <Flex direction="row">
        <DatePicker
          ref={toDateRef}
          value={toDate}
          minDate={dateTimeToDMY(DateTime.now())}
          onSelect={_value => {
            setLastChanged('to');
            checkAndSetToDate(_value, toHour);
          }}
          placeholder={t('placeholder-end-date')}
        />
        <Spacer spacing={Theme.spacing.s} mode="horizontal" />
        <TimePicker
          testID={testIDs?.timePickerTo}
          ref={toHourRef}
          value={toHour}
          onChange={_value => {
            setLastChanged('to');
            checkAndSetToDate(toDate, _value);
          }}
          placeholder={t('placeholder-end-hour')}
        />
      </Flex>
      <Popup {...toDateErrorPopupProps} />
      <Popup {...fromDateErrorPopupProps} />
    </>
  );
};

export default PeriodSelector;
