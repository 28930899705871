import testIDs from '@testIDs';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Linking, Pressable, View} from 'react-native';
import {useHover} from 'react-native-web-hooks';
import {usePopper} from 'react-popper';

import appConfig from '@common/config/appConfig';
import Theme from '@common/config/theme';
import {useAppSelector} from '@common/reducers';

import Box from '@components/Box';
import DisconnectPopup from '@components/DisconnectPopup';
import Flex from '@components/Flex';
import Icon from '@components/Icon';
import Modal from '@components/Modal';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import {
  openPrivacyPolicy,
  openTermsAndConditions,
} from '@services/helpers/appHelper';
import {openZenparkPro, useMailToContact} from '@services/helpers/linksHelper';
import logger from '@services/logger';
import {UserType} from '@services/pacoApi';
import {
  useResponsiveProperty,
  responsiveContainerWidth,
} from '@services/responsive';

import {HomeParamList} from '../../Home';
import {getNavigationContainer} from '../../NavigationContainer';
import {HeaderFlexLogo} from '../Header';

type MenuItem = {
  hidden?: boolean;
  title: string;
  iconName?: string;
  onPress: () => void;
  name?: string;
  testID?: string;
};

const navigateTo = (page: keyof HomeParamList) => {
  return () => {
    const currentPage = getNavigationContainer()?.getCurrentRoute();

    if (currentPage?.name === page) {
      return;
    }

    getNavigationContainer()?.navigate('Home', {screen: page});
  };
};

const Navbar: React.FC = () => {
  const {t} = useTranslation();
  const {showNavbar, driverInfo} = useAppSelector(state => {
    return {
      showNavbar: state.App.showNavbar,
      driverInfo: state.Member.driverInfo,
    };
  });
  const container = useResponsiveProperty(responsiveContainerWidth);

  const leftMenu: MenuItem[] = [
    {
      title: t('navbar-planning'),
      iconName: 'Planning',
      onPress: navigateTo('Planning'),
      name: 'Planning',
      hidden: driverInfo?.userType === UserType.Admin,
      testID: testIDs.navBar.planning,
    },
    {
      title: t('navbar-access'),
      iconName: 'Access',
      onPress: navigateTo('Access'),
      name: 'Access',
      hidden: driverInfo?.userType === UserType.Admin,
      testID: testIDs.navBar.access,
    },
  ];

  if (!showNavbar) {
    return null;
  }

  return (
    <Flex alignItems="center" backgroundColor={Theme.colors.white}>
      <Flex
        direction="row"
        style={container}
        alignItems="center"
        justifyContent="space-between">
        <Flex direction="row" alignItems="center">
          <Pressable onPress={navigateTo('Planning')}>
            <HeaderFlexLogo />
          </Pressable>
          <Spacer mode="horizontal" spacing={Theme.spacing.xxl} />
          {leftMenu
            .filter(item => !item.hidden)
            .map((item, index) => (
              <MenuItem key={index} item={item} />
            ))}
        </Flex>
        <AccountMenu />
      </Flex>
    </Flex>
  );
};

const MenuItem: React.FC<{item: MenuItem}> = ({item}) => {
  const ref = React.useRef<View>(null);
  const isHover = useHover(ref);
  const currentTab = useAppSelector(state => state.App.currentTab);
  const isActive = currentTab === item.name;
  return (
    <>
      <Pressable ref={ref} onPress={item.onPress} testID={item.testID}>
        <Flex direction="row" alignItems="center">
          {item.iconName && (
            <Icon
              name={item.iconName}
              size={Theme.icons.xl}
              color={
                isHover || isActive
                  ? Theme.colors.callToAction
                  : Theme.colors.primary
              }
            />
          )}
          <Spacer mode="horizontal" spacing={Theme.spacing.xs} />
          <Text
            variant="Small"
            color={
              isHover || isActive
                ? Theme.colors.callToAction
                : Theme.colors.primary
            }>
            {item.title}
          </Text>
        </Flex>
      </Pressable>
      <Spacer mode="horizontal" spacing={Theme.spacing.l} />
    </>
  );
};

const AccountMenu: React.FC = () => {
  const {t} = useTranslation();
  const mailToContact = useMailToContact();
  const {driverInfo, beeMyFlex} = useAppSelector(state => {
    return {
      driverInfo: state.Member.driverInfo,
      beeMyFlex: state.App.configuration.beeMyFlex,
    };
  });
  const [showDisconnetPopup, setShowDisconnectPopup] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [target, setTarget] = React.useState<HTMLDivElement | null>(null);
  const [popper, setPopper] = React.useState<HTMLDivElement | null>(null);
  const {styles, attributes} = usePopper(target, popper, {
    placement: 'bottom-end',
  });

  const handlePress = (func: () => void) => {
    return () => {
      func();
      setOpen(false);
    };
  };
  const rightMenu: MenuItem[] = [
    {
      title: `😎 ${t('navbar-dev')}`,
      hidden: !appConfig.showDevTab || driverInfo?.userType === UserType.Admin,
      onPress: () => {
        getNavigationContainer()?.navigate('Dev');
        setOpen(false);
      },
    },
    {
      title: t('navbar-settings'),
      testID: testIDs.navBar.myAccount,
      onPress: handlePress(navigateTo('Account')),
      hidden: driverInfo?.userType === UserType.Admin,
    },
    {
      title: t('navbar-contact-us'),
      onPress: handlePress(mailToContact),
    },
    {
      title: t('navbar-discover-zenpark-pro'),
      onPress: openZenparkPro,
    },
    {
      title: t('navbar-terms-and-conditions'),
      onPress: () => openTermsAndConditions(),
    },
    {
      title: t('navbar-privacy-policy'),
      onPress: () => openPrivacyPolicy(),
    },
    {
      title: t('navbar-go-back-beemyflex'),
      onPress: () =>
        Linking.openURL(beeMyFlex?.appUrl || '').catch(logger.recordError),
      hidden: !beeMyFlex?.connectedCompanyIds?.find(
        id => id === driverInfo?.company?.id,
      ),
    },
    {
      title: t('navbar-logout'),
      testID: testIDs.navBar.disconnect,
      onPress: handlePress(() => setShowDisconnectPopup(true)),
    },
  ];

  const displayedName =
    driverInfo?.userType === UserType.Driver
      ? `${driverInfo.firstName} ${driverInfo.lastName}`
      : driverInfo?.userType === UserType.Admin
      ? driverInfo.email
      : null;

  return (
    <>
      <div ref={setTarget}>
        <Pressable onPress={() => setOpen(true)}>
          <Flex direction="row" alignItems="center">
            <Text>{driverInfo && displayedName}</Text>
            <Spacer spacing={Theme.spacing.l} mode="horizontal" />
            <Icon
              name="Dropdown"
              color={Theme.colors.primary}
              size={Theme.icons.xl}
              testID={testIDs.navBar.openButton}
            />
          </Flex>
        </Pressable>
      </div>
      <Modal visible={open}>
        <Pressable
          style={Theme.commonStyles.flex1}
          onPress={() => setOpen(false)}>
          <div ref={setPopper} style={styles.popper} {...attributes.popper}>
            <Spacer spacing={Theme.spacing.xxl + Theme.spacing.s} />
            <View style={[Styles.ul, boxShadowStyle]}>
              <Box padding={Theme.spacing.s} skipRight skipLeft>
                {rightMenu.map((item, index) => {
                  if (item.hidden) {
                    return <React.Fragment key={index} />;
                  }
                  return <AccountMenuItem key={index} item={item} />;
                })}
              </Box>
            </View>
          </div>
        </Pressable>
      </Modal>
      <DisconnectPopup
        visible={showDisconnetPopup}
        close={() => setShowDisconnectPopup(false)}
      />
    </>
  );
};

const AccountMenuItem: React.FC<{item: MenuItem}> = ({item}) => {
  const ref = React.useRef<View>(null);
  const isHover = useHover(ref);

  return (
    <Pressable
      ref={ref}
      onPress={item.onPress}
      style={[Styles.li, isHover ? Styles.AccountMenuItemHover : {}]}
      testID={item.testID}>
      <Text>{item.title}</Text>
    </Pressable>
  );
};

const Styles = {
  ul: {
    borderRadius: Theme.radius.container,
    backgroundColor: Theme.colors.white,
  },
  li: {
    minWidth: 218,
    paddingVertical: Theme.spacing.s,
    paddingHorizontal: Theme.spacing.xxl,
  },
  AccountMenuItemHover: {
    backgroundColor: Theme.colors.secondaryHover,
  },
  menuItem: {
    color: Theme.colors.secondary,
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const boxShadowStyle: any = {boxShadow: '0 2px 34px -10px rgb(0 0 0 / 12%)'};

export default Navbar;
