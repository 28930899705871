import * as React from 'react';
import {View, StyleSheet, ViewStyle} from 'react-native';

import Theme from '../config/theme';

import Flex from './Flex';
import Spacer from './Spacer';
import Text from './Text';

interface Props {
  title?: string;
  style?: ViewStyle;
}
const Block: React.FC<Props> = ({style, title, children}) => {
  return (
    <Flex style={style}>
      {title && (
        <>
          <Text variant="Medium">{title}</Text>
          <Spacer spacing={Theme.spacing.m} />
        </>
      )}
      <View style={Styles.container}>
        <View style={Styles.innerContainer}>{children}</View>
      </View>
    </Flex>
  );
};

const Styles = StyleSheet.create({
  innerContainer: {
    flexGrow: 1,
    backgroundColor: Theme.colors.white,
    borderRadius: Theme.radius.container,
    overflow: 'hidden',
  },
  container: {
    flexGrow: 1,
    borderRadius: Theme.radius.container,
    shadowColor: '#000000AA',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 20.22,
    elevation: 10,
  },
});

export default Block;
