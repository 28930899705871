import * as React from 'react';

import {AppActions, useAppDispatch, useAppSelector} from '@common/reducers';

import Loader from '@components/Loader';
import Popup from '@components/Popup';

import Outdated from '@screens/Outdated';

import {useVersionState} from '@services/helpers/versionHelper';
import {useApi} from '@services/pacoApi';
import {useQuery} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

const ConfigurationGate: React.FC = ({children}) => {
  const {configuration} = useAppSelector(state => {
    return {
      configuration: state.App.configuration,
    };
  });
  const versionState = useVersionState();
  const dispatch = useAppDispatch();
  const api = useApi();
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError();

  const {
    data: fetchConfigurationResponse,
    isSuccess: isFetchingConfigurationSuccess,
    isLoading: isConfigurationLoading,
    error: fetchConfigurationError,
    isError: isConfigurationError,
  } = useQuery(
    'configuration',
    React.useCallback(async () => {
      return api.Configuration.v1ConfigurationGet();
    }, [api]),
  );

  React.useEffect(() => {
    if (isFetchingConfigurationSuccess && fetchConfigurationResponse?.data) {
      dispatch(AppActions.setConfiguration(fetchConfigurationResponse.data));
    }
  }, [dispatch, fetchConfigurationResponse, isFetchingConfigurationSuccess]);

  React.useEffect(() => {
    if (isConfigurationError && !configuration) {
      handleError(fetchConfigurationError);
    }
  }, [
    configuration,
    fetchConfigurationError,
    isConfigurationError,
    handleError,
  ]);

  if (configuration && versionState === 'ERROR') {
    return <Outdated />;
  }
  return (
    <>
      {configuration && children}
      <Loader isLoading={isConfigurationLoading} />
      <Popup {...errorPopupProps} />
    </>
  );
};

export default ConfigurationGate;
