import * as React from 'react';
import {
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
} from 'react-native';

import Theme from '@config/theme';

import OnboardingHeader from './OnBoardingHeader';
import OnBoardingPagination from './OnBoardingPagination';

const LAST_SLIDE_INDEX = Platform.select({ios: 3, default: 2});
const PAGINATION_SIZE = Platform.select({ios: 4, default: 3});

const OnBoardingSlider: React.FC = ({children}) => {
  const scrollRef = React.useRef<FlatList>(null);

  const [currentIndex, setCurrentIndex] = React.useState(0);
  const handleSkipPress = () => {
    scrollRef.current?.scrollToEnd({
      animated: true,
    });
  };
  const handleNextPress = () => {
    if (Math.round(currentIndex) + 1 <= LAST_SLIDE_INDEX) {
      scrollRef.current?.scrollToIndex({index: Math.round(currentIndex) + 1});
    }
  };

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    setCurrentIndex(
      event.nativeEvent.contentOffset.x / Theme.spacing.window.width,
    );
  };

  const DATA = React.useMemo(
    () => React.Children.toArray(children),
    [children],
  );
  return (
    <>
      <OnboardingHeader
        onSkipPress={handleSkipPress}
        showSkipButton={Math.round(currentIndex) !== LAST_SLIDE_INDEX}
      />
      <FlatList
        ref={scrollRef}
        onScroll={handleScroll}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        data={DATA}
        renderItem={({item}) => {
          return item;
        }}
      />
      <OnBoardingPagination
        size={PAGINATION_SIZE}
        currentIndex={currentIndex}
        onNextPress={handleNextPress}
      />
    </>
  );
};

export default OnBoardingSlider;
