import {SpaceTypes} from '../../../services/pacoApi';

export const getIconName = (type: SpaceTypes | undefined): string | null => {
  switch (type) {
    case SpaceTypes.Utility:
      return 'Utility';
    case SpaceTypes.Car:
      return 'Car';
    case SpaceTypes.Moto:
      return 'Moto';
    case SpaceTypes.Bike:
      return 'Bike';
    default:
      return null;
  }
};

export type VehicleOption = {
  value: number;
  label?: string | null;
  type?: SpaceTypes;
  unsupported?: boolean;
};
