// aka Date month year

import {DateTime} from 'luxon';

//TODO find a better name
export type DMY = {
  day: number;
  month: number;
  year: number;
};

const shiftZeros = (number: number, length: 2 | 4): string => {
  const str = `0000${number}`;

  return str.slice(-length);
};

export const dateTimeToDMY = (date: DateTime): DMY => {
  return {
    day: date.day,
    month: date.month,
    year: date.year,
  };
};

export const DMYToDateTime = (date: DMY): DateTime => {
  return DateTime.local(date.year, date.month, date.day);
};

export const parseDateTime = (date: DateTime): string => {
  return parseDMY(dateTimeToDMY(date)) as string;
};

export const parseDMY = (date?: DMY): string | undefined => {
  if (!date) {
    return;
  }
  const {day, month, year} = date;

  //prettier-ignore
  return `${shiftZeros(year, 4)}-${shiftZeros(month, 2)}-${shiftZeros(day, 2)}`;
};

export const formatDMY = (datetime?: DMY): string | undefined => {
  if (!datetime) {
    return;
  }

  const {day, month, year} = datetime;

  //prettier-ignore
  return `${shiftZeros(day, 2)}/${shiftZeros(month, 2)}/${shiftZeros(year, 4)}`;
};
