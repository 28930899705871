import * as React from 'react';
import {Animated, StyleSheet, View} from 'react-native';

import {Styles as TextStyles} from '../components/Text';
import Theme from '../config/theme';

import Spacer from './Spacer';

const ANIMATION_DURATION = 100;
const OPENED_TEXT_FONT = 10;
const CLOSED_TEXT_FONT = 16;
const OPENED_TOP_SPACING = 4;
const CLOSED_TOP_SPACING = 14;

interface Props {
  isOpened: boolean;
  label?: string;
  testID?: string;
}
const Label: React.FC<Props> = ({label, isOpened, testID}) => {
  const animation = React.useRef(new Animated.Value(CLOSED_TEXT_FONT)).current;

  React.useEffect(() => {
    Animated.timing(animation, {
      toValue: isOpened ? OPENED_TEXT_FONT : CLOSED_TEXT_FONT,
      duration: ANIMATION_DURATION,
      useNativeDriver: false,
    }).start();
  }, [animation, isOpened]);

  return (
    <View style={Styles.container}>
      <Spacer
        spacing={isOpened ? OPENED_TOP_SPACING : CLOSED_TOP_SPACING}
        animated
        animationDuration={ANIMATION_DURATION}
      />
      <Animated.Text
        testID={testID}
        numberOfLines={1}
        style={[TextStyles.Label, Styles.label, {fontSize: animation}]}>
        {label}
      </Animated.Text>
    </View>
  );
};

const Styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
  },
  label: {
    color: Theme.colors.secondary,
    lineHeight: undefined,
  },
});

export default Label;
