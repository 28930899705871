import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';
import {Popup} from 'react-native-map-link';

import Theme from '@config/theme';

import Box from '@components/Box';
import Flex from '@components/Flex';
import PressableIcon from '@components/PressableIcon';
import Text from '@components/Text';
import TextButton from '@components/TextButton';

interface Props {
  location: string | null | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}
const MobileAllocationAddress: React.FC<Props> = ({
  location,
  latitude,
  longitude,
}) => {
  const {t} = useTranslation();

  const [isPopupVisible, setIsPopupVisible] = React.useState<boolean>(false);

  const cancelButtonText = t('allocation-address-map-link-cancel-message');
  return (
    <>
      <TextButton
        style={Styles.parkingLocation}
        align="center"
        onPress={() => setIsPopupVisible(true)}>
        {location}
      </TextButton>
      <Popup
        isVisible={isPopupVisible}
        onCancelPressed={() => setIsPopupVisible(false)}
        onAppPressed={() => setIsPopupVisible(false)}
        onBackButtonPressed={() => setIsPopupVisible(false)}
        style={{
          cancelButtonText: {color: Theme.colors.persianRose},
          itemText: {color: Theme.colors.primary},
          container: {overflow: 'visible'},
        }}
        appsWhiteList={[
          'google-maps',
          'waze',
          'apple-maps',
          'yandex-maps',
          'moovit',
          'citymapper',
          /* Array of apps (apple-maps, google-maps, etc...) that you want
          to show in the popup, if is undefined or an empty array it will show all supported apps installed on device.*/
        ]}
        options={{
          latitude: latitude as number,
          longitude: longitude as number,
          title: location as string,
          directionsMode: 'car',
          googleForceLatLon: true,
          cancelText: cancelButtonText,
        }}
        customHeader={
          <Flex>
            <Box padding={Theme.spacing.l}>
              <Flex alignItems="center">
                <Text variant="Medium" color={Theme.colors.primary}>
                  {t('allocation-address-map-link-title')}
                </Text>
                <Text variant="Label">
                  {t('allocation-address-map-link-message')}
                </Text>
              </Flex>
            </Box>
            <Flex style={Styles.closeIcon}>
              <PressableIcon
                name="Cross"
                size={Theme.icons.xl}
                onPress={() => setIsPopupVisible(false)}
                color={Theme.colors.white}
              />
            </Flex>
          </Flex>
        }
      />
    </>
  );
};

const Styles = StyleSheet.create({
  parkingLocation: {
    color: Theme.colors.white,
    textDecorationLine: 'underline',
  },
  closeIcon: {
    marginRight: Theme.icons.l / 2,
    position: 'absolute',
    backgroundColor: Theme.colors.persianRose,
    borderRadius: Theme.icons.xl / 2,
    overflow: 'hidden',
    top: -Theme.icons.l / 2,
    right: 0,
  },
});

export default MobileAllocationAddress;
