import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Settings as LuxonSettings} from 'luxon';

import AppConfig from '@config/appConfig';

import i18next, {AppLanguage, defaultLanguage} from '@services/i18n';
import {ConfigurationModel} from '@services/pacoApi';

type NavbarScreens = 'Planning' | 'Access' | 'Account';
interface AppState {
  apiUrl: string;
  appLanguage: AppLanguage;
  showNavbar: boolean;
  currentTab: NavbarScreens | null;
  configuration: ConfigurationModel;
  shouldCheckMarketingOptin: boolean;
  shouldDisplayTutorial: boolean;
  shouldDisplayPushNotificationRequest: boolean;
}

const initialState: AppState = {
  apiUrl: AppConfig.pacoApiUrls[AppConfig.currentEnv],
  appLanguage: defaultLanguage,
  showNavbar: true,
  currentTab: null,
  configuration: null as unknown as ConfigurationModel,
  shouldCheckMarketingOptin: false,
  shouldDisplayTutorial: true,
  shouldDisplayPushNotificationRequest: true,
};

const AppSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    setApiUrl: (state, action: PayloadAction<string>) => {
      state.apiUrl = action.payload;
    },
    setAppLocale: (state, action: PayloadAction<AppLanguage>) => {
      i18next.changeLanguage(action.payload);
      state.appLanguage = action.payload;
      LuxonSettings.defaultLocale = state.appLanguage;
    },
    setShowNavbar: (state, action: PayloadAction<boolean>) => {
      state.showNavbar = action.payload;
    },
    setConfiguration: (state, action: PayloadAction<ConfigurationModel>) => {
      state.configuration = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<NavbarScreens | null>) => {
      state.currentTab = action.payload;
    },
    setShouldCheckMarketingOptin: (state, action: PayloadAction<boolean>) => {
      state.shouldCheckMarketingOptin = action.payload;
    },
    tutorialEnd: state => {
      state.shouldDisplayTutorial = false;
      state.shouldDisplayPushNotificationRequest = false;
    },
    pushNotificationRequestFinished: state => {
      state.shouldDisplayPushNotificationRequest = false;
    },
  },
});

export const AppActions = AppSlice.actions;
export default AppSlice.reducer;
