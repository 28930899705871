import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Animated, Easing, ScrollView, Image} from 'react-native';

import images from '@assets/images';

import Theme from '@config/theme';

import Block from '@components/Block';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import Styles from './OnBoarding.styles';

const FirstSlide: React.FC = () => {
  const {t} = useTranslation();
  const translateAnim = useRef(new Animated.Value(200)).current;
  useEffect(() => {
    Animated.timing(translateAnim, {
      toValue: 0,
      easing: Easing.elastic(1),
      duration: 700,
      useNativeDriver: true,
    }).start();
  }, [translateAnim]);
  return (
    <Box
      skipTop
      style={[Styles.slideContainer, Theme.commonStyles.flex1]}
      padding={Theme.spacing.l}>
      <Flex
        alignItems="center"
        style={Theme.commonStyles.flex1}
        justifyContent="center">
        <Animated.View style={{transform: [{translateX: translateAnim}]}}>
          <Image
            source={images['onBoarding-slide1']}
            style={Styles.slideImage}
          />
        </Animated.View>
      </Flex>

      <Block style={Styles.slideBodyContainer}>
        <ScrollView contentContainerStyle={Styles.slideBodyContent}>
          <Text variant="Medium" align="center">
            {t('onboarding-slide1-title')}
          </Text>
          <Spacer spacing={Theme.spacing.s} />
          <Text variant="Sub" color={Theme.colors.black} align="center">
            {t('onboarding-slide1-description')}
          </Text>
        </ScrollView>
      </Block>
      <Spacer spacing={Theme.spacing.m} />
    </Box>
  );
};

export default FirstSlide;
