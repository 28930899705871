import {FormikContextType} from 'formik';

import {useVehicleName} from '@services/helpers/vehicleHelper';

import {SpaceTypes} from '../../services/pacoApi';

export type FormValues = {
  isEv: boolean;
  registrationPlateCountry: string | null;
  selectedType: number;
  vehicleName: string;
  registrationPlate: string | null;
  _nameEdited: boolean;
};

export const getTypeIndex = (
  types: SpaceTypes[],
  type?: SpaceTypes,
): number => {
  if (type) {
    return types.indexOf(type);
  }
  return 0;
};

export const types: SpaceTypes[] = Object.values(SpaceTypes).filter(
  st => st !== SpaceTypes.None,
);

export const useSetVehicleType = () => {
  const getVehicleName = useVehicleName();
  return (formik: FormikContextType<FormValues>, vehicleTypeIndex: number) => {
    if (types[vehicleTypeIndex] === SpaceTypes.Bike && formik.values.isEv) {
      formik.setFieldValue('isEv', false);
    }
    formik.setFieldValue('selectedType', vehicleTypeIndex);
    if (!formik.values._nameEdited) {
      const vehicleName = getVehicleName(
        types[vehicleTypeIndex],
        formik.values.isEv,
      );
      formik.setFieldValue('vehicleName', vehicleName);
    }
  };
};

export const useSetVehicleIsEv = () => {
  const getVehicleName = useVehicleName();
  return (formik: FormikContextType<FormValues>, isEv: boolean) => {
    formik.setFieldValue('isEv', isEv);
    if (!formik.values._nameEdited) {
      const vehicleName = getVehicleName(
        types[formik.values.selectedType],
        isEv,
      );
      formik.setFieldValue('vehicleName', vehicleName);
    }
  };
};
