import * as React from 'react';
import {Pressable, TextStyle} from 'react-native';

import Theme from '../config/theme';

import Flex from './Flex';
import Icon from './Icon';
import Spacer from './Spacer';
import Text from './Text';

interface Props {
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  labelColor?: string;
  activeColor?: string;
  inactiveColor?: string;
  disabledColor?: string;
  iconName?: string;
  checkedIconName?: string;
  labelStyle?: TextStyle;
  size?: number;
  onPress?: (checked: boolean) => void;
  testID?: string;
}
const Checkbox: React.FC<Props> = ({
  checked,
  disabled,
  iconName = 'CheckboxRequestEmpty',
  checkedIconName = 'CheckboxActive',
  size = Theme.icons.m,
  label,
  labelColor,
  activeColor = Theme.colors.callToAction,
  inactiveColor = Theme.colors.border,
  disabledColor = Theme.colors.secondary,
  onPress,
  testID,
}) => {
  const color = checked ? activeColor : inactiveColor;
  return (
    <Pressable
      testID={testID}
      onPress={() => !disabled && onPress && onPress(!checked)}>
      <Flex direction="row" alignItems="center">
        <Icon
          name={checked ? checkedIconName : iconName}
          color={disabled ? disabledColor : color}
          size={size}
        />
        <Spacer spacing={Theme.spacing.s} mode="horizontal" />
        {label && (
          <Text
            color={
              labelColor ?? disabled
                ? Theme.colors.secondary
                : Theme.colors.primary
            }>
            {label}
          </Text>
        )}
      </Flex>
    </Pressable>
  );
};
export default Checkbox;
