import * as React from 'react';
import {Pressable} from 'react-native';

import Theme from '../config/theme';

import Box, {Props as BoxProps} from './Box';
import Icon, {Props as IconProps} from './Icon';

interface Props extends IconProps, Partial<BoxProps> {
  onPress: () => void;
}

const PressableIcon: React.FC<Props> = ({
  hitSlop = Theme.spacing.s,
  size = Theme.icons.l,
  color = Theme.colors.primary,
  padding = 0,
  name,
  onPress,
  ...boxProps
}) => {
  return (
    <Pressable onPress={onPress} hitSlop={hitSlop}>
      <Box padding={padding} {...boxProps}>
        <Icon size={size} color={color} name={name} />
      </Box>
    </Pressable>
  );
};

export default PressableIcon;
