import {DateTime} from 'luxon';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Linking, StyleSheet} from 'react-native';

import Text from '../../components/Text';
import AppConfig from '../../config/appConfig';
import Theme from '../../config/theme';
import {useAppSelector} from '../../reducers';
import {useVersionState} from '../../services/helpers/versionHelper';

const OutdateAppWarning: React.FC = () => {
  const {t} = useTranslation();

  const configuration = useAppSelector(state => state.App.configuration);
  const versionState = useVersionState();

  if (!configuration || versionState !== 'WARNING') {
    return null;
  }

  const formatDate = (stringDate: string | null | undefined): string | null => {
    if (!stringDate) {
      return null;
    }

    const date = DateTime.fromISO(stringDate);

    const formatted = `${date.toLocaleString({weekday: 'long'})} 
    ${date.day} ${date.toLocaleString({month: 'long'}).toLowerCase()}`;

    return formatted;
  };

  const date = formatDate(configuration?.appMinimalVersion?.limitDate);

  return (
    <>
      <Text style={Styles.warningMessage}>
        {t('minimal-app-version-warning', {date: date})}
      </Text>
      <Text
        style={Styles.storeLink}
        onPress={() => Linking.openURL(AppConfig.storeUrl)}>
        {t('update')}
      </Text>
    </>
  );
};

const Styles = StyleSheet.create({
  warningMessage: {
    color: Theme.colors.warning,
    textAlign: 'center',
  },
  storeLink: {
    color: Theme.colors.callToAction,
    textAlign: 'center',
  },
});

export default OutdateAppWarning;
