import React from 'react';
import {Image, ScrollView} from 'react-native';

import Theme from '@config/theme';

import Block from '@components/Block';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import Styles from './OnBoarding.styles';

interface Props {
  image: number;
  title: string;
  description: string;
}

const SlideComponent: React.FC<Props> = ({image, title, description}) => {
  return (
    <Box
      skipTop
      style={[Styles.slideContainer, Theme.commonStyles.flex1]}
      padding={Theme.spacing.l}>
      <Flex
        alignItems="center"
        style={Theme.commonStyles.flex1}
        justifyContent="center">
        <Image source={image} style={Styles.slideImage} />
      </Flex>

      <Block style={Styles.slideBodyContainer}>
        <ScrollView contentContainerStyle={Styles.slideBodyContent}>
          <Text variant="Medium" align="center">
            {title}
          </Text>
          <Spacer spacing={Theme.spacing.s} />
          <Text variant="Sub" color={Theme.colors.black} align="center">
            {description}
          </Text>
        </ScrollView>
      </Block>
      <Spacer spacing={Theme.spacing.m} />
    </Box>
  );
};

export default SlideComponent;
