import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Animated, Platform} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import images from '@assets/images';

import {RootNavigationProps} from '@common/navigators';
import {AppActions, useAppDispatch} from '@common/reducers';

import PushNotificationRequestView from '@components/PushNotificationRequestView';

import tracker from '@services/tracker';

import FirstSlide from './FirstSlide';
import Styles from './OnBoarding.styles';
import OnBoardingSlider from './OnBoardingSlider';
import SlideComponent from './SlideComponent';
import ThirdSlide from './ThirdSlide';

const OnBoardingScreen = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const navigation =
    useNavigation<RootNavigationProps<'OnBoarding'>['navigation']>();

  const handleFinishPress = () => {
    dispatch(AppActions.tutorialEnd());
    tracker.trackTutorialEnd();
    navigation.replace('Login');
  };
  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  return (
    <SafeAreaView style={Styles.container}>
      <OnBoardingSlider>
        <Animated.View style={{opacity: fadeAnim}}>
          <FirstSlide />
        </Animated.View>
        <SlideComponent
          image={images['onBoarding-slide2']}
          title={t('onboarding-slide2-title')}
          description={t('onboarding-slide2-description')}
        />
        {Platform.OS === 'ios' ? (
          <SlideComponent
            image={images['onBoarding-slide3']}
            title={t('onboarding-slide3-title')}
            description={t('onboarding-slide3-description')}
          />
        ) : (
          <ThirdSlide onFinishPress={handleFinishPress} />
        )}
        {Platform.OS === 'ios' && (
          <PushNotificationRequestView
            onFinishPress={handleFinishPress}
            labelCTA={t('onboarding-finish-button')}
          />
        )}
      </OnBoardingSlider>
    </SafeAreaView>
  );
};

export default OnBoardingScreen;
