import {DateTime} from 'luxon';

export type TimeComponentProps = {
  value: string;
  visible: boolean;
  close: () => void;
  placeholder?: string;
  onTimeChange?: (hm?: HM) => void;
  target: Element | null;
  testID?: string;
};

export type HM = {
  hour: number;
  minutes: number;
};

export const shiftZero = (number: number): string => {
  const str = `0${number}`;

  return str.slice(-2);
};

export const dateTimeToHM = (date: DateTime): HM => {
  return {
    hour: date.hour,
    minutes: date.minute,
  };
};

export const stringToHM = (str?: string): HM | null => {
  if (!str) {
    return null;
  }

  const parts = str.split(':').map(Number);
  if (parts.length !== 2) {
    return null;
  }

  const [hour, minutes] = parts;

  if (hour < 0 || hour > 24 || minutes < 0 || minutes > 60) {
    return null;
  }

  return {hour, minutes};
};

export const HMtoString = (hm?: HM | null): string => {
  if (!hm) {
    return '';
  }

  return `${shiftZero(hm.hour)}:${shiftZero(hm?.minutes)}`;
};
