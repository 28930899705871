import {Platform} from 'react-native';

const isMobileBrowser = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
};
export type DeviceType = 'ios' | 'android' | 'web-desktop' | 'web-mobile';

export const getDeviceType = (): DeviceType => {
  if (Platform.OS === 'web') {
    return isMobileBrowser() ? 'web-mobile' : 'web-desktop';
  }

  return Platform.OS as DeviceType;
};

export const isBrowser = (): boolean => {
  return Platform.OS === 'web';
};
