import * as React from 'react';
import {View, Image, StyleSheet, Pressable} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import {openZenparkPro} from '@common/services/helpers/linksHelper';

import images from '../../../assets/images';
import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Spacer from '../../components/Spacer';
import Theme from '../../config/theme';

const LoginLayout: React.FC = ({children}) => {
  return (
    <SafeAreaView style={Styles.container} edges={['bottom']}>
      <View>
        <Spacer spacing={Theme.spacing.s} />
        <Box padding={Theme.spacing.l}>{children}</Box>
      </View>
      <Flex alignItems="center">
        <Pressable onPress={openZenparkPro}>
          <Image
            source={images['zenpark-pro' as keyof typeof images]}
            style={Styles.logo}
            resizeMode="contain"
          />
        </Pressable>
        <Spacer spacing={Theme.spacing.xxl} />
      </Flex>
    </SafeAreaView>
  );
};

const Styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Theme.colors.secondaryHover,
    justifyContent: 'space-between',
  },
  logo: {
    width: 150,
    height: 32,
  },
});

export default LoginLayout;
