import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Linking, StyleSheet, View} from 'react-native';

import Box from '../components/Box';
import Button from '../components/Button';
import Text from '../components/Text';
import AppConfig from '../config/appConfig';
import theme from '../config/theme';

const Outdated: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <View style={[Styles.container]}>
        <Text variant="Medium" style={Styles.minimalVersionTitle}>
          {t('minimal-version-error-title')}
        </Text>
        <Text style={Styles.minimalVersionText}>
          {t('minimal-version-error')}
        </Text>
        <Box
          padding={theme.spacing.l}
          skipBottom
          skipTop
          style={Styles.buttonStyle}>
          <Button
            title={t('update')}
            onPress={() => {
              Linking.openURL(AppConfig.storeUrl);
            }}
          />
        </Box>
      </View>
    </>
  );
};

const Styles = StyleSheet.create({
  minimalVersionText: {
    textAlign: 'center',
    padding: 25,
  },
  minimalVersionTitle: {
    textAlign: 'center',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  buttonStyle: {
    width: '100%',
  },
});

export default Outdated;
