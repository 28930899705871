import * as React from 'react';
import {ScrollView} from 'react-native';

import Box from '../components/Box';
import Flex from '../components/Flex';
import Theme from '../config/theme';
import WebBaseContainer from '../containers/WebBaseContainer';
import {getDeviceType} from '../services/helpers/deviceHelper';

const Container: React.FC =
  getDeviceType() === 'web-desktop'
    ? WebBaseContainer
    : ({children}) => <Box padding={Theme.spacing.l}>{children}</Box>;

const SimpleLayout: React.FC = ({children}) => {
  return (
    <Flex
      style={Theme.commonStyles.flex1}
      backgroundColor={Theme.colors.secondaryHover}>
      <ScrollView>
        <Container>{children}</Container>
      </ScrollView>
    </Flex>
  );
};

export default SimpleLayout;
