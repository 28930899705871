import * as React from 'react';
import {Platform} from 'react-native';

import theme from '../../config/theme';
import Field from '../Field';
import {LabelComponentProps} from '../Select/LabelComponent';
import Option from '../Select/Option';
import Spacer from '../Spacer';

import CountryRow from './CountryRow';

const LabelComponent: React.FC<LabelComponentProps<string>> = props => {
  const {label: countryName, value: countryAlpha2} =
    props.option as Option<string>;

  return (
    <>
      <Field
        isFocused={false}
        isOpened
        isValid
        {...props}
        rightIconName={Platform.OS === 'web' ? 'Dropdown' : undefined}
        onPressIconRight={props.onPressIcon}>
        <CountryRow countryName={countryName} countryAlpha2={countryAlpha2} />
      </Field>
      <Spacer spacing={theme.spacing.s} />
    </>
  );
};

export default LabelComponent;
