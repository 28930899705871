import React from 'react';
import {useTranslation} from 'react-i18next';
import {Image, ScrollView, View} from 'react-native';

import images from '@assets/images';

import Theme from '@config/theme';

import Block from '@components/Block';
import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import Styles from './OnBoarding.styles';

interface Props {
  onFinishPress: () => void;
}

const ThirdSlide: React.FC<Props> = ({onFinishPress}) => {
  const {t} = useTranslation();

  return (
    <Box
      skipTop
      style={[Styles.slideContainer, Theme.commonStyles.flex1]}
      padding={Theme.spacing.l}>
      <Flex
        alignItems="center"
        style={Theme.commonStyles.flex1}
        justifyContent="center">
        <Image source={images['onBoarding-slide3']} style={Styles.slideImage} />
      </Flex>

      <Block style={Styles.lastSlideBodyContainer}>
        <Flex style={Styles.lastSlideBodyContainer}>
          <ScrollView contentContainerStyle={Styles.slideBodyContent}>
            <Flex
              justifyContent="space-between"
              style={Theme.commonStyles.flex1}>
              <View>
                <Text variant="Medium" align="center">
                  {t('onboarding-slide3-title')}
                </Text>
                <Spacer spacing={Theme.spacing.s} />
                <Text variant="Sub" align="center" color={Theme.colors.black}>
                  {t('onboarding-slide3-description')}
                </Text>
              </View>
            </Flex>
          </ScrollView>
          <Button
            title={t('onboarding-finish-button')}
            onPress={onFinishPress}
            style={Styles.buttonCTA}
          />
        </Flex>
      </Block>
      <Spacer spacing={Theme.spacing.m} />
    </Box>
  );
};

export default ThirdSlide;
