import {Portal} from '@gorhom/portal';
import {useFocusEffect} from '@react-navigation/core';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {RefreshControl} from 'react-native';

import {HomeNavigationProps} from '@common/navigators';

import Loader from '@components/Loader';
import Popup from '@components/Popup';

import {useApi, Way} from '@services/pacoApi';
import {useMessagePopup} from '@services/popups';
import {useMutation, useQuery} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

import AccessLayout from './AccessLayout';
import AllocationInfo from './AllocationInfo';
import Remote from './Remote';

const Access: React.FC<HomeNavigationProps<'Access'>> = () => {
  const [way, setWay] = React.useState(Way.CarEntry);
  const [shouldOpenDoor, setShouldOpenDoor] = React.useState(false);

  const api = useApi();
  const {popupProps: errorPopupProps, handle: showError} = usePacoApiError();
  const {props: successPopupProps, show: showSuccess} = useMessagePopup();

  const {
    isError,
    isSuccess,
    isLoading,
    error: apiError,
    mutate: handleOpenDoorClick,
    data: apiResponse,
  } = useMutation(async () => {
    const body = JSON.stringify(way);
    return api.Parkings.v1ParkingsParkingIdAccessPost(
      accessModel?.allocation?.parkingId as string,
      body,
    );
  });

  const {
    data: fetchUpcomingAccessResponse,
    error: fetchingUpcomingAccessError,
    isLoading: isLoadingUpcomingAccess,
    refetch: upComingaccessRefetch,
  } = useQuery(
    'getUpcomingAccess',
    async () => api.Parkings.v1ParkingsGetUpcomingAccessGet(),
    {
      refetchOnWindowFocus: true,
    },
  );

  React.useEffect(() => {
    if (fetchingUpcomingAccessError) {
      showError(fetchingUpcomingAccessError);
    }
  }, [fetchingUpcomingAccessError, showError]);

  React.useEffect(() => {
    if (shouldOpenDoor) {
      setShouldOpenDoor(false);
      handleOpenDoorClick();
    }
  }, [handleOpenDoorClick, shouldOpenDoor]);

  const {t} = useTranslation();

  React.useEffect(() => {
    if (isError) {
      showError(apiError);
    }

    if (isSuccess) {
      showSuccess({
        message: apiResponse?.data.message,
        title: apiResponse?.data.title,
      });
    }
  }, [isError, isSuccess, apiResponse, apiError, showError, showSuccess]);

  const {
    data: accessData,
    isLoading: accessIsLoading,
    refetch: accessRefetch,
  } = useQuery('getaccess', async () => api.Parkings.v1ParkingsGetAccessGet(), {
    refetchOnWindowFocus: true,
  });

  const accessModel = accessData?.data;

  useFocusEffect(
    React.useCallback(() => {
      upComingaccessRefetch();
      accessRefetch();
    }, [accessRefetch, upComingaccessRefetch]),
  );

  return (
    <AccessLayout
      showRemote={accessModel?.isZenithParking}
      refreshControl={
        <RefreshControl
          refreshing={accessIsLoading && isLoadingUpcomingAccess}
          onRefresh={() => {
            accessRefetch();
            upComingaccessRefetch();
          }}
        />
      }>
      <Portal hostName="access-parking-block">
        <AllocationInfo
          isZenithParking={accessModel?.isZenithParking}
          allocation={accessModel?.allocation ?? null}
          hasDigicodeAccess={accessModel?.hasDigicode ?? false}
          hasUpcomingAccess={
            fetchUpcomingAccessResponse?.data.hasUpcomingAllocation ?? false
          }
        />
      </Portal>
      <Portal hostName="access-remote-block">
        {accessModel?.isZenithParking && (
          <Remote
            key="remote"
            carEntryInterval={accessModel?.carEntry ?? null}
            carExitInterval={accessModel?.carExit ?? null}
            pedestrianInterval={accessModel?.pedestrian ?? null}
            onPress={(newWay: React.SetStateAction<Way>) => {
              setWay(newWay);
              setShouldOpenDoor(true);
            }}
          />
        )}
      </Portal>
      <Loader isLoading={isLoading} title={t('access-button-click-loading')} />
      <Popup {...successPopupProps} />
      <Popup {...errorPopupProps} />
    </AccessLayout>
  );
};

export default Access;
