import * as React from 'react';

import {UpdatesContext, UpdatesContextState} from './UpdatesContext';

export const useUpdates = (): UpdatesContextState =>
  React.useContext(UpdatesContext);

export type {RemotePackage} from 'react-native-code-push';

export {default as UpdatesProvider} from './UpdatesProvider';
export {clearUpdates} from './codepush';
