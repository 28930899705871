import testIDs from '@testIDs';
import {DateTime} from 'luxon';
import * as React from 'react';

import PressableIcon from '@components/PressableIcon';

import {PlanningItem, PlanningItemType} from '@services/pacoApi';

import CancelReservationPopup from './CancelReservationPopup';
import ReleaseSpacePopup from './ReleaseSpacePopup';

interface Props {
  day: DateTime;
  canBeReleased: boolean;
  allocation: PlanningItem;
}
const AllocationItemButtons: React.FC<Props> = ({
  day,
  allocation,
  canBeReleased,
}) => {
  const [popupVisible, setPopupVisible] = React.useState(false);

  if (
    !canBeReleased ||
    allocation.type === PlanningItemType.Release ||
    !allocation.localFrom
  ) {
    return null;
  }

  return (
    <>
      <PressableIcon
        name="Delete"
        size={24}
        onPress={() => setPopupVisible(true)}
        testID={`${testIDs.planning.allocationIcon}-${day.day}`}
      />
      {allocation.type === PlanningItemType.Reservation ? (
        <CancelReservationPopup
          allocationId={allocation.allocationId as string}
          from={DateTime.fromISO(allocation.localFrom, {setZone: true})}
          to={DateTime.fromISO(allocation.localTo as string, {setZone: true})}
          parkingName={allocation.parkingName as string}
          visible={popupVisible}
          close={() => setPopupVisible(false)}
        />
      ) : (
        <ReleaseSpacePopup
          allocationId={allocation.allocationId as string}
          day={day}
          parkingName={allocation.parkingName as string}
          visible={popupVisible}
          close={() => setPopupVisible(false)}
        />
      )}
    </>
  );
};

export default AllocationItemButtons;
