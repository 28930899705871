import * as React from 'react';

import Field from '../../../components/Field';
import {LabelComponentProps} from '../../../components/Select/LabelComponent';
import Spacer from '../../../components/Spacer';
import theme from '../../../config/theme';

import LanguageRow from './LanguageRow';
import {LanguageOption, getIconName} from './utils';

const LabelComponent: React.FC<LabelComponentProps<number>> = props => {
  const {language, label} = props.option as LanguageOption;
  const iconName = getIconName(language);
  return (
    <>
      <Field
        isFocused={false}
        isOpened
        isValid
        rightIconName="Dropdown"
        onPressIconRight={props.onPressIcon}
        {...props}>
        <LanguageRow label={label} iconName={iconName} />
      </Field>
      <Spacer spacing={theme.spacing.s} />
    </>
  );
};

export default LabelComponent;
