import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../../components/Button';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import Theme from '../../config/theme';

type MessagePopupConfig = {
  title?: string | null;
  message?: string | null;
  buttonTitle?: string;
  onClose?: () => void;
  testID?: string;
};

type MessagePopupReturn = {
  show: (params: MessagePopupConfig) => void;
  close: () => void;
  props: {
    title?: string | null;
    close: () => void;
    visible: boolean;
    children: JSX.Element;
    testID?: string;
  };
};

export const useMessagePopup = (
  defaultConfig?: MessagePopupConfig,
): MessagePopupReturn => {
  const {t} = useTranslation();
  const [visible, setVisible] = React.useState(false);
  const [params, setParams] = React.useState<MessagePopupConfig | undefined>(
    defaultConfig,
  );
  const show = React.useCallback(
    (newParams: MessagePopupConfig) => {
      setParams(_params => ({..._params, ...newParams}));
      setVisible(true);
    },
    [setParams, setVisible],
  );

  const close = React.useCallback(() => {
    params?.onClose && params.onClose();
    setVisible(false);
  }, [setVisible, params]);

  const defaultButtonTitle = t('ok');
  const buttonTitle = params?.buttonTitle ?? defaultButtonTitle;
  const children = (
    <>
      <Text testID={`${params?.testID}-message`}>{params?.message}</Text>
      <Spacer spacing={Theme.spacing.m} />

      <Button
        testID={`${params?.testID}-button`}
        variant="Secondary"
        title={buttonTitle}
        onPress={close}
      />
    </>
  );

  const props = {
    title: params?.title,
    close,
    visible,
    children,
    testID: params?.testID,
  };
  return {show, close, props};
};
