import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import {useHover} from 'react-native-web-hooks';

import Theme from '../config/theme';

import Flex from './Flex';
import Label from './Label';
import PressableIcon from './PressableIcon';
import Spacer from './Spacer';

interface Props {
  disabled?: boolean;
  disabledBackgroundColor?: string;
  leftIconName?: string;
  rightIconName?: string;
  iconColor?: string;
  showLabel?: boolean;
  isOpened: boolean;
  isValid: boolean;
  isFocused: boolean;
  placeholder?: string;
  onPressIconLeft?: () => void;
  onPressIconRight?: () => void;

  testID?: string;
}

const Field: React.FC<Props> = ({
  disabled,
  disabledBackgroundColor = Theme.colors.border,
  leftIconName,
  rightIconName,
  iconColor: defaultIconColor,
  showLabel: withLabel = true,
  isOpened,
  isValid,
  isFocused,
  placeholder,
  onPressIconLeft = () => null,
  onPressIconRight = () => null,
  children,
  testID,
}) => {
  const container = React.useRef<View>(null);
  const isHover = useHover(container);

  const backgroundColor = disabled
    ? disabledBackgroundColor
    : isHover
    ? Theme.colors.secondaryHover
    : undefined;

  const showLabel = Boolean(placeholder && withLabel);

  const borderColor = isFocused
    ? Theme.colors.primary
    : isValid
    ? Theme.colors.border
    : Theme.colors.error;

  const iconColor =
    defaultIconColor ||
    (isOpened ? Theme.colors.primary : Theme.colors.secondary);

  const paddingTop = showLabel ? 8 : 0;

  return (
    <View
      ref={container}
      style={[Styles.container, {borderColor, backgroundColor}]}>
      <Flex
        direction="row"
        style={Theme.commonStyles.flex1}
        alignItems="center">
        {leftIconName ? (
          <>
            <PressableIcon
              name={leftIconName}
              size={Theme.icons.xl}
              color={iconColor}
              onPress={onPressIconLeft}
            />
            <Spacer spacing={Theme.spacing.s} mode="horizontal" />
          </>
        ) : null}
        <Flex
          style={[Theme.commonStyles.flex1, Styles.innerContainer]}
          justifyContent="center">
          {showLabel && (
            <Label
              testID={`${testID}-label`}
              isOpened={isOpened}
              label={placeholder}
            />
          )}
          <Spacer spacing={paddingTop} />
          {children}
        </Flex>

        {rightIconName ? (
          <>
            <Spacer spacing={Theme.spacing.s} mode="horizontal" />
            <PressableIcon
              name={rightIconName}
              size={Theme.icons.xl}
              color={iconColor}
              onPress={onPressIconRight}
            />
          </>
        ) : null}
      </Flex>
    </View>
  );
};

const Styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    justifyContent: 'center',
    borderRadius: Theme.radius.default,
    paddingHorizontal: Theme.spacing.l,
    minHeight: 50,
  },
  innerContainer: {height: '100%'},
});

export default Field;
