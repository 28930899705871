import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import Theme from '@config/theme';

import Block from '@components/Block';
import Box from '@components/Box';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import Input from '@components/Input';
import Loader from '@components/Loader';
import Popup from '@components/Popup';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import {useApi} from '@services/pacoApi';
import {useMessagePopup} from '@services/popups';

import {AuthActions, useAppDispatch} from '../../reducers';

import ActivateAccountErrorPopup from './ActivateAccountErrorPopup';
YupPassword(yup);

type FormValues = {
  newPassword: string;
  confirmPassword: string;
  marketingOptin: boolean;
};

type Props = {
  code: string;
  email: string;
};
const ActivateAccountForm: React.FC<Props> = ({code, email}) => {
  const {t} = useTranslation();
  const api = useApi();
  const dispatch = useAppDispatch();
  const [showActivateAccountErrorPopup, setShowActivateAccountErrorPopup] =
    React.useState(false);

  const {
    props: activateAccountSuccessPopupProps,
    show: showActivateAccountSuccessPopup,
  } = useMessagePopup({
    title: t('activate-account-success-popup-title'),
    message: t('activate-account-success-popup-message'),
    buttonTitle: t('activate-account-success-popup-button'),
  });

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        newPassword: yup
          .string()
          .min(8, t('new-password-too-short'))
          .max(128, t('new-password-too-long'))
          .minLowercase(1, t('new-password-needs-lowercase'))
          .minUppercase(1, t('new-password-needs-uppercase'))
          .minNumbers(1, t('new-password-needs-digit'))
          .minSymbols(1, t('new-password-needs-symbol'))
          .required(t('new-password-required')),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('newPassword'), ''], t('passwords-not-match'))
          .required(t('confirm-password-required')),
      }),
    [t],
  );

  const {values, errors, touched, setFieldValue, handleSubmit, handleBlur} =
    useFormik<FormValues>({
      initialValues: {
        newPassword: '',
        confirmPassword: '',
        marketingOptin: false,
      },
      validationSchema,
      validateOnChange: false,
      onSubmit: () => {
        submitPassword();
      },
    });

  const {
    isLoading: isLoadingActivateAccount,
    isSuccess: isSuccessActivateAccount,
    data: apiResponseActivateAccount,
    isError: isErrorActivateAccount,
    error: apiErrorActivateAccount,
    mutate: submitPassword,
  } = useMutation(async () => {
    return api.Authentication.v1AuthenticationActivateAccountPost({
      email: email,
      token: code,
      newPassword: values.newPassword,
      newPasswordConfirm: values.confirmPassword,
      marketingOptin: values.marketingOptin,
    });
  });

  React.useEffect(() => {
    if (isErrorActivateAccount) {
      setShowActivateAccountErrorPopup(true);
    }
  }, [isErrorActivateAccount, apiErrorActivateAccount]);

  React.useEffect(() => {
    if (
      isSuccessActivateAccount &&
      apiResponseActivateAccount?.data.accessToken
    ) {
      showActivateAccountSuccessPopup({
        onClose: () => {
          dispatch(AuthActions.setTicket(apiResponseActivateAccount.data));
        },
      });
    }
  }, [
    dispatch,
    showActivateAccountSuccessPopup,
    isSuccessActivateAccount,
    apiResponseActivateAccount,
  ]);
  return (
    <>
      <Block title={t('activate-account-screen-title')}>
        <Box padding={Theme.spacing.l}>
          <Input
            value={values.newPassword}
            onChangeText={value => setFieldValue('newPassword', value)}
            secureTextEntry={true}
            placeholder={t('login-password')}
            error={touched.newPassword ? errors.newPassword : undefined}
            onSubmitEditing={() => handleSubmit()}
            onBlur={handleBlur('newPassword')}
            rightIconName={'Password'}
          />
          <Text variant="Label">{t('password-rules')}</Text>
          <Spacer spacing={Theme.spacing.l} />
          <Input
            value={values.confirmPassword}
            onChangeText={value => setFieldValue('confirmPassword', value)}
            secureTextEntry={true}
            placeholder={t('confirm-password')}
            error={touched.confirmPassword ? errors.confirmPassword : undefined}
            onSubmitEditing={() => handleSubmit()}
            onBlur={handleBlur('confirmPassword')}
            rightIconName={'Password'}
          />
          <Spacer spacing={Theme.spacing.l} />
          <Checkbox
            label={t('marketing-label')}
            checked={values.marketingOptin}
            onPress={value => setFieldValue('marketingOptin', value)}
          />
          <Spacer spacing={Theme.spacing.xl} />
          <Button
            title={t('save-password-button')}
            onPress={() => handleSubmit()}
          />
        </Box>
      </Block>
      <ActivateAccountErrorPopup
        visible={showActivateAccountErrorPopup}
        close={() => setShowActivateAccountErrorPopup(false)}
        error={apiErrorActivateAccount}
        email={email}
      />
      <Popup {...activateAccountSuccessPopupProps} />
      <Loader isLoading={isLoadingActivateAccount} />
    </>
  );
};

export default ActivateAccountForm;
