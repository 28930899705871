import * as React from 'react';
import {Image} from 'react-native';
import {createIconSetFromIcoMoon} from 'react-native-vector-icons';

import icoMoonConfig from '../../assets/icomoon/selection.json';
import MultiColorIcons from '../../assets/multi_color_icons';
import Theme from '../config/theme';

const BaseIcon = createIconSetFromIcoMoon(icoMoonConfig);

const multiColorIcons = Object.keys(MultiColorIcons);
export interface Props {
  size?: number;
  width?: number;
  height?: number;
  color?: string;
  name: string;
  testID?: string;
}
const Icon: React.FC<Props> = ({
  size = Theme.icons.l,
  width,
  height,
  color = Theme.colors.primary,
  name,
  testID,
}) => {
  if (multiColorIcons.includes(name)) {
    return (
      <Image
        testID={testID}
        source={MultiColorIcons[name as keyof typeof MultiColorIcons]}
        style={{
          width: width || size,
          height: height || size,
        }}
        resizeMode="contain"
      />
    );
  }
  return (
    <BaseIcon
      testID={testID}
      size={size}
      selectable={false}
      color={color}
      name={name}
    />
  );
};

export default Icon;
