import * as React from 'react';

import usePacoApiError from '@services/usePacoApiError';

import Loader from '../components/Loader';
import Popup from '../components/Popup';
import {
  AppActions,
  MemberActions,
  useAppDispatch,
  useAppSelector,
} from '../reducers';
import {getAppLanguageFromCulture} from '../services/helpers/languageHelper';
import {useApi} from '../services/pacoApi';
import {useQuery} from '../services/queries';

const DriverInfoGate: React.FC = ({children}) => {
  const {ticket, driverInfo} = useAppSelector(state => {
    return {
      ticket: state.Auth.ticket,
      driverInfo: state.Member.driverInfo,
    };
  });
  const userType = driverInfo?.userType;
  const dispatch = useAppDispatch();
  const api = useApi();
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError();
  const {
    data: driverInfoResponse,
    isSuccess: isFetchingDriverInfoSuccess,
    refetch: refetchDriverInfo,
    isLoading: isDriverInfoLoading,
    error: driverFetchError,
    isError: isDriverFetchError,
  } = useQuery(
    'driverInfo',
    React.useCallback(async () => {
      return api.Drivers.v1DriversInfoGet();
    }, [api]),
    {
      enabled: false,
    },
  );

  React.useEffect(() => {
    if (isFetchingDriverInfoSuccess && driverInfoResponse?.data) {
      dispatch(MemberActions.setDriverInfo(driverInfoResponse.data));
      dispatch(
        AppActions.setAppLocale(
          getAppLanguageFromCulture(driverInfoResponse.data.culture),
        ),
      );
    }
  }, [driverInfoResponse, isFetchingDriverInfoSuccess, dispatch]);

  React.useEffect(() => {
    if (isDriverFetchError) {
      handleError(driverFetchError);
    }
  }, [driverFetchError, isDriverFetchError, handleError]);

  React.useEffect(() => {
    if (ticket) {
      refetchDriverInfo();
    }
  }, [ticket, refetchDriverInfo]);
  return (
    <>
      {!ticket || (isFetchingDriverInfoSuccess && userType) ? children : null}
      <Loader isLoading={isDriverInfoLoading} />
      <Popup {...errorPopupProps} />
    </>
  );
};

export default DriverInfoGate;
