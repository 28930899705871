import globalAxios from 'axios';
import {
  getManufacturer,
  getVersion,
  getSystemName,
  getSystemVersion,
  getDeviceId,
  getUniqueId,
} from 'react-native-device-info';

import appConfig from '../../config/appConfig';
import {
  AuthActions,
  MemberActions,
  useAppSelector,
  store,
} from '../../reducers';

import {
  AuthenticationApi,
  AdminApi,
  Configuration,
  DriversApi,
  PacoDriverFrontOfficeApiApi,
  ParkingsApi,
  ReservationsApi,
  SpaceReleasesApi,
  VehiclesApi,
  ConfigurationApi,
  CompaniesApi,
  SsoApi,
  MarketingOptinApi,
} from './generated';

globalAxios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequestConfig = error.config;
    const state = store.getState();
    const basePath = state.App.apiUrl;
    const ticket = state.Auth.ticket;

    if (
      error.response.status === 401 &&
      !originalRequestConfig._hasRetried &&
      ticket
    ) {
      delete originalRequestConfig.headers.Authorization;
      originalRequestConfig._hasRetried = true;

      const authenticationApi = new AuthenticationApi(
        new Configuration({
          basePath,
          baseOptions: {
            // prevetent having an infnite loop if we recieve a 401 error while refreshing the token
            _hasRetried: true,
          },
        }),
      );

      let response;
      try {
        response = await authenticationApi.v1AuthenticationRefreshPost({
          accessToken: ticket.accessToken?.toString() || '',
          refreshToken: ticket.refreshToken?.toString() || '',
          identityToken: ticket.identityToken?.toString() || '',
        });
      } catch (_) {
        //
      }

      if (response?.data?.accessToken) {
        store.dispatch(AuthActions.setTicket(response.data));
        originalRequestConfig.headers.Authorization = `Bearer ${response.data.accessToken}`;
        return await globalAxios(originalRequestConfig);
      } else {
        store.dispatch(AuthActions.clearTicket());
        store.dispatch(MemberActions.clearDriverInfo());
      }
    }

    throw error;
  },
);

export async function setCustomUserAgent(): Promise<void> {
  const manufacturer = await getManufacturer();
  const customUserAgent = `Zenpark Paco Mobile/${getVersion()} ${getSystemName()} ${getSystemVersion()} - ${manufacturer} ${getDeviceId()} - #${getUniqueId()}`;

  globalAxios.defaults.headers['User-Agent'] = customUserAgent;
}

export interface PacoApi {
  Authentication: AuthenticationApi;
  Admin: AdminApi;
  Configuration: ConfigurationApi;
  Home: PacoDriverFrontOfficeApiApi;
  Parkings: ParkingsApi;
  Reservations: ReservationsApi;
  Drivers: DriversApi;
  Vehicles: VehiclesApi;
  SpaceReleases: SpaceReleasesApi;
  Companies: CompaniesApi;
  Sso: SsoApi;
  MarketingOptin: MarketingOptinApi;
}

export const useApi = (): PacoApi => {
  const {url, token, appLanguage} = useAppSelector(state => ({
    url: state.App.apiUrl,
    token: state.Auth.ticket?.accessToken,
    appLanguage: state.App.appLanguage,
  }));

  const headers: Record<string, string> = {
    'Accept-Language': appLanguage,
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config = new Configuration({
    basePath: url,
    baseOptions: {
      headers,
      timeout: appConfig.apiTimeout,
    },
  });

  return {
    Authentication: new AuthenticationApi(config),
    Admin: new AdminApi(config),
    Configuration: new ConfigurationApi(config),
    Home: new PacoDriverFrontOfficeApiApi(config),
    Parkings: new ParkingsApi(config),
    Reservations: new ReservationsApi(config),
    Drivers: new DriversApi(config),
    Vehicles: new VehiclesApi(config),
    SpaceReleases: new SpaceReleasesApi(config),
    Companies: new CompaniesApi(config),
    Sso: new SsoApi(config),
    MarketingOptin: new MarketingOptinApi(config),
  };
};
