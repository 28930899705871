import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ActivityIndicator} from 'react-native';
import {useQuery} from 'react-query';

import Theme from '@config/theme';

import {RootNavigationProps} from '@common/navigators';
import SsoServices from '@common/services/sso';
import LoginLayout from '@common/shared_layouts/LoginLayout';

import Block from '@components/Block';
import Box from '@components/Box';
import Popup from '@components/Popup';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import {useApi} from '@services/pacoApi';
import usePacoApiError from '@services/usePacoApiError';

const SsoCompanyLogin: React.FC<RootNavigationProps<'SsoCompanyLogin'>> = ({
  navigation: {navigate},
  route,
}) => {
  const {ssoProvider} = route.params;
  const {t} = useTranslation();
  const api = useApi();
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError();

  const {
    isSuccess,
    data: apiResponse,
    isError,
    error: apiError,
  } = useQuery(
    'SsoConfig',
    async () => {
      return api.Sso.ssoProviderConfigGet(ssoProvider);
    },
    {
      enabled: true,
    },
  );

  React.useEffect(() => {
    if (isSuccess && apiResponse?.data) {
      SsoServices.authorize(apiResponse.data);
    }
  }, [isSuccess, apiResponse, navigate]);

  React.useEffect(() => {
    if (isError) {
      handleError(apiError);
    }
  }, [isError, apiError, handleError]);

  return (
    <LoginLayout>
      <Block title={t('sso-company-login-screen-title')}>
        <Box padding={Theme.spacing.xxxl}>
          <ActivityIndicator color={Theme.colors.primary} />
          <Spacer spacing={Theme.spacing.xl} />
          <Text align="center">{t('sso-company-login-description')}</Text>
        </Box>
      </Block>

      <Popup {...errorPopupProps} />
    </LoginLayout>
  );
};

export default SsoCompanyLogin;
