import Storage from './storage';

export type Storage = {
  getItem(key: string): Promise<string | null>;

  setItem(key: string, item: string): Promise<void>;

  removeItem(key: string): Promise<void>;
  clear: (callback: () => void) => void;
};

export default Storage as Storage;
