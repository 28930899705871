import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Image, StyleSheet} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import images from '@assets/images';

import theme from '@config/theme';

import {RootNavigationProps} from '@common/navigators';
import {AppActions, useAppDispatch, useAppSelector} from '@common/reducers';

import Flex from '@components/Flex';
import PushNotificationRequestView from '@components/PushNotificationRequestView';

import {UserType} from '@services/pacoApi';

const PushNotificationRequest: React.FC<
  RootNavigationProps<'PushNotificationRequest'>
> = ({navigation}) => {
  const dispatch = useAppDispatch();
  const {ticket, driverInfo} = useAppSelector(state => {
    return {
      ticket: state.Auth.ticket,
      driverInfo: state.Member.driverInfo,
    };
  });
  const userType = driverInfo?.userType;
  const {t} = useTranslation();

  const getRedirectionRoute = () => {
    if (!ticket) {
      return 'Login';
    }
    if (userType === UserType.Admin) {
      return 'Admin';
    }
    return 'Home';
  };
  const handleFinishPress = () => {
    dispatch(AppActions.pushNotificationRequestFinished());
    const route = getRedirectionRoute();
    navigation.replace(route);
  };

  return (
    <SafeAreaView style={theme.commonStyles.flex1}>
      <Flex justifyContent="center" alignItems="center">
        <Image source={images['zenpark-flex-logo']} style={Styles.slideImage} />
      </Flex>
      <PushNotificationRequestView
        onFinishPress={handleFinishPress}
        labelCTA={t('push-notification-request-confirm-button')}
      />
    </SafeAreaView>
  );
};

const Styles = StyleSheet.create({
  slideImage: {
    height: 100,
    width: 100,
    resizeMode: 'contain',
  },
});
export default PushNotificationRequest;
