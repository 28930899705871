import {CommonActions} from '@react-navigation/native';
import * as React from 'react';
import {Platform} from 'react-native';
import {getVersion, getBuildNumber} from 'react-native-device-info';

import appConfig from '@config/appConfig';

import {getNavigationContainer} from '@common/navigators';

import AppInfo from '../../../package.json';
import CodePush, {LocalPackage} from '../updates/codepush';

export const restartApp = (): void => {
  if (Platform.OS === 'web') {
    location.reload();
  } else {
    CodePush.restartApp();
  }
};

const useWebAppVersion = (): string => {
  return AppInfo.version;
};

const useMobappVersion = () => {
  const [versionDetails, setVersionDetails] = React.useState<LocalPackage>();

  React.useEffect(() => {
    const getMetaData = async () => {
      const metadata = await CodePush.getUpdateMetadata();
      if (metadata) {
        setVersionDetails(metadata);
      }
    };

    getMetaData();
  }, []);

  return `${getVersion()} (${getBuildNumber()}) ${
    versionDetails ? `- ${versionDetails.label}` : ''
  }`;
};

export const useAppVersion =
  Platform.OS === 'web' ? useWebAppVersion : useMobappVersion;

export const openPrivacyPolicy = () => {
  if (Platform.OS === 'web') {
    window.open(`${appConfig.webBaseUrl}/privacy`, '_blank');
  } else {
    getNavigationContainer()?.dispatch(
      CommonActions.navigate({name: 'PrivacyPolicy'}),
    );
  }
};

export const openTermsAndConditions = () => {
  if (Platform.OS === 'web') {
    window.open(`${appConfig.webBaseUrl}/terms`, '_blank');
  } else {
    getNavigationContainer()?.dispatch(
      CommonActions.navigate({name: 'TermsAndConditions'}),
    );
  }
};
