import {useNavigation} from '@react-navigation/native';
import testIDs from '@testIDs';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable} from 'react-native';

import Block from '../../components/Block';
import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Icon from '../../components/Icon';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import Theme from '../../config/theme';
import {HomeNavigationProps} from '../../navigators';

type MenuItem = {
  hidden?: boolean;
  title: string;
  iconName?: string;
  onPress: () => void;

  testID?: string;
};

const SectionsBlock: React.FC = () => {
  const {navigate} =
    useNavigation<HomeNavigationProps<'Account'>['navigation']>();
  const {t} = useTranslation();
  const accountList: MenuItem[] = React.useMemo(
    () => [
      {
        title: t('account-list-informations'),
        iconName: 'Profile',
        onPress: () => navigate('Informations'),
        testID: testIDs.account.informations,
      },
      {
        title: t('account-list-vehicles'),
        testID: testIDs.account.myVehicles,
        iconName: 'MyVehicles',
        onPress: () => navigate('Vehicles'),
      },
    ],
    [navigate, t],
  );

  return (
    <Block title={t('account')}>
      <Box padding={Theme.spacing.l}>
        <Flex>
          {accountList.map((item, index) => (
            <React.Fragment key={index}>
              <MenuItem item={item} />
              {index !== accountList.length - 1 && (
                <Spacer spacing={Theme.spacing.l} />
              )}
            </React.Fragment>
          ))}
        </Flex>
      </Box>
    </Block>
  );
};

const MenuItem: React.FC<{item: MenuItem}> = ({item}) => {
  return (
    <Pressable testID={item.testID} onPress={item.onPress}>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Flex direction="row" alignItems="center">
          {item.iconName && (
            <Icon
              name={item.iconName}
              size={Theme.icons.xl}
              color={Theme.colors.callToAction}
            />
          )}
          <Spacer mode="horizontal" spacing={Theme.spacing.l} />
          <Text>{item.title}</Text>
        </Flex>
        <Icon
          name="Next"
          size={Theme.icons.l}
          color={Theme.colors.callToAction}
        />
      </Flex>
    </Pressable>
  );
};

export default SectionsBlock;
