import * as React from 'react';
import {Platform} from 'react-native';

import {useAppSelector} from '@common/reducers';

import {useDeeplinksRedirectionEffect} from '@services/deeplinks';
import {getDeviceType} from '@services/helpers/deviceHelper';
import {UserType} from '@services/pacoApi';

import Header from '../Header';
import Navbar from '../Navbar';

import {renderAdminScreens} from './renderAdminScreens';
import {renderCommonScreens} from './renderCommonScreens';
import {renderDriverScreens} from './renderDriverScreens';
import {renderUnauthenticatedScreens} from './renderUnauthenticatedScreens';
import {RootParamList} from './type';
import {RootStack, useScreenTitle} from './utils';

const Root: React.FC = () => {
  const getTitle = useScreenTitle();

  const {
    ticket,
    userType,
    shouldDisplayTutorial,
    shouldDisplayPushNotificationRequest,
  } = useAppSelector(state => {
    return {
      ticket: state.Auth.ticket,
      userType: state.Member.driverInfo?.userType,
      shouldDisplayTutorial: state.App.shouldDisplayTutorial,
      shouldDisplayPushNotificationRequest:
        state.App.shouldDisplayPushNotificationRequest,
    };
  });

  const isDriver = Boolean(ticket) && userType === UserType.Driver;
  const isAdmin = Boolean(ticket) && userType === UserType.Admin;
  const isUnauthenticated = !ticket;

  const getInitialRoute = (): keyof RootParamList => {
    if (Platform.OS !== 'web' && shouldDisplayTutorial) {
      return 'OnBoarding';
    }
    if (Platform.OS !== 'web' && shouldDisplayPushNotificationRequest) {
      return 'PushNotificationRequest';
    }
    if (!ticket) {
      return 'Login';
    }
    if (userType === UserType.Admin) {
      return 'Admin';
    }
    return 'Home';
  };

  useDeeplinksRedirectionEffect();

  return (
    <>
      {ticket && <Navbar />}
      <RootStack.Navigator
        headerMode="screen"
        mode="modal"
        screenOptions={{
          headerShown: getDeviceType() !== 'web-desktop',
          animationEnabled: true,
          header: Header,
        }}
        initialRouteName={getInitialRoute()}>
        {isDriver && renderDriverScreens(RootStack, getTitle)}
        {isAdmin && renderAdminScreens(RootStack, getTitle)}
        {isUnauthenticated && renderUnauthenticatedScreens(RootStack, getTitle)}
        {renderCommonScreens(RootStack, getTitle)}
      </RootStack.Navigator>
    </>
  );
};

export default Root;
