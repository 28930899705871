import testIDs from '@testIDs';
import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Theme from '@config/theme';

import {RootNavigationProps} from '@common/navigators';
import {
  MemberActions,
  PlanningActions,
  useAppDispatch,
  useAppSelector,
} from '@common/reducers';
import ReservationLayout from '@common/shared_layouts/ReservationLayout';
import {MobileReservationLayoutRef} from '@common/shared_layouts/ReservationLayout/MobileReservationLayout';

import AlertMessage from '@components/AlertMessage';
import Block from '@components/Block';
import Box from '@components/Box';
import Checkbox from '@components/Checkbox';
import {DMY} from '@components/DatePicker/utils';
import Flex from '@components/Flex';
import Loader from '@components/Loader';
import ParkingSelector from '@components/ParkingSelector';
import {getDefaultParkingIndex} from '@components/ParkingSelector/utils';
import PeriodSelector from '@components/PeriodSelector';
import Popup from '@components/Popup';
import Footer from '@components/ReservationFooter';
import RulesView from '@components/RulesView';
import Spacer from '@components/Spacer';
import Text from '@components/Text';
import {HM} from '@components/TimePicker/utils';
import VehicleSelector from '@components/VehicleSelector';

import {
  getLocalDateTime,
  toLocaleISOStringTime,
} from '@services/helpers/dateHelper';
import {getDeviceType} from '@services/helpers/deviceHelper';
import {
  CompanyAlertMessage,
  SpaceAllocationType,
  useApi,
  Vehicle,
} from '@services/pacoApi';
import {useMessagePopup} from '@services/popups';
import {queryClient, useMutation} from '@services/queries';
import usePacoApiError from '@services/usePacoApiError';

import {FormValues, validationSchema} from './utils';

interface Props extends RootNavigationProps<'NewReservationForm'> {
  vehicles: Vehicle[];
  defaultDate: DMY;
  defaultReservationPeriod?: {from: HM; to: HM};
  isDriverPrm: boolean;
  alertMessage?: CompanyAlertMessage | null;
}
const Container: React.FC<Props> = props => {
  const {
    vehicles,
    isDriverPrm,
    defaultDate,
    defaultReservationPeriod,
    alertMessage,
    navigation,
  } = props;
  const {lastUsedVehicleId, parkings, lastUsedParkingId} = useAppSelector(
    state => {
      return {
        lastUsedVehicleId: state.Member.lastUsedVehicleId,
        parkings:
          state.Member.driverInfo?.parkings?.filter(
            p => p.spaceAllocationType === SpaceAllocationType.Priority,
          ) || [],
        lastUsedParkingId: state.Member.lastUsedParkingId,
      };
    },
  );

  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const appLanguage = useAppSelector(state => state.App.appLanguage);

  const layoutRef = React.useRef<MobileReservationLayoutRef>(null);
  const api = useApi();
  const {popupProps: errorPopupProps, handle: showError} = usePacoApiError();
  const {props: successPopupProps, show: showSuccess} = useMessagePopup({
    testID: testIDs.newReservation.form.successPopup,
    onClose: () => navigation.goBack(),
  });
  const formik = useFormik<FormValues>({
    initialValues: {
      isEv: false,
      isPrmSpaceRequired: isDriverPrm,
      selectedParking: getDefaultParkingIndex(parkings, lastUsedParkingId),
    },
    validationSchema,
    onSubmit: () => {
      submit();
    },
  });
  const {values, setFieldValue} = formik;
  const {
    isError,
    isSuccess,
    isLoading,
    error,
    data: response,
    mutate: submit,
  } = useMutation(async () =>
    api.Reservations.v1ReservationsPost({
      parkingId:
        values.selectedParking != null
          ? parkings[values.selectedParking].id
          : undefined,
      localFrom: toLocaleISOStringTime(
        getLocalDateTime(values.fromDate, values.fromHour),
      ) as string,
      localTo: toLocaleISOStringTime(
        getLocalDateTime(values.toDate, values.toHour),
      ) as string,
      spaceType:
        values.selectedVehicle != null
          ? vehicles[values.selectedVehicle].type
          : undefined,
      chargingPointRequired: values.isEv,
      prmSpaceRequired: values.isPrmSpaceRequired,
      vehicleId:
        values.selectedVehicle != null
          ? vehicles[values.selectedVehicle].vehicleId
          : undefined,
    }),
  );

  const {data: apiResponse, mutate: evaluateRules} = useMutation(async () => {
    if (
      values.selectedParking == null ||
      values.selectedParking === undefined
    ) {
      return;
    }
    return api.Parkings.v1ParkingsParkingIdEvaluateRulesGet(
      parkings[values.selectedParking].id as string,
      getLocalDateTime(values.fromDate, values.fromHour)?.toJSON(),
      getLocalDateTime(values.toDate, values.toHour)?.toJSON(),
    );
  });

  const rules = apiResponse?.data ?? [];

  React.useEffect(() => {
    queryClient.resetQueries('');
    evaluateRules();
  }, [
    values.fromDate,
    values.fromHour,
    values.toDate,
    values.toHour,
    values.selectedParking,
    evaluateRules,
  ]);

  React.useEffect(() => {
    if (!isSuccess || values.selectedVehicle == null) {
      return;
    }
    const {vehicleId} = vehicles[values.selectedVehicle];

    if (vehicleId && vehicleId !== lastUsedVehicleId) {
      dispatch(MemberActions.setLastUsedVehicleId(vehicleId));
    }
  }, [values, vehicles, isSuccess, dispatch, lastUsedVehicleId]);

  React.useEffect(() => {
    if (!isSuccess || values.selectedParking == null) {
      return;
    }
    const {id} = parkings[values.selectedParking];

    if (id && id !== lastUsedParkingId) {
      dispatch(MemberActions.setLastUsedParkingId(id));
    }
  }, [values, parkings, isSuccess, dispatch, lastUsedParkingId]);

  React.useEffect(() => {
    if (isError) {
      showError(error);
      dispatch(PlanningActions.setShouldReloadPlanning(true));
    }

    if (isSuccess && response?.data) {
      showSuccess({
        message: response?.data?.message,
        title: response?.data?.title,
      });
      dispatch(PlanningActions.setShouldReloadPlanning(true));
    }
  }, [isError, isSuccess, error, response, showError, showSuccess, dispatch]);

  return (
    <ReservationLayout
      ref={layoutRef}
      Footer={
        <Footer
          testIDs={{
            submit: testIDs.newReservation.form.submit,
          }}
          rules={rules}
          onSubmit={() => formik.handleSubmit()}
          onSubmitInvalid={() => layoutRef.current?.scrollToBottom()}
          canSubmit={
            rules.every(rule => rule.success) && values.selectedVehicle != null
          }
          submitButtonLabel={t('new-reservation-form-confirm-button')}
        />
      }>
      <Flex style={Theme.commonStyles.flex1}>
        <Text variant="Medium">{t('new-reservation-form-screen-title')}</Text>
        <Spacer spacing={Theme.spacing.m} />
        {alertMessage && (
          <>
            <AlertMessage
              level={alertMessage.level}
              message={
                appLanguage === 'fr'
                  ? alertMessage.contentFr
                  : alertMessage.contentEn
              }
            />
            <Spacer spacing={Theme.spacing.m} />
          </>
        )}
        <Block style={Theme.commonStyles.flex1}>
          <Box padding={Theme.spacing.l}>
            {parkings.length > 1 && (
              <ParkingSelector
                parkings={parkings}
                formik={formik}
                testID={testIDs.newReservation.form.parkingSelector}
              />
            )}
            <Spacer spacing={Theme.spacing.s} />
            <PeriodSelector
              testIDs={{
                timePickerTo: testIDs.newReservation.form.timePickerTo,
                timePickerFrom: testIDs.newReservation.form.timePickerFrom,
              }}
              value={formik.values}
              onChange={formik.setFieldValue}
              defaultDate={defaultDate}
              defaultReservationPeriod={defaultReservationPeriod}
            />
            <VehicleSelector
              vehicles={vehicles}
              value={values.selectedVehicle}
              onVehicleChange={index => {
                setFieldValue('selectedVehicle', index);
                if (index != null) {
                  setFieldValue('isEv', vehicles[index]?.isEv);
                }
              }}
            />
            {values.selectedVehicle != null && vehicles.length > 0 && (
              <>
                <Spacer spacing={Theme.spacing.xs} />
                <Checkbox
                  disabled={!vehicles[values.selectedVehicle].isEv}
                  label={t('new-reservation-form-charging-point')}
                  checked={values.isEv}
                  onPress={value => setFieldValue('isEv', value)}
                />
              </>
            )}
            {isDriverPrm && (
              <>
                <Spacer spacing={Theme.spacing.s} />
                <Checkbox
                  label={t('new-reservation-form-prm-space')}
                  checked={values.isPrmSpaceRequired}
                  onPress={value => setFieldValue('isPrmSpaceRequired', value)}
                />
              </>
            )}
          </Box>
        </Block>
      </Flex>
      {getDeviceType() !== 'web-desktop' ? (
        <>
          <Spacer spacing={Theme.spacing.xl} />
          <Block>
            <RulesView rules={rules} />
          </Block>
          <Spacer spacing={Theme.spacing.m} />
        </>
      ) : null}
      <Loader
        isLoading={isLoading}
        title={t('new-reservation-form-submit-loader-title')}
      />
      <Popup {...successPopupProps} />
      <Popup {...errorPopupProps} />
    </ReservationLayout>
  );
};

export default Container;
