import {useNavigation} from '@react-navigation/native';
import testIDs from '@testIDs';
import {DateTime} from 'luxon';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet} from 'react-native';

import Theme from '@config/theme';

import {HomeNavigationProps} from '@common/navigators';

import Box from '@components/Box';
import Button from '@components/Button';
import {parseDateTime} from '@components/DatePicker/utils';
import Flex from '@components/Flex';
import Icon from '@components/Icon';
import PopoverComponent, {
  POPOVER_BACKDROP_COLOR,
} from '@components/PopoverComponent';
import PressableIcon from '@components/PressableIcon';
import Spacer from '@components/Spacer';
import Text from '@components/Text';
import {getIconName} from '@components/VehicleForm/VehicleTypeSelector/utils';

import {parseEndStr, parseStartStr} from '@services/helpers/dateHelper';
import {getDeviceType} from '@services/helpers/deviceHelper';
import {useVehicleTypeLabel} from '@services/helpers/vehicleHelper';
import {PlanningItem, SpaceTypes} from '@services/pacoApi';

interface Props {
  dropdownVisible: boolean;
  setDropdownVisible: (value: boolean) => void;
  target: Element | null;
  allocation: PlanningItem;
  needsToShowParking: boolean;
  canBeUpdated: boolean;
  day: DateTime;
}

const AllocationInformation: React.FC<Props> = ({
  dropdownVisible,
  setDropdownVisible,
  target,
  allocation,
  needsToShowParking,
  canBeUpdated,
  day,
}) => {
  const startStr = parseStartStr(
    parseDateTime(day),
    allocation.localFrom as string,
  );
  const endStr = parseEndStr(parseDateTime(day), allocation.localTo);
  const {t} = useTranslation();
  const navigation =
    useNavigation<HomeNavigationProps<'Planning'>['navigation']>();

  const getVehicleTypeLabel = useVehicleTypeLabel();

  const iconName = getIconName(allocation.spaceType);

  const getVehicleInfo = () => {
    let result = `${allocation.vehicleName}`;

    if (
      allocation.vehicleRegistrationPlate != null &&
      allocation.vehicleRegistrationPlate !== ''
    ) {
      result += ` (${allocation.vehicleRegistrationPlate})`;
    }

    return result;
  };

  const getSpaceTypeInfo = () => {
    let result = t('allocation-item-info-vehicle-type', {
      vehicleType: getVehicleTypeLabel(allocation.spaceType as SpaceTypes),
    });
    if (allocation.chargingPointRequired) {
      result += `, ${t('allocation-item-info-charging-station')}`;
    }
    if (allocation.prmSpaceRequired) {
      result += `, ${t('allocation-item-info-prm')}`;
    }

    return result;
  };

  const capitalizeFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const modalTitle = capitalizeFirst(day.toLocaleString(DateTime.DATE_HUGE));

  return (
    <PopoverComponent
      visible={dropdownVisible}
      close={() => setDropdownVisible(false)}
      target={target as Element | null}
      backdropColor={POPOVER_BACKDROP_COLOR}>
      <View testID={testIDs.allocationInfo.modal} style={[webStyle]}>
        <Box padding={Theme.spacing.xl}>
          <Flex direction="column">
            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Text
                testID={testIDs.allocationInfo.title}
                style={Styles.modalTitle}>
                {modalTitle}
              </Text>
              <PressableIcon
                testID={testIDs.allocationInfo.close}
                name="Unavailable"
                color="black"
                size={32}
                onPress={() => setDropdownVisible(false)}
              />
            </Flex>
            <Spacer mode="vertical" spacing={Theme.spacing.l} />
            <Flex direction="row" alignItems="center">
              <Spacer mode="horizontal" spacing={Theme.spacing.s} />
              <Icon size={24} name="Planning" />
              <Spacer mode="horizontal" spacing={Theme.spacing.xl} />
              <Text testID={testIDs.allocationInfo.dateFrom}>{startStr}</Text>
              <Spacer mode="horizontal" spacing={Theme.spacing.s} />
              <Text testID={testIDs.allocationInfo.dateTo}>{endStr}</Text>
            </Flex>
            {needsToShowParking && (
              <>
                <Spacer mode="vertical" spacing={Theme.spacing.s} />
                <Flex direction="row" alignItems="center">
                  <Spacer mode="horizontal" spacing={Theme.spacing.s} />
                  <Icon size={24} name="ParkingSpot" />
                  <Spacer mode="horizontal" spacing={Theme.spacing.xl} />
                  <Text testID={testIDs.allocationInfo.parking}>
                    {allocation.parkingName}
                  </Text>
                </Flex>
              </>
            )}
            <Spacer mode="vertical" spacing={Theme.spacing.s} />
            <Flex direction="row" alignItems="center">
              <Icon size={21} name={iconName != null ? iconName : 'Parking'} />
              <Spacer mode="horizontal" spacing={Theme.spacing.l} />
              <Flex direction="column" style={Theme.commonStyles.flex1}>
                <Text
                  testID={testIDs.allocationInfo.vehicleInfo}
                  numberOfLines={1}>
                  {getVehicleInfo()}
                </Text>
                <Text testID={testIDs.allocationInfo.spaceTypeInfo}>
                  {getSpaceTypeInfo()}
                </Text>
              </Flex>
            </Flex>
            {canBeUpdated && (
              <Flex alignItems="center">
                <Spacer mode="vertical" spacing={Theme.spacing.xl} />
                <Button
                  testID={testIDs.allocationInfo.update}
                  title={t('update-reservation')}
                  expanded={false}
                  onPress={() => {
                    setDropdownVisible(false);
                    navigation.navigate('EditReservationForm', {
                      allocationId: allocation.allocationId as string,
                    });
                  }}
                />
              </Flex>
            )}
          </Flex>
        </Box>
      </View>
    </PopoverComponent>
  );
};

const webStyle = getDeviceType() === 'web-desktop' ? {width: '350px'} : {};

const Styles = StyleSheet.create({
  modalTitle: {
    fontSize: 18,
    color: 'black',
    fontWeight: '600',
  },
});

export default AllocationInformation;
