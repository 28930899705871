import {PortalHost} from '@gorhom/portal';
import * as React from 'react';
import {ScrollView, ScrollViewProps} from 'react-native';

import Block from '../../../components/Block';
import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Spacer from '../../../components/Spacer';
import Theme from '../../../config/theme';

interface Props {
  showRemote?: boolean;
  refreshControl: ScrollViewProps['refreshControl'];
}
const MobileAccessLayout: React.FC<Props> = ({
  refreshControl,
  showRemote,
  children,
}) => {
  return (
    <Flex
      style={Theme.commonStyles.flex1}
      backgroundColor={Theme.colors.secondaryHover}>
      <ScrollView refreshControl={refreshControl}>
        <Box style={Theme.commonStyles.flex1} padding={Theme.spacing.l}>
          {children}

          <Block>
            <PortalHost name="access-parking-block" />
          </Block>
          <Spacer spacing={Theme.spacing.l} />
          {showRemote && (
            <Block>
              <Box padding={Theme.spacing.l}>
                <PortalHost name="access-remote-block" />
              </Box>
            </Block>
          )}
        </Box>
      </ScrollView>
    </Flex>
  );
};

export default MobileAccessLayout;
