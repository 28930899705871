import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import Block from '../../components/Block';
import Box from '../../components/Box';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Loader from '../../components/Loader';
import Popup from '../../components/Popup';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import Theme from '../../config/theme';
import {RootNavigationProps} from '../../navigators';
import {useApi} from '../../services/pacoApi';
import {useMessagePopup} from '../../services/popups';
import LoginLayout from '../../shared_layouts/LoginLayout';

import ResetPasswordPopup from './ResetPasswordErrorPopup';

YupPassword(yup);

type FormValues = {
  newPassword: string;
  confirmPassword: string;
};

const ResetPassword: React.FC<RootNavigationProps<'ResetPassword'>> = ({
  route,
  navigation,
}) => {
  const {t} = useTranslation();
  const {data} = route.params;
  const api = useApi();
  const {props: successPopupProps, show: showSuccess} = useMessagePopup();
  const [showResetPasswordErrorPopup, setShowResetPasswordErrorPopup] =
    React.useState(false);

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        newPassword: yup
          .string()
          .min(8, t('new-password-too-short'))
          .max(128, t('new-password-too-long'))
          .minLowercase(1, t('new-password-needs-lowercase'))
          .minUppercase(1, t('new-password-needs-uppercase'))
          .minNumbers(1, t('new-password-needs-digit'))
          .minSymbols(1, t('new-password-needs-symbol'))
          .required(t('new-password-required')),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('newPassword'), ''], t('passwords-not-match'))
          .required(t('confirm-password-required')),
      }),
    [t],
  );

  const {values, errors, touched, setFieldValue, handleSubmit, handleBlur} =
    useFormik<FormValues>({
      initialValues: {
        newPassword: '',
        confirmPassword: '',
      },
      validationSchema,
      validateOnChange: false,
      onSubmit: () => {
        resetPassword();
      },
    });

  const {
    isLoading,
    isSuccess,
    data: apiResponse,
    isError,
    error: apiError,
    mutate: resetPassword,
  } = useMutation(async () => {
    return api.Drivers.v1DriversResetPasswordPatch({
      token: data,
      newPassword: values.newPassword,
    });
  });

  React.useEffect(() => {
    if (isError) {
      setShowResetPasswordErrorPopup(true);
    }
  }, [isError, apiError]);

  React.useEffect(() => {
    if (isSuccess && apiResponse?.data) {
      showSuccess({
        message: apiResponse.data.message,
        title: apiResponse.data.title,
        onClose: () => navigation.navigate('Login'),
      });
    }
  }, [isSuccess, apiResponse, navigation, showSuccess]);

  return (
    <LoginLayout>
      <Block title={t('reset-password-screen-title')}>
        <Box padding={Theme.spacing.l}>
          <Input
            value={values.newPassword}
            onChangeText={value => setFieldValue('newPassword', value)}
            secureTextEntry={true}
            placeholder={t('new-password')}
            error={touched.newPassword ? errors.newPassword : undefined}
            onSubmitEditing={() => handleSubmit()}
            onBlur={handleBlur('newPassword')}
            rightIconName={'Password'}
          />
          <Text variant="Label">{t('password-rules')}</Text>
          <Spacer spacing={Theme.spacing.s} />
          <Input
            value={values.confirmPassword}
            onChangeText={value => setFieldValue('confirmPassword', value)}
            secureTextEntry={true}
            placeholder={t('confirm-password')}
            error={touched.confirmPassword ? errors.confirmPassword : undefined}
            onSubmitEditing={() => handleSubmit()}
            onBlur={handleBlur('confirmPassword')}
            rightIconName={'Password'}
          />
          <Spacer spacing={Theme.spacing.l} />
          <Button
            title={t('change-password-button')}
            onPress={() => handleSubmit()}
          />
        </Box>
      </Block>

      <Popup {...successPopupProps} />
      <ResetPasswordPopup
        visible={showResetPasswordErrorPopup}
        close={() => setShowResetPasswordErrorPopup(false)}
        error={apiError}
        data={data}
      />
      <Loader isLoading={isLoading} />
    </LoginLayout>
  );
};

export default ResetPassword;
