import * as React from 'react';

import Loader from '@common/components/Loader';
import {RootNavigationProps} from '@common/navigators';

const SsoWebview: React.FC<RootNavigationProps<'SsoWebview'>> = ({
  navigation,
}) => {
  React.useEffect(() => {
    navigation.navigate('Login');
  }, [navigation]);
  return <Loader isLoading />;
};

export default SsoWebview;
