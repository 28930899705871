import * as React from 'react';
import {Image, StyleSheet} from 'react-native';

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import theme from '../../config/theme';
import {useAppSelector} from '../../reducers';

const PlanningParkingInfo: React.FC = () => {
  const company = useAppSelector(state => state.Member.driverInfo?.company);

  if (!company) {
    return null;
  }

  return (
    <Flex backgroundColor={theme.colors.primary}>
      <Box padding={theme.spacing.l}>
        <Flex direction="row" alignItems="center">
          {company.logoUrl && (
            <Box style={Styles.imageContainer} padding={theme.spacing.s}>
              <Image
                source={{uri: company.logoUrl}}
                style={Styles.image}
                resizeMode="contain"
              />
            </Box>
          )}
          <Spacer mode="horizontal" spacing={theme.spacing.l} />
          <Flex style={theme.commonStyles.flex1}>
            <Text
              variant="Medium"
              color={theme.colors.white}
              style={Styles.title}
              numberOfLines={2}>
              {company.name}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

const Styles = StyleSheet.create({
  title: {
    lineHeight: 28,
  },
  image: {
    width: 84,
    height: 84,
  },
  imageContainer: {
    backgroundColor: theme.colors.white,
    borderRadius: theme.spacing.l,
  },
});

export default PlanningParkingInfo;
