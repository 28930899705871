import {useNavigation} from '@react-navigation/native';
import testIDs from '@testIDs';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Theme from '@config/theme';

import Button from '@components/Button';
import Flex from '@components/Flex';
import Loader from '@components/Loader';
import Popup from '@components/Popup';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import {useApi} from '@services/pacoApi';
import {useMessagePopup} from '@services/popups';
import {useMutation} from '@services/queries';

import {RootNavigationProps} from '../../navigators';

interface Props {
  visible: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  email: string;

  close: () => void;
}
const ActivateAccountErrorPopup: React.FC<Props> = ({
  visible,
  error,
  email,
  close,
}) => {
  const {t} = useTranslation();
  const api = useApi();
  const navigation =
    useNavigation<RootNavigationProps<'ActivateAccount'>['navigation']>();

  const {
    props: resendActivationMailSuccessPopupProps,
    show: showResendActivationMailSuccessPopup,
  } = useMessagePopup({
    title: t('resend-activation-mail-success-popup-title'),
    message: t('resend-activation-mail-success-popup-message'),
  });

  const {
    props: resendActivationMailErrorPopupProps,
    show: showResendActivationMailErrorPopup,
  } = useMessagePopup();
  const body = JSON.stringify(email);
  const {
    isLoading: isLoadingResendActivationMail,
    isSuccess: isSuccessResendActivationMail,
    error: errorResendActivationMail,
    isError: isErrorResendActivationMail,
    mutate: resendActivationMail,
  } = useMutation(async () => {
    return api.Authentication.v1AuthenticationResendActivationMailPost(body);
  });

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorMail: any = errorResendActivationMail;
    if (isErrorResendActivationMail && errorResendActivationMail != null) {
      const isDelayError =
        errorMail?.response?.data?.errorCode === 'RESEND_ACTIVATION_MAIL_DELAY';

      showResendActivationMailErrorPopup({
        title: isDelayError
          ? t('resend-activation-mail-delay-error-popup-title')
          : t('activate-account-generic-error-title'),
        message: isDelayError
          ? t('resend-activation-mail-delay-error-popup-message')
          : t('activate-account-generic-error-message'),
      });
    }
  }, [
    t,
    isErrorResendActivationMail,
    errorResendActivationMail,
    showResendActivationMailErrorPopup,
  ]);

  React.useEffect(() => {
    if (isSuccessResendActivationMail) {
      showResendActivationMailSuccessPopup({});
    }
  }, [isSuccessResendActivationMail, showResendActivationMailSuccessPopup]);

  const isTokenError = error?.response?.data?.errorCode === 'INVALID_TOKEN';

  const title = isTokenError
    ? t('activate-account-token-error-title')
    : t('activate-account-generic-error-title');
  const message = isTokenError
    ? t('activate-account-token-error-message')
    : t('activate-account-generic-error-message');

  return (
    <>
      <Popup
        testID={testIDs.activateAccount.errorPopup}
        title={title}
        visible={visible}
        close={close}>
        <Flex alignItems="center">
          <Text>{message}</Text>
        </Flex>
        <Spacer spacing={Theme.spacing.l} />
        {!isTokenError && (
          <Button
            title={t('activate-account-error-popup-ok')}
            variant="Primary"
            testID={testIDs.activateAccount.errorPopupPrimaryButton}
            onPress={() => {
              close();
              navigation.navigate('Login');
            }}
          />
        )}
        {isTokenError && (
          <Button
            title={t('activate-account-error-popup-new-link')}
            variant="Primary"
            testID={testIDs.activateAccount.errorPopupNewLinkButton}
            onPress={() => {
              close();
              resendActivationMail();
            }}
          />
        )}
      </Popup>
      <Popup {...resendActivationMailSuccessPopupProps} />
      <Popup {...resendActivationMailErrorPopupProps} />
      <Loader isLoading={isLoadingResendActivationMail} />
    </>
  );
};

export default ActivateAccountErrorPopup;
