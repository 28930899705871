import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';

import Flex from '../../../../components/Flex';
import Text from '../../../../components/Text';
import Theme from '../../../../config/theme';

const NoAllocationItem: React.FC = () => {
  const {t} = useTranslation();
  return (
    <Flex
      style={[styles.container, Theme.commonStyles.flex1]}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      backgroundColor={Theme.colors.white}>
      <Text style={styles.text}>{t('planning-no-allocation')}</Text>
    </Flex>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 56,
    width: 200,
    borderWidth: 1,
    paddingHorizontal: Theme.spacing.l,
    borderColor: Theme.colors.border,
    borderRadius: Theme.radius.default,
  },
  text: {
    fontFamily: Theme.fonts.family.InterMedium,
    lineHeight: 20,
    fontSize: 12,
    width: 150,
  },
});

export default NoAllocationItem;
