import * as React from 'react';
import {Platform} from 'react-native';

import Loader from '@common/components/Loader';
import {RootNavigationProps} from '@common/navigators';

const SsoLogin: React.FC<RootNavigationProps<'SsoRedirect'>> = ({
  route: {params},
  navigation: {navigate},
}) => {
  React.useEffect(() => {
    if (Platform.OS === 'web') {
      navigate('SsoValidate', params);
    }
  }, [params, navigate]);

  return <Loader isLoading />;
};

export default SsoLogin;
