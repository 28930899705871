import {DateTime} from 'luxon';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, StyleSheet} from 'react-native';

import Box from '../../components/Box';
import {dateTimeToDMY, DMYToDateTime} from '../../components/DatePicker/utils';
import Flex from '../../components/Flex';
import Icon from '../../components/Icon';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import TextButton from '../../components/TextButton';
import Theme from '../../config/theme';
import {PlanningActions, useAppDispatch, useAppSelector} from '../../reducers';
import {getDeviceType} from '../../services/helpers/deviceHelper';

import PlanningCalendarModal from './PlanningCalendarModal';

const AgendaHeader: React.FC = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const selectedDay = useAppSelector(state => state.Planning.selectedDay);
  const [open, setOpen] = React.useState(false);

  const isMobile = getDeviceType() !== 'web-desktop';
  return (
    <Flex backgroundColor={Theme.colors.white}>
      <Box padding={Theme.spacing.l}>
        <Flex
          direction="row"
          backgroundColor={Theme.colors.white}
          justifyContent="space-between"
          alignItems="center">
          <Pressable
            onPress={() => isMobile && setOpen(true)}
            disabled={!isMobile}>
            <Flex direction="row" alignItems="center">
              <Text variant="Small" style={Styles.month}>{`${DMYToDateTime(
                selectedDay,
              ).toLocaleString({month: 'long'})} ${selectedDay.year}`}</Text>
              <Spacer mode="horizontal" spacing={Theme.spacing.m} />
              <Icon name="Planning" />
            </Flex>
          </Pressable>

          <TextButton
            onPress={() => {
              const now = dateTimeToDMY(DateTime.now());
              dispatch(PlanningActions.setSelectedDay(now));
            }}>
            {t('planning-today')}
          </TextButton>
        </Flex>
        {isMobile && (
          <PlanningCalendarModal visible={open} close={() => setOpen(false)} />
        )}
      </Box>
    </Flex>
  );
};

const Styles = StyleSheet.create({
  month: {
    textTransform: 'capitalize',
  },
});

export default AgendaHeader;
