import {createContext} from 'react';

import type {RemotePackage} from './codepush';

export interface UpdatesContextState {
  status: 'IDLE' | 'SUCCESS' | 'FAIL' | 'SYNC';
  progress: null | number;
  update: RemotePackage | null;
}

export const UpdatesContextInitialState: UpdatesContextState = {
  status: 'IDLE',
  progress: null,
  update: null,
};

export const UpdatesContext = createContext<UpdatesContextState>(
  UpdatesContextInitialState,
);
