import * as React from 'react';
import {View, StyleSheet, ScrollViewProps} from 'react-native';

import Flex from '../../components/Flex';
import Spacer from '../../components/Spacer';
import Theme from '../../config/theme';
import {getFontImageUrl} from '../../services/helpers/ressourceHelper';
import {useResponsiveProperty} from '../../services/responsive';

interface Props extends ScrollViewProps {
  companyIllustrationUrl: string;
  landingPageDescription: string;
}

const WebLandingPageLayout: React.FC<Props> = ({
  companyIllustrationUrl,
  landingPageDescription,
  children,
}) => {
  const introductionResponsiveStyle = useResponsiveProperty({
    sm: {
      width: '0%',
    },
    lg: {
      width: '30%',
    },
  });

  const contentResponsiveStyle = useResponsiveProperty({
    sm: {
      width: '100%',
    },
    md: {
      width: 632,
    },
  });

  return (
    <Flex direction={'row'} style={Theme.commonStyles.flex1}>
      <View style={[Styles.introduction, introductionResponsiveStyle]}>
        <View style={Styles.leftContainer}>
          <Flex direction={'row'} style={Styles.bannerContainer}>
            <img src={getFontImageUrl('flex-logo.svg')} />
            <img
              src={getFontImageUrl('zenpark-flex-logo-white.svg')}
              style={bannerImageStyle.bannerImage}
            />
          </Flex>
          <Spacer spacing={Theme.spacing.xl} />
          <Flex direction={'row'} style={Styles.companyLogoContainer}>
            <img
              src={companyIllustrationUrl}
              style={companyLogoStyle.companyLogo}
            />
          </Flex>
          <Spacer spacing={Theme.spacing.xxxl} />
          <div
            style={landingPageDescriptionStyle.landingPageDescription}
            dangerouslySetInnerHTML={{__html: landingPageDescription}}
          />
        </View>
      </View>
      <View style={Styles.rightContainer}>
        <View style={contentResponsiveStyle}>{children}</View>
      </View>
    </Flex>
  );
};

const bannerImageStyle: Record<'bannerImage', React.CSSProperties> = {
  bannerImage: {
    maxHeight: 20,
  },
};

const companyLogoStyle: Record<'companyLogo', React.CSSProperties> = {
  companyLogo: {
    maxHeight: 200,
    maxWidth: 250,
  },
};

const landingPageDescriptionStyle: Record<
  'landingPageDescription',
  React.CSSProperties
> = {
  landingPageDescription: {
    color: Theme.colors.white,
    fontFamily: Theme.fonts.family.InterMedium,
  },
};

const Styles = StyleSheet.create({
  companyLogoContainer: {
    justifyContent: 'center',
  },
  leftContainer: {
    padding: '15%',
  },
  bannerContainer: {
    maxHeight: 65,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  introduction: {
    width: '0%',
    backgroundColor: Theme.colors.primary,
  },
  rightContainer: {
    flex: 1,
    backgroundColor: Theme.colors.secondaryHover,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default WebLandingPageLayout;
