import * as React from 'react';

import Theme from '../config/theme';

import Box from './Box';
import Flex from './Flex';
import Spacer from './Spacer';
import Text from './Text';

interface Props {
  label?: string | null;
  isSelected?: boolean;
}
const ParkingRow: React.FC<Props> = props => {
  const {label, isSelected} = props;

  return (
    <Box padding={Theme.spacing.m} skipRight skipLeft>
      <Box padding={Theme.spacing.s} skipTop skipBottom skipLeft>
        <Flex direction="row" alignItems="center">
          <Spacer spacing={Theme.spacing.s} mode="horizontal" />
          <Text
            color={
              isSelected ? Theme.colors.callToAction : Theme.colors.primary
            }>
            {label}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default ParkingRow;
