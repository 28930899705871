import {getStateFromPath, getActionFromState} from '@react-navigation/core';
import {Path} from 'path-parser';
import * as React from 'react';
import {Linking, Platform} from 'react-native';

import {getBrazeInitialUrl} from '@services/braze';

import appConfig from '../../config/appConfig';
import {getNavigationContainer} from '../../navigators';
import {useAppSelector} from '../../reducers';
import logger from '../logger';

import {deeplinksConfig, linkingOptions} from './config';

declare global {
  interface Window {
    initialUrl?: string;
  }
}

const normalize = (url: string): string => {
  for (const prefix of appConfig.deeplinkPrefixs) {
    if (prefix != null && url.startsWith(prefix)) {
      return url.slice(prefix.length);
    }
  }
  return url;
};

const _state = {
  mounted: false,
  deeplink: null as string | null,
};

const setDeeplink = (deeplink: string | null): void => {
  if (!deeplink) {
    return;
  }
  _state.deeplink = deeplink;
};

export const useDeeplinksRedirectionEffect = (): void => {
  const isAuthenticated = useAppSelector(state => Boolean(state.Auth.ticket));

  React.useEffect(() => {
    if (!_state.mounted && !isAuthenticated) {
      Linking.getInitialURL().then(setDeeplink);
      getBrazeInitialUrl(setDeeplink);
      if (Platform.OS === 'web' && window?.initialUrl) {
        _state.deeplink = window.initialUrl;
      }
    }

    _state.mounted = true;

    const onReceiveURL = ({url}: {url: string}) => {
      if (!isAuthenticated) {
        _state.deeplink = url;
      }
    };

    Linking.addEventListener('url', onReceiveURL);

    return () => Linking.removeEventListener('url', onReceiveURL);
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (isAuthenticated && _state.deeplink) {
      const deeplink = _state.deeplink;
      _state.deeplink = null;

      const path = normalize(deeplink);
      const config = Object.values(deeplinksConfig).find(conf => {
        return Path.createPath(conf.path).test(path);
      });

      if (!config || !config.authorized) {
        return;
      }

      const link = `/${path}`;
      const navigationState = getStateFromPath(link, linkingOptions.config);

      if (!navigationState) {
        logger.log('Failed to parse the path to a navigation state.', 'Error');
        return;
      }
      const action = getActionFromState(navigationState, linkingOptions.config);

      if (action !== undefined) {
        getNavigationContainer()?.dispatch(action);
      } else {
        getNavigationContainer()?.reset(navigationState);
      }
    }
  }, [isAuthenticated]);
};
