import {
  getNames,
  registerLocale,
  LocalizedCountryNames,
} from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';

import {AppLanguage} from '../../services/i18n';
import Option from '../Select/Option';

export const getCountries = (
  appLanguage: AppLanguage,
): LocalizedCountryNames<{select: 'official'}> => {
  if (appLanguage === 'en') {
    registerLocale(en);
    return getNames('en');
  }

  registerLocale(fr);
  return getNames('fr');
};

export const getOptions = (appLanguage: AppLanguage): Option<string>[] => {
  const countries = getCountries(appLanguage);

  const preferredCountries = ['FR', 'BE', 'DE', 'ES', 'LU', 'NL', 'GB', 'CH'];
  const options: Option<string>[] = [];

  preferredCountries.forEach(countryAlpha2 => {
    const country = countries[countryAlpha2];
    options.push({value: countryAlpha2, label: country});
  });

  for (const countryAlpha2 in countries) {
    if (
      !preferredCountries.find(
        preferredCountryAlpha2 =>
          preferredCountryAlpha2.toLowerCase() === countryAlpha2.toLowerCase(),
      )
    ) {
      const country = countries[countryAlpha2];
      options.push({value: countryAlpha2, label: country});
    }
  }

  return options;
};

export const getSelectedCountryIndex = (
  options: Option<string>[],
  selectedCountryAlpha2: string,
): number => {
  return options.findIndex(
    o => o.value.toLowerCase() === selectedCountryAlpha2.toLowerCase(),
  );
};
