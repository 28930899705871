import * as React from 'react';

import Block from '../../../components/Block';
import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Spacer from '../../../components/Spacer';
import Theme from '../../../config/theme';
import SimpleLayout from '../../../shared_layouts/SimpleLayout';

interface Props {
  title: string;
  Footer: JSX.Element;
}
const WebVehiclesFormLayout: React.FC<Props> = ({title, children, Footer}) => {
  return (
    <SimpleLayout>
      <Block title={title}>
        <Box padding={Theme.spacing.l}>
          {children}
          <Spacer spacing={Theme.spacing.xl} />
          <Flex alignItems="center">{Footer}</Flex>
        </Box>
      </Block>
    </SimpleLayout>
  );
};

export default WebVehiclesFormLayout;
