import {Placement} from '@popperjs/core';
import * as React from 'react';
import {View, Pressable, ScrollView, StyleSheet} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Modifier, usePopper} from 'react-popper';

import Theme from '../config/theme';
import {getDeviceType} from '../services/helpers/deviceHelper';

import Box from './Box';
import Modal from './Modal';
import Text from './Text';

export interface PopoverComponentProps {
  target: Element | null;
  visible: boolean;
  placeholder?: string;
  maxHeight?: number;
  enableSameWidthModifier?: boolean;
  placement?: Placement;
  close: () => void;
  testID?: string;
  backdropColor?: string;
}

export const POPOVER_BACKDROP_COLOR = '#00000033';

const MobilePopoverComponent: React.FC<PopoverComponentProps> = ({
  visible,
  children,
  close,
  placeholder,
  maxHeight,
  testID,
  backdropColor = POPOVER_BACKDROP_COLOR,
}) => {
  return (
    <Modal visible={visible}>
      <View style={Styles.container}>
        <Pressable
          style={[Styles.backdrop, {backgroundColor: backdropColor}]}
          onPress={() => close()}
        />
        <SafeAreaView
          testID={testID}
          style={[Styles.options, {maxHeight}]}
          edges={['bottom']}>
          {placeholder && (
            <Box padding={Theme.spacing.m} skipRight skipLeft>
              <Box padding={Theme.spacing.xl} skipTop skipBottom>
                <Text color={Theme.colors.secondary}>{placeholder}</Text>
              </Box>
            </Box>
          )}
          {children}
        </SafeAreaView>
      </View>
    </Modal>
  );
};

const sameWidthModifier: Modifier<'sameWidth'> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({state}) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({state}) => {
    const {offsetWidth} = state.elements.reference as unknown as {
      offsetWidth: number;
    };
    state.elements.popper.style.width = `${offsetWidth}px`;
  },
};

const WebPopoverComponent: React.FC<PopoverComponentProps> = ({
  visible,
  target,
  close,
  children,
  maxHeight,
  enableSameWidthModifier,
  placement,
  testID,
  backdropColor,
}) => {
  const [popper, setPopper] = React.useState<HTMLDivElement | null>(null);
  const {styles, attributes} = usePopper(target, popper, {
    modifiers: enableSameWidthModifier ? [sameWidthModifier] : undefined,
    placement,
  });
  return (
    <Modal visible={visible}>
      <Pressable
        testID={testID}
        style={[Styles.container, {backgroundColor: backdropColor}]}
        onPress={() => close()}>
        <div ref={setPopper} style={styles.popper} {...attributes.popper}>
          <Pressable onPress={() => null}>
            <View style={[webContainerStyle, {maxHeight}]}>
              <ScrollView>{children}</ScrollView>
            </View>
          </Pressable>
        </div>
      </Pressable>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const webContainerStyle: any = {
  backgroundColor: Theme.colors.white,
  borderRadius: Theme.radius,
  borderWidth: 1,
  overflow: 'hidden',
  borderColor: Theme.colors.border,
  boxShadow: '0 2px 34px -10px rgba(0, 0, 0, 0.12)',
};

const Styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backdrop: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  options: {
    justifyContent: 'flex-end',
    backgroundColor: 'white',
  },
});

const PopoverComponent =
  getDeviceType() === 'web-desktop'
    ? WebPopoverComponent
    : MobilePopoverComponent;

export default PopoverComponent;
