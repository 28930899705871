import * as React from 'react';
import {TouchableOpacity} from 'react-native';

import Theme from '@config/theme';

import Box from '../Box';
import Flex from '../Flex';
import Hoverable from '../Hoverable';
import Icon from '../Icon';
import {RowComponentProps} from '../Select/RowComponent';
import Spacer from '../Spacer';
import VehicleRow from '../VehicleRow';

import {getIconName, NO_ITEMS_VALUE, VehicleOption} from './utils';

const RowComponent: React.FC<RowComponentProps<number>> = props => {
  const {index, isSelected, options, onPress, testID} = props;
  const option = options[index] as VehicleOption;

  if (option.value === NO_ITEMS_VALUE) {
    return null;
  }

  const iconName = getIconName(option.type, option.value);
  return (
    <TouchableOpacity onPress={onPress} testID={testID}>
      <Hoverable
        backgroundColor={Theme.colors.white}
        hoverColor={Theme.colors.secondaryHover}>
        <Box padding={Theme.spacing.l} skipTop skipBottom>
          <Flex direction="row" alignItems="center">
            {option.isEv ? (
              <Icon
                name="Electric"
                size={Theme.icons.l}
                color={
                  isSelected ? Theme.colors.callToAction : Theme.colors.primary
                }
              />
            ) : (
              <Spacer spacing={Theme.icons.l} mode="horizontal" />
            )}
            <VehicleRow
              iconName={iconName}
              label={option.label}
              isSelected={isSelected}
            />
          </Flex>
        </Box>
      </Hoverable>
    </TouchableOpacity>
  );
};

export default RowComponent;
