import React from 'react';
import {useTranslation} from 'react-i18next';
import {Image, View, StyleSheet, ScrollView} from 'react-native';

import images from '@assets/images';

import Theme from '@config/theme';

import Block from '@components/Block';
import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import Spacer from '@components/Spacer';
import Text from '@components/Text';

import {requestMobileNotifications} from '@services/permissions';
import tracker from '@services/tracker';

interface Props {
  onFinishPress: () => void;
  labelCTA: string;
}

const PushNotificationRequestView: React.FC<Props> = ({
  onFinishPress,
  labelCTA,
}) => {
  const handleFinishPress = () => {
    requestMobileNotifications(['alert', 'sound']).then(({status}) => {
      if (status === 'blocked') {
        tracker.trackOptinPushDeclined();
      }
      if (status === 'granted') {
        tracker.trackOptinPushAccepted();
      }
      onFinishPress();
    });
  };

  const {t} = useTranslation();
  return (
    <Box
      skipTop
      style={[Styles.slideContainer, Theme.commonStyles.flex1]}
      padding={Theme.spacing.l}>
      <Flex
        alignItems="center"
        style={Theme.commonStyles.flex1}
        justifyContent="center">
        <Image source={images['onBoarding-slide4']} style={Styles.slideImage} />
      </Flex>

      <Block style={Styles.lastSlideBodyContainer}>
        <Flex
          backgroundColor={Theme.colors.primary}
          style={Styles.lastSlideBodyContainer}>
          <ScrollView contentContainerStyle={Styles.slideBodyContent}>
            <Flex
              justifyContent="space-between"
              style={Theme.commonStyles.flex1}>
              <View>
                <Text
                  variant="Medium"
                  align="center"
                  color={Theme.colors.white}>
                  {t('onboarding-slide4-title')}
                </Text>
                <Spacer spacing={Theme.spacing.s} />
                <Text variant="Sub" align="center" color={Theme.colors.white}>
                  {t('onboarding-slide4-description')}
                </Text>
              </View>
            </Flex>
          </ScrollView>
          <Button
            title={labelCTA}
            onPress={handleFinishPress}
            style={Styles.buttonCTA}
          />
        </Flex>
      </Block>
      <Spacer spacing={Theme.spacing.m} />
    </Box>
  );
};

const Styles = StyleSheet.create({
  slideContainer: {width: Theme.spacing.window.width, overflow: 'visible'},
  slideImage: {
    width: Theme.spacing.window.width * 0.82,
    height: Theme.spacing.window.width * 0.82,
    resizeMode: 'contain',
  },
  slideBodyContainer: {
    height: Theme.spacing.window.height * 0.25,
  },
  slideBodyContent: {
    padding: Theme.spacing.l,
  },
  lastSlideBodyContainer: {
    height: Theme.spacing.window.height * 0.25 + 72,
  },
  buttonCTA: {
    marginHorizontal: Theme.spacing.l,
    paddingBottom: Theme.spacing.l,
  },
});

export default PushNotificationRequestView;
