import * as React from 'react';
import {FlatList, StyleSheet} from 'react-native';

import {parseDMY} from '../../../../components/DatePicker/utils';
import Flex from '../../../../components/Flex';
import Spacer from '../../../../components/Spacer';
import Theme from '../../../../config/theme';
import {useAppSelector} from '../../../../reducers';
import {isDateTimeInPast} from '../../../../services/helpers/dateHelper';
import {
  PlanningItem,
  PlanningItemType,
  SpaceAllocationType,
} from '../../../../services/pacoApi';
import {AgendaItem} from '../types';

import AddReservationItem from './AddReservationItem';
import AllocationItem from './AllocationItem/AllocationItem';
import NoAllocationItem from './NoAllocationItem';

interface Props {
  item: AgendaItem;
}

const AgendRowContent: React.FC<Props> = ({item: dayPlanning}) => {
  const driverInfo = useAppSelector(state => state.Member.driverInfo);
  const driverHasPriority =
    driverInfo?.spaceAllocationType === SpaceAllocationType.Priority;

  const isEmpty =
    !dayPlanning.items ||
    dayPlanning.items.length === 0 ||
    dayPlanning.items.every(item => item.type === PlanningItemType.Release);

  const isPastDate = isDateTimeInPast(dayPlanning.date);

  const data = React.useMemo(
    () =>
      dayPlanning.items?.filter(item => item.type !== PlanningItemType.Release),
    [dayPlanning.items],
  );

  if (isEmpty) {
    if (isPastDate || !driverHasPriority) {
      return <NoAllocationItem />;
    }

    return <AddReservationItem day={dayPlanning.date} />;
  }

  return (
    <FlatList<PlanningItem>
      contentContainerStyle={Styles.contentContainer}
      ListFooterComponentStyle={Theme.commonStyles.flex1}
      horizontal
      data={data}
      ItemSeparatorComponent={() => (
        <Spacer mode="horizontal" spacing={Theme.spacing.s} />
      )}
      ListFooterComponent={() => {
        if (dayPlanning.isFull || isPastDate || !driverHasPriority) {
          return null;
        }
        return (
          <Flex direction="row" style={[Theme.commonStyles.flex1]}>
            <Spacer mode="horizontal" spacing={Theme.spacing.s} />
            <AddReservationItem day={dayPlanning.date} />
          </Flex>
        );
      }}
      keyExtractor={(_, index) => `${parseDMY(dayPlanning.dmy)}-${index}`}
      renderItem={({item}) => {
        return (
          <AllocationItem
            day={dayPlanning.date}
            allocation={item}
            canBeReleased={!isPastDate}
          />
        );
      }}
    />
  );
};

const Styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
  },
});

export default AgendRowContent;
