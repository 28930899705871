import * as React from 'react';
import {TextInputProps, ViewStyle} from 'react-native';

import {
  getCountryAlpha2,
  getCountryCodeOrDefault,
  getNationalNumber,
} from '../services/phoneUtils';

import Input from './Input';

interface Props {
  value?: string;
  disabled?: boolean;
  disabledBackgroundColor?: string;
  placeholder?: string;
  error?: string;
  rightIconName?: string;
  iconColor?: string;
  showLabel?: boolean;
  label?: string;
  style?: ViewStyle;
  pointerEvents?: 'box-none' | 'none' | 'box-only' | 'auto';

  onBlur?: TextInputProps['onBlur'];
  onFocus?: TextInputProps['onFocus'];
  onSubmitEditing?: () => void;
  onPressIconRight?: () => void;
  onPhoneNumberChange: (
    value: string,
    countryCode?: number,
    number?: number,
  ) => void;

  testID?: string;
}

const PhoneInput: React.FC<Props> = props => {
  const {value, onPhoneNumberChange} = props;

  const countryCode = React.useMemo(() => {
    return getCountryCodeOrDefault(value);
  }, [value]);

  const iconName = React.useMemo(() => {
    return getCountryAlpha2(countryCode)?.toLocaleLowerCase();
  }, [countryCode]);

  return (
    <Input
      {...props}
      keyboardType="phone-pad"
      leftIconName={iconName}
      onChangeText={text => {
        const newCountrycode = getCountryCodeOrDefault(text);
        const newNumber = getNationalNumber(text);
        onPhoneNumberChange(text, newCountrycode, newNumber);
      }}
    />
  );
};

export default PhoneInput;
