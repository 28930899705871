import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';
import NestedText from 'rn-nested-text';

import Theme from '@config/theme';

import Spacer from '@components/Spacer';

const TermsAndConditionsAndPolicyText: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <Spacer mode="vertical" spacing={Theme.spacing.l} />
      <NestedText style={Styles.text}>
        {t('activate-account-terms-and-conditions')}
      </NestedText>
    </>
  );
};

const Styles = StyleSheet.create({
  text: {
    color: Theme.colors.gray,
    fontSize: 12,
    lineHeight: 20,
    fontFamily: Theme.fonts.family.InterMedium,
    textAlign: 'center',
  },
});

export default TermsAndConditionsAndPolicyText;
