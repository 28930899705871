import * as React from 'react';

import {RootNavigationProps} from '@common/navigators';

const TermsAndConditions: React.FC<RootNavigationProps<'TermsAndConditions'>> =
  () => {
    return (
      <embed
        src="https://cdn.flex.zenpark.pro/legal/terms_fr.pdf"
        width="100%"
        height="100%"
      />
    );
  };

export default TermsAndConditions;
