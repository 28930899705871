import appConfig from '../../config/appConfig';

import * as Crashlytics from './crashlytics';
import * as Sentry from './sentry';
import {Severity} from './types';

export default {
  log: (message: string, severity: Severity = 'Info'): void => {
    Crashlytics.log(message);
    Sentry.log(message, severity);
    devLog(message, severity);
  },
  recordError: (error: Error, jsErrorName?: string): void => {
    Crashlytics.recordError(error, jsErrorName);
    Sentry.recordError(error);
    devLog(error.toString(), 'Error');
  },
};

const devLog = (message: string, severity: Severity): void => {
  if (appConfig.isProduction) {
    return;
  }

  switch (severity) {
    case 'Critical':
    case 'Error':
      // eslint-disable-next-line no-console
      console.error(`[${severity}]`, message);
      return;

    case 'Info':
      // eslint-disable-next-line no-console
      console.info(`[${severity}]`, message);
      return;

    case 'Debug':
      // eslint-disable-next-line no-console
      console.debug(`[${severity}]`, message);
      return;

    case 'Warning':
      // eslint-disable-next-line no-console
      console.warn(`[${severity}]`, message);
      return;

    case 'Log':
    default:
      // eslint-disable-next-line no-console
      console.warn('[Log]', message);
      return;
  }
};
