import {LinkingOptions} from '@react-navigation/native';
import {Linking} from 'react-native';

import {getBrazeInitialUrl} from '@services/braze';

import appConfig from '../../config/appConfig';

type Deeplinkconfig = {
  path: string;
  authorized?: boolean;
};

export const deeplinksConfig: Record<string, Deeplinkconfig> = {
  Access: {
    path: 'access',
    authorized: true,
  },
  Account: {
    path: 'account',
    authorized: true,
  },
  Planning: {
    path: 'planning',
    authorized: true,
  },
  ChangePassword: {
    path: 'updatepassword',
    authorized: true,
  },
  ResetPassword: {
    path: 'resetpassword/:data',
  },
  Registration: {
    path: 'welcome/:companyUrn',
  },
  CompanyLogin: {
    path: 'welcome/:companyUrn/login',
  },
  SsoValidate: {
    path: 'sso/validate-ticket/',
  },
  SsoLogin: {
    path: 'sso/login',
  },
  SsoCompanyLogin: {
    path: 'sso/:ssoProvider',
  },
  SsoRedirect: {
    path: 'sso-redirect',
  },
  ActivateAccount: {
    path: 'activate-account',
  },
  PrivacyPolicy: {
    path: 'privacy',
  },
  TermsAndConditions: {
    path: 'terms',
  },
};

export const linkingOptions: LinkingOptions = {
  enabled: true,
  prefixes: appConfig.deeplinkPrefixs,
  config: {
    screens: {
      Home: {
        screens: {
          Access: deeplinksConfig.Access.path,
          Account: deeplinksConfig.Account.path,
          Planning: deeplinksConfig.Planning.path,
        },
      },
      ChangePassword: deeplinksConfig.ChangePassword.path,
      ResetPassword: deeplinksConfig.ResetPassword.path,
      Registration: deeplinksConfig.Registration.path,
      CompanyLogin: deeplinksConfig.CompanyLogin.path,
      SsoRedirect: deeplinksConfig.SsoRedirect.path,
      SsoValidate: deeplinksConfig.SsoValidate.path,
      SsoLogin: deeplinksConfig.SsoLogin.path,
      SsoCompanyLogin: deeplinksConfig.SsoCompanyLogin.path,
      ActivateAccount: deeplinksConfig.ActivateAccount.path,
      PrivacyPolicy: deeplinksConfig.PrivacyPolicy.path,
      TermsAndConditions: deeplinksConfig.TermsAndConditions.path,
    },
  },

  getInitialURL: async () => {
    const notificationDeeplink = await new Promise<string | null>(resolve => {
      getBrazeInitialUrl(url => resolve(url));
    });

    if (notificationDeeplink) {
      return notificationDeeplink;
    }

    return await Linking.getInitialURL();
  },
};
