import * as React from 'react';

import AddVehicle from '@screens/AddVehicle';
import ChangePassword from '@screens/ChangePassword';
import EditReservationForm from '@screens/EditReservationForm';
import EditVehicle from '@screens/EditVehicle';
import Informations from '@screens/Informations';
import NewReservationForm from '@screens/NewReservationForm';
import SsoMarketingOptin from '@screens/SsoMarketingOptin';
import Vehicles from '@screens/Vehicles';

import HomeNavigator from '../../Home';

import type {RootStack} from './utils';

export const renderDriverScreens = (
  Stack: RootStack,
  getTitle: (key: string) => string,
) => {
  return (
    <>
      <Stack.Screen
        name="Home"
        component={HomeNavigator}
        options={{
          title: getTitle('home-navigator-title'),
        }}
      />
      <Stack.Screen
        name="NewReservationForm"
        component={NewReservationForm}
        options={{
          title: getTitle('new-reservation-screen-title'),
        }}
      />
      <Stack.Screen
        name="EditReservationForm"
        component={EditReservationForm}
        options={{
          title: getTitle('edit-reservation-screen-title'),
        }}
      />

      <Stack.Screen
        name="Informations"
        component={Informations}
        options={{
          title: getTitle('informations-screen-title'),
        }}
      />

      <Stack.Screen
        name="Vehicles"
        component={Vehicles}
        options={{
          title: getTitle('vehicles-screen-title'),
        }}
      />
      <Stack.Screen
        name="ChangePassword"
        component={ChangePassword}
        options={{
          title: getTitle('change-password-screen-title'),
        }}
      />
      <Stack.Screen
        name="EditVehicle"
        component={EditVehicle}
        options={{
          title: getTitle('vehicles-edit-vehicle'),
        }}
      />
      <Stack.Screen
        name="SsoMarketingOptin"
        component={SsoMarketingOptin}
        options={{
          title: getTitle('sso-signin-title'),
        }}
      />
      <Stack.Screen
        name="AddVehicle"
        component={AddVehicle}
        options={{
          title: getTitle('vehicles-add-vehicle'),
        }}
      />
    </>
  );
};
