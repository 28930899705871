import testIDs from '@testIDs';
import {useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';
import {useMutation} from 'react-query';
import * as yup from 'yup';

import usePacoApiError from '@services/usePacoApiError';

import Block from '../components/Block';
import Box from '../components/Box';
import Button from '../components/Button';
import Input from '../components/Input';
import Loader from '../components/Loader';
import Popup from '../components/Popup';
import Spacer from '../components/Spacer';
import TextButton from '../components/TextButton';
import appConfig from '../config/appConfig';
import Theme from '../config/theme';
import {RootNavigationProps} from '../navigators';
import {AuthActions, useAppDispatch} from '../reducers';
import {useApi} from '../services/pacoApi';
import LoginLayout from '../shared_layouts/LoginLayout';

type FormValues = {
  username: string;
  password: string;
};

const Login: React.FC<RootNavigationProps<'Login'>> = ({navigation}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const api = useApi();
  const {popupProps: errorPopupProps, handle: handleError} = usePacoApiError();
  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .email(t('invalid-email-format'))
      .required(t('email-required')),
    password: yup.string().required(t('password-required')),
  });

  const {values, errors, touched, setFieldValue, handleSubmit, handleBlur} =
    useFormik<FormValues>({
      initialValues: {
        username: '',
        password: '',
      },
      validationSchema,
      validateOnChange: false,
      validateOnBlur: true,
      onSubmit: () => {
        authenticate();
      },
    });

  const {
    isLoading,
    isSuccess,
    data: apiResponse,
    isError,
    error: apiError,
    mutate: authenticate,
  } = useMutation(async () => {
    return api.Authentication.v1AuthenticationLoginPost({
      email: values.username,
      password: values.password,
    });
  });

  React.useEffect(() => {
    if (isSuccess && apiResponse?.data.accessToken) {
      dispatch(AuthActions.setTicket(apiResponse.data));
    }
  }, [dispatch, isSuccess, apiResponse]);

  React.useEffect(() => {
    if (isError) {
      handleError(apiError);
    }
  }, [isError, apiError, handleError]);

  return (
    <LoginLayout>
      <Block title={t('login')}>
        <Box padding={Theme.spacing.l}>
          <Input
            testID={testIDs.login.email}
            value={values.username}
            keyboardType="email-address"
            autoCapitalize="none"
            onChangeText={value => setFieldValue('username', value)}
            placeholder={t('email-placeholder')}
            error={touched.username ? errors.username : undefined}
            onSubmitEditing={() => handleSubmit()}
            onBlur={handleBlur('username')}
          />
          <Spacer spacing={Theme.spacing.s} />
          <Input
            testID={testIDs.login.password}
            value={values.password}
            onChangeText={value => setFieldValue('password', value)}
            placeholder={t('login-password')}
            error={touched.password ? errors.password : undefined}
            secureTextEntry={true}
            onSubmitEditing={() => handleSubmit()}
            onBlur={handleBlur('password')}
            rightIconName={'Password'}
          />
          <TextButton
            testID={testIDs.login.forgottenPassword}
            onPress={() => navigation.navigate('ForgottenPassword')}
            align="left"
            style={Styles.forgottenPassword}>
            {t('forgotten-password')}
          </TextButton>
          <Spacer spacing={Theme.spacing.xl} />
          <Button
            testID={testIDs.login.submit}
            title={t('login')}
            onPress={() => handleSubmit()}
          />
          <Spacer spacing={Theme.spacing.l} />
          <TextButton
            testID={testIDs.login.sso}
            onPress={() => navigation.navigate('SsoLogin')}>
            {t('sso-signin-title')}
          </TextButton>
        </Box>
      </Block>

      {appConfig.showDevTab && (
        <>
          <Spacer spacing={Theme.spacing.s} />
          <Button
            onPress={() => navigation.navigate('Environment')}
            title={t('env-config')}
          />
        </>
      )}

      <Popup {...errorPopupProps} />
      <Loader isLoading={isLoading} />
    </LoginLayout>
  );
};

const Styles = StyleSheet.create({
  forgottenPassword: {
    fontSize: 14,
  },
});

export default Login;
