import {DateTime} from 'luxon';
import {Platform} from 'react-native';
import {lt as versionLowerThan} from 'semver';

import {version} from '../../../package.json';
import {useAppSelector} from '../../reducers';

export type VersionState = 'OK' | 'WARNING' | 'ERROR';

export function useVersionState(): VersionState | null {
  const configuration = useAppSelector(state => state.App.configuration);
  if (!configuration) {
    return null;
  }

  const isVersionOlderThanMinVersion = versionLowerThan(
    version,
    configuration.appMinimalVersion?.version ?? '',
  );

  const limitDate = configuration.appMinimalVersion?.limitDate;

  if (
    (Platform.OS === 'android' || Platform.OS === 'ios') &&
    isVersionOlderThanMinVersion &&
    limitDate
  ) {
    const isDateOlderThanMinDate = DateTime.now() > DateTime.fromISO(limitDate);

    return isDateOlderThanMinDate ? 'ERROR' : 'WARNING';
  }

  return 'OK';
}
